import { Box, Button, InputLabel, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from "sonner";
import api from "../api";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from "libphonenumber-js";
import TooltipCustom from '../components/TooltipCustom';

const Inbound = ({ assistants, data, updatePhone }) => {
    const [inbound, setInbound] = useState("");
    const [ Fallback, setFallback] = useState("");
    const [error, setError] = useState(""); // Validation error message
   
    const handlePhoneNumberInputChange = (value, country) => {
            if (!value) {
                        setError(`Please enter a phone number for ${country.name}.`);
                        return;
                    }
    
                    try {
                        const phoneNumber = parsePhoneNumberFromString(`+${value.toString()}`, country.countryCode.toUpperCase());    
                        // Check if phone number is valid
                        if (!phoneNumber || !phoneNumber.isValid()) {
                            setError(`Invalid phone number format for ${country.name}.`);
                        } else {
                            setError(""); // Clear error if valid
                        }
                    } catch (error) {
                        setError(`Invalid input. Please check the phone number for ${country.name}.`);
                    }
        setFallback(value);
    };

    const handleAssistantChange = (event) => {
        setInbound(event.target.value);
    };

    const updateInboundSettings = async () => {
        try {
            const token = JSON.parse(localStorage.getItem("token"))?.token;
            if (!token) {
                throw new Error("Token not found");
            }

            const response = await api.put(`/phones/${data?._id}`, {
                InboundAssistantId: inbound,
                Fallback: !Fallback?null:"+"+Fallback,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            toast.success("Inbound assistant updated successfully");
            updatePhone("inbound")
        } catch (error) {
            console.error("Error updating inbound settings:", error);
        }
    };

    useEffect(() => {
        if (data?.InboundAssistantId) {
            setInbound(data.InboundAssistantId);
        }
        if (data?.Fallback) {
            setFallback(data.Fallback);
        }
    }, [data]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem', borderRadius: "5px", bgcolor: "rgba(177, 177, 190, 0.11)", border: "1px solid #A9A9A9" }}>
            <Typography variant="subtitle2" component="h4">
                Inbound Settings
            </Typography>

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                gap:1,
            }}>
                <InputLabel id="phone-label">Inbound Phone Number</InputLabel>
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={data?.PhoneNumber}
                    id="phone-label"
                    placeholder="+17608245111"
                    disabled
                    sx={{ bgcolor: "#FFF" }}
                />
            </Box>

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                gap:1,
            }}>
                <InputLabel id="phone-label">Assistant</InputLabel>
                <TextField
                    labelId="assistant-label"
                    id="assistant"
                    select
                    size="small"
                    value={inbound}
                    onChange={handleAssistantChange}
                    label="Select Assistant"
                    sx={{ bgcolor: "#FFF" }}
                >
                    {assistants?.length > 0 ? (
                        assistants.map((assistant) => (
                            <MenuItem key={assistant._id} value={assistant._id}>
                                {assistant.Name}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="">
                            No assistants available
                        </MenuItem>
                    )}
                </TextField>
           </Box>

           <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}
        >
            <InputLabel 
              sx={{ marginRight: '16px' }} 
              id="phone-label"
            >
              Fallback Number
              <TooltipCustom 
                title={
                  "If the primary contact cannot be reached, calls will be redirected to this fallback number. " +
                  "Please ensure this number is always accessible during business hours."
                }
              />
            </InputLabel>
            <PhoneInput
                country={"us"}
                value={Fallback}
                onChange={(value, country) => handlePhoneNumberInputChange(value, country)} // Validation logic
                id="phone-label"
                inputStyle={{
                    width: '100%',
                    height: '2.5rem',
                    fontSize: '1rem',
                    paddingLeft: '3rem',
                }}
                buttonStyle={{
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px 0 0 4px",
                }}
                inputProps={{
                    name: "PhoneNumber",
                    required: true,
                    label: "Phone Number",
                    'aria-label': 'Phone Number',
                }}
                containerStyle={{
                            width: '100%',
                 }}
            />
            {error && (
               <Typography 
                 variant="body2" 
                 color="error" 
                 sx={{ marginTop: "0.5rem" }}
                 role="alert"
                 id="phone-error"
               >
                 {error}
               </Typography>
            )}
             </Box>
            <Button
                variant="contained"
                sx={{ width: "5rem", bgcolor: "#020429" }}
                onClick={updateInboundSettings}
            >
                Save
            </Button>
       
        </Box>
    );
};

export default Inbound;
