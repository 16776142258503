import React, { useState, useRef } from 'react';
import { Box, Button, Typography, Slider, Dialog } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import AvatarEditor from 'react-avatar-editor';
import { toast, Toaster } from 'sonner';
const EnhancedLogoUpload = ({ 
  previewUrl, 
  onLogoChange, 
  maxFileSize,
  boxColumn = {},
  recommended = "256x256px or larger, PNG or JPEG"
}) => {
  const recommendedText = `${recommended}. Max file size: ${maxFileSize}MB`;
  const [isEditing, setIsEditing] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const editorRef = useRef(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const handleFileSelect = (file) => {
    if (!file) return;
  
    setLoading(true);
    // file processing logic...
  
  
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedTypes.includes(file.type)) {
      toast.error("Please upload only PNG or JPEG files");
      return;
    }
  
    if (file.size > maxFileSize * 1024 * 1024) {
      toast.error(`File size should not exceed ${maxFileSize}MB`);
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (e) => {
      setCurrentImage(e.target.result); // Set the image data
      setIsEditing(true);
    };
    reader.readAsDataURL(file); // Ensure you're passing a valid file (Blob type)
  };

  
  

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  
    const file = e.dataTransfer.files[0]; // Extract file from drop event
    if (file) {
      handleFileSelect(file);
    }
  };

  const handleSave = () => {
    if (!editorRef.current) return;

    const canvas = editorRef.current.getImageScaledToCanvas();
    canvas.toBlob((blob) => {
      const file = new File([blob], 'logo.png', { type: 'image/png' });
      const previewUrl = URL.createObjectURL(blob);

      console.log('Generated File:', file);
      console.log('Generated Preview URL:', previewUrl);

      onLogoChange({
        file,
        previewUrl
      });

      setIsEditing(false);
      toast.success('Logo updated successfully!');
    }, 'image/png');
  };


  const handleLogoUpload = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      handleFileSelect(file);
    }
  };

  const handleDeleteLogo = () => {
    onLogoChange({ file: null, previewUrl: null });
    setCurrentImage(null);
    setScale(1);
    setRotation(0);
  
    // Clear the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  
    // Remove the logo from localStorage
    localStorage.removeItem("organizationLogo");
    localStorage.removeItem("organizationLogoPreview");
  
    toast.success('Logo removed successfully!');
  };
  
  return (
    
    <Box sx={boxColumn}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
        <Typography variant="h6">
          Organization Logo
        </Typography>
        <Typography sx={{fontSize:'1rem', color:'#7C7C83', fontWeight:'500'}}>
          Upload and customize your organization's logo
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
      <Box
        sx={{
          width: 100,
          height: 100,
          bgcolor: 'grey.200',
          borderRadius: '8px',
          mr: 2,
          backgroundImage: previewUrl ? `url(${previewUrl})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          border: isDragging ? '2px dashed #02042D' : '2px dashed transparent',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            backgroundColor: previewUrl ? 'inherit' : '#F8F9FC',
            transform: 'scale(1.1)',
            '& .drag-overlay': {
              opacity: previewUrl ? 0 : 1,
            },
            '& .upload-icon': {
              opacity: 0, // Hide the button on hover
            }
          }
        }}
        onClick={() => fileInputRef.current?.click()}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {!previewUrl && (
          <FileUploadIcon 
            className="upload-icon"
            sx={{ color: 'grey.400', fontSize: '2rem', transition: 'opacity 0.2s ease-in-out' }} 
          />
        )}
    {!previewUrl && (
     <Box
      className="drag-overlay"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(2, 4, 45, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        transition: 'opacity 0.2s ease-in-out',
        borderRadius: '6px',
      }}
    >
      <Typography
        sx={{
          color: '#000000',
          fontSize: '0.75rem',
          textAlign: 'center',
          padding: '0.5rem',
          fontWeight: 500,
        }}
      >
        Drag & Drop or Click to Upload
      </Typography>
    </Box>
  )}
</Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, flex: 1 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="outlined"
              component="label"
              startIcon={<FileUploadIcon />}
              sx={{
                borderRadius: "8px",
                borderColor: "#E5E7EB",
                color: "#02042D",
                fontWeight: "500",
                padding: "10px 16px",
                textTransform: "none",
                "&:hover": {
                  borderColor: "#E5E7EB",
                  backgroundColor: "#F8F9FC",
                },
                "&:focus": {
                  borderColor: "#E5E7EB",
                },
              }}
            >
              {previewUrl ? 'Change Logo' : 'Upload New Logo'}
              <input
                type="file"
                hidden
                accept="image/png,image/jpeg"
                onChange={handleLogoUpload}
                ref={fileInputRef}
              />
            </Button>
            
            {previewUrl && (
              <Button
                variant="outlined"
                onClick={handleDeleteLogo}
                startIcon={<DeleteIcon />}
                sx={{
                  borderRadius: "8px",
                  borderColor: "#dc2626",
                  color: "#dc2626",
                  fontWeight: "500",
                  padding: "10px 16px",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "#dc2626",
                    backgroundColor: "rgba(220, 38, 38, 0.04)",
                  },
                }}
              >
                Remove
              </Button>
            )}
          </Box>
          <Typography sx={{fontSize:'0.75rem', color: "#A1A1B0", fontWeight: "500"}}>
            {recommendedText}
          </Typography>
        </Box>
      </Box>

      <Dialog 
        open={isEditing} 
        onClose={() => setIsEditing(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
            bgcolor: '#FFFFFF',
          }
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 3, color: '#02042D', fontWeight: '600' }}>
            Edit Logo
          </Typography>
          
          <Box sx={{ 
            position: 'relative',
            display: 'flex', 
            justifyContent: 'center', 
            mb: 3,
            '.react-avatar-editor': {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#F8F9FC',
            }
          }}>
            <AvatarEditor
              ref={editorRef}
              image={currentImage}
              width={250}
              height={250}
              border={50}
              borderRadius={8}
              color={[0, 0, 0, 0.4]}
              scale={scale}
              rotate={rotation}
              imagePosition={{ x: 0.5, y: 0.5 }} // Ensures the image stays centered
            />
            <GridOverlay />
            
            <Box sx={{
              position: 'absolute',
              top: 50,
              left: 50,
              right: 50,
              bottom: 50,
              border: '2px dashed rgba(255, 255, 255, 0.5)',
              borderRadius: '4px',
              pointerEvents: 'none',
            }} />
          </Box>
              
          <Box sx={{ mb: 4 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 1
            }}>
              <Typography sx={{ color: '#02042D', fontWeight: '500' }}>
                Zoom
              </Typography>
              <Typography sx={{ color: '#7C7C83', fontSize: '0.875rem' }}>
                {Math.round(scale * 100)}%
              </Typography>
            </Box>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 2,
              px: 1
            }}>
              <ZoomOutIcon sx={{ color: '#7C7C83' }} />
              <Slider
                value={scale}
                min={1}
                max={3}
                step={0.1}
                onChange={(_, value) => setScale(value)}
                sx={{
                  color: '#02042D',
                  '& .MuiSlider-thumb': {
                    width: 14,
                    height: 14,
                    '&:hover, &.Mui-focusVisible': {
                      boxShadow: '0 0 0 8px rgba(2, 4, 45, 0.1)',
                    },
                  },
                  '& .MuiSlider-rail': {
                    opacity: 0.3,
                  },
                }}
              />
              <ZoomInIcon sx={{ color: '#7C7C83' }} />
            </Box>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            justifyContent: 'flex-end',
            '& .MuiButton-root': {
              minWidth: '120px',
              fontWeight: '500',
            }
          }}>
            <Button
              variant="outlined"
              onClick={() => setIsEditing(false)}
              sx={{
                borderColor: '#E5E7EB',
                color: '#02042D',
                '&:hover': {
                  borderColor: '#E5E7EB',
                  backgroundColor: '#F8F9FC',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              sx={{
                backgroundColor: '#02042D',
                '&:hover': {
                  backgroundColor: '#080A3C',
                },
              }}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

const GridOverlay = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 50,
      left: 50,
      right: 50,
      bottom: 50,
      pointerEvents: 'none',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: '33.33%',
        height: '100%',
        width: '1px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: '66.66%',
        height: '100%',
        width: '1px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
      },
      '.horizontal-line': {
        position: 'absolute',
        left: 0,
        width: '100%',
        height: '1px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
      }
    }}
  >
    <Box className="horizontal-line" sx={{ top: '33.33%' }} />
    <Box className="horizontal-line" sx={{ top: '66.66%' }} />
  </Box>
);

export default EnhancedLogoUpload;