import React, { useEffect, useState } from 'react';
import { Box, TextField, Select, MenuItem, FormControl, Typography, Grid } from '@mui/material';
import TooltipCustom from "../TooltipCustom"

const AdditionalConfigurations = () => {
    const [token, setToken] = useState(0);
    const [interruption, setInterruption] = useState("low");
    const [cutoff, setCutoff] = useState(0);
    const [idealTime, setIdealTime] = useState(0);
    const [callEndFlag, setCallEndFlag] = useState(0);
    const [transferPhoneNumber, setTransferPhoneNumber] = useState("");
    const [endConversationOnTransfer, setEndConversationOnTransfer] = useState(0);
    const [numOfHumanCheck, setNumOfHumanCheck] = useState(0);
    const [initialMsgDelay, setInitialMsgDelay] = useState(0);
    const [endConversationOnGoodbye, setEndConversationOnGoodbye] = useState(0);
    const [cutOffResponse, setCutOffResponse] = useState('');
    const [fallback, setFallback] = useState('');

    const fetchToken = async () => {
        const t = await JSON.parse(localStorage.getItem("token")).token;
        setToken(t);
    };

    const fetchSetting = async () => {
        const storedAssistant = JSON.parse(localStorage.getItem("assistant")) || {};

        setInterruption(storedAssistant.Interruption || "low");
        setCutoff(storedAssistant.Cutoff || 0);
        setIdealTime(storedAssistant.IdealTime || 0);
        setCallEndFlag(storedAssistant.CallEndFlag || 0);
        setTransferPhoneNumber(storedAssistant.TransferPhoneNumber || "");
        setEndConversationOnTransfer(storedAssistant.EndConversationOnTransfer || 0);
        setNumOfHumanCheck(storedAssistant.NumOfHumanCheck || 0);
        setInitialMsgDelay(storedAssistant.InitialMsgDelay || 0);
        setEndConversationOnGoodbye(storedAssistant.EndConversationOnGoodbye || 0);
        setCutOffResponse(storedAssistant.CutOffResponse || '');
        setFallback(storedAssistant.Fallback || '');
    }

    useEffect(() => {
        fetchToken();
        fetchSetting()
    }, []);

    const saveToLocalStorage = (key, value) => {
        // Get the current assistant object or create a new one
        const assistant = JSON.parse(localStorage.getItem("assistant")) || {};
        assistant[key] = value;

        // Save the updated assistant object back to localStorage
        localStorage.setItem("assistant", JSON.stringify(assistant));
    };

    return (
        <Grid container spacing={2} style={{ minHeight: 'calc(100vh - 4rem)' }}>
            <Grid item xs={12} md={12} lg={6}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem", bgcolor: "rgba(177, 177, 190, 0.11)", padding: "1rem", borderRadius: "0.5rem" }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1">Interruption Level <TooltipCustom title="This setting determines the degree to which Lia can interrupt a speaker based on conversational context. A high interruption level means Lia can cut in more actively to ensure smoother dialogue flow, especially if it detects urgency or overlap in user input." /> </Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={interruption}
                                onChange={(e) => { setInterruption(e.target.value); saveToLocalStorage('Interruption', e.target.value) }}
                                sx={{ bgcolor: "white" }}
                            >
                                <MenuItem value="Low">Low</MenuItem>
                                <MenuItem value="Medium">Medium</MenuItem>
                                <MenuItem value="High">High</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1">Cutoff Flag <TooltipCustom title="The Cutoff Flag is a signal indicating when the assistant should stop listening or responding. It’s activated when a user tries to cut short an ongoing response from Lia, allowing immediate attention to the user’s input or redirection to a new topic." /> </Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={cutoff}
                                onChange={(e) => { setCutoff(e.target.value); saveToLocalStorage('Cutoff', e.target.value) }}
                                sx={{ bgcolor: "white" }}
                            >
                                <MenuItem value={0}>No</MenuItem>
                                <MenuItem value={1}>Yes</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1">Ideal Time (in seconds)<TooltipCustom title="This is the maximum period of silence Lia tolerates before prompting the user again. Idle Time settings help prevent conversation lags and keep interactions efficient by prompting the user if there’s prolonged silence." /> </Typography>
                        <TextField
                            size='small'
                            fullWidth
                            variant="outlined"
                            type="number"
                            value={idealTime}
                            onChange={(e) => { setIdealTime(e.target.value); saveToLocalStorage('IdealTime', e.target.value) }}
                            sx={{ bgcolor: "white" }}
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>

                <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem", bgcolor: "rgba(177, 177, 190, 0.11)", padding: "1rem", borderRadius: "0.5rem" }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1">End Conversation on Goodbye <TooltipCustom title="When this setting is active, Lia will automatically end the session if a user says goodbye or any similar farewell phrase. It streamlines the conversation flow by reducing unnecessary prompts after a clear end signal." /></Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={endConversationOnGoodbye}
                                onChange={(e) => { setEndConversationOnGoodbye(e.target.value); saveToLocalStorage("EndConversationOnGoodbye", e.target.value) }}
                                sx={{ bgcolor: "white" }}
                            >
                                <MenuItem value={0}>No</MenuItem>
                                <MenuItem value={1}>Yes</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1">Number of Human Checks <TooltipCustom title="This indicates the threshold of user inputs or complex queries Lia processes before suggesting escalation to a human agent. It’s useful for ensuring high-quality support on intricate issues." /></Typography>
                        <TextField
                            size='small'
                            fullWidth
                            variant="outlined"
                            type="number"
                            value={numOfHumanCheck}
                            onChange={(e) => { setNumOfHumanCheck(e.target.value); saveToLocalStorage("NumOfHumanCheck", e.target.value) }}
                            sx={{ bgcolor: "white" }}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1">Cut Off Response <TooltipCustom title="This is Lia’s behavior for handling interruptions mid-response. With this setting, Lia can quickly pause, evaluate new user input, and either pivot to the updated query or resume where it left off, based on conversational relevance." /></Typography>
                        <TextField
                            size='small'
                            fullWidth
                            variant="outlined"
                            value={cutOffResponse}
                            onChange={(e) => { setCutOffResponse(e.target.value); saveToLocalStorage("CutOffResponse", e.target.value) }}
                            sx={{ bgcolor: "white" }}
                        />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default AdditionalConfigurations;
