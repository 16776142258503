import { useState, useCallback } from 'react';
import api from '../../api';

export const useGetAllPermissions = () => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [token] = useState(() => {
    const tokenData = localStorage.getItem("token");
    return tokenData ? JSON.parse(tokenData).token : null;
  });

  const getAllPermissions = useCallback(async () => {
    if (!token) return;

    setLoading(true);
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await api.get('/v1/users/permissions', config);
      setPermissions(response.data.permissions);
    } catch (error) {
      console.error('Error fetching permissions:', error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  return { permissions, permissionsLoading:loading, getAllPermissions };
};
