// CustomAppBar.js
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, TextField, Select, MenuItem, FormControl, Box, IconButton, InputAdornment } from '@mui/material';
import { Menu } from "@mui/icons-material";

const Navbar = ({ title, searchOptions = [], onSearchOptionChange = () => { }, onSearchValueChange = () => { }, searchOption = '', searchValue = '' }) => {

  // Sidebar state, initialized from localStorage
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(() => {
    return localStorage.getItem('sidebarOpen') === 'true';
  });


  const toggleSidebar = () => {
    const newState = !isSidebarOpen; // Toggle the state
    setIsSidebarOpen(newState); // Update React state
    localStorage.setItem('sidebarOpen', newState); // Update localStorage
  };

  useEffect(() => {
    // Sync state with localStorage when component mounts
    const savedState = localStorage.getItem('sidebarOpen') === 'true';
    if (savedState !== isSidebarOpen) {
      setIsSidebarOpen(savedState);
    }

    // Listen for localStorage changes (e.g., from other tabs)
    const handleStorageChange = (e) => {
      if (e.key === 'sidebarOpen') {
        setIsSidebarOpen(e.newValue === 'true');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [ isSidebarOpen ]);

  return (
    <AppBar position="static" className='appBar' sx={{ boxShadow: 0, borderBottom: "1px solid #F0F0F0" }}>
      <Box
        sx={{
          backgroundColor: '#a84e47',
          color: 'white',
          textAlign: 'center',
          padding: '0.5rem',
          fontSize: '0.9rem',
        }}
      >
        Dashboard is under maintenance until 15 Dec 2024 09:00 PM IST. Please email lakshya@liaplus.com for any queries
      </Box>
      <Toolbar className='toolBar'>
        <Box style={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', flexGrow: 1 }}>
          <IconButton
            size="large"
            edge="start"
            color="#000"
            aria-label="menu"
            sx={{ mr: 0 }}
            onClick={toggleSidebar}
          >
            <Menu />
          </IconButton>
          <Typography variant='h5' color="#000">{title}</Typography>
        </Box>

        {searchOptions.length > 0 && (
          <Box style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'flex-end', maxWidth: '400px' }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchValue}
              onChange={onSearchValueChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FormControl variant="outlined" size="small">
                      <Select
                        value={searchOption}
                        onChange={onSearchOptionChange}
                        size="small"
                        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 'none' } }}  // Remove the border for the select box
                      >
                        <MenuItem value="All">
                          Search By
                        </MenuItem>
                        {searchOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </InputAdornment>
                ),
              }}
              sx={{ flexGrow: 1 }}
            />
          </Box>

        )}
      </Toolbar>

    </AppBar>
  );
};

export default Navbar;
