import React, { useState } from "react";
import { MoreVert } from "@mui/icons-material";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { ResponsiveContainer } from "recharts";
import { toast } from "sonner";
import ShimmerLoader from './ShimmerLoader';

export const ChartContainer = ({ title, subtitle, chart, download, loading }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    return (
        <div>
            <Box sx={{ border: "1px solid #A9A9A9", padding: "1rem", borderRadius: ".8rem", height: "fit-content", overflow: "hidden", bgcolor: "#FFF", paddingLeft:"24px"}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:"16px"}}>
                    <Box>
                        <Typography variant="h6" component="div">{title}</Typography>
                        <Typography sx={{ color: "#6A6A6A", fontSize: "0.8rem" }}>{subtitle}</Typography>
                    </Box>
                    {!loading && <MoreVert sx={{ cursor: "pointer" }} onClick={(e) => setAnchorEl(e.currentTarget)} />}
                </Box>
                <ResponsiveContainer width="100%" height={320}>
                    {loading ? <ShimmerLoader height="100%" /> : chart}
                </ResponsiveContainer>
            </Box>

            {download && !loading && (
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            toast.promise(download, {
                                loading: "Downloading CSV File",
                                success: "CSV File Downloaded Successfully",
                                error: ({ error, message }) => error || "Error downloading CSV File",
                            });
                            setAnchorEl(null);
                        }}
                    >
                        Download CSV
                    </MenuItem>
                </Menu>
            )}
        </div>
    )
}
