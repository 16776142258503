import { Box } from '@mui/material'
import React from 'react'
import Navbar from '../components/Navbar'
import AssistantForm from '../components/AssistantForm'

const CreateAssistant = () => {
    return (
        <Box>
            <Navbar
                title="Create Assistant"
            />
            <AssistantForm/>
        </Box>
    )
}

export default CreateAssistant