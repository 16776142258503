import React, { useEffect, useState } from 'react';
import api from "../../api";
import { Box, Select, MenuItem, FormControl, Typography, Slider, Grid } from '@mui/material';
import Loader from '../Loader';
import TooltipCustom from '../TooltipCustom';

const TranscriberAndAudio = () => {
    const [token, setToken] = useState("");
    const [transcriberProvider, setTranscriberProvider] = useState("");
    const [transcriber, setTranscriber] = useState("");
    const [transcriberLanguage, setTranscriberLanguage] = useState("");
    const [voiceProvider, setVoiceProvider] = useState("");
    const [voice, setVoice] = useState("");
    const [voiceLanguage, setVoiceLanguage] = useState("");
    const [filterAudio, setFilterAudio] = useState(false);
    const [voiceSpeed, setVoiceSpeed] = useState(0.3);

    const [transcriberProviderList, setTranscriberProviderList] = useState([]);
    const [transcriberList, setTranscriberList] = useState([]);
    const [transcriberLanguageList, setTranscriberLanguageList] = useState([]);
    const [voiceProviderList, setVoiceProviderList] = useState([]);
    const [voiceList, setVoiceList] = useState([]);
    const [voiceLanguageList, setVoiceLanguageList] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchToken = async () => {
            const t = await JSON.parse(localStorage.getItem("token")).token;
            setToken(t);
        };
        fetchToken();

        // Fetch existing settings from local storage or initialize to default
        if (token) {
            const storedSettings = JSON.parse(localStorage.getItem("assistant")) || {};
            setTranscriberProvider(storedSettings.TranscriberProvider || "");
            if (storedSettings.TranscriberProvider) {
                fetchTrascriberByTranscriberProvider(storedSettings.TranscriberProvider)
            }
            setTranscriber(storedSettings.Transcriber || "");
            if (storedSettings.Transcriber) {
                fetchTrascriberLangaugeByTranscriber(storedSettings.Transcriber)
            }
            setTranscriberLanguage(storedSettings.TranscriberLanguage || "");
            setVoiceProvider(storedSettings.VoiceProvider || "");
            if (storedSettings.VoiceProvider) {
                fetchVoiceList(storedSettings.VoiceProvider)
            }
            setVoice(storedSettings.Voice || "");
            if (storedSettings.Voice) {
                fetchVoiceLanguageByVoice(storedSettings.Voice)
            }
            setVoiceLanguage(storedSettings.VoiceLanguage || "");
            setFilterAudio(storedSettings.FilterAudio !== undefined ? storedSettings.FilterAudio : false);
            setVoiceSpeed(storedSettings.VoiceSpeed !== undefined ? storedSettings.VoiceSpeed : 0.3);

        }
    }, [token]);

    useEffect(() => {
        if (token) {
            fetchTranscriberProviderList();
            fetchVoiceProviderList();
        }
    }, [token]);

    const fetchTranscriberProviderList = async () => {
        const response = await api.get("/transcriber-models?limit=999", {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
        setTranscriberProviderList(response.data.data.transcriberProviders);
    };

    const fetchTrascriberByTranscriberProvider = async (provider_id) => {
        const response = await api.post("/transcribers/transcribers-by-model", { TranscriberModelId: provider_id }, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
        setTranscriberList(response.data.data);
    };

    const fetchTrascriberLangaugeByTranscriber = async (provider_id) => {
        const response = await api.post("/transcriber-languages/languages-by-transcriber", { TranscriberId: provider_id }, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
        setTranscriberLanguageList(response.data);
    };


    const fetchVoiceProviderList = async () => {
        const response = await api.get("/voice-provider", {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
        setVoiceProviderList(response.data.data.voiceProviders);
    }
    const fetchVoiceList = async (provider_id) => {
        try {
            const response = await api.post("/voice-synthesizers/synthesizers-by-provider",
                { VoiceProviderId: provider_id },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            );
            setVoiceList(response.data);
        } catch (error) {
            console.error('Error fetching voice list:', error);
            // You can also set an error state here if needed
        }
    };

    const fetchVoiceLanguageByVoice = async (provider_id) => {
        try {
            const response = await api.post("/voice-languages/languages-by-synthesizer",
                { VoiceSynthesizerId: provider_id },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            );
            console.log(response.data);
            setVoiceLanguageList(response.data);

        } catch (error) {
            console.error('Error fetching voice list:', error);
            // You can also set an error state here if needed
        }
    };

    const saveToLocalStorage = (key, value) => {
        // Get the current assistant object or create a new one
        const assistant = JSON.parse(localStorage.getItem("assistant")) || {};
        assistant[key] = value;

        // Save the updated assistant object back to localStorage
        localStorage.setItem("assistant", JSON.stringify(assistant));
    };

    if (loading) {
        return <Loader />
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem", bgcolor: "rgba(177, 177, 190, 0.11)", padding: "1rem", borderRadius: "0.5rem" }}>
                    <Typography variant="h5">Transcriber</Typography>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Provider <TooltipCustom title="The service or platform supplying either the transcription (Speech to Text) or voice synthesis (Text to Speech) technology. " /></Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={transcriberProvider}
                                onChange={(e) => { setTranscriberProvider(e.target.value); saveToLocalStorage("TranscriberProvider", e.target.value); fetchTrascriberByTranscriberProvider(e.target.value); }}
                                sx={{ bgcolor: "white" }}
                            >
                                {
                                    transcriberProviderList && transcriberProviderList.length > 0 && transcriberProviderList.map((transcriberProviderValue, key) => (
                                        <MenuItem value={transcriberProviderValue._id} key={key}>{transcriberProviderValue.TranscriberModelName}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Model <TooltipCustom title="The specific language model used to generate the assistant’s responses, which impacts the assistant's conversational abilities, accuracy, and response quality." /></Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={transcriber}
                                onChange={(e) => { setTranscriber(e.target.value); saveToLocalStorage("Transcriber", e.target.value); fetchTrascriberLangaugeByTranscriber(e.target.value); }}
                                sx={{ bgcolor: "white" }}
                            >
                                {
                                    transcriberList && transcriberList.length > 0 && transcriberList.map((transcriber, key) => (
                                        <MenuItem value={transcriber._id} key={key}>{transcriber.TranscriberName}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Language <TooltipCustom title="The specific language model used to generate the assistant’s responses, which impacts the assistant's conversational abilities, accuracy, and response quality." /></Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={transcriberLanguage}
                                onChange={(e) => { setTranscriberLanguage(e.target.value); saveToLocalStorage("TranscriberLanguage", e.target.value); }}
                                sx={{ bgcolor: "white" }}
                            >
                                {
                                    transcriberLanguageList && transcriberLanguageList.length > 0 && transcriberLanguageList.map((transcriberLanguage, key) => (
                                        <MenuItem value={transcriberLanguage._id} key={key}>{transcriberLanguage.Name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>

                <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem", bgcolor: "rgba(177, 177, 190, 0.11)", padding: "1rem", borderRadius: "0.5rem" }}>
                    <Typography variant="h5">Voice</Typography>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Provider <TooltipCustom title="The service or platform supplying either the transcription (Speech to Text) or voice synthesis (Text to Speech) technology. " />  </Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={voiceProvider}
                                onChange={(e) => { setVoiceProvider(e.target.value); saveToLocalStorage("VoiceProvider", e.target.value); fetchVoiceList(e.target.value) }}
                                sx={{ bgcolor: "white" }}
                            >
                                {
                                    voiceProviderList && voiceProviderList.length > 0 && voiceProviderList.map((voiceProvider_, key) => (
                                        <MenuItem value={voiceProvider_._id} key={key}>{voiceProvider_.Name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Model <TooltipCustom title="The specific language model used to generate the assistant's responses, which impacts the assistant's conversational abilities, accuracy, and response quality." /></Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={voice}
                                onChange={(e) => { setVoice(e.target.value); saveToLocalStorage("Voice", e.target.value); fetchVoiceLanguageByVoice(e.target.value) }}
                                sx={{ bgcolor: "white" }}
                            >
                                {
                                    voiceList && voiceList.length > 0 && voiceList.map((voice_, key) => (
                                        <MenuItem value={voice_._id} key={key}>{voice_.VoiceName}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Language <TooltipCustom title="The primary language and dialect setting for both input (user's speech) and output (assistant's responses), such as English (India) or English (UK)." /></Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={voiceLanguage}
                                onChange={(e) => { setVoiceLanguage(e.target.value); saveToLocalStorage("VoiceLanguage", e.target.value) }}
                                sx={{ bgcolor: "white" }}
                            >
                                {
                                    voiceLanguageList && voiceLanguageList.length > 0 && voiceLanguageList.map((voice_) => (
                                        <MenuItem value={voice_._id}>{voice_.Name}</MenuItem>

                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Background Sound <TooltipCustom title="An option to add or remove background noise or ambiance during interactions, used to make the assistant's responses sound more realistic or immersive." /></Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={filterAudio ? 1 : 0}
                                onChange={(e) => { setFilterAudio(e.target.value === "1"); saveToLocalStorage("FilterAudio", e.target.value) }}
                                sx={{ bgcolor: "white" }}
                            >
                                <MenuItem value="0">No</MenuItem>
                                <MenuItem value="1">Yes</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Speed of Voice <TooltipCustom title="The pace at which the assistant speaks, allowing customization to match user preferences for either a fast-paced or a slower, more measured tone." /></Typography>
                        <Box display="flex" alignItems="center">
                            <Slider
                                value={voiceSpeed}
                                onChange={(e, newValue) => { setVoiceSpeed(newValue); saveToLocalStorage("SpeedOfText", newValue) }}
                                min={0}
                                max={1}
                                step={0.1}
                                valueLabelDisplay="auto"
                                sx={{
                                    color: "#02042D",
                                }}
                            />
                            <div style={{ backgroundColor: "#02042D", color: "white", borderRadius: "8px", marginLeft: "1rem", width: "2rem", height: "2rem", display: "flex", alignItems: "center", justifyContent: "center", }}>
                                {voiceSpeed}
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>

    );
};

export default TranscriberAndAudio;