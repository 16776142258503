import { Box, Typography } from '@mui/material'
import React from 'react'

const Title = ({ title, description }) => {
    return (
        <Box>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="p" color="#8A8A8A">{description}</Typography>
        </Box>
    )
}

export default Title