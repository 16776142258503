import api from '../../api'; 

export const fetchConfig = async (configKeys, callback) => {
    try {
        const data = await checkTokenAndFetchData('/v1/users/permissions/getconfig', configKeys);
        callback(data);
    } catch (error) {
        console.error(`Error fetching config for keys: ${configKeys}`, error);
        // Optionally handle errors globally or pass them to the callback
    }
};

export const fetchAssistantConfig = async (configKeys, callback) => {
    try {
        // Fetch the token from local storage
        const data = await checkTokenAndFetchData('/v1/users/permissions/assistantconfig', configKeys);
        callback(data);
    } catch (error) {
        console.error(`Error fetching config for keys: ${configKeys}`, error);
        // Optionally handle errors globally or pass them to the callback
    }
};

async function checkTokenAndFetchData(apiPath, configKeys) {
    const token = JSON.parse(localStorage.getItem("token")).token ; // Ensure you store your token with the key 'authToken'

        if (!token) {
            throw new Error('No token found in local storage.');
        }

        // Make the API call
        const {data:{data}} = await api.post(apiPath, {
            configKeys,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (data) {
            return data;
        }
}
