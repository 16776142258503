import React, { useState } from 'react';
import { CallMade, CallReceived, Close } from '@mui/icons-material';
import { Box, Card, CardContent, Modal, Typography } from '@mui/material';
import ShimmerLoader from './ShimmerLoader';

const DataCard = ({ value, icons, inc = false, title, change, subtitle, chart, loading }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Card
                sx={{ cursor: "pointer", border: "1px solid #CCCCCC", borderRadius: "1rem", padding: 0, boxShadow: 0, bgcolor: "#FFF" }}
                onClick={() => !loading && setOpen(true)}
            >
                <CardContent
                    style={{
                        display: "flex", flexDirection: "column",
                        gap: ".5rem", padding: "12px 15px"
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex', alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography variant="subtitle1" color="#02042d" sx={{ textWrap: "nowrap" }}>
                            {title}
                        </Typography>
                        {icons}
                    </Box>

                    {loading ? (
                        <ShimmerLoader height="47px" />
                    ) : (
                        <h1 style={{ color: "#020429", fontSize: "47px" }}>
                            {value || 0}
                        </h1>
                    )}

                    {loading ? (
                        <ShimmerLoader height="25px" />
                    ) : (
                        <Box
                            style={{
                                display: "flex", alignItems: "center", gap: "5px",
                                color: inc ? "#00BF6F" : "#D11F12"
                            }}
                        >
                            {inc ? <CallMade style={{ fontSize: '25px' }} /> : <CallReceived style={{ fontSize: '25px' }} />}

                            <Typography variant="body1"
                                color="text.secondary"
                                sx={{ color: inc ? "#00BF6F" : "#D11F12", fontSize: "0.9rem" }}
                            >
                                {change}
                            </Typography>

                        </Box>
                    )}
                </CardContent>
            </Card>

            {open && (
                <Modal open={open} onClose={() => setOpen(false)}>
                    <Box sx={{ width: "100vw", height: "100vh", backdropFilter: "blur(10px)" }}>
                
                        <Box sx={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", gap: "1rem", bgcolor: "#FFF", padding: "2rem", borderRadius: "8px" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Box>
                                    <Typography variant="h6" color="text.secondary" sx={{ color: "#020429" }}>
                                        {title}
                                    </Typography>

                                    <Typography sx={{ color: "#6A6A6A" }}>
                                        {subtitle}
                                    </Typography>
                                </Box>

                                <Close onClick={() => setOpen(false)} sx={{ cursor: "pointer" }} />

                            </Box>

                            {loading ? <ShimmerLoader height="300px" /> : chart}
                        </Box>
                    </Box>
                </Modal>
            )}
        </>
    )
}

export default DataCard;