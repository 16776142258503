import React from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip } from 'recharts';
import PropTypes from 'prop-types';

export const CustomRadarChart = ({ data }) => {
  CustomRadarChart.propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      })
    ).isRequired,
  };
  // Format the data and calculate the maximum value
  const formattedData = data.map(item => ({
    ...item,
    name: item.name === "Call Abandonment" ? "Call Abandonment Rate" : item.name,
    value: Number.parseFloat(item.value || 0).toFixed(2), // Ensures proper decimal formatting with fallback
  }));

  const maxValue = data?.length 
  ? Math.max(...formattedData.map(item => Number(item.value)), 100)
 : 100;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={formattedData}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" fontSize={13} />
        {/* Set the maximum value dynamically */}
        <PolarRadiusAxis angle={40} domain={[0, maxValue]} />
        <Radar dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
        <Tooltip />
      </RadarChart>
    </ResponsiveContainer>
  );
};
