import React, { useState, useEffect } from 'react';
import { 
    Box,
    TextField,
    Typography,
    Alert, 
    AlertTitle,
    IconButton } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import api from "../../api";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TooltipCustom from '../TooltipCustom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "../../styles/generatePrompt.css";
import { PROMPT_VALIDATION } from "../../config/validation";
import { useCategoryContent } from '../GeneratePrompt/CategoryContent';



const GeneratePrompt = ({ token, onPromptGenerated, setPrompt, saveToLocalStorage }) => {
    const [generateKeywords, setGenerateKeywords] = useState('');
    const [generatingPrompt, setGeneratingPrompt] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [hasGenerated, setHasGenerated] = useState(false);
    const [showFallback, setShowFallback] = useState(false);
    const [category, setCategory] = useState('general');

    const categoryContent = useCategoryContent();
    useEffect(() => {
        const assistantData = localStorage.getItem('assistant');
        let categoryValue = 'general';
        if (assistantData) {
            try {
                const { Category } = JSON.parse(assistantData);
                const trimmedCategory = Category?.trim() || 'general';
                categoryValue = categoryContent[trimmedCategory] ? trimmedCategory : 'general';
            } catch (error) {
                console.warn("Invalid 'assistant' data", error);
            }
        }
        setCategory(categoryValue);
    }, [categoryContent]);
   
    

    const getContent = () => {
        // Check if the category is 'general', and provide default content
        return category === 'general' ? {
            tooltip: "Enter specific context like department, platform, company name, or use case details",
            examples: [
                "Describe your specific use case",
                "Include relevant business context",
                "Specify target audience"
            ],
            fallback: "Please provide more specific context for your assistant"
        } : categoryContent[category] || {}; // Return content from categoryContent or an empty object if not found
    };

    const getFallbackMessage = (input) => {
        const content = getContent();
        
        // Check empty input
        if (!input.trim()) {
            return (
                <Box>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        {PROMPT_VALIDATION.MESSAGES.EMPTY}
                        <br />
                        {content.fallback}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Try these examples:
                    </Typography>
                    <Box component="ul" sx={{ mt: 1, pl: 2 }}>
                        {(content.examples || []).map((example, index) => (
                            <li key={index} style={{ marginBottom: '4px' }}>{example}</li>
                        ))}
                    </Box>
                </Box>
            );
        }
    
        // Check minimum length
        if (input.length < PROMPT_VALIDATION.MINIMUM_INPUT_LENGTH) {
            return (
                <Box>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        {PROMPT_VALIDATION.MESSAGES.TOO_SHORT(PROMPT_VALIDATION.MINIMUM_INPUT_LENGTH)}
                        <br />
                        {content.fallback}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        Example: {content.examples[0]}
                    </Typography>
                </Box>
            );
        }
    
        // Check maximum length
        if (input.length > PROMPT_VALIDATION.MAXIMUM_INPUT_LENGTH) {
            return PROMPT_VALIDATION.MESSAGES.TOO_LONG(PROMPT_VALIDATION.MAXIMUM_INPUT_LENGTH);
        }
    
        // Check for invalid characters
        if (PROMPT_VALIDATION.PATTERNS.SPECIAL_CHARS.test(input)) {
            return PROMPT_VALIDATION.MESSAGES.INVALID_CHARS;
        }
    
        // Check for formatting issues
        if (PROMPT_VALIDATION.PATTERNS.CONSECUTIVE_SPACES.test(input)) {
            return PROMPT_VALIDATION.MESSAGES.FORMATTING;
        }
    
        return content.fallback;
    };
    
    const handleGeneratePrompt = async () => {
        if (!generateKeywords.trim()) {
            setShowFallback(true);
            return;
        }
        
        setGeneratingPrompt(true);
        setError('');
        setSuccess('');
        setShowFallback(false);

        try {
            const response = await api.post("/ai-prompt/generate-prompt", {
                context: generateKeywords
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            if (response.data?.success && response.data?.data?.prompt) {
                setPrompt(response.data.data.prompt);
                saveToLocalStorage("Prompt", response.data.data.prompt);
                setSuccess('Prompt generated successfully!');
                setHasGenerated(true);
            }
        } catch (error) {
            console.error("Error generating prompt:", error);
            const errorMessage = error.response?.data?.suggestion
                || error.response?.data?.details
                || error.response?.data?.message
                || 'Please provide more specific context for generating an accurate prompt.';
            console.error('Prompt generation failed:', {
                status: error.response?.status,
                message: errorMessage,
                error
            });
            setError(errorMessage);
        } finally {
            setGeneratingPrompt(false);
        }
    };

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        
        // Prevent input if it would exceed maximum length
        if (newValue.length > PROMPT_VALIDATION.MAXIMUM_INPUT_LENGTH) {
            setError(
                      PROMPT_VALIDATION.MESSAGES.TOO_LONG(
                        PROMPT_VALIDATION.MAXIMUM_INPUT_LENGTH
                      )
                    );
            return;
        }
        
        setGenerateKeywords(newValue);
        setError('');
        setSuccess('');
        setShowFallback(false);
        if (!newValue.trim()) {
            setHasGenerated(false);
        }
    };

    return (
        <Box sx={{ width: '100%', mt: 2, marginTop: '-8px' }}>
            <Box sx={{ 
                width: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                my: 2,
                '&::before, &::after': {
                    content: '""',
                    flex: 1,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                }
            }}>
                <Typography sx={{ 
                    px: 2, 
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '0.875rem'
                }}>
                    OR
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                        <TextField
                            size="small"
                            fullWidth
                            placeholder={category === 'general' 
                            ? "Describe your assistant's purpose and key responsibilities..."
                            : `Describe your ${category.toLowerCase()} assistant's specific tasks and goals...`}
                            value={generateKeywords}
                            onChange={handleInputChange}
                            sx={{ bgcolor: "white" }}
                            error={!!error || showFallback}
                            InputProps={{
                                endAdornment: (
                                    <TooltipCustom
                                        title={getContent().tooltip}
                                        placement="top"
                                    >
                                        <IconButton size="small">
                                            <InfoOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    </TooltipCustom>
                                ),
                            }}
                        />
                    </Box>
                    <Box
                        component="button"
                        onClick={handleGeneratePrompt}
                        disabled={generatingPrompt}
                        className="generate-button"
                    >
                        <AutoFixHighIcon sx={{ fontSize: 20 }} />
                        <Typography>
                            {generatingPrompt 
                                ? "Generating..." 
                                : (hasGenerated ? "Regenerate" : "Generate")}
                        </Typography>
                    </Box>
                </Box>

                {(error || showFallback) && (
                    <Alert
                        severity="error"
                        sx={{
                        mt: 1,
                        padding: 2,
                        backgroundColor: 'rgba(220, 53, 69, 0.15)',
                        color: 'rgb(210, 67, 67)',
                        borderRadius: 2,
                        '& .MuiAlert-message': {
                            width: '100%',
                            alignItems: 'center',
                        },
                        '& .MuiAlert-icon': {
                            marginRight: 1,
                        },
                        }}
                        className="fadeInAlert" // Apply the fade-in animation
                    >
                        <AlertTitle>
                        {error ? "Generation Failed" : "Improve Your Prompt"}
                        </AlertTitle>
                        {error || getFallbackMessage(generateKeywords)}
                    </Alert>
                    )}

                    {success && (
                    <Alert
                        severity="success"
                        sx={{
                        mt: 1,
                        padding: 2,
                        backgroundColor: 'rgba(76, 175, 80, 0.1)',
                        borderRadius: 2,
                        '& .MuiAlert-message': {
                            width: '100%',
                            alignItems: 'center',
                        },
                        '& .MuiAlert-icon': {
                            marginRight: 1,
                        },
                        }}
                        className="fadeInAlert" // Apply the fade-in animation
                        iconMapping={{
                        success: <CheckCircleIcon fontSize="inherit" />,
                        }}
                    >
                        <AlertTitle>Success</AlertTitle>
                        {success}
                    </Alert>
                    )}

            </Box>
        </Box>
    );
};

export default GeneratePrompt;
