import React, { useRef, useState } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { UploadFile, SearchOutlined as SearchOutlinedIcon, FileDownloadOutlined as FileDownloadOutlinedIcon } from '@mui/icons-material';

const FileUpload = ({ onFileUpload, downloadSampleFile, validateFile }) => {
    const fileInputRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];
        if (file && validateFile(file)) {
            await onFileUpload(file);
        }
    };

    const handleFileSelect = async (e) => {
        e.preventDefault();
        const file = e.target.files?.[0];
        if (file && validateFile(file)) {
            await onFileUpload(file);
            // Clear the input value to ensure onChange fires even if same file is selected
            e.target.value = '';
        }
    };

    const handleBrowseClick = (e) => {
        e.stopPropagation(); // Prevent click from bubbling up
        fileInputRef.current?.click();
    };

    const handleDownloadClick = (e) => {
        e.stopPropagation(); // Prevent click from bubbling up
        downloadSampleFile();
    };

    const handleDropZoneClick = (e) => {
        // Only trigger file input click if clicking the drop zone directly
        if (e.target === e.currentTarget) {
            fileInputRef.current?.click();
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "180px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: isDragging ? "2px dashed #02042D" : "2px dashed #D9D9D9",
                borderRadius: "8px",
                cursor: "pointer",
                backgroundColor: isDragging ? "#f8f9fa" : "",
            }}
            onDragEnter={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(true); }}
            onDragOver={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(true); }}
            onDragLeave={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(false); }}
            onDrop={handleDrop}
            onClick={handleDropZoneClick}
        >
            <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept=".xlsx,.xls,.csv"
            />
            <IconButton sx={{ color: '#02042c', mb: 1 }} onClick={(e) => e.stopPropagation()}>
                <UploadFile fontSize="large" />
            </IconButton>
            <Typography sx={{ color: '#666', textAlign: 'center', mb: 2 }} onClick={(e) => e.stopPropagation()}>
                Drag & Drop File Here
                <br />
                <span style={{ fontSize: '0.8rem' }}>Max 50MB | Supports only .xls, .xlsx, .csv</span>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }} onClick={(e) => e.stopPropagation()}>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{
                        color: "#02042D",
                        borderColor: "#02042D",
                        '&:hover': { borderColor: "#0a0b3b", bgcolor: 'rgba(2, 4, 45, 0.1)' }
                    }}
                    onClick={handleBrowseClick}
                    startIcon={<SearchOutlinedIcon />}
                >
                    Browse Files
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{
                        color: "#02042D",
                        borderColor: "#02042D",
                        '&:hover': { borderColor: "#0a0b3b", bgcolor: 'rgba(2, 4, 45, 0.1)' }
                    }}
                    onClick={handleDownloadClick}
                    startIcon={<FileDownloadOutlinedIcon />}
                >
                    Sample File
                </Button>
            </Box>
        </Box>
    );
};

export default FileUpload;