export function formatDuration(seconds) {
    if (seconds) {
        const secs = Math.floor(seconds);

        const hours = Math.floor(secs / 3600);
        const minutes = Math.floor((secs % 3600) / 60);

        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");

        return `${formattedHours}H:${formattedMinutes}M`;
    } else {
        return "0H:00M";
    }
}


export const formatDate = (dateString) => {
    const [, end] = dateString.split(' to ');

    // Use `end` if it exists, otherwise default to `start`
    const targetDate = end || dateString;

    return new Date(targetDate).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
};


export const formatSingleDate = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);

    // Date components
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();

    // Time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
}

export const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    // Date components
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day} ${month}, ${year} - ${hours}:${minutes}:${seconds}`;
}


export const formatDateTime12Hours = (dateString) => {
    const date = new Date(dateString);

    // Date components
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Time components
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = String(hours).padStart(2, '0');

    return `${day} ${month}, ${year}  ${hours}:${minutes}:${seconds} ${ampm}`;
}

export const convertToMinutes = function (seconds) {
    if (typeof seconds !== "number" || seconds < 0) {
        return "Invalid input. Please provide a non-negative number.";
    }

    if (seconds < 60) {
        return `${seconds} second${seconds !== 1 ? "s" : ""}`;
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes} minute${minutes !== 1 ? "s" : ""} and ${remainingSeconds} second${remainingSeconds !== 1 ? "s" : ""}`;
};

export const getCallCost = (time = 0, value = 0.01) => {
    const minutes = Math.ceil(time / 60); // Rounds up to the nearest full minute
    return (minutes * value).toFixed(3); // Calculates cost and fixes to 3 decimal places
};



