import {
  Call,
  Visibility,
  VisibilityOff,
  Error,
  ErrorOutline,
} from "@mui/icons-material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Button,
  CardHeader,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import api from "../api";
import WebCall from "./WebCall";

const SharedWebCall = () => {
  const { uuid } = useParams();
  const [password, setPassword] = useState("");
  const [isPasswordRequired, setIsPasswordRequired] = useState(false);
  const [maxCredit, setMaxCredit] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [credit, setCredit] = useState(0);
  const [assistantConfig, setAssistantConfig] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notAValidLink, setNotAValidLink] = useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    if (uuid) {
      fetchLinkDetails();
    }
  }, [uuid]);

  const SPACING = { mobile: { container: 2, content: 2 },desktop: { container: 8, content: 4 }};
  
  const fetchLinkDetails = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/v1/assistant/share/link/${uuid}`);
      setIsPasswordRequired(data?.password_required);
      setMaxCredit(data?.max_credit_allowed);
    } catch (error) {
      console.log("error", error);
      setNotAValidLink(true);
    } finally {
      setLoading(false);
    }
  };

  const handleWebCall = async () => {
    if (maxCredit <= 0) {
      toast.error("No credit available");
      return;
    }

    try {
      setLoading1(true);
      const response = await api.post("/v1/assistant/initiate-web-call", {
        uuid,
        password,
      });
      setAssistantConfig(response.data.data);
      setIsModalOpen(true);
    } catch (err) {
      toast.error(err.response?.data?.message || "Failed to initiate call");
    } finally {
      setLoading1(false);
    }
  };

  const handleSubmitPassword = () => {
    if (!password.trim()) {
      setError("Password is required");
      return;
    }
    setError("");
    handleWebCall();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  if (notAValidLink) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          bgcolor: "background.default",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        <ErrorOutline sx={{ fontSize: 96, color: "error.main", mb: 3 }} />
        <Typography variant="h4" component="h1" gutterBottom>
          Oops! Link Not Found
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: 4, textAlign: "center" }}
        >
          The link you’re trying to access is either invalid or has expired.
        </Typography>
        <Typography variant="caption" color="text.secondary" sx={{ mt: 4 }}>
          Error Code: 404
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          bgcolor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: isMobile ? 2 : 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            borderStyle: "solid",
            borderRadius: "16px",
            borderWidth: 1,
            borderColor: "#E5E7EB",
            p: isMobile ? SPACING.mobile.container : SPACING.desktop.container,
          }}
        >
          <CardHeader
            sx={{ textAlign: "center" }}
            title={
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1}
              >
                <Avatar
                  sx={{
                    bgcolor: "#F3E8FF",
                    width: isMobile ? 72 : 96,
                    height: isMobile ? 72 : 96,
                  }}
                >
                  <ChatBubbleOutlineIcon
                    style={{
                      height: isMobile ? 36 : 48,
                      width: isMobile ? 36 : 48,
                      color: "#9334E9",
                    }}
                  />
                </Avatar>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                  <Typography
                    sx={{
                      fontSize: isMobile ? "28px" : "48px",
                      fontWeight: 600,
                    }}
                  >
                    LiaPlus Assistant
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: isMobile ? "0.875rem" : "1rem",
                      color: "#7C7C83",
                      fontWeight: 500,
                    }}
                    gutterBottom
                  >
                    Connect with our AI assistant for intelligent conversations
                    and real-time support
                  </Typography>
                </Box>
              </Box>
            }
          />
          <Box sx={{ width: "auto", display: "flex", flexDirection: "column" }}>
            {isPasswordRequired && (
              <Box sx={{ width: "auto", mb: 1 }}>
                <TextField
                  size="small"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Shared Assistant Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{
                    width: isMobile ? "260px" : "300px",
                    bgcolor: "white",
                    borderRadius: "8px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                          sx={{ color: "#02042D", ml: 1 }}
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ fontSize: "18px" }} />
                          ) : (
                            <Visibility sx={{ fontSize: "18px" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
            {error && (
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Error sx={{ fontSize: "15px" }} />
                  {error}
                </Typography>
              </Box>
            )}
          </Box>
          <Button
            variant="contained"
            onClick={isPasswordRequired ? handleSubmitPassword : handleWebCall}
            sx={{
              bgcolor: "#02042D",
              color: "white",
              width: isMobile ? "260px" : "300px",
              borderRadius: "10px",
              padding: isMobile ? "8px 32px" : "10px 48px",
              fontWeight: 600,
              textTransform: "none",
              "&:hover": {
                bgcolor: "#1B1D50",
              },
            }}
            startIcon={<LockIcon />}
          >
            {loading1 ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              <>Start Secured Web Call</>
            )}
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 3,
            }}
          >
            <LockIcon fontSize="small" sx={{ color: "#02042D", marginRight: 1 }} />
            <Typography
              sx={{
                fontSize: isMobile ? "0.875rem" : "1rem",
                color: "black",
                fontWeight: "500",
              }}
            >
              Your connection is secured and encrypted
            </Typography>
          </Box>
        </Box>
        {isModalOpen && (
          <WebCall
            open={isModalOpen}
            onClose={handleCloseModal}
            wsUrl={`${process.env.REACT_APP_VOICE_URL}`}
            callConfig={assistantConfig}
          />
        )}
      </Box>
    </>
  );
};

export default SharedWebCall;
