import React, { useState } from 'react';
import {
  Drawer,
  Box,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import {
  Close,
  Phone,
  Message,
  Computer
} from '@mui/icons-material';
import TextChat from './TextChat';
import PhoneCall from './PhoneCall';
import WebCallDrawer from './WebCallDrawer';
const ChatDrawer = ({ open, onClose, wsUrl, callConfig, avatar, name, handleWebCall }) => {
  const [activeMode, setActiveMode] = useState('text');

  const handleModeChange = (mode) => {
    setActiveMode(mode);
  };
  const renderContent = () => {
    switch (activeMode) {
      case 'text':
        return <TextChat />;
      case 'call':
        return <PhoneCall avatar={avatar} name={name} />;
      case 'web':
        return (
          <WebCallDrawer
            open={true}
            onClose={() => handleModeChange('text')}
            wsUrl={wsUrl}
            callConfig={callConfig}
            avatar={avatar}
            name={name}
            isEmbedded={true}
            handleWebCall={handleWebCall}
          />
        );
      default:
        return (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100%' 
          }}>
            <Typography>Select a communication mode</Typography>
          </Box>
        );
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose} // Use the new handleClose function
      sx={{
        '& .MuiDrawer-paper': {
          width: { xs: '100%', sm: 500 }, // Increased width
          bgcolor: 'background.default',
          marginRight: { xs: 0, sm: 2 },
          marginTop: { xs: 0, sm: 2 },
          marginBottom: { xs: 0, sm: 2 },
          height: { xs: '100%', sm: 'calc(100% - 32px)' },
          borderRadius: { xs: 0, sm: '16px' }, // More pronounced border radius
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)', // Softer shadow
        }
      }}
    >
      <Box sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        bgcolor: '#FFFFFF',
      }}>
        {/* Header */}
        <Box sx={{ 
          p: 2.5, // Increased padding
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          borderBottom: '1px solid',
          borderColor:'#E5E7EB', // Lighter border
          bgcolor: '#FAFAFA', // Slight background color
          borderTopLeftRadius: { xs: 0, sm: '16px' },
          borderTopRightRadius: { xs: 0, sm: '16px' },
        }}>
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 600,
              color: '#1A1A1A',
              fontSize: '1.25rem',
            }}
          >
            Communication Center
          </Typography>
          <IconButton 
            onClick={onClose} // Use the new handleClose function
            sx={{
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            <Close />
          </IconButton>
        </Box>

        {/* Navigation Tabs */}
        <Box sx={{ 
          px: 3, // Increased horizontal padding
          py: 2,
          display: 'flex', 
          justifyContent: 'space-between', // Changed from space-around
          borderBottom: '1px solid',
          borderColor:'#E5E7EB',
          bgcolor: '#FFFFFF',
        }}>
          {[
            { mode: 'web', icon: <Computer />, label: 'Web Call' },
            { mode: 'call', icon: <Phone />, label: 'Phone Call' },
            { mode: 'text', icon: <Message />, label: 'Chat' }
          ].map((item) => (
            <Button
              key={item.mode}
              variant={activeMode === item.mode ? 'contained' : 'outlined'}
              startIcon={item.icon}
              onClick={() => handleModeChange(item.mode)}
              sx={{
                minWidth: '140px', // Fixed width for buttons
                height: '42px', // Taller buttons
                textTransform: 'none', // No uppercase
                borderRadius: '8px', // Rounded corners
                fontWeight: 500,
                fontSize: '0.95rem',
                ...(activeMode === item.mode
                  ? {
                      bgcolor: '#02042D', // Custom blue color
                      '&:hover': {
                        bgcolor: '#262A5A',
                      },
                      boxShadow: '0 2px 8px rgba(33, 150, 243, 0.25)', // Subtle shadow for active tab
                    }
                  : {
                      color: '#02042D',
                      borderColor: '#02042D',
                      '&:hover': {
                        bgcolor: 'rgba(33, 150, 243, 0.04)',
                        borderColor: '#262A5A',
                      },
                    }
                ),
              }}
            >
              {item.label}
            </Button>
          ))}
        </Box>

        {/* Content Area */}
        <Box sx={{ 
          flexGrow: 1, 
          position: 'relative', 
          overflow: 'hidden',
          bgcolor: '#FFFFFF',
        }}>
          {renderContent()}
        </Box>
      </Box>
    </Drawer>
  );
};

export default ChatDrawer;