import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  CardContent,
  CardActions,
  Button,
  Alert,
} from "@mui/material";
import { CreditCard, Warning } from "@mui/icons-material";

import Navbar from "../components/Navbar";

const BuyCallingMinutes = () => {
  const [callingMinutes, setCallingMinutes] = useState(0);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState("");
  const [rate, setRate] = useState(0.1);

  useEffect(() => {
    // Simulate rate fetching
    setRate(0.1);
  }, []);

  const handleMinutesChange = (e) => {
    const value = e.target.value;
    setCallingMinutes(value);
    setError("");

    if (!value) {
      setTotal(0);
      return;
    }

    if (!/^\d*\.?\d*$/.test(value)) {
      setError("Please enter a valid number");
      setTotal(0);
      return;
    }
   const numMinutes = Number.parseFloat(value);
    if (numMinutes < 0) {
      setError("Calling Minutes cannot be negative");
      setTotal(0);
      return;
    }
    if (!Number.isInteger(numMinutes)) {
      setError("Please enter a whole number");
      setTotal(0);
      return;
    }
    setTotal(numMinutes * rate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!callingMinutes || error) return;

    // Handle the purchase logic here
  };

  return (
    <>
      <Navbar title="Purchase Calling Minutes" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          p: { xs: 2, sm: 4 },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
            px: { xs: 2, sm: 4 },
            py: { xs: 2, sm: 4 },
            bgcolor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderColor: "#E5E7EB",
            borderWidth: "1px",
            borderRadius: "12px",
            borderStyle: "solid",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                mb: 2,
                gap: 0,
              }}
            >
              <Typography
                sx={{ fontSize: 24, fontWeight: 600, textAlign: "left" }}
                gutterBottom
              >
                Purchase Calling Minutes
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#7C7C83",
                  textAlign: "left",
                }}
                gutterBottom
              >
                Get More Calling Minutes for Assistant Calls
              </Typography>
            </Box>

            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Calling Minutes"
                type="number"
                value={callingMinutes}
                onChange={handleMinutesChange}
                margin="normal"
                error={!!error}
                helperText={error}
                placeholder="Enter the number of calling minutes"
                InputProps={{
                  startAdornment: (
                    <CreditCard sx={{ mr: 1, color: "action.active" }} />
                  ),
                }}
              />
              <Typography variant="body2" color="text.secondary">
                Specify the number of calling minutes you want to purchase for making
                assistant calls.
              </Typography>

              {error && (
                <Alert
                  severity="error"
                  icon={<Warning />}
                  sx={{ mt: 2 }}
                >
                  {error}
                </Alert>
              )}
            </form>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 2,
                mt: 4,
                bgcolor: "#F9FAFB",
                gap: 2,
                borderRadius: "12px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#E5E7EB",
              }}
            >
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#7C7C83",
                  }}
                >
                  Price per Calling Minute:
                </Typography>
                <Typography>${rate.toFixed(2)}</Typography>
              </Box>
              <Box
                component="hr"
                sx={{ 
                  width: "100%", 
                  border: "none",
                  borderTop: "1px solid #E5E7EB"
              }}
              />
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">Total Cost:</Typography>
                <Typography variant="h6">${total.toFixed(2)}</Typography>
              </Box>
            </Box>
          </CardContent>

          <CardActions>
            <Button
              fullWidth
              variant="contained"
              size="large"
              sx={{
                bgcolor: "#02042D",
                color: "#ffffff",
                borderRadius: "8px",
              }}
              disabled={!callingMinutes || error || callingMinutes <= 0}
              onClick={handleSubmit}
            >
              Purchase Calling Minutes
            </Button>
          </CardActions>
        </Box>
      </Box>
    </>
  );
};

export default BuyCallingMinutes;
