import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Button } from '@mui/material';
import InitialDetails from './AssistantForms/InitialDetails';
import Prompt from './AssistantForms/Prompt';
import TranscriberAndAudio from './AssistantForms/TranscriberAndAudio';
import AdditionalConfigurations from './AssistantForms/AdditionalConfigurations';
import Actions from './AssistantForms/Actions';
import { toast } from 'sonner';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckCircleOutline, DashboardOutlined, DescriptionOutlined, RecordVoiceOverOutlined, SettingsOutlined } from '@mui/icons-material';
import api from "../api";

const AssistantForm = () => {
  const [value, setValue] = useState(0);
  const [token, setToken] = useState('');
  const [assistantId, setAssistantId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState("");

  const navigate = useNavigate()
  const { company_id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getToken = async () => {
    const userData = JSON.parse(localStorage.getItem("User"));
    setUserRole(userData?.Role || "");
    setToken(userData?.Token);
  };

  useEffect(() => {
    getToken();
  }, []);

  const handleSave = async () => {
    try {
      const assistant = JSON.parse(localStorage.getItem("assistant"));
      const logoData = JSON.parse(localStorage.getItem("Logo")); // Logo metadata and content
      const knowledgeBaseData = JSON.parse(localStorage.getItem("KnowledgeBase")); // Logo metadata and content

      const formData = new FormData();
      for (const [key, value] of Object.entries(assistant)) {
        formData.append(key, value); // Append each key-value pair
      }

      if (logoData) {
        // Convert Base64 to Blob
        const byteString = atob(logoData.content.split(',')[1]);
        const mimeString = logoData.content.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });

        // Append the logo file to the formData
        formData.append("logo", new File([blob], logoData.name, { type: logoData.type }));
      }
      if (knowledgeBaseData) {
        // Convert Base64 to Blob
        const byteString = atob(knowledgeBaseData.content.split(',')[1]);
        const mimeString = knowledgeBaseData.content.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });

        // Append the logo file to the formData
        formData.append("knowledgeBase", new File([blob], knowledgeBaseData.name, { type: knowledgeBaseData.type }));
      }

      setLoading(true);

      const response = await api.post("/v1/assistant/create", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });

      setLoading(false);

      const actions = localStorage.getItem('actions');
      if (actions) {
        const parsedActions = JSON.parse(actions);
        for (const action of parsedActions) {
          if (!action._id) {
            action.Assistant = response.data.assistant._id
            await api.post("/v1/actions/create-action", action, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            });
          }
        }
      }


      toast.success(response.data.message);
      // navigate('/assistants');
      navigate(
        userRole === "SuperAdmin"
          ? "/assistants"
          : `/company/${company_id}/assistants`
      );
    } catch (error) {
      toast.error("Profile details are required");
      console.log(error);
    }
  };

  return (
    <Box sx={{ width: '100%', padding: '20px' }}>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box sx={{ display: 'flex', justifyContent: 'start', mb: 3, backgroundColor: '#02042d', paddingTop: '0.5rem', paddingLeft: '0.5rem', borderRadius: '0.5rem', width: 'fit-content' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            size="small"
            style={{ marginBottom: '-0.5rem' }}
            sx={{
              '& .MuiTabs-flexContainer': { borderRadius: '8px', overflow: 'hidden', height: '2rem' },
              '& .MuiTab-root': {
                textTransform: 'none', padding: '0.25rem .5rem', marginRight: 1,
                borderRadius: '8px', backgroundColor: '#02042d', color: '#ffffff',
                minHeight: '1rem', height: '2rem', fontWeight: 'semi',
                '&.Mui-selected': { backgroundColor: '#353657', color: '#ffffff' },
                '&:hover': { backgroundColor: '#353657', color: '#ffffff' },
              },
              '& .MuiTabs-indicator': { display: 'none' },
            }}
          >
            <Tab label="Information" iconPosition="start" icon={<DashboardOutlined sx={{ fontSize: 20 }} />} />
            <Tab label="Prompt" iconPosition="start" icon={<DescriptionOutlined sx={{ fontSize: 20 }} />} />
            <Tab label="Transcriber and Voice" iconPosition="start" icon={<RecordVoiceOverOutlined sx={{ fontSize: 20 }} />} />
            <Tab label="Configurations" iconPosition="start" icon={<SettingsOutlined sx={{ fontSize: 20 }} />} />
            <Tab label="Actions" iconPosition="start" icon={<CheckCircleOutline sx={{ fontSize: 20 }} />} />
          </Tabs>
        </Box>

        <Button variant="contained" style={{ color: '#fff', backgroundColor: '#02042d' }} onClick={handleSave} sx={{ marginLeft: 'auto', marginTop: '0.5rem' }}>
          Publish
        </Button>
      </Box>

      {value === 0 && <InitialDetails assistantId={assistantId} />}
      {value === 1 && <Prompt assistantId={assistantId} />}
      {value === 2 && <TranscriberAndAudio assistantId={assistantId} />}
      {value === 3 && <AdditionalConfigurations assistantId={assistantId} />}
      {value === 4 && <Actions assistantId={assistantId} />}
    </Box>
  );
};

export default AssistantForm;
