import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

const PriceCard = ({ cardNumber, expiry, name }) => {
  return (
    <Card
      sx={{
        width: 400,
        height: 220,
        background: "linear-gradient(135deg, #667eea, #764ba2)",
        color: "white",
        borderRadius: 3,
        padding: 2,
        position: "relative",
        mb: 3,
      }}
    >
      {/* Chip */}
      <Box
        sx={{
          width: 50,
          height: 35,
          backgroundColor: "#ffcc00",
          borderRadius: 2,
          position: "absolute",
          top: 20,
          left: 20,
        }}
      />

      <CardContent>
        {/* Card Number */}
        <Typography variant="h6" sx={{ letterSpacing: 2, mt: 6 }}>
          {cardNumber || "**** **** **** ****"}
        </Typography>

        {/* Card Details */}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography variant="body1">{name || "CARDHOLDER"}</Typography>
          <Typography variant="body1">{expiry || "MM/YY"}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PriceCard;