import { toast } from 'sonner';

export const formatFileSize = (bytes) => {
    if (bytes && !isNaN(bytes) && bytes > 0) {
        const sizes = ['B', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
    }
    return '';
};

export const validateFile = (file) => {
    const validTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'text/csv'
    ];

    if (!validTypes.includes(file.type)) {
        toast.error("Please upload an Excel or CSV file");
        return false;
    }
    return true;
};