import { Route, Routes } from "react-router-dom";
import { lazy } from "react";
import WebCall from "./pages/WebCall";

const TranscriberLanguage = lazy(() => import("./pages/tables/TranscriberLanguage"));
const VoiceProvider = lazy(() => import("./pages/tables/VoiceProvider"));
const Voice = lazy(() => import("./pages/tables/Voice"));
const VoiceLanguage = lazy(() => import("./pages/tables/VoiceLanguage"));
const LLMProvider = lazy(() => import("./pages/tables/LLMProvider"));
const LLM = lazy(() => import("./pages/tables/LLM"));
const PhoneNumberDetail = lazy(() => import("./pages/PhoneNumberDetail"));
const Logs = lazy(() => import("./pages/Logs"));
const Overview = lazy(() => import("./pages/Overview"));
const AssistantDetails = lazy(() => import("./pages/AssistantDetails"));
const Company = lazy(() => import("./pages/Company"));
const CompanyDetail = lazy(() => import("./pages/CompanyDetail"));
const CreateAssistant = lazy(() => import("./pages/CreateAssistant"));
const Transcriber = lazy(() => import("./pages/tables/Transcriber"));
const TranscriberProvider = lazy(() => import("./pages/tables/TranscriberProvider"));
const Assistants = lazy(() => import("./pages/Assistants"));
const PhoneNumber = lazy(() => import("./pages/PhoneNumber"));
const About = lazy(() => import("./pages/About"));
const PhoneProvider = lazy(() => import("./pages/tables/PhoneProvider"));
const ViewAssistantTemplate = lazy(() => import("./pages/ViewAssistantTemplate"));


const SuperAdminRoutes = () => {
    return (
        <Routes>
            <Route path="*" element={<Overview />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/transcriber" element={<Transcriber />} />
            <Route path="/transcriber-provider" element={<TranscriberProvider />} />
            <Route path="/transcriber-language" element={<TranscriberLanguage />} />
            <Route path="/voice-provider" element={<VoiceProvider />} />
            <Route path="/voice" element={<Voice />} />
            <Route path="/voice-language" element={<VoiceLanguage />} />
            <Route path="/llm-provider" element={<LLMProvider />} />
            <Route path="/llm" element={<LLM />} />
            <Route path="/about" element={<About />} />
            <Route path="/assistants" element={<Assistants />} />
            <Route path="/assistants/create" element={<CreateAssistant />} />
            <Route path="/phone-number" element={<PhoneNumber />} />
            <Route path="/phone-number/:id" element={<PhoneNumberDetail />} />
            <Route path="/view-assistant/:id" element={<ViewAssistantTemplate />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/assistants/:assistantId" element={<AssistantDetails />} />
            <Route path="/company" element={<Company />} />
            <Route path="/company/:id" element={<CompanyDetail />} />
            <Route path="/phone-provider" element={<PhoneProvider />} />
            <Route path="/web-call" element={<WebCall />} />
        </Routes>
    );
};

export { SuperAdminRoutes };