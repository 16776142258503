import { Avatar, Box, Button, Card, CardContent, Chip, Typography } from '@mui/material';
import React from 'react'

const AssistantTemplate = ({ name, type, category }) => {
    return (
        <Card style={{ border: "1px solid #EEE" }}>
            <CardContent>
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: ".5rem" }}>
                    <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                    />
                    <Box>
                        <Typography variant="h6">
                            {name}
                        </Typography>
                        <Box style={{ display: "flex", gap: "0.5rem" }}>
                            <Chip
                                label={type}
                                size="small"
                            />
                            <Chip
                                label={category}
                                size="small"
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", gap: "1rem", marginTop: "2rem" }}>

                    <Button size="small" variant="contained" color="inherit" style={{ width: "100%", }}>
                        View Assistant
                    </Button>

                    <Button variant="contained" color="primary"
                        style={{ width: "100%", backgroundColor: '#020429' }}
                    >
                        Create
                    </Button>

                </Box>
            </CardContent>
        </Card>
    )
}

export default AssistantTemplate;