import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  Modal,
  Pagination,
} from "@mui/material";
import {
  CallRounded,
  Add,
  Close,
  Dashboard,
  AccessTime,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Title from "../components/Title";
import AssistantCard from "../components/AssistantCard";
import AssistantTemplate from "../components/AssistantForms/AssistantTemplate";
import Loader from "../components/Loader";
import NoDataFound from "../components/NoDataFound";
import api from "../api";
import "../styles/assistants.css";
import { fetchAssistantConfig } from './utils/fetchConfig';
import { useGetAllPermissions } from "./utils/getAllPermissions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const CONFIG = [
  'Cutoff',
  'EndConversationOnGoodbye',
  'FilterAudio',
  'IdealTime',
  'InitialMsg',
  'Interruption',
  'LLMModel',
  'LLMProvider',
  'MaxToken',
  'NumOfHumanCheck',
  'Prompt',
  'SpeedOfText',
  'Temperature',
  'Transcriber',
  'TranscriberLanguage',
  'TranscriberProvider',
  'Voice',
  'VoiceLanguage',
  'VoiceProvider'
];

const NewAssistants = () => {
  const [searchOption, setSearchOption] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [token, setToken] = useState("");
  const [assistants, setAssistants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingAssistants, setLoadingAssistants] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [organization, setOrganization] = useState({});
  const [defaultData, setDefaultData] = useState({});
  const [templates, setTemplates] = useState([]); // New state for templates
  const { permissions, permissionsLoading, getAllPermissions } = useGetAllPermissions();
  const canFetchAssistant = permissions?.includes("fetch_assistant") ?? false;
  const canCreateAssistant = permissions?.includes("create_assistant") ?? false;

  const navigate = useNavigate();
  const { company_id} = useParams();

  const searchOptions = [
    { value: "Name", label: "Name" },
    { value: "UpdatedAt", label: "Updated At" },
    { value: "Category", label: "Category" },
  ];


  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("User"));
    setUserRole(userData?.Role || "");
    setToken(userData?.Token);
  }, []);

  const getDefaultConfig = async () => {
    try {
        await fetchAssistantConfig(CONFIG,(data) => {
          if (data) {
            setDefaultData(data)
          }
        });
    } catch (error) {
        console.error("Error getting default config:", error);
    }
  };

  const handleOpenModal = () => {
    getDefaultConfig();
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenTemplateModal = () => {
    // New function to open template modal
    setTemplateModalOpen(true);
  };

  const handleCloseTemplateModal = () => {
    // New function to close template modal
    setTemplateModalOpen(false);
  };

  const fetchOrganization = async () => {
    if (!company_id || userRole === "Admin") return;

    try {
      const response = await api.get(`/organizations/${company_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrganization(response.data);
    } catch (error) {
      console.error("Error fetching organization:", error);
    }
  };

  const fetchAssistants = useCallback(
    async (page = 1, search = "") => {
      setLoadingAssistants(true);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        let response;
        if (userRole === "SuperAdmin") {
          response = await api.get(
            `/assistants?limit=9&search=${search}&page=${page}`,
            config
          );
          setAssistants(response.data.data.assistants);
          setTotalPages(response.data.data.totalPages);
          setCurrentPage(response.data.data.currentPage);
        } else {
          // For admin, fetch both company assistants and demo assistants if ShowDemo is true
          // const demoResponse = organization?.ShowDemo ?
          //     await api.post("/assistants/demo-assistants", {}, {
          //         ...config,
          //         params: { page }
          //     }) : { data: { data: { demoAssistants: [] } } };

          const companyResponse = await api.post(
            "/organizations/assistants-by-company",
            {},
            {
              ...config,
              params: { page },
            }
          );

          const combinedAssistants = [
            ...companyResponse.data.data,
            // ...(demoResponse.data.data?.demoAssistants || [])
          ];

          setAssistants(combinedAssistants);
          setTotalPages(
            Math.max(
              companyResponse.data?.totalPages || 0
              // demoResponse.data?.totalPages || 0
            )
          );
        }
      } catch (error) {
        console.error("Error fetching assistants:", error);
      } finally {
        setLoadingAssistants(false);
      }
    },
    [token, userRole]
  );

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const {data:{data}} = await api.get(`/v1/template/`, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if(data){
        setTemplates(data);
      }
    }  catch (error) {
      console.error('Error fetching assistant data:', error);
    }finally{
    setLoading(false)
    }
  };

  useEffect(() => {
    if (token) {
      fetchOrganization();
      getAllPermissions();
      fetchTemplates();

    }
  }, [token]);

  useEffect(() => {
    if (token && (userRole === "SuperAdmin" || organization)) {
      fetchAssistants(currentPage, searchValue);
    }
  }, [
    token,
    userRole,
    organization,
    fetchAssistants,
    currentPage,
    searchValue,
  ]);

  const handleSearchChange = (event) => {
    const newSearchValue = event.target.value;
    setSearchValue(newSearchValue);
    fetchAssistants(1, newSearchValue);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchAssistants(value, searchValue); // Explicitly call fetchAssistants for new page
  };

  const handleCreateAssistant = () => {
    localStorage.removeItem("assistant");
    localStorage.removeItem("KnowledgeBase");
    localStorage.removeItem("Logo");
    localStorage.setItem("assistant",JSON.stringify(defaultData))
    navigate(`/company/${company_id}/assistants/create`);
  };
  
  const [activeFilters, setActiveFilters] = useState([]);

  // Add a filter when a chip in an assistant card is clicked
  const addFilter = (filter) => {
    setActiveFilters((prevFilters) => (!prevFilters.includes(filter) ? [...prevFilters, filter] : prevFilters));
  };

  // Remove a filter when the "X" on a filter chip is clicked
  const removeFilter = (filter) => {
    setActiveFilters((prevFilters) => prevFilters.filter((f) => f !== filter));
  };

  // Clear all filters
  const clearFilters = () => setActiveFilters([]);

  // Filter assistant cards dynamically
  const filteredTemplates = templates.filter(
    (template) =>
      activeFilters.length === 0 ||
      activeFilters.includes(template.type) ||
      activeFilters.includes(template.category)
  );
  
  if (permissionsLoading || loading) return <Loader />;

  return (
    <Box>
      {permissions &&
      permissions.includes("fetch_assistant") &&
      permissions.includes("search_assistant") ? (
        <Navbar
          title="Assistants"
          searchOptions={searchOptions}
          searchOption={searchOption}
          searchValue={searchValue}
          onSearchOptionChange={(e) => setSearchOption(e.target.value)}
          onSearchValueChange={handleSearchChange}
        />
      ) : (
        <Navbar title="Assistants" />
      )}

      <Box p={2}>
        {permissions && permissions.includes("create_assistant") && (
          <>
            <Title
              title="Create Assistant"
              description="Start from scratch and build your AI assistant"
            />

            <Card
              className="color-prim-cetacean-blue-600"
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                borderRadius: "1rem",
              }}
            >
              <CardContent className="assistantCardDetails">
                <Box className="boxHeading">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "1rem",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: "#353657",
                        borderRadius: "50%",
                        padding: "0.5rem",
                      }}
                    >
                      <CallRounded
                        style={{ color: "#fff", marginBottom: "-0.5rem" }}
                      />
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Real-Time Voice Assistant
                      </Typography>
                      <Typography variant="body2">
                        Personalized AI assistant available for you 24/7
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    style={{
                      color: "#02042d",
                      backgroundColor: "#fff",
                      textTransform: "capitalize",
                      borderRadius: ".6rem",
                    }}
                    onClick={handleOpenModal}
                  >
                    <Add /> Create Assistant
                  </Button>
                </Box>
                <Box sx={{ display: "flex", gap: ".5rem", mt: "1.5rem" }}>
                  <Chip
                    icon={
                      <Box
                        style={{
                          display: "grid",
                          placeItems: "center",
                          backgroundColor: "#353657",
                          borderRadius: "50%",
                          padding: 4,
                        }}
                      >
                        <CallRounded
                          style={{ fontSize: "1rem", color: "#fff" }}
                        />
                      </Box>
                    }
                    label="Inbound"
                    variant="outlined"
                    sx={{
                      borderColor: "#353657",
                      borderWidth: "2px",
                      color: "white",
                    }}
                  />
                  <Chip
                    icon={
                      <Box
                        style={{
                          display: "grid",
                          placeItems: "center",
                          backgroundColor: "#353657",
                          borderRadius: "50%",
                          padding: 4,
                        }}
                      >
                        <CallRounded
                          style={{ fontSize: "1rem", color: "#fff" }}
                        />
                      </Box>
                    }
                    label="Outbound"
                    variant="outlined"
                    sx={{
                      borderColor: "#353657",
                      borderWidth: "2px",
                      color: "white",
                    }}
                  />
                  <Chip
                    icon={
                      <Box
                        style={{
                          display: "grid",
                          placeItems: "center",
                          backgroundColor: "#353657",
                          borderRadius: "50%",
                          padding: 4,
                        }}
                      >
                        <AccessTime
                          style={{ fontSize: "1rem", color: "#fff" }}
                        />
                      </Box>
                    }
                    label="24/7 Uptime"
                    variant="outlined"
                    sx={{
                      borderColor: "#353657",
                      borderWidth: "2px",
                      color: "white",
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </>
        )}

        {loadingAssistants ? (
          <Loader /> // Show loader only for assistants
        ) : permissions && permissions.includes("fetch_assistant") ? (
          assistants.length > 0 ? (
            <>
            {canFetchAssistant && (
            <Title
              title="All Assistants"
              description={
                canCreateAssistant
                  ? "Browse your existing assistants and adjust their configurations to fit your requirements."
                  : "View your existing assistants."
              }
            />
          )}
              <Grid
                container
                spacing={2}
                mt={1}
                mb={2}
                sx={{ overflow: "hidden" }}
              >
                {assistants.map((assistant) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={assistant._id}>
                    <AssistantCard
                      name={assistant.Name}
                      category={assistant.Category}
                      description={assistant.Description || "No description"}
                      Type={assistant.Type}
                      lastUpdated={assistant.UpdatedAt}
                      fileUrl={assistant.Logo?.fileUrl || "defaultLogo.png"}
                      buttonAction={{
                        label: "View Assistant",
                        url:
                          userRole === "SuperAdmin"
                            ? `/assistants/${assistant._id}`
                            : `/company/${company_id}/assistant/${assistant._id}`,
                      }}
                      assistant={assistant}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box display="flex" justifyContent="center" mt={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="#02042D"
                />
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: "70dvh",
              }}
            >
              <NoDataFound
                title="No Assistant Found"
                subtitle="There is no assistant found to show the data right now."
              />
            </Box>
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "70dvh",
            }}
          >
            <NoDataFound
              title="Insufficient Permissions"
              subtitle="You do not have permission to view assistants."
            />
          </Box>
        )}
      </Box>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="create-assistant-modal-title"
        aria-describedby="create-assistant-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 700,
            height: 400,
            borderRadius: "1rem",
            backgroundColor: "#FAFAFA",
            overflow: "hidden",
            padding: "1.2rem",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Create Assistant</Typography>
            <Button onClick={handleCloseModal} style={{ minWidth: "auto" }}>
              <Close sx={{ color: "#8A8A8A" }} />
            </Button>
          </Box>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              mt: "1.5rem",
              textAlign: "center",
            }}
          >
            <Box>
              <Typography variant="h6">Choose Preference</Typography>
              <Typography variant="p">
                Pick your starting point for assistant
              </Typography>
            </Box>

            <Box
              mt={2}
              style={{
                display: "flex",
                columnGap: "1rem",
                justifyContent: "center",
              }}
            >
              <Card
                onClick={handleOpenTemplateModal}
                sx={{
                  cursor: "pointer",
                  boxShadow: 0,
                  border: "1px solid #B0B0B0",
                  borderRadius: "12px",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                  }}
                >
                  <Box
                    style={{
                      width: "2rem",
                      height: "2rem",
                      backgroundColor: "#02042d",
                      borderRadius: "50%",
                      padding: "1.5rem 1.5rem 1.5rem 1.5rem",
                      position: "relative",
                    }}
                  >
                    <Dashboard
                      style={{
                        color: "#fff",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </Box>
                  <Typography variant="h6" style={{ color: "#02042d" }}>
                    Template Library
                  </Typography>
                  <Typography variant="p">
                    Get start with predefined assistants
                  </Typography>
                </CardContent>
              </Card>
              <Card
                onClick={handleCreateAssistant}
                sx={{
                  cursor: "pointer",
                  boxShadow: 0,
                  border: "1px solid #B0B0B0",
                  borderRadius: "12px",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                  }}
                >
                  <Box
                    style={{
                      width: "2rem",
                      height: "2rem",
                      backgroundColor: "#02042d",
                      borderRadius: "50%",
                      padding: "1.5rem 1.5rem 1.5rem 1.5rem",
                      position: "relative",
                    }}
                  >
                    <Add
                      style={{
                        color: "#fff",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </Box>
                  <Typography variant="h6" style={{ color: "#02042d" }}>
                    Create from Scratch
                  </Typography>
                  <Typography variant="p">
                    Get start with predefined assistants
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      </Modal>


      <Modal
        open={templateModalOpen}
        onClose={handleCloseTemplateModal}
        aria-labelledby="template-library-modal-title"
        aria-describedby="template-library-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: "80dvw",
            height: "80dvh",
            padding: "2rem",
            borderRadius: "1rem",
            overflow: "auto",
          }}
        >
         
            
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                bgcolor:'red'
              }}
            >

              <Box sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'flex-start',
                gap:0.5
              }}>
              <Typography variant="h5" id="template-library-modal-title">
                Template Library
              </Typography>
              <Typography sx={{fontSize:'1rem', color:'#7C7C83', fontWeight:'500'}} gutterBottom>
              Here you can choose from predefined assistants to help you get
              started.
            </Typography>
            </Box>

            <Button
                onClick={handleCloseTemplateModal}
                style={{ minWidth: "auto" }}
              >
                <Close sx={{
                  color:'black'
                }} />
              </Button>
            </Box>

                {/* Filters Section */}
                
                {activeFilters.length > 0 && (
                <Box sx={{ display: "flex", gap: 1, marginBottom: 2, mt:2 }}>
                  {activeFilters.map((filter) => (
                    <Chip
                      key={filter}
                      label={filter}
                      onDelete={() => removeFilter(filter)}
                      sx={{
                        py: 1,
                        pl:1,
                        fontSize: '14px',
                        fontWeight: '500',
                        '& .MuiChip-label': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    },
                        bgcolor: "#02042D",
                        color: "white",
                        "& .MuiChip-deleteIcon": { color: "white",ml:0.5 },
                      }}
                    />
                  ))}
                  <Button onClick={clearFilters} sx={{ textTransform: "none" }}>
                    Clear All
                  </Button>
                </Box>
              )}

        <Box
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(500px, 1fr))",
              gap: "1rem",
              margin: "1rem 0",
              marginTop: '1rem'
            }}
          >

              {filteredTemplates.map((template) => ( <AssistantTemplate 
                  key={template.id}
                  name={template.name}
                  type={template.type}
                  category={template.category}
                  description={template.description}
                  onChipClick={addFilter}
                  data={template}
                  setModalOpen={setModalOpen}
                />
              ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default NewAssistants;
