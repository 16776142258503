import React from 'react';
import { Box, FormLabel, TextField, Button,Typography } from '@mui/material';
import Info from '@mui/icons-material/Info';
import { TiArrowBack } from "react-icons/ti";
import CopyLinkComponent from './CopyLink';
import { formatDate } from '../common/formatDate';


const ShareAssistantLinkGeneration = ({ onClose, generatedLink }) => {

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Button variant="text" onClick={onClose} sx={{ minWidth: "auto", mr:2 }}>
          <TiArrowBack style={{ width: "28px", height: "28px", color: "black" }} />
          </Button>
          <h6>Share Assistant Access</h6>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 1,
          padding: "24px 0 24px 0",
          border: "1px solid #E6E6E6",
          borderRadius: "12px",
          backgroundColor: "#F9FAFB",
          color: "#6D6D6D",
        }}
      >
        {/* Assistant Shared Info Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "start",
            padding: "0 16px 0 16px",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Info sx={{ color: "#6D6D6D", marginRight: 2 }} />
            <FormLabel sx={{ fontSize: 16 }}>
              <span style={{ fontWeight: "bold" }}>Assistant Shared</span>
              <br />
              By sharing this assistant access, you are allowing others to make
              web calls using your allocated credits.
            </FormLabel>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0 16px 0 40px",
            }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
              <span style={{ fontWeight: 400 }}>Expiration:</span>{" "}
              {formatDate(new Date(generatedLink?.expiration_date || new Date()))}
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
              <span style={{ fontWeight: 400 }}>Calling Minutes Allotted: </span>
              {generatedLink?.max_credit_allowed}
            </Typography>
          </Box>
        </Box>

        {/* Horizontal line between sections */}
        <hr
          style={{
            border: "1px solid #E6E6E6",
            width: "100%",
            margin: "16px 0",
          }}
        />

        {/* Unique Link Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            padding: "0 16px 0 16px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <TextField
              value={generatedLink?.link}
              fullWidth // Ensure it takes up 100% of the available space in the container
              variant="standard" // Use "standard" variant to remove the border
              InputProps={{
                readOnly: true,
                disableUnderline: true, // Removes the underline in the standard variant
              }}
              sx={{
                "& .MuiInputBase-root": {
                  width: "100%", // Ensure it takes up 100% of the parent container
                  backgroundColor: "#F9FAFB", // Optional: background color for the text field
                  color: "#0A74DA", // Optional: set text color (blue)
                },
              }}
            />
          </Box>
          {/* "Copy Link" Text and IconButton */}
          <CopyLinkComponent generatedLink={generatedLink?.link} />
        </Box>
      </Box>
      
    </Box>
  );
};

export default ShareAssistantLinkGeneration;
