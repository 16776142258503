import { Close, Delete, Edit, SearchOutlined } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import HazardIcon from '../../components/HazardIcon';
import { useParams } from 'react-router-dom';
import api from "../../api";
import InputAdornmend from "@mui/material/InputAdornment";
import { toast } from 'sonner';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString, isPossiblePhoneNumber } from "libphonenumber-js";
import NoDataFound from "../../components/NoDataFound";
import Loader from "../../components/Loader";
import { useGetAllPermissions } from '../../pages/utils/getAllPermissions';
const Member = () => {
    
    const { company_id: id } = useParams();
    const token = JSON.parse(localStorage.getItem("token")).token;
    const [members, setMembers] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [error, setError] = useState(""); // Error message state
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phonenumber: '',
        email: '',
        Role: '',
        Organization: id,
    });
    const [deleteModal, setDeleteModal] = useState(false);
    const [userId, setUserId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState({
        Name: '',
        Phone: '',
        Email: '',
        Role: '',
    });
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("all");
    const [consentChecked, setConsentChecked] = useState(false);
    const [nameError, setNameError] = useState("");
    const [userRole] = useState(() => localStorage.getItem("Role"));
    const { permissions, permissionsLoading, getAllPermissions } = useGetAllPermissions();
    

     useEffect(() => {
       getAllPermissions();
     }, [token]);

    const handleEditUser = (member) => {
        setSelectedMember(member);
        setIsModalOpen(true);
    };

    const handleInputChange = (e) => {
        setSelectedMember({ ...selectedMember, [e.target.name]: e.target.value });
    };
    const handlePhoneNumberInputChange = (value, country) => {
            if (!value) {
                        setError(`Please enter a phone number for ${country.name}.`);
                        return;
                    }
            
                    try {
                        const phoneNumber = parsePhoneNumberFromString("+" + value.toString(), (country.countryCode).toUpperCase());    
                        // Check if phone number is valid
                        if (!phoneNumber || !phoneNumber.isValid()) {
                            setError(`Invalid phone number format for ${country.name}.`);
                        } else {
                            setError(""); // Clear error if valid
                        }
                    } catch (error) {
                        setError(`Invalid input. Please check the phone number for ${country.name}.`);
                    }
        setFormData({ ...formData, phonenumber: value });
    };



    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (name === "name") {
            if (!/^[\p{L}]+([-'\s][\p{L}]+)*$/u.test(value)) {
              setNameError("Only alphabetic characters are allowed, and spaces cannot be at the beginning or end.");
            } else {
              setNameError("");
            }
          }
    };

    const updateUser = async (e) => {
        e.preventDefault();
        const { Name, Email, Phone, Role } = selectedMember;

        try {
            const response = await api.put(`/admin/${selectedMember._id}`, {
                Name: Name,
                Email: Email,
                Phone: Phone,
                Role: Role,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            toast.success(response.data.message);
            getMembersBycompany();
            setIsModalOpen(false);
        } catch (error) {
            toast.error(error.response.data.message || "Error updating user");
            console.error(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post(`/admin/create-user`, formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success(response.data.message);
            getMembersBycompany();
            setOpen(false);
            setFormData({
                name: '',
                phonenumber: '',
                email: '',
                Role: '',
            })

        } catch (error) {
            toast.error(error.response.data.error);
            console.error(error);
        }
    };

    const handleDelete = async (id) => {
        if (userId === id) {
            toast.warning("You can't delete your own account");
            return;
        }
        try {
            const response = await api.delete(`/admin/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            toast.success(response.data.message);
            getMembersBycompany();

        } catch (error) {
            toast.error(error.response.message);
            console.error(error);
        }
    };

    const getMembersBycompany = useCallback(async () => {
        try {
            const response = await api.post(`/organizations/members`, {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        page: page + 1,
                        limit: rowsPerPage,
                        search: search,
                        filter: filter,
                    },
                });

            setMembers(response.data.data);
            setTotalDocuments(response.data.totalDocuments);
        } catch (error) {
            console.log(error);
        }
    }, [filter, page, rowsPerPage, search, token]);

    useEffect(() => {
        if (token) {
            getMembersBycompany();
        }
    }, [getMembersBycompany, token])

    if (permissionsLoading) return <Loader />;

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1.5rem" }}>
                <Box>
                    <Typography variant="h6">Members</Typography>
                    <Typography variant="body">
                        See all members associated with the company.
                    </Typography>
                </Box>
                {permissions?.includes("view_company_member") ?
                    <>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", gap: "1rem" }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    placeholder="Search..."
                                    value={search}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornmend position="start">
                                                <SearchOutlined />
                                            </InputAdornmend>
                                        ),
                                    }}
                                    onChange={(e) => setSearch(e.target.value)}
                                    sx={{ width: "20rem" }}
                                />

                                <FormControl>
                                    <InputLabel>Filter</InputLabel>
                                    <Select
                                        label="Filter"
                                        sx={{ width: "10rem" }}
                                        size="small"
                                        value={filter}
                                        onChange={(e) => setFilter(e.target.value)}
                                    >
                                        <MenuItem value="Name">Name</MenuItem>
                                        <MenuItem value="MemeberId">Member Id</MenuItem>
                                        <MenuItem value="Email">Email</MenuItem>
                                        <MenuItem value="Role">Role</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>



             {permissions && permissions?.includes("add_company_member") &&
                  <Button 
                      variant="contained" 
                      style={{ backgroundColor: '#020429' , color:'white'}} onClick={() => setOpen(true)}
                    >
                       Add Member
                  </Button>
               }

                        </Box>

                        <TableContainer sx={{ bgcolor: "rgba(177, 177, 190, 0.15)", borderRadius: "1rem", height: "auto", mb: 6 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Name
                                        </TableCell>
                                        <TableCell>
                                            Member ID
                                        </TableCell>
                                        <TableCell>
                                            Email
                                        </TableCell>
                                        <TableCell>
                                            Role
                                        </TableCell>
                                        {(permissions?.includes("edit_company_member") || permissions?.includes("delete_company_member")) && (
                                            <TableCell>
                                                Action
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        members.length > 0 ? members?.map((member) => (
                                            <>
                                                <TableRow key={member._id}>
                                                    <TableCell>{member.Name}</TableCell>
                                                    <TableCell>{member._id}</TableCell>
                                                    <TableCell>{member.Email}</TableCell>
                                                    <TableCell>{member.Role}</TableCell>
                                                    <TableCell sx={{ display: "flex", gap: "1rem" }}>
                                                        {permissions && permissions?.includes("edit_company_member") &&
                                                            <Edit sx={{ cursor: "pointer" }} onClick={() => handleEditUser(member)} />
                                                        }
                                                        {permissions && permissions?.includes("delete_company_member") &&
                                                            <Delete sx={{ cursor: "pointer" }}
                                                                onClick={() => { setDeleteModal(true); setUserId(member._id) }}
                                                            />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )) :
                                            <TableRow>
                                                <TableCell>
                                                    No members found
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={totalDocuments} // total items from backend
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(e, newpage) => setPage(newpage)}
                                onRowsPerPageChange={(e) => {
                                    setRowsPerPage(parseInt(e.target.value, 10));
                                    setPage(0);
                                }}
                            />

                        </TableContainer>
                    </>: 
                    <Box
                        sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        height: "70dvh",
                        }}
                    >
                        <NoDataFound
                            title="Insufficient Permissions"
                            subtitle="You do not have permission to view company members."
                        />
                    </Box>
                }
                {open && <Modal open={open}>
                    <Box sx={{ width: "100dvw", display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>

                        <Box sx={{ width: "30rem", display: "flex", flexDirection: "column", gap: "2rem", bgcolor: "#FFF", padding: "2rem", borderRadius: "8px" }}>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Box>
                                    <Typography variant="h6" color="text.secondary" sx={{ color: "#020429" }}>
                                        Add Members
                                    </Typography>

                                </Box>

                                <Close onClick={() => setOpen(false)} sx={{ cursor: "pointer" }} />

                            </Box>

                            <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                            <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            required
                            size="small"
                            error={!!nameError} // Set to true if there's an error
                            helperText={nameError} // Display the error message
                            />
                            
                                <Box sx={{
                                    display:'flex',
                                    flexDirection:'column',
                                    gap:0.5
                                }}>
                                <PhoneInput
                                    country={"us"}
                                    value={formData.phonenumber}
                                    onChange={(value, country) => handlePhoneNumberInputChange(value, country)}
                                    inputStyle={{
                                        width: '100%',
                                        height: '2.5rem',
                                        fontSize: '1rem',
                                        paddingLeft: '3rem',
                                    }}
                                    buttonStyle={{
                                        border: "1px solid #c4c4c4",
                                        borderRadius: "4px 0 0 4px",
                                    }}
                                    inputProps={{
                                        name: "phonenumber",
                                        required: true,
                                        label: "Phone",
                                        'aria-label': 'Phone Number',
                                    }}
                                    containerStyle={{
                                                width: '100%',
                                     }}
                                />
                                {error && (
                                    <p style={{ color: "red", marginTop: "0.5rem", fontSize: "14px" }}>
                                        {error}
                                    </p>
                                )}
                                </Box>
                                <TextField
                                    label="Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    type="email"
                                    size="small"
                                />

                                <FormControl fullWidth size="small">
                                    <TextField
                                        name="Role"
                                        label="Role"
                                        value={formData.Role}
                                        onChange={handleChange}
                                        required
                                        select
                                        size="small"
                                    >
                                        {["SuperAdmin", "Admin", "User"]
                                            .filter(role =>
                                                userRole === "SuperAdmin" ||
                                                (userRole === "Admin" && role !== "SuperAdmin") ||
                                                (userRole === "User" && role === "User")
                                            )
                                            .map(role => (
                                                <MenuItem key={role} value={role}>{role}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </FormControl>

                                <Button 
                                type="submit" 
                                disabled={nameError || !formData.phonenumber || error} 
                                variant="contained" 
                                sx={{ width: "100%", bgcolor: "#02042D" }}
                                >
                                Add Member
                                </Button>
                            </form>

                        </Box>

                    </Box>
                </Modal>}

                {isModalOpen && (
                    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                        <Box sx={{ width: "100dvw", display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>
                            <Box sx={{ width: "30rem", display: "flex", flexDirection: "column", gap: "2rem", bgcolor: "#FFF", padding: "2rem", borderRadius: "8px" }}>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box>
                                        <Typography variant="h6" color="text.secondary" sx={{ color: "#020429" }}>
                                            Update Members
                                        </Typography>

                                    </Box>

                                    <Close onClick={() => setIsModalOpen(false)} sx={{ cursor: "pointer" }} />
                                </Box>

                                <form onSubmit={updateUser} style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                    <TextField
                                        name="Name"
                                        value={selectedMember.Name}
                                        onChange={handleInputChange}
                                        fullWidth
                                        size="small"
                                        placeholder={selectedMember.Name}
                                    />
                                    <TextField
                                        name="Phone"
                                        value={selectedMember.Phone}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ pattern: "\\d{10}" }}
                                        size="small"
                                        placeholder={selectedMember.Phone}
                                    />
                                    <TextField
                                        name="Email"
                                        value={selectedMember.Email}
                                        onChange={handleInputChange}
                                        fullWidth
                                        type="email"
                                        size="small"
                                        placeholder={selectedMember.Email}
                                    />
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            name="Role"
                                            value={selectedMember.Role}
                                            onChange={handleInputChange}
                                            label={selectedMember.Role}
                                            size="small"
                                            select
                                        >
                                            {
                                                userRole === "Admin" ? <>
                                                    <MenuItem value="Admin">Admin</MenuItem>
                                                    <MenuItem value="User">User</MenuItem>
                                                </> :
                                                    <MenuItem value="User">User</MenuItem>
                                            }
                                        </TextField>
                                    </FormControl>

                                    <Button type="submit" variant="contained" sx={{ width: "100%", bgcolor: "#02042D" }}>Update Member</Button>
                                </form>

                            </Box>
                        </Box>
                    </Modal>
                )}

                {deleteModal && (
                    <Modal open={deleteModal} onClose={() => setDeleteModal(false)}>
                        <Box sx={{ width: "100dvw", display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>

                            <Box sx={{ width: "380px", bgcolor: "#FFF", padding: "10px 47px 47px 47px", borderRadius: "18.8px", display: "flex", flexDirection: "column", gap: "15.67px" }}>

                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div></div>
                                    <Close onClick={() => setDeleteModal(false)} />
                                </Box>


                                <div style={{ display: "flex", flexDirection: "column", gap: "18.8px", alignItems: "center" }}>

                                    <HazardIcon sx={{ width: "60px", height: "60px" }} />

                                    <Typography variant="body1" textAlign="center" fontWeight="500">
                                        Are you sure you want to delete this Member you created?
                                    </Typography>

                                    <Box display="flex" alignItems="center" bgcolor="#F7F9FD">
                                        <Checkbox
                                            id="consent-checkbox"
                                            checked={consentChecked}
                                            onChange={(e) => setConsentChecked(e.target.checked)}
                                        />
                                        <label htmlFor="consent-checkbox" style={{ fontSize: "12.537px" }}>
                                            This will permanently delete the Member and its associated data.
                                        </label>
                                    </Box>


                                    <div style={{ width: "100%", display: "flex", gap: "1rem" }}>

                                        <Button onClick={() => {
                                            handleDelete(userId); setDeleteModal(false)
                                        }} variant="contained"
                                            disabled={!consentChecked}
                                            sx={{ textWrap: "nowrap", fontSize: "12.537px", bgcolor: "#02042D", textTransform: "none", padding: "8px 16px" }}
                                        >
                                            Yes, delete this Member
                                        </Button>

                                        <Button onClick={() => setDeleteModal(false)} variant="outlined" sx={{ fontSize: "12.537px", color: "#02042D", borderColor: "gray" }}>
                                            Cancel
                                        </Button>

                                    </div>
                                </div>

                            </Box>
                        </Box>
                    </Modal>)}

            </Box>
        </>
    )
}

export default Member