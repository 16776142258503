import { Box, FormControl, Grid, Hidden, MenuItem, Select } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import CallIcon from '@mui/icons-material/Call';
import DataCard from '../components/DataCard';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { PieChart, Pie, Cell, Legend, Tooltip, CartesianGrid, XAxis, YAxis, BarChart, Bar, Area, ResponsiveContainer, AreaChart, Label } from "recharts";
import { FilterList, ReportProblem } from '@mui/icons-material';
import { useParams } from "react-router-dom";
import { ChartContainer } from './Chart';
import { formatDate, formatDuration } from './Format';
import Loader from '../components/Loader';
import { CustomRadarChart } from "./CustomRadarChart";
import api from '../api';
import { downloadAIToHumanTransferCSV, downloadAvgDurationByIssueTypeCSV, downloadCallAnalysisReport, downloadFirstCallResolutionCSV, downloadPeakHourTrafficCSV, downloadTotalSpentCSV,downloadWPMMetricsCSV } from './download';
import SpeedIcon from '@mui/icons-material/Speed';

const CustomTick = ({ x, y, payload, width }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <foreignObject x={-width / 2} y={0} width={width} height={100}>
                <div style={{
                    width: `${width - 10}px`,
                    whiteSpace: 'break-spaces',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',  // Ensures word wrapping
                    textAlign: 'center',
                    fontSize: 10
                }}>
                    {payload.value}
                </div>
            </foreignObject>
        </g>
    );
};  

const AssistantOverview = () => {
    // State management with default filter as "All"
    const [filter, setFilter] = useState("weekly");
    const [displayData, setDisplayData] = useState({});
    const [loading, setLoading] = useState(false);

    const token = JSON.parse(localStorage.getItem("token"))?.token;
    const { assistantId } = useParams();

    const getFilteredData = useCallback(async () => {
        setLoading(true);
        try {
            const res = await api.get(`/call-logs/assistant-metrics/${assistantId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    filter
                }
            });
            setDisplayData(res.data);
        } catch (error) {
            console.error('Error fetching filtered data:', error);
            setDisplayData({}); // Fallback to prop data on error
        } finally {
            setLoading(false);
        }
    }, [assistantId, filter, token]);

    useEffect(() => {
        if (token && assistantId) {
            getFilteredData();
        }
    }, [filter, getFilteredData]);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    if (loading) {
        return <Loader />;
    }

    const isIncrease = (percentageChange) => {
        return percentageChange && parseFloat(percentageChange) >= 0;
    };


    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem" }}>
                <Box>

                    <FormControl size='small' sx={{ width: "8rem", bgcolor: "#FFF", height: 'fit-content' }}>
                        <Select
                            labelId="filter"
                            id="filter"
                            value={filter}
                            label="Filter"
                            onChange={handleFilterChange}
                            startAdornment={<FilterList />}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="yearly">Yearly</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={3}>
                        <DataCard
                            title="Total Calls"
                            value={displayData.totalCalls?.totalCalls}
                            icons={<CallIcon />}
                            change={displayData.totalCalls?.percentageChange}
                            inc={isIncrease(displayData.totalCalls?.percentageChange)}
                            subtitle="Total call duration accumulated over the selected time period."
                            chart={
                                <ResponsiveContainer width={"100%"} height={"90%"}>
                                    <AreaChart data={displayData.totalCalls?.dataPoints}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={formatDate}
                                            interval="preserveStartEnd"
                                            minTickGap={50}
                                            textAnchor="middle"
                                            fontSize={10}
                                            height={100}
                                        >
                                            <Label value="Date" position="insideBottom" offset={-5} />
                                        </XAxis>
                                        <YAxis
                                            tickFormatter={(value) => value}
                                            fontSize={10}
                                        >
                                            <Label value="Total Calls" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
                                        </YAxis>
                                        <Tooltip
                                            labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                            formatter={(value) => [value, 'Total Calls']}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="totalCalls"
                                            stroke="#02042D"
                                            fill="#A5A8E6"
                                            fillOpacity={0.3}
                                            activeDot={{ r: 4 }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <DataCard
                            title="Average Handle Time"
                            value={`${displayData.avgCallMinutes?.avgDuration || 0} S`}
                            icons={<AccessTimeFilledIcon />}
                            change={displayData.avgCallMinutes?.percentageChange}
                            inc={isIncrease(displayData.avgCallMinutes?.percentageChange)}
                            chart={
                                <ResponsiveContainer width={"100%"} height={"90%"}>
                                    <AreaChart data={displayData.avgCallMinutes?.dataPoints}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={formatDate}
                                            interval="preserveStartEnd"
                                            minTickGap={50}
                                            textAnchor="middle"
                                            fontSize={10}
                                            height={100}
                                            width={110}
                                        >
                                            <Label value="Date" position="insideBottom" offset={-5} />
                                        </XAxis>
                                        <YAxis
                                            tickFormatter={(value) => value}
                                            fontSize={10}
                                        >
                                            <Label value="Average Call Minutes" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
                                        </YAxis>
                                        <Tooltip
                                            labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                            formatter={(value) => [formatDuration(value), 'Average Handle Time']}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="avgDuration"
                                            stroke="#02042D"
                                            fill="#A5A8E6"
                                            fillOpacity={0.3}
                                            activeDot={{ r: 4 }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>

                        <DataCard
                            title="Abandonment Rate"
                            value={`${displayData?.abandonmentRate?.rate || 0}%`}
                            icons={<ReportProblem />}
                            change={displayData.abandonmentRate?.percentageChange}
                            inc={isIncrease(displayData.abandonmentRate?.percentageChange)}
                            chart={
                                <ResponsiveContainer width={"100%"} height={"90%"}>
                                    <AreaChart data={displayData.abandonmentRate?.dataPoints}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={formatDate}
                                            interval="preserveStartEnd"
                                            minTickGap={50}
                                            fontSize={10}
                                            textAnchor="middle"
                                            height={100}
                                        >
                                            <Label value="Date" position="insideBottom" offset={-5} />
                                        </XAxis>
                                        <YAxis
                                            tickFormatter={(value) => `${value}%`}
                                            fontSize={10}
                                        >
                                            <Label value="Abandonment Rate" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
                                        </YAxis>
                                        <Tooltip
                                            labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                            formatter={(value) => [`${value}%`, 'Abandonment Rate']}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="abandonmentRate"
                                            stroke="#02042D"
                                            fill="#A5A8E6"
                                            fillOpacity={0.3}
                                            activeDot={{ r: 4 }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>

                        <DataCard
                            title="Total Cost"
                            value={`${displayData.totalCost?.total || 0} $`}
                            icons={<AttachMoneyIcon />}
                            change={displayData.totalCost?.percentageChange}
                            inc={isIncrease(displayData.totalCost?.percentageChange)}
                            chart={
                                <ResponsiveContainer width={"100%"} height={"90%"}>
                                    <AreaChart data={displayData.totalCost?.dataPoints}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={formatDate}
                                            interval="preserveStartEnd"
                                            minTickGap={50}
                                            fontSize={10}
                                            textAnchor="middle"
                                            height={100}
                                        >
                                            <Label value="Date" position="insideBottom" offset={-5} />
                                        </XAxis>
                                        <YAxis
                                            tickFormatter={(value) => `$ ${value}`}
                                            fontSize={10}
                                        >
                                            <Label value="Total Cost" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
                                        </YAxis>
                                        <Tooltip
                                            labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                            formatter={(value) => [`$ ${value}`, 'Total Cost']}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="totalCost"
                                            stroke="#02042D"
                                            fill="#A5A8E6"
                                            fillOpacity={0.3}
                                            activeDot={{ r: 4 }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        />
                    </Grid>

                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                        <DataCard
                            title="Words Per Minute"
                            value={`${displayData.wpmAnalysis?.avgWPM || 0}`}
                            icons={<SpeedIcon />}
                            change={displayData.wpmAnalysis?.percentageChange}
                            inc={isIncrease(displayData.wpmAnalysis?.percentageChange)}
                            chart={
                                <ResponsiveContainer width={"100%"} height={"90%"}>
                                    <AreaChart data={displayData.wpmAnalysis?.dataPoints}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={formatDate}
                                            interval="preserveStartEnd"
                                            minTickGap={50}
                                            fontSize={10}
                                            textAnchor="middle"
                                            height={100}
                                        >
                                            <Label value="Date" position="insideBottom" offset={-5} />
                                        </XAxis>
                                        <YAxis
                                            tickFormatter={(value) => `${value}`}
                                            fontSize={10}
                                        >
                                            <Label value="WPM" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
                                        </YAxis>
                                        <Tooltip
                                            labelFormatter={(label) => `Date: ${formatDate(label)}`}
                                            formatter={(value) => [`${value} WPM`, 'Words per Minute']}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="wpm"
                                            stroke="#02042D"
                                            fill="#A5A8E6"
                                            fillOpacity={0.3}
                                            activeDot={{ r: 4 }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        />
                    </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <ChartContainer
                            title="Call Analysis"
                            subtitle="Insights on call performance on various factors."
                            download={() => downloadCallAnalysisReport({ filter, assistant: assistantId, organization: null })}
                            chart={
                                displayData?.callAnalysis?.transferedResult || displayData?.callAnalysis?.goalAchievementResult || displayData?.callAnalysis?.userSatisfactionResult || displayData?.callAnalysis?.scriptAdherenceResult || displayData?.callAnalysis?.callAbandonmentResult ?
                                    <CustomRadarChart
                                        data={[
                                            { name: "Call Transferred", value: displayData.callAnalysis?.transferedResult || 0 },
                                            { name: "Goal Achievement", value: displayData.callAnalysis?.goalAchievementResult || 0 },
                                            { name: "User Satisfaction", value: displayData.callAnalysis?.userSatisfactionResult || 0 },

                                            { name: "SOP Adherence (%)", value: displayData.callAnalysis?.scriptAdherenceResult || 0 },
                                            { name: "Call Abandonment", value: (displayData.callAnalysis?.callAbandonmentResult || 0).toFixed(2) },

                                        ]}
                                    /> :
                                    "No data found for applied filters."
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <ChartContainer
                            title={"Total Spent"}
                            subtitle="Total spending view on call operations for budgeting and cost tracking."
                            download={() => downloadTotalSpentCSV({ filter, assistant: assistantId, organization: null })}
                            chart={
                                displayData.totalCost?.total > 0 ? (
                                    <AreaChart data={displayData.totalCost?.dataPoints}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={formatDate}
                                            interval="preserveStartEnd"
                                            minTickGap={50}
                                            textAnchor="middle"
                                            height={50}
                                            fontSize={10}
                                        >
                                            <Label
                                                value="Date"
                                                position="insideBottom"
                                                height="100"
                                            />
                                        </XAxis>
                                        <YAxis tickFormatter={(value) => `$ ${value.toFixed(2)}`} fontSize={10}>
                                            <Label
                                                value="Total Cost"
                                                angle={-90}
                                                position="insideLeft"
                                                style={{ textAnchor: 'middle' }}
                                            />
                                        </YAxis>
                                        <Tooltip
                                            labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                            formatter={(value) => [`$ ${value.toFixed(2)}`, 'Total Cost']}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="totalCost"
                                            stroke="#02042D"
                                            fill="#A5A8E6"
                                            fillOpacity={1}
                                            dot={false}
                                            activeDot={true}
                                        />
                                    </AreaChart>
                                ) : (
                                    "No data found for applied filters."
                                )
                            }
                        />

                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <ChartContainer
                            title={"First Call Resolution"}
                            subtitle="Percentage of issues resolved on the first call to enhance customer satisfaction."
                            download={() => downloadFirstCallResolutionCSV({ filter, organization: null, assistant: assistantId })}
                            chart={
                                displayData?.firstCallResolution?.firstCallResolutionCount || displayData?.firstCallResolution?.nonFirstCallResolutionCount ?
                                    <PieChart>
                                        <Pie
                                            data={[
                                                {
                                                    name: "Resolved",
                                                    value: displayData?.firstCallResolution?.firstCallResolutionCount
                                                },
                                                {
                                                    name: "Not Resolved",
                                                    value: displayData?.firstCallResolution?.nonFirstCallResolutionCount
                                                }

                                            ]}
                                            innerRadius={50}
                                            outerRadius={80}
                                            fill="#AAA"
                                            dataKey="value"
                                        >
                                            {["#02042D", "#CCCCCC"].map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={["#02042D", "#CCCCCC"][index]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                    : "No data found for applied filters."
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <ChartContainer
                            title={"AI to Human Handover Rate"}
                            subtitle="Rate of interactions transferred from AI to human agents for resolution."
                            download={() => downloadAIToHumanTransferCSV({ filter, assistant: assistantId, organization: null })}
                            chart={
                                displayData?.aiToHumanTransfer?.transferred || displayData?.aiToHumanTransfer?.notTransferred ?
                                    <PieChart>
                                        <Pie
                                            data={[

                                                { name: "Transferred", value: displayData?.aiToHumanTransfer?.transferred },
                                                { name: "Not Transferred", value: displayData?.aiToHumanTransfer?.notTransferred }
                                            ]}
                                            innerRadius={50}
                                            outerRadius={80}
                                            fill="#AAA"
                                            dataKey="value"
                                        >
                                            {["#02042D", "#CCCCCC"].map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={["#02042D", "#CCCCCC"][index]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                    : "No data found for applied filters."
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <ChartContainer
                            title={"Peak Hours Traffic"}
                            subtitle="Call volume trends by hour for optimal staffing."
                            download={() => downloadPeakHourTrafficCSV({ filter, assistant: assistantId, organization: null })}
                            chart={
                                displayData.peakHourTraffic ?
                                    <AreaChart
                                        width={800}
                                        height={400}
                                        data={displayData?.peakHourTraffic}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="hour" height={50} fontSize={10}>
                                            <Label value="Hour of the Day" position="insideBottom" />
                                        </XAxis>
                                        <YAxis fontSize={10}>
                                            <Label
                                                value="Number of Calls"
                                                angle={-90} position="insideLeft" style={{ textAnchor: "middle" }}
                                            />
                                        </YAxis>
                                        <Tooltip
                                            content={({ payload }) =>
                                                <div style={{ display: "flex", flexDirection: "column", backgroundColor: "white", padding: ".5rem 1rem", borderRadius: "5px", outline: "1px solid #CCC" }}>
                                                    <span>Hour: {payload[0]?.payload.hour}</span>
                                                    <span>Call Count: {payload[0]?.value}</span>
                                                </div>
                                            }
                                        />
                                        <Area type="monotone" dataKey="callCount" stroke="#02042D" fill="#A5A8E6" fillOpacity={0.3} />
                                    </AreaChart>
                                    : "No data found for applied filters."
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <ChartContainer
                    title={"Average Handle Time by Issue Type"}
                    subtitle="Average resolution time per issue type."
                    download={() => downloadAvgDurationByIssueTypeCSV({ filter, assistant: assistantId, organization: null })}
                    chart={
                        displayData.avgCallMinutesByIssueType ?
                            <BarChart data={displayData?.avgCallMinutesByIssueType} width={500} height={300}>
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis
                                    dataKey="issueType"
                                    fontSize={10}
                                    tick={<CustomTick width={400 / displayData.avgCallMinutesByIssueType.length} />}
                                />
                                <YAxis
                                    fontSize={10}
                                    tick={<CustomTick width={displayData.avgCallMinutes/3600}/>}
                                >
                                    <Label value="Average Handle Time" angle={-90} position="insideLeft" style={{ textAnchor: "middle" }} />
                                </YAxis>
                                <Tooltip 
                                    formatter={(value, name) => {
                                        // Round seconds to nearest integer
                                        const roundedValue = Math.round(value);
                                        const hours = Math.floor(roundedValue / 3600);
                                        const minutes = Math.floor((roundedValue % 3600) / 60);
                                        const seconds = roundedValue % 60;

                                        // Return formatted time with labels: either hhhrs:mmmins or mmmins:ssseconds
                                        return [`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`, "Average Duration"];

                                    }} 
                                    cursor={{ fill: "transparent" }} 
                                />
                                <Bar dataKey="avgDuration" fill="#02042D" />
                            </BarChart>
                        : "No data found for applied filters."
                    }
                />


                    </Grid>

                    <Grid item xs={12} md={6}>
                        <ChartContainer
                            title="Words Per Minute Analysis"
                            subtitle="Detailed analysis of Speaking Rate over time"
                            download={() => downloadWPMMetricsCSV({ 
                                filter, 
                                assistant: assistantId, 
                                organization: null 
                            })}
                        chart={
                            displayData.wpmAnalysis?.dataPoints ? (
                                <AreaChart data={displayData.wpmAnalysis.dataPoints}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="date"
                                        tickFormatter={formatDate}
                                        interval="preserveStartEnd"
                                        minTickGap={50}
                                        textAnchor="middle"
                                        height={50}
                                        fontSize={10}
                                    >
                                        <Label
                                            value="Date"
                                            position="insideBottom"
                                            height="100"
                                        />
                                    </XAxis>
                                    <YAxis 
                                        tickFormatter={(value) => `${value} WPM`} 
                                        fontSize={10}
                                    >
                                        <Label
                                            value="Words Per Minute"
                                            angle={-90}
                                            position="insideLeft"
                                            style={{ textAnchor: 'middle' }}
                                        />
                                    </YAxis>
                                    <Tooltip
                                        labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                        formatter={(value) => [`${value.toFixed(2)} WPM`, 'Speaking Rate']}
                                        cursor={{ fill: "transparent" }}
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="wpm"
                                        stroke="#02042D"
                                        fill="#A5A8E6"
                                        fillOpacity={0.3}
                                        dot={false}
                                        activeDot={true}
                                    />
                                </AreaChart>
                            ) : (
                                "No data found for applied filters."
                            )
                        }
                    />
                    </Grid>

                    </Grid >
            </Box>
        </>
    )
}

export default AssistantOverview;
