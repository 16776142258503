import { Route, Routes } from "react-router-dom";
import AdminOverview from "./pages/admin/Overview.admin";
import AdminAssistant from "./pages/admin/Assistant.admin";
import AdminPhoneNumber from "./pages/admin/PhoneNumber.admin";
import Logs from "./pages/Logs";
import Member from "./pages/admin/Member.admin";
import AssistantDetailAdmin from "./pages/admin/AssistantDetail.admin";
import PhoneNumberDetailAdmin from "./pages/admin/PhoneNumberDetail.admin";
import NewAssistant from "./pages/NewAssistant";
import CreateAssistant from "./pages/CreateAssistant";
import NewAssistantDetails from "./pages/NewAssistantDetails";
import ViewAssistantTemplate from "./pages/ViewAssistantTemplate"
import BuyCallingMinutes from "./pages/BuyCallingMinutes";

export const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="*" element={<AdminOverview />} />
            <Route path="/company/:company_id/overview" element={<AdminOverview />} />
            {/* <Route path="/company/:company_id/assistants" element={<AdminAssistant />} /> */}
            <Route path="/company/:company_id/assistants" element={<NewAssistant />} />
            <Route path="/company/:company_id/assistants/create" element={<CreateAssistant />} />
            <Route path="/company/:company_id/phone-number" element={<AdminPhoneNumber />} />
            <Route path="/company/:company_id/logs" element={<Logs />} />
            <Route path="/company/:company_id/member" element={<Member />} />
            {/* <Route path="/company/:company_id/assistant/:id" element={<AssistantDetailAdmin />} /> */}
            <Route path="/company/:company_id/assistant/:assistantId" element={<NewAssistantDetails />} />
            <Route path="/company/:company_id/phone-number/:id" element={<PhoneNumberDetailAdmin />} />
            <Route path="/company/:company_id/view-assistant/:id" element={<ViewAssistantTemplate />} />
            <Route path="/company/:company_id/credits" element={<BuyCallingMinutes />} />
        </Routes>
    );
};
