import React, { useEffect, useState, useCallback } from 'react';
import { Tabs, Tab, Box, Button, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import {
    CheckCircleOutline,
    DashboardOutlined,
    DescriptionOutlined,
    RecordVoiceOverOutlined,
    SettingsOutlined
} from '@mui/icons-material';

import InitialDetails from './AssistantForms/InitialDetails';
import Prompt from './AssistantForms/Prompt';
import TranscriberAndAudio from './AssistantForms/TranscriberAndAudio';
import AdditionalConfigurations from './AssistantForms/AdditionalConfigurations';
import Actions from './AssistantForms/Actions';
import Loader from './Loader';
import api from "../api";
import { useGetAllPermissions } from '../pages/utils/getAllPermissions';

const TABS_CONFIG = [
    {
        id: 'information',
        label: 'Information',
        icon: <DashboardOutlined sx={{ fontSize: 20 }} />,
        component: InitialDetails,
        requiredPermissions: []
    },
    {
        id: 'prompt',
        label: 'Prompt',
        icon: <DescriptionOutlined sx={{ fontSize: 20 }} />,
        component: Prompt,
        requiredPermissions: ['fetch_llm_model', 'fetch_LLM_providers']
    },
    {
        id: 'transcriber',
        label: 'Transcriber and Voice',
        icon: <RecordVoiceOverOutlined sx={{ fontSize: 20 }} />,
        component: TranscriberAndAudio,
        requiredPermissions: [
            'get_voice_providers',
            'fetch_VoiceSynthesizers_model',
            'fetch_voicelanguage_by_synthesizerid',
            'fetch_language_by_transcriberid',
            'fetch_transcribers_provider'
        ]
    },
    {
        id: 'configurations',
        label: 'Configurations',
        icon: <SettingsOutlined sx={{ fontSize: 20 }} />,
        component: AdditionalConfigurations,
        requiredPermissions: []
    },
    {
        id: 'actions',
        label: 'Actions',
        icon: <CheckCircleOutline sx={{ fontSize: 20 }} />,
        component: Actions,
        requiredPermissions: ['create_action']
    }
];

const NewAssistantForm = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userData, setUserData] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);

    const navigate = useNavigate();
    const { company_id } = useParams();
    const { permissions, permissionsLoading, getAllPermissions } = useGetAllPermissions();

    useEffect(() => {
        const initializeComponent = async () => {
            try {
                const user = JSON.parse(localStorage.getItem("User"));
                setUserData(user);
                await getAllPermissions();
                setIsInitialized(true);
            } catch (error) {
                console.error('Initialization error:', error);
                toast.error('Failed to initialize component');
            }
        };

        initializeComponent();
    }, [getAllPermissions]);

    const convertBase64ToBlob = useCallback((base64Data) => {
        if (!base64Data) return null;

        const byteString = atob(base64Data.content.split(',')[1]);
        const mimeString = base64Data.content.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File(
            [new Blob([ab], { type: mimeString })],
            base64Data.name,
            { type: base64Data.type }
        );
    }, []);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const assistant = JSON.parse(localStorage.getItem("assistant"));
            const formData = new FormData();

            Object.entries(assistant).forEach(([key, value]) => {
                formData.append(key, value);
            });

            formData.append("Organization", company_id?company_id:localStorage.getItem("Organization"));

            const logoFile = convertBase64ToBlob(JSON.parse(localStorage.getItem("Logo")));
            const knowledgeFile = convertBase64ToBlob(JSON.parse(localStorage.getItem("KnowledgeBase")));

            if (logoFile) formData.append("logo", logoFile);
            if (knowledgeFile) formData.append("knowledgeBase", knowledgeFile);

            const { data } = await api.post("/v1/assistant/create", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${userData?.Token}`
                }
            });

            toast.success(data.message);

            const actions = JSON.parse(localStorage.getItem('actions') || '[]');
            await Promise.all(actions
                .filter(action => !action._id)
                .map(action => api.post("/v1/actions/create-action",
                    { ...action, Assistant: data.assistant._id },
                    {
                        headers: {
                            'Authorization': `Bearer ${userData?.Token}`
                        }
                    }
                ))
            );

            navigate(userData?.Role === "SuperAdmin"
                ? "/assistants"
                : `/company/${company_id}/assistants`
            );

        } catch (error) {
            toast.error(error?.response?.data?.details || 'An error occurred');
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Show loader while permissions are being fetched or component is initializing
    if (permissionsLoading || !isInitialized) {
        return <Loader />;
    }

    const availableTabs = TABS_CONFIG.filter(tab =>
        tab.requiredPermissions.length === 0 ||
        tab.requiredPermissions.every(permission => permissions?.includes(permission))
    );

    return (
        <Box sx={{ width: '100%', p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box sx={{
                    display: 'flex',
                    backgroundColor: '#02042d',
                    borderRadius: '0.5rem',
                    pt: 1,
                    pl: 1,
                    width: 'fit-content'
                }}>
                    <Tabs
                        value={activeTab}
                        onChange={(_, newValue) => setActiveTab(newValue)}
                        sx={{
                            mb: -1,
                            '& .MuiTabs-flexContainer': {
                                borderRadius: '8px',
                                overflow: 'hidden',
                                height: '2rem'
                            },
                            '& .MuiTab-root': {
                                textTransform: 'none',
                                p: '0.25rem .5rem',
                                mr: 1,
                                borderRadius: '8px',
                                backgroundColor: '#02042d',
                                color: '#ffffff',
                                minHeight: '1rem',
                                height: '2rem',
                                fontWeight: 'semi',
                                '&.Mui-selected, &:hover': {
                                    backgroundColor: '#353657',
                                    color: '#ffffff'
                                }
                            },
                            '& .MuiTabs-indicator': {
                                display: 'none'
                            }
                        }}
                    >
                        {availableTabs.map((tab) => (
                            <Tab
                                key={tab.id}
                                label={tab.label}
                                icon={tab.icon}
                                iconPosition="start"
                            />
                        ))}
                    </Tabs>
                </Box>

                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    sx={{
                        ml: 'auto',
                        mt: 1,
                        backgroundColor: '#02042d',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#353657'
                        }
                    }}
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} sx={{ color: '#fff' }} />
                    ) : (
                        "Publish"
                    )}
                </Button>
            </Box>

            {availableTabs[activeTab]?.component &&
                React.createElement(availableTabs[activeTab].component, {
                    assistantId: null
                })
            }
        </Box>
    );
};

export default NewAssistantForm;