import { Box, Button, Input, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import {
    Chip,
    FormControl,
} from "@mui/material";
import api from "../api";
import { toast } from 'sonner';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString, isPossiblePhoneNumber } from "libphonenumber-js";



const Outbound = ({ assistantId, phone }) => {
    const [phoneNumberInput, setphoneNumberInput] = useState(""); // Current input value
    const [phoneNumbers, setphoneNumbers] = useState([]); // List of valid phone numbers
    const [error, setError] = useState(""); // Validation error message
    const [selected, setSelected] = useState("")
    const [assistants, setAssistants] = useState([])
    const [token, setToken] = useState("");

    const handleKeyUp = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            // Ensure no error and a valid number is inputted
            if (!error && phoneNumberInput.trim() !== "") {
                setphoneNumbers((prev) => [...prev, phoneNumberInput]);
                setphoneNumberInput(""); // Clear input after adding
            }
        }
    };

    const handleChange = (value, countryData) => {
        if (!value) {
            setError(`Please enter a phone number for ${countryData.name}.`);
            setphoneNumberInput(value);
            return;
        }
    
        try {
            const phoneNumber = parsePhoneNumberFromString(`+${value.toString()}`, countryData.countryCode.toUpperCase());    
            // Check if phone number is valid
            if (!phoneNumber || !phoneNumber.isValid()) {
                setError(`Invalid phone number format for ${countryData.name}.`);
            } else {
                setError(""); // Clear error if valid
            }
        } catch (error) {
            setError(`Invalid input. Please check the phone number for ${countryData.name}.`);
        }
    
        setphoneNumberInput(value);
    };



    const handleDelete = (itemToRemove) => {
        setphoneNumbers((prev) => prev.filter((item) => item !== itemToRemove));
    };

    const handleSelect = (e) => {
        setSelected(e.target.value)
    }

    const getToken = () => {
        const t = JSON.parse(localStorage.getItem('token')).token
        setToken(t)
    }


    const fetchAllAssistants = async () => {
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }

        const response = await api.get("/assistants?limit=9999", config)
        setAssistants(response.data.data.assistants)
    }

    const startOutboundCall = async () => {
        try {
            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }

            const response = await api.post("/assistants/initiate-outbound-call", { assistantId: assistantId, OutboundPhone: phoneNumbers, associatedPhone: phone.PhoneNumber }, config)
            toast.success("Call Initiated")
        } catch (error) {
            toast.error("Failed to initiate call.")
        }
    }

    useEffect(() => {
        getToken();
        if (token) {
            fetchAllAssistants()
        }
    }, [token])

    if (!phone?.OutboundAssistantId) {
        return (
            <Typography variant="h5">first assign the assistant to initiate outbound call</Typography>
        );
    }

    return (
        <Box sx={{ display: 'flex',flexDirection: 'column', gap: '1rem', padding: '1rem', marginTop: '2rem',  borderRadius: "5px", bgcolor: "rgba(177, 177, 190, 0.11)", border: "1px solid #A9A9A9" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem',borderRadius: "8px" }}>
                <Typography variant="subtitle2" component="h4">Outbound Call</Typography>
                <Typography variant="body1">Add numbers into the list and press 'Enter'.</Typography>

                <FormControl>
                <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "1rem" }}>
                    {phoneNumbers.map((item, index) => (
                        <Chip
                            key={index}
                            size="small"
                            onDelete={() => handleDelete(item)}
                            label={item}
                            sx={{
                                mb:2,
                            }}
                        />
                    ))}
                </Box>
                <PhoneInput
                    country={"us"}
                    value={phoneNumberInput}
                    onChange={(value, country) => handleChange(value, country)}
                    onKeyDown={handleKeyUp}
                    id="phone-number-input"
                    placeholder="Enter phone number and press 'Enter'"
                    inputStyle={{
                    width: '100%',
                    height: '2.5rem',
                    fontSize: '1rem',
                    paddingLeft: '3rem',
                }}
                buttonStyle={{
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px 0 0 4px",
                }}
                inputProps={{
                    name: "PhoneNumber",
                    required: true,
                    label: "Phone Number",
                    'aria-label': 'Phone Number',
                    'aria-required': 'true',
                    'aria-invalid': !!error,
                    'aria-errormessage': error ? 'phone-error' : undefined
                }}
                containerStyle={{
                            width: '100%',
                 }}
                />
                {error && (
                    <Typography variant="body2" color="error" sx={{ marginTop: "0.5rem" }}>
                        {error}
                    </Typography>
                )}
            </FormControl>


            <Button 
                variant="contained" 
                sx={{ bgcolor: "#020429", color: "white" }} 
                onClick={startOutboundCall} 
                disabled={phoneNumbers.length === 0} // Disable button if no numbers are entered
            >
                Start Call
            </Button>
            </Box>
        </Box>
    )
}

export default Outbound;
