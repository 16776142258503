import React, { useCallback, useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { 
    Avatar, 
    Box, 
    Chip, 
    Divider, 
    Drawer, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TextField, 
    Typography, 
    TablePagination, 
    tableCellClasses,
    Menu,
    MenuItem,
    IconButton,
    FormControl,
    InputLabel,
    Select
} from '@mui/material';
import { 
    AnalyticsOutlined, 
    AssignmentTurnedInOutlined, 
    AttachMoneyOutlined, 
    Call, 
    CardMembership, 
    CheckCircleOutline, 
    CodeOutlined, 
    DescriptionOutlined, 
    FilterList,
    GetApp, 
    InfoOutlined, 
    ListAltOutlined, 
    MoreVert, 
    RecordVoiceOverOutlined, 
    SearchOutlined, 
    VolumeOffOutlined,
    ContentCopy
} from '@mui/icons-material';
import api from "../api";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import WaveformPlayer from '../components/Wave';
import ClipboardBox from "../components/ClipboardBox";
import ShimmerLoader from "../components/ShimmerLoader";
import { CustomLabel } from '../components/CustomLabel';
import NoDataFound from '../components/NoDataFound';
import { COLORS } from "../components/Constant";
import { convertToMinutes, getCallCost , formatDateTime12Hours } from '../components/Format';
import { capitalizeFirstLetter } from '../pages/utils/stringCapital';

const TabHeader = ({ title, subtitle }) => (
    <Box sx={{ 
        p: 3,
        mx: 3
    }}>
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 1, color: 'black', fontSize: '24px' }}>{title}</Typography>
        <Typography variant="body1" sx={{ color: '#7c7c83', fontWeight: 500, fontSize: '16px' }}>{subtitle}</Typography>
    </Box>
);

const Logs = () => {
    const [open, setOpen] = useState(false);
    const [datas, setDatas] = useState([]);
    const [details, setDetails] = useState("");
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("");
    const [tab, setTab] = useState("info");
    const [anchorEl, setAnchorEl] = useState(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [loading, setLoading] = useState({
        assistants: true,
        logs: true,
        details: false
    });
    const [drawerTab, setDrawerTab] = useState("info");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);
    const [assistantList, setAssistantList] = useState([]);
    const [assistant, setAssistant] = useState("All");
    const [apiFilter, setApiFilter] = useState("");
    const token = JSON.parse(localStorage.getItem("token"))?.token;

    const handleTabChange = (newValue) => {
        setCurrentTab(newValue);
        setPage(0);
        setSearch("");
        setLoading(prev => ({ ...prev, logs: true }));
    };

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const handleDownload = (event, fileUrl) => {
        event.stopPropagation();
        if (fileUrl) {
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', '');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleFilterChange = (filterValue) => {
        // Map the filterValue for display purposes
        const displayFilter = filterValue === "" 
          ? "" 
          : (filterValue === "last7days" ? "Last 7 Days" : (filterValue === "last30days" ? "Last 30 Days" : capitalizeFirstLetter(filterValue)));
    
        // Set both the display filter and the API filter
        setFilter(displayFilter);
        setApiFilter(filterValue);  // This stores the raw filter value used by the API
        setPage(0);
        setLoading(prev => ({ ...prev, logs: true }));
        handleFilterClose();
    };
    

    const GetAllLogs = useCallback(async (searchTerm = "") => {
        setLoading(prev => ({ ...prev, logs: true }));
        try {
            let response;
            if (currentTab === 4) {
                response = await api.get('/v1/scheduled-calls/all-schedule-bulk-call', {
                    params: {
                        page: page + 1,
                        batchSize: rowsPerPage,
                        filter: apiFilter,
                    },
                    
                    headers: { Authorization: `Bearer ${token}` },
                });
                console.log(apiFilter);
                setDatas(response.data.data.campaigns);
                setTotalPages(response.data.data.pagination.totalPages);
                setTotalRows(response.data.data.pagination.totalRecords);
            } else {
                const tabTypes = ["", "inbound", "outbound", "Web"];
                const tabSearch = tabTypes[currentTab];
                response = await api.get(`/call-logs/all-call-logs`, {
                    params: {
                        limit: rowsPerPage,
                        page: page + 1,
                        search: searchTerm,
                        tabSearch:tabSearch,
                        filter: apiFilter,
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setDatas(response.data.data);
                setTotalPages(response.data.pages);
                setTotalRows(response.data.total);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(prev => ({ ...prev, logs: false }));
        }
    }, [rowsPerPage, apiFilter, page, token, currentTab]);

    const handleSearch = (event) => {
        setSearch(event.target.value);
        setLoading(prev => ({ ...prev, logs: true }));
    };

    const getAllAssistants = useCallback(async () => {
        setLoading(prev => ({ ...prev, assistants: true }));
        try {
            const response = await api.post(`/organizations/assistants-by-company`,
                {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );
            setAssistantList(response.data.data);
        } catch (error) {
            console.error("Error fetching companies:", error);
        } finally {
            setLoading(prev => ({ ...prev, assistants: false }));
        }
    }, [token]);

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setDebounceTimeout(search);
        }, 1000);

        return () => clearTimeout(debounceTimeout);
    }, [search]);

    useEffect(() => {
        if (token && debounceTimeout !== null) {
            GetAllLogs(search);
        } else {
            GetAllLogs()
        }
    }, [token, search, GetAllLogs, currentTab, filter]);

    useEffect(() => {
        if (token) {
            getAllAssistants();
        }
    }, [token, getAllAssistants]);

    const getStatusChip = (status) => {
        if (!status) {
            return <Chip label="Unknown" size="small" sx={{ bgcolor: '#e0e0e0', color: '#000000' }} />;
        }
        
        switch(status.toLowerCase()) {
            case 'completed':
                return <Chip 
                    label="Completed" 
                    size="small" 
                    sx={{ 
                        bgcolor: '#dfffef', 
                        color: '#00cd00',
                        '& .MuiChip-label': { fontWeight: 500 }
                    }} 
                />;
            case 'scheduled':
                return <Chip 
                    label="Scheduled" 
                    size="small" 
                    sx={{ 
                        bgcolor: '#f8f7db', 
                        color: '#9a8a00',
                        '& .MuiChip-label': { fontWeight: 500 }
                    }} 
                />;
            case 'failed':
                return <Chip 
                    label="Failed" 
                    size="small" 
                    sx={{ 
                        bgcolor: '#ffc9c9', 
                        color: '#cd0000',
                        '& .MuiChip-label': { fontWeight: 500 }
                    }} 
                />;
            case 'pending':
                return <Chip 
                    label="Pending" 
                    size="small" 
                    sx={{ 
                        bgcolor: '#fff4e5', 
                        color: '#ffa500',
                        '& .MuiChip-label': { fontWeight: 500 }
                    }} 
                />;
            default:
                return <Chip 
                    label={status} 
                    size="small" 
                    sx={{ 
                        bgcolor: '#e0e0e0', 
                        color: '#000000',
                        '& .MuiChip-label': { fontWeight: 500 }
                    }} 
                />;
        }
    };

    const tabs = ["All", "Inbound", "Outbound", "Web", "Schedule Calls"];

    const handleOpenDrawer = (data) => {
        setOpen(true);
        setDetails(data);
        setLoading(prev => ({ ...prev, details: true }));
        // Simulating data fetch delay
        setTimeout(() => {
            setLoading(prev => ({ ...prev, details: false }));
        }, 1000);
    };

    return (
        <>
            <Navbar title={"Logs"} />
            <Box sx={{ padding: "2rem" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "2rem" }}>
                    <Box sx={{ display: "flex", backgroundColor: "#ededed", borderRadius: "8px", padding: "0.25rem", gap: "1rem" }}>
                        {tabs.map((tab, index) => (
                            <Typography
                                key={index}
                                onClick={() => handleTabChange(index)}
                                sx={{
                                    cursor: "pointer",
                                    padding: "0.5rem 1rem",
                                    borderRadius: currentTab === index ? "4px" : "8px",
                                    fontWeight: 500,
                                    backgroundColor: currentTab === index ? "#ffffff" : "transparent",
                                    color: currentTab === index ? "black" : "#7c7c83",
                                    transition: "all 0.3s ease",
                                    '&:hover': {
                                        backgroundColor: "#ffffff",
                                        color: "black",
                                        borderRadius: "4px",
                                    }
                                }}
                            >
                                {tab}
                            </Typography>
                        ))}
                    </Box>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #e6e7eb',
                                borderRadius: '7px',
                                padding: '0.5rem 1rem',
                                cursor: 'pointer',
                                '&:hover': { bgcolor: '#f5f5f5' }
                            }}
                            onClick={handleFilterClick}
                        >
                            <FilterList sx={{ mr: 1 }} />
                            <Typography>{filter || "Filter"}</Typography>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleFilterClose}
                        >
                            <MenuItem onClick={() => handleFilterChange("")}>All</MenuItem>
                            <MenuItem onClick={() => handleFilterChange("today")}>Today</MenuItem>
                            <MenuItem onClick={() => handleFilterChange("yesterday")}>Yesterday</MenuItem>
                            <MenuItem onClick={() => handleFilterChange("last7days")}>Last 7 Days</MenuItem>
                            <MenuItem onClick={() => handleFilterChange("last30days")}>Last 30 Days</MenuItem>
                        </Menu>
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Search by Assistant or Call Id"
                            value={search}
                            onChange={handleSearch}
                            InputProps={{
                                sx: { borderRadius: '8px', bgcolor: 'white',width: '20rem' },
                            }}
                        />
                    </Box>
                </Box>

                <Box sx={{ 
                    marginTop: "2rem", 
                    backgroundColor: 'white', 
                    border: '1px solid #e6e7eb',
                    borderRadius: '8px',
                    overflow: 'hidden'
                }}>
                    <TabHeader 
                        title={tabs[currentTab] + (currentTab === 4 ? "" : " Calls")} 
                        subtitle={`Easily view and manage ${tabs[currentTab].toLowerCase()} ${currentTab === 4 ? "" : "call"} logs`} 
                    />
                    <TableContainer sx={{ 
                            border: '1px solid #e6e7eb',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            marginLeft: '40px',
                            width:'1250px'
                        }}> 
                        {loading.logs ? (
                            <ShimmerLoader height="400px" />
                        ) : datas.length > 0 ? (
                            <Table sx={{ minWidth: 600 }} aria-label="call logs table">
                                <TableHead>
                                    <TableRow>
                                        {currentTab === 4 ? (
                                            <>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Campaign Name</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Campaign ID</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Scheduled Date</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Contacts</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Status</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Bulk Call File</TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Assistant</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Type</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Call Cost</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Ended Reason</TableCell>
                                                {currentTab !== 3 && <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Phone Number</TableCell>}
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Call Duration</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#777780' }}>Date/Time</TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {datas.map((data, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => { if (currentTab !== 4) { handleOpenDrawer(data); } }}
                                        >
                                            {currentTab === 4 ? (
                                                <>
                                                    <TableCell>{data.name}</TableCell>
                                                    <TableCell>{data.id}</TableCell>
                                                    <TableCell>
                                                        {data.schedule_datetime ? formatDateTime12Hours(data.schedule_datetime) : 'Not Available'}
                                                    </TableCell>
                                                    <TableCell>{data.contact_count}</TableCell>
                                                    <TableCell>{getStatusChip(data.status)}</TableCell>
                                                    <TableCell>
                                                        <IconButton 
                                                            size="small" 
                                                            onClick={(event) => handleDownload(event, data.
                                                                uploaded_file_link)}
                                                            >
                                                                <GetApp fontSize="small" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <TableCell>
                                                            <Box sx={{ display: "flex", alignItems: 'center', gap: '.5rem' }}>
                                                                <Avatar
                                                                    alt={data.AssistantDetails}
                                                                    src={data.Assistant?.Logo?.fileUrl}
                                                                    sx={{ width: 30, height: 30 }}
                                                                />
                                                                <Box>
                                                                    <Typography sx={{ fontSize: "14px", fontWeight: "600", margin: 0 }} variant="body2" component="p">
                                                                        {data.AssistantDetails}
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        {data.Type}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell sx={{ textTransform: "capitalize" }}>{data.Type}</TableCell>
                                                        <TableCell>${data.TotalCost}</TableCell>
                                                        <TableCell>
                                                            {getStatusChip('Not Available')}
                                                        </TableCell>
                                                        {currentTab !== 3 && <TableCell>{data.PhoneNumber ? data.PhoneNumber : "NA"}</TableCell>}
                                                        <TableCell>
                                                            {data.Duration ? convertToMinutes(data.Duration) : '0 second'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {data.CreatedAt ? formatDateTime12Hours(data.CreatedAt) : 'Not Available'}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                                    <Typography variant="body1">No logs available. Set up an assistant to start generating call logs.</Typography>
                                </Box>
                            )}
                        </TableContainer>
                        {datas.length > 0 && (
                            <TablePagination
                                component="div"
                                count={totalRows}
                                page={page}
                                onPageChange={(e, newpage) => { 
                                    setPage(newpage);
                                    setLoading(prev => ({ ...prev, logs: true }));
                                }}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={(e) => { 
                                    setRowsPerPage(parseInt(e.target.value, 10)); 
                                    setPage(0);
                                    setLoading(prev => ({ ...prev, logs: true }));
                                }}
                                sx={{ mx: 3 }} 
                            />
                        )}
                    </Box>
                </Box>
    
                {details && currentTab !== 4 && (
                    <Drawer open={open} onClose={() => setOpen(false)} anchor="right" sx={{ overflowY: "scroll", minHeight: "110vh" }}>
                    <Box sx={{ width: "600px", padding: "1rem 1.5rem", minHeight: "110vh" }}>
                        <Typography variant="h6">Call Log Details</Typography>
                        <Divider orientation='horizontal' sx={{ margin: "1rem 0" }} />

                        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
                            <Typography variant="subtitle2">Detailed Information</Typography>
                            {
                                details.Recording ?
                                    <WaveformPlayer audioUrl={details.Recording} />
                                    :
                                    <Box style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                                        <VolumeOffOutlined />
                                        Recording not available
                                    </Box>

                            }

                            <Box>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", bgcolor: "#02042D", padding: "6px", borderRadius: "8px", width: "100%", margin: "auto" }}
                                >

                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", bgcolor: tab === "info" && "#353657", color: "#FFF", padding: "5px 8px", borderRadius: "8px", cursor: "pointer" }}
                                        onClick={() => setTab("info")}
                                    >
                                        <InfoOutlined />
                                        <span style={{ fontWeight: "500" }}>Info</span>
                                    </Box>

                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", bgcolor: tab === "Logs" && "#353657", color: "#FFF", padding: "5px 8px", borderRadius: "8px", cursor: "pointer" }}
                                        onClick={() => setTab("Logs")}
                                    >
                                        <Call />
                                        <span style={{ fontWeight: "500" }}>Logs</span>
                                    </Box>

                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", bgcolor: tab === "Transcript" && "#353657", color: "#FFF", padding: "5px 8px", borderRadius: "8px", cursor: "pointer" }}
                                        onClick={() => setTab("Transcript")}
                                    >
                                        <ListAltOutlined />
                                        <span style={{ fontWeight: "500", textWrap: "nowrap" }}>
                                            Transcript
                                        </span>
                                    </Box>

                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", bgcolor: tab === "Analysis" && "#353657", color: "#FFF", padding: "5px 8px", borderRadius: "8px", cursor: "pointer" }}
                                        onClick={() => setTab("Analysis")}
                                    >
                                        <AnalyticsOutlined />
                                        <span style={{ fontWeight: "500" }}>Analysis</span>
                                    </Box>

                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", bgcolor: tab === "Cost" && "#353657", color: "#FFF", padding: "5px 8px", borderRadius: "8px", cursor: "pointer" }}
                                        onClick={() => setTab("Cost")}
                                    >
                                        <AttachMoneyOutlined />
                                        <span style={{ fontWeight: "500" }}>Cost</span>
                                    </Box>

                                </Box>

                                {tab === "info" && (<Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem", padding: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)", borderRadius: "1rem" }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                                        <InfoOutlined />
                                        <Typography variant="subtitle2">Info</Typography>
                                    </Box>

                                    <Table sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            borderBottom: "none"
                                        }
                                    }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        Assistant
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Box
                                                        sx={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}
                                                    >
                                                        <Avatar
                                                            src={details.Assistant?.Logo?.fileUrl}
                                                            alt={details.AssistantDetails}
                                                            sx={{ width: 30, height: 30 }}
                                                        />
                                                        <Box>
                                                            <Typography sx={{ fontSize: "16px", fontWeight: "600", margin: 0 }} variant="body2">
                                                                {details.AssistantDetails}
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                Real state - {details.Type}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        Call Id
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        <ClipboardBox id={details._id} />
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        Type
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="inherit" sx={{ textTransform: "capitalize", color: "gray" }}>
                                                        {details.Type}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" sx={{ textTransform: "capitalize", color: "gray" }}>
                                                        Status
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={"Completed"}
                                                        variant="outlined"
                                                        sx={{ textTransform: "capitalize", bgcolor: '#020429', color: 'white' }}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        Phone Number
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        {details.PhoneNumber ? details.PhoneNumber : "NA"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        Call Duration
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        {details.Duration ? convertToMinutes(details.Duration) : "0 second"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        Ended Reason
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={details.CallEndedReason ? details.CallEndedReason : "NA"}
                                                        variant="filled"
                                                        sx={{ textTransform: "capitalize", bgcolor: '#020429', color: 'white' }}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>

                                </Box>)}

                                {tab === "Logs" && (<Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem", padding: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)", borderRadius: "1rem" }}>
                                    <Box sx={{ display: 'flex', gap: '.5rem' }}>
                                        <ListAltOutlined />
                                        {datas.Logs?.map((log) => (
                                            <Typography variant="subtitle2">
                                                {log}
                                            </Typography>
                                        ))}

                                    </Box>

                                    <Table sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            borderBottom: "none"
                                        }
                                    }}>
                                        <TableBody>
                                            {details.Logs.length ? details.Logs.map((log, index) => (
                                                <TableRow>
                                                    <TableCell>
                                                        {log.timestamp}
                                                    </TableCell>
                                                    <TableCell>{log.event}</TableCell>
                                                </TableRow>
                                            ))
                                                : <Typography variant="body">Coming Soon</Typography>
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>)}

                                {tab === "Transcript" && (<Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem", padding: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)", borderRadius: "1rem" }}>
                                    <Box sx={{ display: 'flex', gap: '.5rem' }}>
                                        <CardMembership />
                                        <Typography variant="subtitle2">Transcipt</Typography>
                                    </Box>

                                    <Box sx={{ marginTop: "1rem" }}>
                                        {details.Transcript.length ? details.Transcript.map((transcript, index) => {
                                            // Get the key and value from the transcript object
                                            const key = Object.keys(transcript)[0]; // Get the first key
                                            const value = transcript[key]; // Get the corresponding value

                                            return (
                                                <React.Fragment key={index}>
                                                    <Typography variant="caption">
                                                        {key}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {value}
                                                    </Typography>
                                                </React.Fragment>
                                            );
                                        })
                                            : <Typography variant="body">Not Available</Typography>

                                        }
                                    </Box>
                                </Box>)
                                }

                                {tab === "Analysis" && (<Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem", padding: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)", borderRadius: "1rem" }}>
                                    <Box sx={{ display: 'flex', gap: '.5rem' }}>
                                        <AnalyticsOutlined />
                                        <Typography variant="subtitle2">Analysis</Typography>
                                    </Box>

                                    <Table sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            borderBottom: "none"
                                        }
                                    }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        Analysis
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="inherit">
                                                        {details.Summary ? details.Summary : "NA"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        Goal Achievement
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Chip
                                                    style={{textTransform: 'capitalize'}}
                                                        label={details.GoalAchievement ? details.GoalAchievement : "NA"}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        Follow-up
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{textTransform: 'capitalize'}}>
                                                    {details.FollowUp ? details.FollowUp : "NA"}
                                                </TableCell>
                                            </TableRow>

                                            <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                                                <AssignmentTurnedInOutlined sx={{ fontSize: "1rem" }} />
                                                <Typography variant="body1" sx={{ textWrap: "nowrap", color: "#02042D" }}>
                                                    Success Evaluation
                                                </Typography>
                                            </Box>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        Transfer Status
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={details.CallTransferred ? "Yes" : "No"}
                                                        variant="filled"
                                                        sx={{ bgcolor: "#02042D", color: "white" }}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        Resolution Time
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {details.ResolutionTime ? convertToMinutes(details.ResolutionTime) : "0 second"}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                        User Satisfaction
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={details.UserSatisfaction || "Positive"}
                                                        variant="outlined"
                                                        sx={{ bgcolor: "#02042D", color: "white", textTransform: 'capitalize' }}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="inherit" color='gray'>
                                                    SOP Adherence
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        details.ScriptAdherence ? details.ScriptAdherence+"%" : '80%'
                                                    }
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </Box>)}

                                {tab === "Cost" && (<Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem", padding: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)", borderRadius: "1rem" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                                        <AttachMoneyOutlined />
                                        <Typography variant="subtitle2">Call Cost</Typography>
                                    </Box>

                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "1rem" }}>
                                        <Typography variant="h6" style={{ fontSize: "0.6rem" }}>
                                            Total Cost - $
                                            {
                                                details.TotalCost ?
                                                    <>
                                                        {getCallCost(details.Duration)}
                                                    </> :
                                                    "0 second"
                                            }
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                                                <DescriptionOutlined sx={{ fontSize: 16, color: "#FFBC28" }} />
                                                <Typography variant="inherit" sx={{ fontWeight: 500, color: "#02042D" }}>
                                                    Transcipt
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                                                <RecordVoiceOverOutlined sx={{ fontSize: 16, color: "#008AFF" }} />
                                                <Typography variant="inherit" sx={{ fontWeight: 500, color: "#02042D" }}>
                                                    Voice
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                                                <CodeOutlined sx={{ fontSize: 16, color: "#FF8045" }} />
                                                <Typography variant="inherit" sx={{ fontWeight: 500, color: "#02042D" }}>
                                                    LLM
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                                                <CheckCircleOutline sx={{ fontSize: 16, color: "#5DCC8C" }} />
                                                <Typography variant="inherit" sx={{ fontWeight: 500, color: "#02042D" }}>
                                                    Action
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Typography style={{textAlign: 'center', fontSize: '1.4rem', marginTop: '3rem'}}>Talk more, pay less! Enjoy calls at an unbeatable $0.01/min for a limited time - don't miss out!</Typography>
                                    <ResponsiveContainer width="100%" height={300}>
                                        <PieChart width={400} height={200}>
                                            <Pie
                                                data={[
                                                    { name: "Transcription Cost", value: details.TranscriptionCost },
                                                    { name: "Voice Cost", value: details.Voice },
                                                    { name: "LLM Cost", value: details.LLMCost },
                                                    { name: "Action Cost", value: details.ActionCost }
                                                ]}
                                                cx="50%"
                                                cy="50%"
                                                labelLine={false}
                                                label={<CustomLabel />}
                                                outerRadius={100}
                                                dataKey="value"
                                            >
                                                {COLORS.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip wrapperStyle={{ zIndex: 99999 }} formatter={(value) => `$${value.toFixed(2)}`} />
                                        </PieChart>
                                    </ResponsiveContainer>

                                </Box>)}
                            </Box>
                        </Box>
                    </Box >
                </Drawer >
                )}
            </>
        );
    }
    
    export default Logs;
    
