import { Box, Button, MenuItem, Typography, TextField, InputLabel } from '@mui/material';
import React from 'react';
import useOutBoundSettings from '../hooks/useOutBoundSettings';

const Outbound = ({ assistants = [], data, updatePhone }) => {
   const { outbound, handleAssistantChange, updateOutboundSettings, loading, error } = useOutBoundSettings(data, updatePhone);
    
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 2, 
                p: 2, 
                borderRadius: 1, 
                bgcolor: "rgba(177, 177, 190, 0.11)", 
                border: "1px solid #A9A9A9" 
            }}
        >
            <Typography variant="subtitle2" component="h4">
                Outbound Settings
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <InputLabel htmlFor="assistant">Select Assistant</InputLabel>
                <TextField
                    id="assistant"
                    select
                    size="small"
                    placeholder="Select Assistant"
                    value={outbound || ""}
                    onChange={handleAssistantChange}
                    disabled={loading}
                    required
                    error={Boolean(error)}
                  helperText={error ? (
                        error === "No assistant is selected" 
                            ? "Please select an assistant" 
                            : error === "No token found" 
                                ? "Authentication error" 
                                : "Failed to update settings"
                    ) : ""}
                    sx={{ bgcolor: "#FFF" }}
                >
                    {assistants.length > 0 ? (
                        assistants.map((assistant) => (
                            <MenuItem key={assistant._id} value={assistant._id}>
                                {assistant.Name}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value=""> No assistants available. Please create an assistant first.</MenuItem>
                    )}
                </TextField>
            </Box>

            <Button 
                variant="contained" 
                sx={{ 
                    width: '5rem', 
                    bgcolor: '#020429', 
                    '&:hover': { bgcolor: '#020429' } 
                }} 
                onClick={updateOutboundSettings}
            >
                Save
            </Button>
        </Box>
    );
};

export default Outbound;
