import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  InputAdornment
} from '@mui/material';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/system';
import { Send } from '@mui/icons-material';

const blink = keyframes`
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
`;

const TypingDots = styled('span')({
  display: 'inline-flex',
  marginLeft: '6px',
  '&::after': {
    content: '"..."',
    animation: `${blink} 1.5s infinite`,
  },
});

const TextChat = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isTyping]);

  const handleSend = () => {
    if (inputMessage.trim()) {
      setMessages([...messages, { text: inputMessage, sender: 'user' }]);
      setInputMessage('');
      setIsTyping(true);
      setTimeout(() => {
        setMessages(prev => [...prev, { text: 'Sample response', sender: 'assistant' }]);
        setIsTyping(false);
      }, 1500);
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      backgroundColor: '#fff',
      p: 2
    }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
        <List>
          {messages.map((message, index) => (
            <ListItem
              key={index}
              sx={{
                justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                mb: 0.8,
              }}
            >
              <Paper
                elevation={4}
                sx={{
                  py: 1.2,
                  px: 2,
                  maxWidth: '75%',
                  backgroundColor: message.sender === 'user' ? '#007aff' : '#f5f5f5',
                  color: message.sender === 'user' ? '#ffffff' : '#333',
                  borderRadius: '14px',
                  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                  alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
                  wordBreak: 'break-word',
                }}
              >
                <ListItemText
                  primary={message.text}
                  primaryTypographyProps={{
                    fontSize: '0.9rem',
                    fontWeight: 500,
                    lineHeight: 1.4,
                  }}
                />
              </Paper>
            </ListItem>
          ))}

          {isTyping && (
            <ListItem sx={{ display: 'flex', justifyContent: 'flex-start', pl: 1 }}>
              <Paper
                elevation={3}
                sx={{
                  py: 1,
                  px: 1.5,
                  borderRadius: '12px',
                  backgroundColor: '#eeeeee',
                  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
                  maxWidth: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ListItemText
                  primary={
                    <span style={{ fontSize: '0.9rem', fontWeight: 500, color: '#666' }}>
                      Assistant is typing<TypingDots />
                    </span>
                  }
                />
              </Paper>
            </ListItem>
          )}
          <div ref={messagesEndRef} />
        </List>
      </Box>
      <Box sx={{ p: 1, borderTop: 1, borderColor: 'divider' }}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Type your message..."
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          sx={{
            borderRadius: '20px',
            backgroundColor: '#f8f8f8',
            '& .MuiOutlinedInput-root': {
              borderRadius: '20px',
              paddingRight: 0,
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="primary" onClick={handleSend}>
                  <Send />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
    </Box>
  );
};

export default TextChat;
