import { Box, Typography, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from 'react'
import api from '../api';
import AccessTimeFilledTwoToneIcon from '@mui/icons-material/AccessTimeFilledTwoTone';

export const getRemainingDays = (expiryDate) => {
    return Math.max(
        Math.floor(
            (new Date(expiryDate) - new Date()) / (1000 * 60 * 60 * 24)
        ),
        0
    );
};

const Credit = ({ sidebarHidden = true }) => {
    const [credit, setCredit] = useState(0);
    const [allCredit, setAllCredit] = useState(0);
    const [displayCredit, setDisplayCredit] = useState("0");
    const [displayAllCredit, setDisplayAllCredit] = useState("0");
    const [loading, setLoading] = useState(false);
    const [planName, setPlanName] = useState(null);
    const [planDetails, setPlanDetails] = useState({});
    const [token] = useState(() => {
        const tokenData = localStorage.getItem("token");
        return tokenData ? JSON.parse(tokenData).token : null;
    });
    
    const [id] = useState(() => {
        return localStorage.getItem("Organization");
    })


    // Format number helper function
    const formatNumber = (num) => {
        if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'k';
        }
        return num;
    };

    const getSubscriptionDetails = useCallback(async () => {
        setLoading(true);
        try {
        const response = await api.get(`/v1/billing/subscription-details`, {
            headers: {
            Authorization: `Bearer ${token}`,
            },
        });
        if (response?.data?.success) {
            const data = {
                start_date: response?.data?.data?.subscriptionDetails?.created,
                end_date:
                    response?.data?.data?.subscriptionDetails?.plan_period_end,
                status: response?.data?.data?.subscriptionDetails?.status,
            };
            setPlanDetails(data);
            setPlanName(response?.data?.data?.plan_name);
            localStorage.setItem("planDetails", JSON.stringify(data));
            localStorage.setItem("planId", response?.data?.data?.plan_name);
        }
        } catch (error) {
            // console.error(error);
            localStorage.removeItem("planDetails");
            localStorage.removeItem("planId");
        } finally {
            setLoading(false);
        }
    }, [token]);
    useEffect(() => {
        if (token) {
            getSubscriptionDetails();
        }
    }, [token, getSubscriptionDetails]);

    // Load stored values on mount
    useEffect(() => {
        const storedPlanDetails = localStorage.getItem("planDetails");
        const storedPlanId = localStorage.getItem("planId");

        if (storedPlanDetails) {
            setPlanDetails(JSON.parse(storedPlanDetails));
        }
        if (storedPlanId) {
            setPlanName(storedPlanId);
        }
    }, []);

    const getCredit = useCallback(async () => {
        if (!token || !id) return;
        setLoading(true);
        try {
            const response = await api.post("/organizations/credits", {
                organizationId: id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            const currentCredits = response.data.data?.credits || 0;
            const totalCredits = response.data.data?.allCredits || 0;

            // Store actual numerical values
            setCredit(currentCredits);
            setAllCredit(totalCredits);

            // Set display values
            setDisplayCredit(formatNumber(currentCredits));
            setDisplayAllCredit(formatNumber(totalCredits));

            localStorage.setItem("Credit", currentCredits.toString());

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }, [token, id]);

    useEffect(() => {
        if (token) {
            getSubscriptionDetails();
            if (credit) {
                setInterval(getCredit, 100);
            } else {
                getCredit();
            }
        }
    }, [token, getSubscriptionDetails]);

    if (sidebarHidden) {
        return (
            <Box sx={{ 
                position: 'relative', 
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                
            }}>
                {/* Background circle */}
                <CircularProgress
                    variant="determinate"
                    value={100}
                    thickness={4}
                    sx={{
                        width: '100%', 
                        height: '100%',
                        color: 'rgba(255, 255, 255, 0.2)',
                        position: 'absolute'
                    }}
                />

                {/* Progress circle */}
                <CircularProgress
                    variant="determinate"
                    value={(credit / allCredit) * 100}
                    thickness={4}
                    sx={{
                        width: '100%',
                        height: '100%',
                        color: 'transparent',
                        '& .MuiCircularProgress-circle': {
                            stroke: 'url(#gradient)',
                            strokeLinecap: 'round'
                        }
                    }}
                />
                {/* SVG Gradient Definition */}
                <svg style={{ position: 'absolute', width: 0, height: 0 }}>
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#4A90E2" />
                            <stop offset="100%" stopColor="#00D1FF" />
                        </linearGradient>
                    </defs>
                </svg>

                {/* Center Text */}
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        // flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1px'
                    }}
                >
                    <Typography 
                        variant="caption" 
                        sx={{ 
                            color: '#fff', 
                            lineHeight: 1,
                            fontSize: '12px',
                            fontWeight: 'bold'
                        }}
                    >
                        {displayCredit}
                    </Typography>
                    {/* <Typography 
                        variant="caption" 
                        sx={{ 
                            color: 'rgba(255, 255, 255, 0.7)', 
                            lineHeight: 1,
                            fontSize: '10px'
                        }}
                    >
                        /
                    </Typography>
                    <Typography 
                        variant="caption" 
                        sx={{ 
                            color: 'rgba(255, 255, 255, 0.7)', 
                            lineHeight: 1,
                            fontSize: '12px'
                        }}
                    >
                        {displayAllCredit}
                    </Typography> */}
                </Box>
            </Box>
        
        );
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem ",width: "100%", padding:1}}>
            <Box sx={{ display: "flex", alignItems: "center", gap:1,justifyContent:'space-between' }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                    <Typography sx={{ color: "#FFF", fontWeight:"600", fontSize:"14px",whiteSpace: 'nowrap' }}>{ planName?`${planName} Plan`:"Currently on Free Plan" }</Typography>

                    {/* Expiry Date */}
                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", whiteSpace:'nowrap' }}>
                    <AccessTimeFilledTwoToneIcon sx={{fontSize:'12px', color:'rgba(255,255,255,0.9)'}}/>
                    <Typography sx={{ color: "rgba(255,255,255,0.9)", fontSize: '10px', whiteSpace: 'nowrap' }}>{planName?`Expiring in ${getRemainingDays(planDetails?.end_date)} days`:"Upgrade to Activate Plan" }</Typography>
                    </Box>

                </Box>
                
                {planDetails?.status && (
                    <Box
                        style={{
                            backgroundColor:
                                planDetails?.status === "trialing"
                                ? "#1A2B4D" // Deep blue variant for "On Trial"
                                : planDetails?.status === "cancelled"
                                ? "#3D1C28" // Deep burgundy for "Cancelled"
                                : "#1E3A2A", // Deep green variant for "Active"
                            borderRadius: "999px",
                            padding: "4px 10px",
                        }}
                    >
                        <Typography
                            sx={{
                                color:
                                    planDetails?.status === "trialing"
                                    ? "#0ef4f8" // Bright blue for "On Trial" text
                                    : planDetails?.status === "cancelled"
                                    ? "#ffa8af" // Bright red for cancelled
                                    : "#53ff5a", // Bright cyan for active
                                fontSize: "10px",
                                fontWeight: "700",
                            }}
                        >
                        {(() => {
                            const currentDate = new Date();
                            const planEndDate = new Date(planDetails?.end_date);

                            if (planDetails?.status === "cancelled") {
                              // If canceled and end date is in the future
                              if (planEndDate >= currentDate) {
                                return "Cancelled";
                              }
                            }

                            // If subscription is still active
                            if (planEndDate >= currentDate) {
                            if (planDetails?.status === "trialing") {
                                return "On Trial";
                            } else if (planDetails?.status === "active") {
                                return "Active";
                            }
                            }
                        })()}
                        </Typography>
                    </Box>
                )}
            </Box>

            
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {/* Credit Text */}
                <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    color: "#FFF"
                }}
            >
                <Typography sx={{fontSize:'14px', fontWeight:600 , color: 'rgba(255,255,255,0.8)' }}>{displayCredit} / {displayAllCredit} <span style={{color:'rgba(255,255,255,0.8)'}}>Minutes Remaining</span></Typography>
            </Box>


             {/* Progress Bar */}
            <div style={{ width: "100%", height: "5px", backgroundColor: "#FFFFFF", borderRadius: "5px" }}>
                <div
                    style={{
                        width: `${Math.min((credit / allCredit) * 100, 100)}%`,
                        height: "5px",
                        background: "linear-gradient(to right, #4A90E2, #00D1FF)",
                        borderRadius: "5px",
                    }}
                ></div>
            </div>
            
            </Box>

        </Box>
    )
}

export default Credit;