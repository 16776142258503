import {
    Box,
    Typography,
  } from '@mui/material';
const TemplateConfiguration = ({ title, value }) => (
    <Box sx={{
        px: 3,
        py: 2,
        bgcolor: '#ffffff',
        borderColor: '#E5E7EB',
        borderWidth: '1px',
        borderRadius: '12px',
        borderStyle: 'solid'
      }}>
      <Typography variant="subtitle1" fontWeight="medium" mb={1}>
        {title}
      </Typography>
      <Typography color="text.secondary">
        {value}
      </Typography>
    </Box>
  );

  export default TemplateConfiguration;