import { Box, Button, CircularProgress, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { toast } from 'sonner';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import "../styles/login.css";
import api from '../api';

const SetPassword = ({ open, onClose }) => {
    const [formValues, setFormValues] = useState({
        Password: "",
        ConfirmPassword: "",
    });
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

    const [loading, setLoading] = useState(false);

    const token = JSON.parse(localStorage.getItem("token"))?.token;
    const organisationId = localStorage.getItem("Organization");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { Password, ConfirmPassword } = formValues;
        if (!Password || !ConfirmPassword) {
            return toast.error("Please fill all the fields");
        }
        setLoading(false);
        try {
            const config = {
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${token}`,
                },
            };
            const response = await api.post("/users/set-password", { Password, ConfirmPassword },
                config,
            );
            toast.success(response.data.message);
            window.location.href = `/company/${organisationId}/overview`;
            onClose()
        } catch (error) {
            toast.error(error.response.data.message);
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleSkipPassword = () => {
        onClose()
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="password-reset-modal"
            aria-describedby="password-reset-verification"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >

            <Box
                sx={{
                    width: {
                        xs: '90%',
                        sm: '70%',
                        md: '50%',
                        lg: '40%',
                        xl: '30%',
                    },
                    maxWidth: '500px',
                    bgcolor: 'background.paper',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography className="modelHeadingText"> Set New Password</Typography>
                <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "1.5rem", marginTop: "1rem" }}>
                    <TextField
                        label="New Password"
                        name="Password"
                        variant="outlined"
                        value={formValues.Password}
                        type={showNewPassword ? "text" : "password"}
                        fullWidth
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                        edge="end"
                                    >
                                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleInputChange}
                    />

                    <TextField
                        label="Confirm Password"
                        variant="outlined"
                        name="ConfirmPassword"
                        value={formValues.ConfirmPassword}
                        type={showConfirmedPassword ? "text" : "password"}
                        fullWidth
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowConfirmedPassword(!showConfirmedPassword)}
                                        edge="end"
                                    >
                                        {showConfirmedPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleInputChange}
                    />

                    <Button
                        variant="contained"
                        type="submit"
                        sx={{
                            bgcolor: "#02042D",
                            padding: "0.5rem 0",
                            fontWeight: "500",
                            color: "#FFF",
                            textTransform: "none",
                            width: "auto",
                            minWidth: "120px", // Ensures button doesn't shrink too much
                            ":hover": {
                                bgcolor: "#353657",
                            },
                        }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={25} sx={{ color: "#02042D" }} /> : "Set New Password"}
                    </Button>

                </form>

                <div style={{ marginTop: "1rem", display: "flex", alignContent: "center", gap: ".5rem" }}>
                    <p style={{ color: "gray", textAlign: "center" }}>
                        Or, would you like to continue without setting a password?
                    </p>

                    <button style={{ all: "unset", color: "#02042D", cursor: "pointer" }} onClick={handleSkipPassword}>Skip.</button>
                </div>
            </Box>

        </Modal>
    )
}

export default SetPassword;