import React, { useState } from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import { toast } from 'sonner';
import OtpBox from '../components/OtpBox';
import api from "../api";

const PasswordChangeModal = ({
    open,
    onClose,
    email,
    newPassword,
    confirmPassword,
    token,
    getUser
}) => {
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const payload = {
                Email: email,
                Password: newPassword,
                otp
            };
            const response = await api.post(`/users/validate-otp-google-signup`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(response.data.message);
            setLoading(false);
            getUser(); // Refresh user data
            onClose(); // Close modal on success

        } catch (error) {
            toast.error(error.response?.data?.message || "Error resetting password");
            console.error("Error resetting password:", error);
            setLoading(false);
        }
    };

    const handleResendOtp = async () => {
        try {
            const payload = {
                Email: email,
                Password: newPassword,
                ConfirmPassword: confirmPassword,
            }
            const response = await api.post(`/users/send-otp-google-signup`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.response?.data?.message || "Error resending OTP");
            console.error("Error resending OTP:", error);
        }
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 460,
        bgcolor: 'background.paper',
        boxShadow: 24,
        px: 2,
        py: 4,
        borderRadius: 2
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="password-reset-modal"
            aria-describedby="password-reset-verification"
        >
            <Box sx={modalStyle}>
                <Box className="formbox" sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Typography variant="h6" component="h2" className="loginAccountText">
                        Please verify email account
                    </Typography>
                    <Typography fontSize={14} textAlign="center" sx={{ mt: 2 }}>
                        Please enter the OTP sent to <b>{email.trim().toLowerCase()}</b>
                    </Typography>

                    <form onSubmit={handleOtpSubmit} style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                        marginTop: "1rem"
                    }}>
                        <OtpBox length={6} handleSubmit={setOtp} />

                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                bgcolor: "#02042D",
                                mt: 1,
                                color: "#FFF",
                                "&:hover": {
                                    bgcolor: "#02042D",
                                    color: "#FFF",
                                    cursor: loading ? "not-allowed" : "pointer"
                                }
                            }}
                            fullWidth
                            disabled={loading || otp.length !== 6}
                        >
                            {loading ? "Verifying..." : "Verify OTP"}
                        </Button>

                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 2
                        }}>
                            <Button onClick={onClose}>Cancel</Button>
                            <Button onClick={handleResendOtp}>Resend OTP</Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Modal>
    );
};

export default PasswordChangeModal;