import { Box, Grid, Pagination } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import AssistantCard from '../../components/AssistantCard';
import Navbar from "../../components/Navbar"
import api from "../../api";
import NoDataFound from '../../components/NoDataFound';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';

const Assistant = () => {
    const [ assistantList, setAssistantList ] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const token = JSON.parse(localStorage.getItem("token")).token;
    const { company_id } = useParams();
    const [organization, setOrganization] = useState({});

    const getAllAssistants = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.post("/organizations/assistants-by-company", {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                    params: {
                        page: currentPage + 1
                    }
                }
            );

            setAssistantList(response.data.data);
            setTotalPages(response.data?.totalPages || 0);

            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, [currentPage, token]);

    const getAllDemoAssistants = useCallback(async () => {
        try {
            const response = await api.post("/assistants/demo-assistants", {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                    params: {
                        page: currentPage + 1
                    }
                }
            );

            setAssistantList(response.data.data?.demoAssistants);
            setTotalPages(response.data?.totalPages || 0);
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, [currentPage, token]);

    const getOrganization = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/organizations/${company_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            });
            setOrganization(response.data);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (token) {
            getOrganization();
        }
    }, [token]);

    useEffect(() => {
        if (organization) {
            if (organization?.ShowDemo === true) {
                getAllDemoAssistants();
            } else if (organization?.ShowDemo === false) {
                getAllAssistants();
            }
        }
    }, [getAllAssistants, getAllDemoAssistants, organization]);



    if (loading) {
        return <Loader />
    }

    if (assistantList.length === 0) {
        return (
            <Box>
                <Navbar title="Assistants" />
                <Box sx={{ display: 'flex', justifyContent: 'center', width: "100%", height: "90dvh" }}>
                    <NoDataFound
                        title='Get Started with Your Metrices'
                        subtitle="You haven't created any assistants yet. Start by creating one to get started!"
                    />
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Navbar title="Assistants" />

            {assistantList.length > 0 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "0 2rem" }}>
                    <Grid container spacing={2} mt={1} mb={2} sx={{ overflow: "hidden" }}>

                        {assistantList.map((data) => (
                            <Grid item xs={12} sm={6} md={4} lg={4} key={data._id}>
                                <AssistantCard
                                    fileUrl={data.Logo.fileUrl}
                                    Type={data.Type}
                                    name={data.Name}
                                    category={data.Category}
                                    description={data.Description}
                                    lastUpdated={data.UpdatedAt}
                                    buttonAction={{ "label": "View Assistant", "url": `/company/${company_id}/assistant/${data._id}` }}
                                    assistant={data}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(e, value) => setCurrentPage(value)}
                        style={{ alignSelf: "center" }}
                        color="#02042D"
                    />
                </Box>)

            }

        </>
    )
}

export default Assistant;