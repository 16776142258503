import React, { useRef, useState, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Select, MenuItem, FormControl, Button, Slider } from '@mui/material';
import { toast } from 'sonner';
import AvatarEditor from 'react-avatar-editor';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { fetchConfig } from '../../pages/utils/fetchConfig';

const UploadIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    style={{ width: "2rem", height: "2rem", color: "GrayText" }}
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="17 8 12 3 7 8" />
    <line x1="12" y1="3" x2="12" y2="15" />
  </svg>
);
 const CONFIG = [
        'max_logo_size'
      ];

const InitialDetails = ({ assistantId }) => {
    const [token, setToken] = useState("");
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const editorRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [finalImage, setFinalImage] = useState(null);
    const [maxFileSize, setMaxFileSize] = useState(3); // Default 30MB

    useEffect(() => {
        getToken();
        loadSavedValues();
        getDefaultConfig();
    }, []);

   
      const getDefaultConfig = async () => {
        try {
            await fetchConfig(CONFIG, ({ data }) => {
               setMaxFileSize(data?.max_logo_size ?? 3);
            });
        } catch (error) {
            console.error("Failed to load configuration. Using default values.");
        }
    };
    

    const getToken = async () => {
        const tokenData = localStorage.getItem("token");
        if (tokenData) {
            const t = JSON.parse(tokenData).token;
            setToken(t);
        } else {
            console.error("Token not found in local storage");
        }
    };

    const resetFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setImage(null);
        setIsEditing(false);
    };
    const loadSavedValues = () => {
        const savedAssistant = JSON.parse(localStorage.getItem("assistant")) || {};
        setName(savedAssistant.Name || '');
        setCategory(savedAssistant.Category || '');
        setType(savedAssistant.Type || '');
        setDescription(savedAssistant.Description || '');
        const savedLogo = localStorage.getItem('Logo');
        if (savedLogo) {
            try {
                const logoData = JSON.parse(savedLogo);
                setFinalImage(logoData.content);
                // Only set the image if we're in editing mode
                if (isEditing) {
                    fetch(logoData.content)
                        .then(res => res.blob())
                        .then(blob => {
                            const file = new File([blob], logoData.name, { type: logoData.type });
                            setImage(file);
                        });
                }
            } catch (error) {
                console.error('Error loading saved logo:', error);
                localStorage.removeItem('Logo'); // Clear invalid data
            }
        }
    };

    const updateAssistantInLocalStorage = (key, value) => {
        const savedAssistant = JSON.parse(localStorage.getItem("assistant")) || {};
        savedAssistant[key] = value;
        localStorage.setItem("assistant", JSON.stringify(savedAssistant));
    };

    const handleRemoveLogo = () => {
        setImage(null);
        setFinalImage(null);
        setScale(1);
        setRotation(0);
        localStorage.removeItem('Logo');
        toast.success('Logo removed successfully!');
        
        // Reset file input to allow re-upload
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      };

      const handleSave = () => {
        if (!editorRef.current) return;

        const canvas = editorRef.current.getImageScaledToCanvas();
        canvas.toBlob((blob) => {
            const reader = new FileReader();
            
            reader.onload = () => {
                const logoData = {
                    content: reader.result,
                    name: 'avatar.png',
                    type: 'image/png'
                };
                
                localStorage.setItem('Logo', JSON.stringify(logoData));
                setFinalImage(reader.result);
                setIsEditing(false);
                toast.success('Logo saved successfully!');
            };
            
            reader.onerror = () => {
                toast.error('Failed to save logo');
            };
            
            reader.readAsDataURL(blob);
        }, 'image/png');
    };
    const handleFileSelect = (file) => {
        const isValid = handleFile(file);
        setIsEditing(isValid);
    };
    
    const handleFile = (file) => {
        if (!file) return;

        const allowedTypes = ["image/png", "image/jpg", "image/jpeg", "image/svg+xml", "image/gif"];
        if (!allowedTypes.includes(file.type)) {
            toast.error("Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed.");
            resetFileInput();
            return;
        }

        if (file.size > maxFileSize * 1024 * 1024) {
            toast.error(`File size should not exceed ${maxFileSize} MB.`);
            resetFileInput();
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            setImage(e.target.result);
            setIsEditing(true);
        };
        reader.onerror = () => {
            toast.error("Error reading file");
            resetFileInput();
        };
        reader.readAsDataURL(file);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) handleFile(file);
    };


    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={8}>
                <Box sx={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    gap: ".5rem", 
                    bgcolor: "rgba(177, 177, 190, 0.11)", 
                    padding: "1.5rem", 
                    borderRadius: "0.5rem",
                    height: "100%"
                }}>
                    <Box sx={{ mb: 3, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100, fontWeight: 500 }}>Name</Typography>
                        <TextField
                            size='small'
                            fullWidth
                            variant="outlined"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                updateAssistantInLocalStorage("Name", e.target.value);
                            }}
                            sx={{ bgcolor: "white" }}
                        />
                    </Box>

                    <Box sx={{ mb: 3, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100, fontWeight: 500 }}>Category</Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={category}
                                onChange={(e) => {
                                    setCategory(e.target.value);
                                    updateAssistantInLocalStorage("Category", e.target.value);
                                }}
                                sx={{ bgcolor: "white" }}
                            >
                                <MenuItem value="Sales">Sales</MenuItem>
                                <MenuItem value="Customer Support">Customer Support</MenuItem>
                                <MenuItem value="IT Support">IT Support</MenuItem>
                                <MenuItem value="Human Resources">Human Resources</MenuItem>
                                <MenuItem value="Healthcare / Service Industry">Healthcare / Service Industry</MenuItem>
                                <MenuItem value="Banking Support">Banking Support</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ mb: 3, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100, fontWeight: 500 }}>Type</Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={type}
                                onChange={(e) => {
                                    setType(e.target.value);
                                    updateAssistantInLocalStorage("Type", e.target.value);
                                }}
                                sx={{ bgcolor: "white" }}
                            >
                                <MenuItem value="inbound">Inbound</MenuItem>
                                <MenuItem value="outbound">Outbound</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100, fontWeight: 500 }}>Description</Typography>
                        <TextField
                            size='small'
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                updateAssistantInLocalStorage("Description", e.target.value);
                            }}
                            sx={{ bgcolor: "white" }}
                        />
                    </Box>
                </Box>
            </Grid>

            {/*Logo Area */}
            <Grid item xs={12} md={12} lg={4}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0.5rem",
                    alignContent:'center',
                    bgcolor: "rgba(177, 177, 190, 0.11)",
                    padding: "1.5rem",
                    borderRadius: "0.5rem",
                    height:'auto'
                }}>
                    <Typography variant="h6" align='center' sx={{ mb: 2 }}>Assistant Logo</Typography>
                    
                    <Box sx={{
                        width: "200px",
                        height: "200px",
                        position: "relative",
                        cursor: "pointer",
                        borderRadius: "50%",
                        overflow: "hidden",
                        backgroundColor: "white",
                        border: isDragging ? "2px dashed #02042D" : "2px dashed #D9D9D9",
                    }}
                    

                    onDragEnter={(e) => {
                        e.preventDefault();
                        setIsDragging(true);
                    }}
                    onDragOver={(e) => {
                        e.preventDefault();
                        setIsDragging(true);
                    }}
                    onDragLeave={(e) => {
                        e.preventDefault();
                        setIsDragging(false);
                    }}
                    onDrop={(e) => {
                        handleDrop(e);
                        setIsEditing(true);
                    }}
                    >
                        <input
                            type="file"
                            accept="image/*"
                            id="upload-file"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={(e) => handleFileSelect(e.target.files?.[0])}
                        />

                        {!image && !finalImage ? (
                            <Box sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "1rem"
                            }}
                            onClick={() => fileInputRef.current?.click()}>
                                <UploadIcon />
                                <Typography variant="caption" sx={{ textAlign: "center", mt: 1, color: "GrayText" }}>
                                    SVG, PNG, JPG or GIF (Max. {maxFileSize} MB)
                                </Typography>
                            </Box>
                        ) : isEditing ? (
                            <AvatarEditor
                                ref={editorRef}
                                image={image}
                                width={200}
                                height={200}
                                border={0}
                                borderRadius={125}
                                color={[255, 255, 255, 0.6]}
                                scale={scale}
                                rotate={rotation}
                            />
                        ) : (
                            <img
                                src={finalImage}
                                alt="Assistant Logo"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                }}
                            />
                        )}
                    </Box>

                    {isEditing && image && (
                    <Box sx={{ width: "100%", mt: 2 }}>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            mb: 2 
                        }}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setScale(1);
                                    setRotation(0);
                                }}
                                sx={{
                                    textTransform: "none",
                                    color: "#1d4ed8",
                                    borderColor: "#1d4ed8",
                                    "&:hover": {
                                        borderColor: "#1d4ed8",
                                        backgroundColor: "rgba(29, 78, 216, 0.04)"
                                    }
                                }}
                            >
                                Reset Position
                            </Button>
                        </Box>

                        <Typography gutterBottom>Zoom</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <ZoomOutIcon />
                            <Slider
                                value={scale}
                                min={1}
                                max={3}
                                step={0.1}
                                onChange={(_, value) => setScale(value)}
                            />
                            <ZoomInIcon />
                        </Box>
                        </Box>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', mt: 2 }}>
                   
                        {isEditing ? (
                            <>
                                <Button
                                    variant="contained"
                                    onClick={handleSave}
                                    sx={{
                                        textTransform: "none",
                                        backgroundColor: "#32345E",
                                        "&:hover": { backgroundColor: "#02042D" }
                                    }}
                                >
                                    Save Changes
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => fileInputRef.current?.click()}
                                    sx={{
                                        textTransform: "none",
                                        color: "#02042D",
                                        borderColor: "#02042D",
                                        "&:hover": {
                                            borderColor: "#080A3C",
                                            backgroundColor: "rgba(2, 4, 45, 0.04)"
                                        }
                                    }}
                                >
                                    Change Logo
                                </Button>
                                
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => fileInputRef.current?.click()}
                                    sx={{
                                        textTransform: "none",
                                        backgroundColor: "#02042D",
                                        "&:hover": { backgroundColor: "#080A3C" }
                                    }}
                                >
                                    {finalImage ? "Change Logo" : "Upload Logo"}
                                </Button>

                                {finalImage && (
                                    <Button
                                        variant="outlined"
                                        onClick={handleRemoveLogo}
                                        startIcon={<DeleteIcon />}
                                        sx={{
                                            textTransform: "none",
                                            color: "#dc2626",
                                            borderColor: "#dc2626",
                                            "&:hover": {
                                                borderColor: "#dc2626",
                                                backgroundColor: "rgba(220, 38, 38, 0.04)"
                                            }
                                        }}
                                    >
                                        Remove Logo
                                    </Button>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default InitialDetails;
