import { Box, Button, Tab, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TranscriberAndAudio from './AssistantForms/TranscriberAndAudio';
import AdditionalConfigurations from './AssistantForms/AdditionalConfigurations';
import { toast } from 'sonner';
import api from '../api';
import AssistantOverview from './AssistantOverview';
import { CheckCircleOutline, DashboardOutlined, DescriptionOutlined, RecordVoiceOverOutlined, SettingsOutlined, Share } from '@mui/icons-material';
import { CircularProgress } from "@mui/material";
import NewPrompt from './AssistantForms/NewPrompt';
import NewActions from './AssistantForms/NewAction';
import ModalShare from './ModalShare';
import ChatDrawer from './ChatDrawer/ChatDrawer';
import AssistantIcon from '@mui/icons-material/Assistant';
import { useGetAllPermissions } from '../pages/utils/getAllPermissions';

const NewViewAssistantForm = ({ assistant, actions }) => {
    const [ value, setValue ] = useState(0);
    const [ token, setToken ] = useState('');
    const [ isShareAssistantModalOpen, setIsShareAssistantModalOpen ] = useState(false);
    const [ assistantConfig, setAssistantConfig ] = useState();
    const [ loading, setLoading ] = useState(false)
    const [ credit, setCredit ] = useState(0)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { permissions, permissionsLoading, getAllPermissions } = useGetAllPermissions();

    useEffect(() => {
        getAllPermissions();
    }, [token, getAllPermissions]);

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
      };
    
      const handleDrawerClose = () => {
        setIsDrawerOpen(false);
      };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleShareAssistant = () => {
        setIsShareAssistantModalOpen(true);
    }
    const setLocalStorageValues = () => {
        const localAssistant = {
            Prompt: assistant.Prompt || "",
            InitialMsg: assistant.InitialMsg || "",
            AssistantGoal: assistant.AssistantGoal || "",
            LLMProvider: assistant.LLMProvider?._id || "",
            LLMModel: assistant.LLMModel?._id || "",
            MaxToken: assistant.MaxToken || "",
            Temperature: assistant.Temperature || "",
            TranscriberProvider: assistant.TranscriberProvider?._id || "",
            Transcriber: assistant.Transcriber?._id || "",
            TranscriberLanguage: assistant.TranscriberLanguage?._id || "",
            VoiceProvider: assistant.VoiceProvider?._id || "",
            Voice: assistant.Voice?._id || "",
            VoiceLanguage: assistant.VoiceLanguage?._id || "",
            SpeedOfText: assistant.SpeedOfText || "",
            Interruption: assistant.Interruption || "",
            Cutoff: assistant.Cutoff || "",
            IdealTime: assistant.IdealTime || "",
            CallEndText: assistant.CallEndText || "",
            CallEndFlag: assistant.CallEndFlag || 0,
            TransferPhoneNumber: assistant.TransferPhoneNumber || "",
            EndConversationOnTransfer: assistant.EndConversationOnTransfer || 0,
            EndConversationOnGoodbye: assistant.EndConversationOnGoodbye || 0,
            NumOfHumanCheck: assistant.NumOfHumanCheck || 1,
            FilterAudio: assistant.FilterAudio || 0,
            InitialMsgDelay: assistant.InitialMsgDelay || "",
            CutOffResponse: assistant.CutOffResponse || "",
            _id: assistant._id
        };
        const localKnowledgebase = {
            name: assistant?.KnowledgeBase?.fileUrl || "",
            size: assistant?.KnowledgeBase?.fileSize || ""
        }

        // Store the assistant object
        localStorage.setItem('assistant', JSON.stringify(localAssistant));
        localStorage.setItem('KnowledgeBase', JSON.stringify(localKnowledgebase));

        // Store actions if they exist and are an array
        if (Array.isArray(actions) && actions.length > 0) {
            localStorage.setItem('actions', JSON.stringify(actions));
        } else {
            localStorage.removeItem('actions');
        }
    };


    const handleUpdateSave = async () => {
        setLoading(true);
        try {
            const assistant = JSON.parse(localStorage.getItem("assistant"));
            const logoData = JSON.parse(localStorage.getItem("Logo")); // Logo metadata and content
            const knowledgeBaseData = JSON.parse(localStorage.getItem("KnowledgeBase")); // Logo metadata and content

            const formData = new FormData();
            for (const [ key, value ] of Object.entries(assistant)) {
                if (value) {
                    formData.append(key, value);
                }
            }

            if (logoData) {
                // Convert Base64 to Blob
                const byteString = atob(logoData.content.split(',')[ 1 ]);
                const mimeString = logoData.content.split(',')[ 0 ].split(':')[ 1 ].split(';')[ 0 ];
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[ i ] = byteString.charCodeAt(i);
                }
                const blob = new Blob([ ab ], { type: mimeString });

                // Append the logo file to the formData
                formData.append("logo", new File([ blob ], logoData.name, { type: logoData.type }));
            }
            if (knowledgeBaseData && knowledgeBaseData.content) {
                // Convert Base64 to Blob
                const byteString = atob(knowledgeBaseData.content.split(',')[ 1 ]);
                const mimeString = knowledgeBaseData.content.split(',')[ 0 ].split(':')[ 1 ].split(';')[ 0 ];
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[ i ] = byteString.charCodeAt(i);
                }
                const blob = new Blob([ ab ], { type: mimeString });

                // Append the logo file to the formData
                formData.append("knowledgeBase", new File([ blob ], knowledgeBaseData.name, { type: knowledgeBaseData.type }));
            }

            const response = await api.patch(`/v1/assistant/update/${assistant._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });

            const actions = localStorage.getItem('actions');
            if (actions) {
                const parsedActions = JSON.parse(actions);
                for (const action of parsedActions) {
                    if (!action._id) {
                        action.Assistant = assistant._id
                        await api.post("/v1/actions/create-action", action, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        });
                    } else {
                        if (permissions.includes("update_action")) {
                            await api.put(`/v1/actions/update/${action._id}`, {
                                Type: action.Type,
                                ActionName: action.ActionName,
                                Description: action.Description,
                                InputSchema: action.InputSchema,
                                Url: action.Url,
                            }, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`
                                }
                            });
                        }

                    }
                }
            }

            // Handle actions and other logic...
            toast.success(response.data.message);
            window.location.reload()
        } catch (error) {
            toast.error("Error during save.");
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const getToken = () => {
        const t = JSON.parse(localStorage.getItem('token')).token
        const c = JSON.parse(localStorage.getItem('Credit'))
        setCredit(c);
        setToken(t)
    }
    
    const handleWebCall = async () => {
        if (credit <= 0) {
            return toast.error("No credit available");
        }
    
        setLoading(true); // Start loading state
    
        try {
            const response = await api.post(
                "/assistants/initiate-web-call",
                {
                    assistantId: assistant._id,
                    organizationId: localStorage.getItem("Organization"),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            if (response.data && response.data.data) {
                setAssistantConfig(response.data.data);
            } else {
                toast.error("Failed to fetch assistant configuration");
            }
        } catch (error) {
            console.error("Web call initiation error:", error);
            toast.error("An error occurred while initiating the web call");
        } finally {
            setLoading(false); // Stop loading state
        }
    };
    
    
    useEffect(() => {
        getToken();
        setLocalStorageValues();
    }, [ assistant, token ])

    return (
        <Box p={2}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box sx={{ display: 'flex', justifyContent: 'start', mb: 3, backgroundColor: '#02042d', paddingTop: '0.5rem', paddingLeft: '0.5rem', borderRadius: '0.5rem', width: 'fit-content' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        size="small"
                        style={{ marginBottom: '-0.5rem' }}
                        sx={{
                            '& .MuiTabs-flexContainer': { borderRadius: '8px', overflow: 'hidden', height: '2rem' },
                            '& .MuiTab-root': {
                                textTransform: 'none', padding: '0.25rem .5rem', marginRight: 1,
                                borderRadius: '8px', backgroundColor: '#02042d', color: '#ffffff',
                                minHeight: '1rem', height: '2rem', fontWeight: 'semi',
                                '&.Mui-selected': { backgroundColor: '#353657', color: '#ffffff' },
                                '&:hover': { backgroundColor: '#353657', color: '#ffffff' },
                            },
                            '& .MuiTabs-indicator': { display: 'none' },
                        }}
                    >
                        <Tab label="Overview" iconPosition="start" icon={<DashboardOutlined sx={{ fontSize: 20 }} />} />
                        <Tab label="Prompt" iconPosition="start" icon={<DescriptionOutlined sx={{ fontSize: 20 }} />} />
                        <Tab label="Transcriber and Voice" iconPosition="start" icon={<RecordVoiceOverOutlined sx={{ fontSize: 20 }} />} />
                        <Tab label="Configurations" iconPosition="start" icon={<SettingsOutlined sx={{ fontSize: 20 }} />} />
                        {permissions && (
                            permissions.includes("fetch_actions") ||
                            permissions.includes("create_action") ||
                            permissions.includes("update_action") ||
                            permissions.includes("delete_action")
                        ) && (
                                <Tab
                                    label="Actions"
                                    iconPosition="start"
                                    icon={<CheckCircleOutline sx={{ fontSize: 20 }} />}
                                />
                            )} 
                    </Tabs>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
                    <Button variant="contained" color="white" onClick={handleDrawerOpen}>
                        <AssistantIcon sx={{fontSize:'18px', mr:1}}/>  Try Assistant
                    </Button>
                    <ChatDrawer 
                        open={isDrawerOpen}
                        onClose={handleDrawerClose}
                        avatar={assistant?.Logo?.fileUrl}
                        name={assistant?.Name}
                        wsUrl={`${process.env.REACT_APP_VOICE_URL}`}
                        callConfig={assistantConfig}
                        handleWebCall={handleWebCall}  // Add this line
                        />
                    {/* <Button variant="contained" style={{ color: '#fff', backgroundColor: '#02042d' }} onClick={handleUpdateSave} disabled={loading}>
                        Publish
                    </Button> */}

                    <Button variant="contained" style={{ color: '#02042d', backgroundColor: '#fff' }} onClick={handleShareAssistant}><Share sx={{marginRight:'4px'}}/> Share Assistant</Button>
                    <ModalShare isOpen={isShareAssistantModalOpen} onClose={() => setIsShareAssistantModalOpen(false)} assistantId={assistant._id} />
                    <Button
                        variant="contained"
                        style={{
                            color: '#fff',
                            backgroundColor: '#02042d',
                        }}
                        onClick={handleUpdateSave}
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress
                                size={24} // Size of the loader
                                sx={{
                                    color: '#fff', // Color of the loader
                                }}
                            />
                        ) : (
                            "Publish"
                        )}
                    </Button>

                </Box>
            </Box>
            {/* {isModalOpen &&
                <WebCall
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    avatar={assistant?.Logo?.fileUrl}
                    name={assistant?.Name}
                    wsUrl={`${process.env.REACT_APP_VOICE_URL}`}
                    callConfig={assistantConfig}
                />} */}
            <Box>
                {
                    value === 0 &&
                    <AssistantOverview />
                }
                {
                    value === 1 && <NewPrompt />
                }
                {
                    value === 2 && <TranscriberAndAudio />
                }
                {
                    value === 3 && <AdditionalConfigurations />
                }
                {
                    value === 4 && <NewActions />
                }
            </Box>
        </Box>
    )
}

export default NewViewAssistantForm
