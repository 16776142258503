import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, TextField, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import { ExpandMore, Sms, Phone, DataSaverOn, QueryBuilder, Dashboard, Close } from '@mui/icons-material';
import { toast } from 'sonner';
import api from '../../api';

const Actions = () => {
    const [actionName, setActionName] = useState('');
    const [actionDescription, setActionDescription] = useState('');
    const [actionInputSchema, setActionInputSchema] = useState('');
    const [selectedTab, setSelectedTab] = useState(1); // Changed from 0 to 1
    const [actionType, setActionType] = useState('SEND_MAIL');
    const [actions, setActions] = useState([]);
    const [token, setToken] = useState('')
    const [url, setUrl] = useState('')
    const [actionId, setActionId] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [prevName, setPrevName] = useState("")

    // Example schemas for different action types
    const schemas = {
        SEND_MAIL: {
            caller_name: {
                type: "string",
                pattern: "^[A-Za-z0-9-]+$"
            },
            caller_email_id: {
                type: "string",
                pattern: "^[+]?[0-9]{10,14}$"
            },
            details: {
                type: "string",
                pattern: "^[+]?[0-9]{10,14}$"
            },

        },
        CALL_TRANSFER: {
            name: {
                type: "string",
                pattern: "^[A-Za-z0-9-]+$"
            },
            phone: {
                type: "string",
                pattern: "^[+]?[0-9]{10,14}$"
            }
        },
        DATA_EXTRACTOR: {
            caller_name: {
                type: "string",
                pattern: "^[A-Za-z0-9-]+$"
            },
            caller_email_id: {
                type: "string",
                pattern: "^[+]?[0-9]{10,14}$"
            },
            caller_phone_no: {
                type: "string",
                pattern: "^[+]?[0-9]{10,14}$"

            },
        },
        LIVE_BOOKING: {
            caller_name: {
                type: "string",
                pattern: "^[A-Za-z0-9-]+$"
            },
            date_time: {
                type: "string",
                pattern: "^\\d{4}/\\d{2}/\\d{2} \\d{2}:\\d{2}$"
            },
            details: {
                type: "string",
                pattern: "^[+]?[0-9]{10,14}$"
            },

        },
        CUSTOM_ACTION: {
            key: 'value'
        }
    };

    const tabs = [
        { type: 'SEND_MAIL', label: 'Send Email', description: 'Send Custom SMS', icon: <Sms style={{ marginRight: '8px' }} /> },
        { type: 'CALL_TRANSFER', label: 'Call Transfer', description: 'Transfer Call', icon: <Phone style={{ marginRight: '8px' }} /> },
        { type: 'DATA_EXTRACTOR', label: 'Data Extractor', description: 'Extract the data from the call', icon: <DataSaverOn style={{ marginRight: '8px' }} /> },
        { type: 'LIVE_BOOKING', label: 'Live Booking', description: 'Live Booking', icon: <QueryBuilder style={{ marginRight: '8px' }} /> },
        { type: 'CUSTOM_ACTION', label: 'Custom Action', description: 'Custom Action', icon: <Dashboard style={{ marginRight: '8px' }} /> },
    ];

    const handleVerticalTabChange = (event, newValue) => {
        setSelectedTab(0)
        setSelectedTab(newValue);
        setActionType(tabs[newValue - 1].type); // Changed from newValue - 1 to newValue
        setActionName("")
        setActionDescription("")
        setActionInputSchema("")
        setUrl("")
    };

    const getActionsFromLocal = async () => {
        if (localStorage.getItem('actions')) {
            const savedActions = JSON.parse(localStorage.getItem('actions'));
            setActions(savedActions);
        }
    };

    useEffect(() => {
        getActionsFromLocal();
    }, []);

    useEffect(() => {
        setActionType(tabs[selectedTab - 1].type); // Changed from selectedTab - 1 to selectedTab
    }, [selectedTab]);

    const getToken = () => {
        const t = JSON.parse(localStorage.getItem('token')).token
        setToken(t)
    }

    useEffect(() => {
        getToken()
    }, [token])

    const save = () => {
        let actionExists;
    
        // Check for existing action names when editing or adding a new action
        if (actionId) {
            // If editing, check if the name is already in the list except for the current action
            actionExists = actions.some(action => action._id !== actionId && action.ActionName === actionName);
        } else {
            // If creating a new action, check if the name already exists
            actionExists = actions.some(action => action.ActionName === actionName);
        }
    
        if (actionExists && !isEdit) {
            // Show an error if the name already exists
            toast.error("Action name already exists. Please choose a different name.");
            return; // Exit the function if the name is a duplicate
        }
    
        const newAction = {
            Type: actionType,
            ActionName: actionName,
            Description: actionDescription,
            InputSchema: actionInputSchema,
            Url: url,
            _id: actionId || null, // Use the provided actionId or set to null for new actions
        };
    
        let updatedActions;
    
        if (actionId) {
            // If updating an existing action, replace it with the new action
            updatedActions = actions.map(action =>
                action._id === actionId ? newAction : action
            );
        } else {
            // If adding a new action, use prevName to decide if this is an edit or new action
            if (prevName) {
                // If prevName exists, assume we are updating an existing action
                updatedActions = actions.map(action =>
                    action.ActionName === prevName ? newAction : action
                );
            } else {
                // If no prevName, this is definitely a new action
                updatedActions = [...actions, newAction];
            }
        }
    
        // Update the actions state and local storage
        setActions(updatedActions);
        localStorage.setItem('actions', JSON.stringify(updatedActions));
    
        // Clear the form fields
        setActionName('');
        setActionDescription('');
        setActionInputSchema('');
        setUrl('');
        setActionId(null);
        setPrevName("");
    
        // Show success message
        toast.success("Action saved successfully");
        setIsEdit(false);
    };
    
    

    const showActionData = async (action) => {
        setActionType(action.Type)
        setActionName(action.ActionName)
        setActionDescription(action.Description)
        setActionInputSchema(action.InputSchema)
        setUrl(action.Url)
        if (action._id) {
            setActionId(action._id)
        }
        setIsEdit(true)
        setPrevName(action.ActionName)
    }

    const handleDeleteAction = async (action) => {
        if (action._id) {
            await api.delete(`/actions/${action._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const updatedActions = actions.filter(a => a._id !== action._id);
            setActions(updatedActions);
            localStorage.setItem('actions', JSON.stringify(updatedActions));
            toast.success("Action deleted successfully");
        } else {
            const updatedActions = actions.filter(a => a.ActionName !== action.ActionName);
            setActions(updatedActions);
            localStorage.setItem('actions', JSON.stringify(updatedActions));
            toast.success("Action deleted successfully");
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem", bgcolor: "rgba(177, 177, 190, 0.11)", padding: "1rem", borderRadius: "0.5rem", height: "fit-content" }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={selectedTab}
                        onChange={handleVerticalTabChange}
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                borderRadius: '8px',
                            },
                            '& .MuiTab-root': {
                                border: '1px solid #02042d',
                                marginBottom: '1rem',
                                padding: '1rem 2rem',
                                color: '#02042d',
                                fontWeight: 'semi',
                                maxWidth: '1000px',
                                borderRadius: '10px',
                                textAlign: 'left',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                '&.Mui-selected': {
                                    backgroundColor: '#02042d',
                                    color: '#ffffff',
                                },
                                '&:hover': {
                                    backgroundColor: '#02042d',
                                    color: '#ffffff',
                                },
                            },
                            '& .MuiTabs-indicator': {
                                display: 'none',
                            },
                        }}
                    >
                        <Typography variant="h6">Actions</Typography>
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.type} // Changed from index to tab.type
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {tab.icon}
                                        <div>
                                            <strong>{tab.label}</strong>
                                            <div style={{ fontSize: '0.8rem', color: '#777', textTransform: 'capitalize' }}>{tab.description}</div>
                                        </div>
                                    </div>
                                }
                            />
                        ))}
                    </Tabs>

                    <Box sx={{ mt: 4 }}>
                        {
                            actions && actions.length > 0 ? (
                                <Typography variant="h6">Saved Actions</Typography>
                            ) : (
                                <p>No saved actions available.</p>
                            )
                        }
                        {actions.map((action, index) => (
                            <Button
                                key={index}
                                variant="outlined"
                                style={{
                                    width: "100%",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "flex-start",
                                    color: '#02042d',
                                    borderColor: "#02042d",
                                    marginBottom: '0.5rem',
                                    borderRadius: "0.5rem",
                                    fontSize: '0.9rem',
                                    paddingTop: '1rem',
                                    paddingBottom: "0.5rem",
                                    columnGap: "1rem",
                                    marginBottom: '1rem'
                                }}
                                onClick={() => { showActionData(action) }}
                            >
                                <Dashboard />
                                <Box style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}>
                                    <strong style={{ fontSize: "0.9rem", fontWeight: 700, marginBottom: 0, lineHeight: 1 }}>{action.ActionName}</strong>
                                    <Typography style={{ color: "#7c7c7c", textTransform: 'capitalize' }}>{action.Description}</Typography>
                                </Box>
                                <Close
                                    style={{ position: 'absolute', right: 10, top: 2 }}
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevent the button's onClick from being triggered
                                        handleDeleteAction(action);
                                    }}
                                />
                            </Button>

                        ))}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>

                <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem", bgcolor: "rgba(177, 177, 190, 0.11)", padding: "1rem", borderRadius: "0.5rem" }}>
                    <Typography variant="h6">Action Details</Typography>
                    <form onSubmit={(e) => { e.preventDefault(); save(); }}>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                            <Typography variant="body1" sx={{ minWidth: 100 }}>Action Name</Typography>
                            <TextField
                                size="small"
                                fullWidth
                                label="Name"
                                variant="outlined"
                                value={actionName}
                                onChange={(e) => setActionName(e.target.value)}
                                style={{ marginBottom: '1rem' }}
                                sx={{ bgcolor: "white" }}
                            />
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                            <Typography variant="body1" sx={{ minWidth: 100 }}>Description</Typography>
                            <TextField
                                size="small"
                                fullWidth
                                label="Description"
                                variant="outlined"
                                value={actionDescription}
                                onChange={(e) => setActionDescription(e.target.value)}
                                style={{ marginBottom: '1rem' }}
                                sx={{ bgcolor: "white" }}
                            />
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                            <Typography variant="body1" sx={{ minWidth: 100 }}>Input Schema</Typography>
                            <TextField
                                multiline
                                rows={6}
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={actionInputSchema}
                                onChange={(e) => setActionInputSchema(e.target.value)}
                                style={{ marginBottom: '1rem' }}
                                sx={{ bgcolor: "white" }}
                            />
                        </Box>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                            <Typography variant="body1" sx={{ minWidth: 100 }}>Url</Typography>
                            <TextField
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                style={{ marginBottom: '1rem' }}
                                sx={{ bgcolor: "white" }}
                            />
                        </Box>
                        <input type="hidden" value={actionType} />

                        <Accordion style={{ marginBottom: '2rem' }}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="body1">Schema</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <pre style={{ background: 'none', border: 'none', padding: 0 }}>
                                    {schemas[actionType] ? JSON.stringify(schemas[actionType], null, 2) : 'No schema available.'}
                                </pre>
                            </AccordionDetails>
                        </Accordion>
                        <Button variant="contained" style={{ backgroundColor: '#02042d', color: '#fff' }} type="submit" disabled={!actionName || !actionDescription || !actionInputSchema}>
                            Save
                        </Button>
                    </form>
                </Box>
            </Grid>
        </Grid>

    );
}

export default Actions;
