import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    InputAdornment,
    CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import api from '../../api';
import { toast } from 'sonner';
import PasswordChangeModal from '../../models/PasswordChangeModal';

const SecuritySettings = () => {
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [openOtpModal, setOpenOtpModal] = useState(false);
    const userData = JSON.parse(localStorage.getItem("User"));
    
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const token = JSON.parse(localStorage.getItem("token"))?.token;

    const getUser = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/users/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUser(response.data.user);
            setLoading(false);

        } catch (error) {
            console.error("Error fetching phone:", error);
        }
    }

    const handleSetPassword = async () => {
        setLoading(true);
        try {
            const payload = {
                Email: userData?.Email,
                Password: newPassword,
                ConfirmPassword: confirmedPassword,
            }
            const response = await api.post(`/users/send-otp-google-signup`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(response.data.message);
            setOpenOtpModal(true);
        } catch (error) {
            toast.error(error.response?.data?.message || "Error resending OTP");
            console.error("Error resending OTP:", error);
        } finally {
            setLoading(false);
        }

    };


    const changePassword = async () => {
        try {
            const response = await api.post(`/users/change-password`,
                {
                    OldPassword: currentPassword,
                    NewPassword: newPassword,
                    ConfirmPassword: confirmedPassword,
                    Email: userData.Email
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );

            setUser(response.data);
            toast.success(response.data.message);
            getUser();

        } catch (error) {
            toast.error(error.response?.data?.message || "Error changing password");
            console.error("Error fetching phone:", error);
        }
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            padding: "1rem",
            borderRadius: "1rem",
            bgcolor: "rgba(177, 177, 190, 0.12)"
        }}>
            <Typography variant="h6">Security Settings</Typography>

            {userData?.isGoogleSignup && !userData?.isPasswordSet ? (
                // New password setup for Google signup users
                <>
                    <TextField
                        label="New Password"
                        variant="outlined"
                        type={showNewPassword ? "text" : "password"}
                        fullWidth
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                        edge="end"
                                    >
                                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />

                    <TextField
                        label="Confirm New Password"
                        variant="outlined"
                        type={showConfirmedPassword ? "text" : "password"}
                        fullWidth
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowConfirmedPassword(!showConfirmedPassword)}
                                        edge="end"
                                    >
                                        {showConfirmedPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setConfirmedPassword(e.target.value)}
                    />

                    <Button
                        variant="contained"
                        sx={{
                            bgcolor: "#010320",
                            color: "#FFF",
                            minHeight: '36px',  // Set fixed height to prevent button size change
                            minWidth: '120px',  // Set minimum width to prevent button size change
                            display: 'flex',    
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px',         // Ensure spacing between loader and text if needed
                        }}
                        onClick={handleSetPassword}
                        disabled={!newPassword || !confirmedPassword || loading} // Disable while loading
                    >
                        {loading ? (
                            <CircularProgress
                                size={24} // Adjust size to fit within the button
                                sx={{
                                    color: "#FFF",  // Same color as button text
                                }}
                            />
                        ) : (
                            "Set Password"
                        )}
                    </Button>

                </>
            ) : (
                // Regular password change form
                <>
                    <TextField
                        label="Current Password"
                        variant="outlined"
                        type={showCurrentPassword ? "text" : "password"}
                        fullWidth
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                        edge="end"
                                    >
                                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />

                    <TextField
                        label="New Password"
                        variant="outlined"
                        type={showNewPassword ? "text" : "password"}
                        fullWidth
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                        edge="end"
                                    >
                                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />

                    <TextField
                        label="Re-Enter New Password"
                        variant="outlined"
                        type={showConfirmedPassword ? "text" : "password"}
                        fullWidth
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowConfirmedPassword(!showConfirmedPassword)}
                                        edge="end"
                                    >
                                        {showConfirmedPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setConfirmedPassword(e.target.value)}
                    />

                    <Button
                        variant="contained"
                        sx={{ bgcolor: "#010320", color: "#FFF" }}
                        onClick={changePassword}
                        disabled={!currentPassword || !newPassword || !confirmedPassword}
                    >
                        Update
                    </Button>
                </>
            )}

            {/* Password OTP Modal */}
            <PasswordChangeModal
                open={openOtpModal}
                onClose={() => setOpenOtpModal(false)}
                email={userData?.Email}
                newPassword={newPassword}
                confirmPassword={confirmedPassword}
                token={token}
                getUser={getUser}
            />
        </Box>
    );
};

export default SecuritySettings;