import React from 'react';
import { Box } from '@mui/material';

const ShimmerLoader = ({ height }) => ( <Box
    sx={{
      width: '100%',
      height: height || '100%',
      backgroundColor: '#f0f0f0',
      backgroundImage: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
      backgroundSize: '200% 100%',
      backgroundRepeat: 'no-repeat',
      animation: 'shimmer 1.5s infinite',
      '@keyframes shimmer': {
        '0%': {
          backgroundPosition: '200% 0',
        },
        '100%': {
          backgroundPosition: '-200% 0',
        },
      },
    }}
  />
);

export default ShimmerLoader;