const validateText = (orgName) => {
    // Trim leading/trailing spaces
    const trimmedName = orgName.trim();
  
    // Helper function to return an error message
    const getErrorMessage = (message) => {
      return { isValid: false, message };
    };
  
    // 1. Required field validation
    if (!trimmedName) {
      return getErrorMessage('Organization name is required.');
    }
  
    // 2. Length validation (min 3, max 100 characters)
    if (trimmedName.length < 3 || trimmedName.length > 100) {
      return getErrorMessage('Organization name must be between 3 and 100 characters.');
    }
  
    // 3. Character validation: only letters, numbers, spaces, hyphens, and underscores
    const regex = /^[A-Za-z0-9 _-]+$/;
    if (!regex.test(trimmedName)) {
      return getErrorMessage('Organization name can only contain letters, numbers, spaces, hyphens, and underscores.');
    }
  
    // 4. No leading/trailing spaces
    if (trimmedName !== orgName) {
      return getErrorMessage('Organization name cannot have leading or trailing spaces.');
    }
  
    // 5. No consecutive spaces
    if (/\s{2,}/.test(trimmedName)) {
      return getErrorMessage('Organization name cannot contain consecutive spaces.');
    }
  
    // 6. No numbers-only names
    if (/^\d+$/.test(trimmedName)) {
      return getErrorMessage('Organization name cannot be just numbers.');
    }
  
    // 7. Optional: Require at least one alphabetic character (uncomment if needed)
    // if (!/[a-zA-Z]/.test(trimmedName)) {
    //   return getErrorMessage('Organization name must contain at least one letter.');
    // }
  
    // If everything passes, return valid status
    return { isValid: true, message: '' };
  };
  
  export default validateText;
  