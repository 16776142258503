import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Snackbar, Alert,
  CircularProgress
} from '@mui/material';
import { toast, Toaster } from 'sonner';
import { Link, useLocation } from 'react-router-dom';
import { CreditCard, Download } from '@mui/icons-material';
import api from "../api"; // Ensure you have an API utility file
import Navbar from '../components/Navbar';
import PriceCard from '../components/ManageBilling/CardPrice';
import PriceCardForm from '../components/ManageBilling/PriceCardForm';
import { ManageBillingStyledButton } from '../components/ManageBilling/StyledButtons';
import NoDataFound from '../components/NoDataFound';

export const getNextBillingDate = (expiryDate) => {
  if (!expiryDate) {
    return null;
  }
  const expiry = new Date(expiryDate);
  if (Number.isNaN(expiry.getTime())) {
    throw new Error("Invalid expiryDate provided");
  }
  return expiry.toISOString().split('T')[0];
};

const ManageBilling = () => {
  const [invoices, setInvoices] = useState([]); // Remove this line if not needed
  const [loading, setLoading] = useState(false); // Remove this line if loading is not used
  const planName = localStorage.getItem("planId");
  const planDetails = JSON.parse(localStorage.getItem("planDetails"));
  const [companyId, setCompanyId] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const location = useLocation();
  const [openCancel, setOpenCancel] = useState(false);
  const [openUpdateCard, setOpenUpdateCard] = useState(false);
  const token = JSON.parse(localStorage.getItem("token")).token || null;
  const isCancelled = planDetails?.status === 'cancelled';
  const hasNextBillingDate = getNextBillingDate(planDetails?.end_date);
  const [cancelLoading, setCancelLoading] = useState(false);


  useEffect(() => { setCompanyId(localStorage.getItem('Organization')) }, []);

  const getInvoice = async () => {
    setLoading(true);
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { data: { invoices } } = await api.get("/v1/billing/subscription/invoice", config);
      if (invoices) {
        setInvoices(invoices)
      }
    } catch (error) {
        console.error(error);
    } finally {
        setLoading(false);
    }
  }

  useEffect(() => {
    if (token) getInvoice()
  }, [token])

  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    expiryDate: "",
    cardHolder: "",
  });

  const handleConfirmCancel = async () => {
    setLoading(true);
    setCancelLoading(true); // Start loading

    try {
      const response = await api.post(
        "/v1/billing/cancel-subscription",
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response?.data?.success) {
        handleCloseAll();
        toast.success(response?.data?.message)
        setTimeout(() => {
          window.location.reload();
      }, 2500);
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      toast.error("Failed to cancel subscription. Please try again later.");
    } finally {
      setCancelLoading(false); // Stop loading
      setLoading(false);
    }
  };

  const handleUpdateCard = (details) => {
    setCardDetails(details); // Save the updated card details
    setOpenUpdateCard(false); // Close the dialog
  };

  const handleCloseAll = () => {
    setOpenCancel(false);
    setOpenUpdateCard(false);
  };

  const handleCancel = () => {
    handleConfirmCancel(); // Call the handleConfirmCancel function when cancel button is submitted
  };
  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const getButtonText = (planName, isCancelled, hasNextBillingDate) => {
    if (!planName) return 'Choose Plan';
    if (isCancelled || !hasNextBillingDate) return 'Renew Plan';
    return 'Upgrade Plan';
  };

  return (
    <>
          <Toaster richColors position="top-right" closeButton duration="1500" />

      <Navbar title="Manage Billing" />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 4 }}>
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{
          zIndex: 9999 // Make sure this is higher than your modal's z-index
        }}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant="filled">
            {snackbar.message}
          </Alert>
        </Snackbar>
        <Box flex={1} sx={{ p: 4, bgcolor: '#ffffff', borderColor: '#E5E7EB', height: 'auto', borderWidth: '1px', borderRadius: '12px', borderStyle: 'solid' }}>
          {/* Current Plan */}
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Current Plan
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Manage your subscription and billing details
            </Typography>
            <Box sx={{ bgcolor: "#F9FAFB", p: 3, borderRadius: 4, border: '1px solid', borderColor: '#E5E7EB' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography variant="h5">{planName ? `${planName} Plan` : "Currently on Free Plan"}</Typography>
                  {isCancelled ? (
                    <Typography variant="body1" color="red">
                      Plan cancelled
                    </Typography>
                  ) : getNextBillingDate(planDetails?.end_date) ? (
                    <Typography variant="body1" color="text.secondary">
                      Next billing date: {getNextBillingDate(planDetails?.end_date)}
                    </Typography>
                  ) : (
                    <Typography variant="body1" color="text.secondary">
                      No billing date available
                    </Typography>
                  )}




                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <ManageBillingStyledButton
                      variant="contained"
                      component={Link}
                      to={`/company/${companyId}/pricing`}
                      className={location.pathname.includes(`/company/${companyId}/pricing`) ? 'sideLinksActive' : 'sideLinks'}
                    >
                    {getButtonText(planName, isCancelled, hasNextBillingDate)}
                    </ManageBillingStyledButton>

                    <ManageBillingStyledButton
                      disabled={isCancelled || !hasNextBillingDate}
                      onClick={() => setOpenCancel(true)}
                    >
                      {isCancelled || !hasNextBillingDate ? 'Plan cancelled' : 'Cancel Plan'}
                    </ManageBillingStyledButton>
                  </Box>
              </Box>
            </Box>
          </CardContent>
        </Box>

        {/* Payment Method */}
        <Box flex={1} sx={{ p: 4, bgcolor: '#ffffff', borderColor: '#E5E7EB', height: 'auto', borderWidth: '1px', borderRadius: '12px', borderStyle: 'solid' }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Payment Method
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Your saved payment details
            </Typography>
            <PriceCard
              cardNumber={cardDetails.cardNumber}
              expiry={cardDetails.expiryDate}
              name={cardDetails.cardHolder}
            />
            <Button
              startIcon={<CreditCard />}
              variant="outlined"
              onClick={() => setOpenUpdateCard(true)}
              sx={{
                fontWeight: "600",
                color: "black",
                bgcolor: "#ffffff",
                borderRadius: "6px",
                border: "1px solid",
                borderColor: "#E5E7EB",
                transition: "background-color 0.2s ease, border-color 0.2s ease",
                cursor: "pointer",
                "&:hover": {
                  bgcolor: "#f9fafb",
                  borderColor: "#d1d5db",
                },
              }}
            >
              Update Card
            </Button>
          </CardContent>
        </Box>

        {/* Billing History */}
        <Box flex={1} sx={{ p: 4, maxHeight: '300px', overflow: 'auto', bgcolor: '#ffffff', borderColor: '#E5E7EB', height: 'auto', borderWidth: '1px', borderRadius: '12px', borderStyle: 'solid' }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Billing History
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Download past invoices
            </Typography>
            {loading ? (
              // Loader shown while fetching data
              <Box display="flex" justifyContent="center" py={3}>
                <CircularProgress />
              </Box>
            ) : (
              invoices.length > 0?
                <List>
                  {invoices?.filter(item => item?.invoice_pdf).map((item) => (
                    <ListItem
                      key={item?.id}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        py: 2
                      }}
                    >
                      <Typography sx={{ fontWeight: '600', fontSize: '1rem' }}>{`${item?.total} - ${item?.created}`}</Typography>
                      <Button
                        startIcon={<Download />}
                        sx={{
                          fontWeight: "600",
                          color: "black",
                          bgcolor: "#ffffff",
                          borderRadius: "6px",
                          border: "1px solid",
                          borderColor: "#E5E7EB",
                          transition: "background-color 0.2s ease, border-color 0.2s ease",
                          padding: '6px 24px',
                          cursor: "pointer",
                          "&:hover": {
                            bgcolor: "#f9fafb",
                            borderColor: "#d1d5db",
                          },
                        }}
                        onClick={() => {
                          if (item?.invoice_pdf) {
                            window.open(item.invoice_pdf, "_blank");
                          } else {
                            toast.error("Invoice not generated");
                          }
                        }}
                      >
                        Download
                      </Button>
                    </ListItem>
                  ))}
                </List>
              : 
                <NoDataFound
                  title="No Billing History Available"
                  subtitle="There are no subscription plans to display billing details at the moment."
                />
            )}
           
          </CardContent>
        </Box>

        {/* Modals */}
        {/*Cancel Plan Dialogue*/}
        <Dialog
          open={openCancel}
          onClose={handleCloseAll}

          fullWidth
          PaperProps={{
            sx: {
              padding: 1, // Add padding inside the dialog
              borderRadius: 3,
              maxWidth: '500px', // Adjust border radius
            },
          }}>
          <DialogTitle>
            <Typography sx={{
              fontSize: '24px',
              fontWeight: 600,

            }}>
              Cancel Your Plan?
            </Typography>
            <Typography sx={{ fontSize: '0.9rem', color: '#7C7C83', fontWeight: '500', mt: 1 }} gutterBottom>

              Are you sure you want to cancel your {planName} Plan? This action cannot be undone.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              padding: 2,
              bgcolor: '#F9FAFB',
              borderColor: '#E5E7EB',
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 2
            }}>
              <Typography>You'll continue to have access until the end of your billing period on <span style={{ fontWeight: 700 }}>{getNextBillingDate(planDetails?.end_date)}</span></Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{
            display: 'flex',
            gap: 1,
          }}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#E5E7EB",
                color: "black",
                padding: "8px 24px",
                textTransform: "none",
                fontWeight: 600 // Prevents text from being displayed in all caps
              }}
              onClick={handleCloseAll}
            >
              No, go back
            </Button>

            <Button 
              color="error" 
              variant="contained"
              sx={{ borderColor: "#E5E7EB", color: "white", padding: "8px 24px", fontWeight: 600, textTransform: "none" }}
              onClick={handleCancel}
              disabled={cancelLoading} // Disable button while loading
            >
              {cancelLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Yes, cancel"}
            </Button>
          </DialogActions>

        </Dialog>

        {/*Update Card Dialogue*/}
        <Dialog open={openUpdateCard} onClose={handleCloseAll} maxWidth="sm" fullWidth>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" flexDirection="column" gap="6px">
              <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>
                Update Payment Method
              </Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#7C7C83' }}>
                Enter your new card details below
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <PriceCardForm onSubmit={handleUpdateCard} onClose={() => setOpenUpdateCard(false)} />
          </DialogContent>
        </Dialog>

      </Box>
    </>
  );
};

export default ManageBilling;
