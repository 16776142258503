import { Tooltip } from '@mui/material'
import React from 'react'
import {Info} from '@mui/icons-material'

const TooltipCustom = ({title}) => {
  return (
    <Tooltip title={title} style={{display: 'inline', marginBottom: '-0.25rem'}}>
        <Info fontSize='10'/>
    </Tooltip>
  )
}

export default TooltipCustom