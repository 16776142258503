import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const PhoneInputWithValidation = ({ formValues, setFormValues, country = 'us',error,setError }) => {

  const handlePhoneNumberInputChange = (value, country) => {
    if (!country || !country.name || !country.countryCode) {
      setError("Please select a valid country");
      return;
    }

    if (!value) {
      setError(`Please enter a phone number in ${country.name} format`);
      return;
    }

    try {
      const phoneNumber = parsePhoneNumberFromString(`+${value.toString()}`, country.countryCode.toUpperCase());
      
      if (!phoneNumber || !phoneNumber.isValid()) {
        setError(`Invalid format. Example: ${phoneNumber?.getExample()}`);
      } else {
        setError(""); // Clear error if valid
      }
    } catch (error) {
      setError(`Please enter a valid ${country.name} phone number`);
    }

    // Update formValues with the new PhoneNumber
    setFormValues({ ...formValues, PhoneNumber: value });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
      <PhoneInput
        country={country}
        value={formValues.PhoneNumber}
        onChange={(value, country) => handlePhoneNumberInputChange(value, country)}
        inputStyle={{
          width: '100%',
          height: '2.5rem',
          fontSize: '1rem',
          paddingLeft: '3rem',
        }}
        buttonStyle={{
          border: "1px solid #c4c4c4",
          borderRadius: "4px 0 0 4px",
        }}
        inputProps={{
          name: "PhoneNumber",
          required: true,
          'aria-label': 'Phone Number',
        }}
        containerStyle={{
          width: '100%',
        }}
      />
      {error && (
        <p style={{ color: "red", fontSize: "14px" }}>
          {error}
        </p>
      )}
    </div>
  );
};

export default PhoneInputWithValidation;
