import { useCallback, useEffect, useState } from "react";

import { Avatar, Box, Button, Checkbox, Chip, FormControl, IconButton, Menu, MenuItem, Modal, Select, Switch, TextField, Typography,CircularProgress } from "@mui/material";
import { AssistantOutlined, Close, CopyAll, Delete, Edit, MoreVert } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import { toast } from 'sonner';
import api from "../api";
import ClipboardBox from "../components/ClipboardBox";
import NewViewAssistantForm from "../components/NewViewAssistantForm";
import HazardIcon from "../components/HazardIcon";
import { useGetAllPermissions } from "./utils/getAllPermissions";

const NewAssistantDetails = () => {
    const [ assistantDetails, setAssistantDetails ] = useState(null);
    const [ open, setOpen ] = useState(false);
    const [ deleteConfirmOpen, setDeleteConfirmOpen ] = useState(false);
    const [ assistantName, setAssistantName ] = useState("");
    const [ assistantType, setAssistantType ] = useState("");
    const [ assistantCategory, setAssistantCategory ] = useState("");
    const [ logo, setLogo ] = useState(null);
    const [ logoFile, setLogoFile ] = useState(null);
    const [ consentChecked, setConsentChecked ] = useState(false);
    const [ actions, setActions ] = useState([]);
    const [ menuAnchorEl, setMenuAnchorEl ] = useState(null);
    const [ assignedModal, setAssignedModal ] = useState(false);
    const [ organizationList, setOrganizationList ] = useState([]);
    const [ organization, setOrganization ] = useState();
    const [ isDemo, setIsDemo ] = useState(false);
    const [ token, setToken ] = useState("");
    const [ userRole, setUserRole ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [assistantDescription, setAssistantDescription] = useState('');
    const [validationError, setValidationError] = useState(false);
    const { permissions, permissionsLoading, getAllPermissions } = useGetAllPermissions();
    


    const { assistantId, company_id } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("User"));
        setUserRole(userData?.Role || "");
        setToken(userData?.Token);
    }, []);

    useEffect(() => {
        if (token) {
            
            const initializeData = async () => {
                setLoading(true);
                try {
                    if (token) {
                        await getAllPermissions();
                        await fetchAssistantDetails();
                        await getAllOrganizations();
                    }
                } catch (error) {
                    console.error("Error initializing data:", error);
                } finally {
                    setLoading(false);
                }
            };

            initializeData();
            
        }
    }, [ token ]);

    useEffect(() => {
        if (assistantDetails) {
            setAssistantName(assistantDetails?.Name || "");
            setAssistantType(assistantDetails?.Type || "");
            setAssistantCategory(assistantDetails?.Category || "");
            setLogo(assistantDetails?.Logo?.fileUrl || null);
            setAssistantDescription(assistantDetails?.Description);
        }
    }, [ assistantDetails ]);

    const fetchAssistantDetails = useCallback(async () => {
        setLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await api.get(`/v1/assistant/${assistantId}`, config);
            setAssistantDetails(response.data.assistant);
            setActions(response.data.actions);
            setOrganization(response.data.assistant.Organization);
            setIsDemo(response.data.assistant?.isDemo || false);
        } catch (error) {
            console.error("Error fetching assistant details:", error);
            setLoading(false);
        }finally {
            setLoading(false);
        }
    }, [ assistantId, token ]);

    const getAllOrganizations = useCallback(async () => {
        try {
            const response = await api.get("/organizations", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    limit: 999
                }
            });

            setOrganizationList(response.data.data?.organizations);
        } catch (error) {
            console.error("Error fetching phone:", error);
        }
    }, [ token ]);

    const handleAssign = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await api.post("/superadmin/assign-assistant", {
                assistantId: assistantDetails?._id,
                organizationId: organization
            }, config);

            setAssignedModal(false);
            toast.success("Assistant assigned successfully");

        } catch (error) {
            toast.error("Error assigning assistant");
            console.error("Error assigning assistant:", error);
        }
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleDelete = () => {
        if (!consentChecked) {
            setDeleteConfirmOpen(true);
            return;
        }
        performDelete();
    };

    const performDelete = async () => {
        try {
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            const response = await api.delete(`/v1/assistant/delete/${assistantId}`, config);
            if (response.data && response.data.message) {
              toast.success(response.data.message);
            }
            setDeleteConfirmOpen(false);
            navigate(
              userRole === "SuperAdmin"
                ? "/assistants"
                : `/company/${company_id}/assistants`
            );
        } catch (error) {
            toast.error(error.response.data.details);
            console.error("Error deleting assistant:", error);
        }
    };

    const handleDuplicate = async () => {
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        };

        await api.post("/v1/assistant/duplicate", { assistantId: assistantId }, config);
        toast.success("Assistant duplicated successfully");
        navigate(
            userRole === "SuperAdmin"
                ? "/assistants"
                : `/company/${company_id}/assistants`
        );
    };

    const toggleAssistant = async () => {
        try {
            const response = await api.patch(`/superadmin/toggle-assistant/${assistantDetails._id}`, {
                isDemo: !isDemo
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.response.data.message);
            console.error(error);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogoUpload = (event) => {
        const file = event.target.files[ 0 ];
        if (file) {
            setLogoFile(file);
            setLogo(URL.createObjectURL(file));
        }
    };

    const handleAssistantProfileUpdate = async () => {
        setLoading(true); // Start loading
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            }
        };

        const formData = new FormData();
        formData.append("Name", assistantName);
        formData.append("Type", assistantType);
        formData.append("Category", assistantCategory);
        formData.append("Description",assistantDescription);
        if (logoFile) {
            formData.append("logo", logoFile);
        }

        try {
            const response = await api.patch(`/v1/assistant/update/${assistantId}`, formData, config);
            fetchAssistantDetails();
            handleClose();
            toast.success(response.data?.message);
        } catch (error) {
            const errorMessage = error.response?.data?.details;
            toast.error(errorMessage);
            console.error("Error updating assistant:", errorMessage);
        } finally {
            setLoading(false); // End loading
        }
    };

    return (
        <>
            <Box>
                <Navbar title="View Assistant" />
            </Box>
            {
                assistantDetails && (
                    <Box>
                        <Box sx={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                            <Box style={{ display: 'flex', columnGap: 10, alignItems: 'center', width: '100%' }}>
                                <Avatar
                                    alt={assistantDetails?.Name}
                                    src={assistantDetails.Logo?.fileUrl}
                                    sx={{ width: 50, height: 50 }}
                                />
                                <Box>
                                    <Typography variant="h6">
                                        <Box style={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                                            <Box style={{
                                                maxWidth: '40vw',
                                                overflow: 'hidden',        // Hide overflow text
                                                textOverflow: 'ellipsis',  // Show ellipsis for overflowed text
                                                whiteSpace: 'nowrap'       // Keep text on a single line
                                            }}>
                                                {assistantDetails?.Name}
                                                {permissions?.includes("update_assistant") && <Edit fontSize="small" onClick={() => { handleClickOpen(); handleMenuClose(); }} sx={{ marginLeft: "0.5rem", cursor: "pointer" }} />}
                                            </Box>
                                        </Box>
                                    </Typography>
                                    <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
                                        <ClipboardBox id={assistantDetails._id} />
                                        <Chip
                                            variant="outlined"
                                            size="small"
                                            label={assistantDetails.Category}
                                            sx={{ color: "#02042D", borderColor: "#02042D", borderRadius: "8px", textTransform: "capitalize", fontSize: "1rem" }}
                                        />
                                        <Chip
                                            variant="outlined"
                                            size="small"
                                            label={assistantDetails.Type}
                                            sx={{ color: "#02042D", borderColor: "#02042D", borderRadius: "8px", textTransform: "capitalize", fontSize: "1rem" }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                            {(permissions?.includes("delete_assistant") || 
                            permissions?.includes("duplicate_assistant") || 
                            permissions?.includes("assign_assistant_to_company") || 
                            permissions?.includes("assign_assistant_to_demo")) && (
                                <IconButton onClick={handleMenuOpen}>
                                <MoreVert />
                                </IconButton>
                            )}
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={Boolean(menuAnchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    {permissions?.includes("delete_assistant") && <MenuItem onClick={() => { handleDelete(); handleMenuClose(); }}>
                                        <Delete fontSize="small" style={{ marginRight: "0.5rem" }} /> Delete
                                    </MenuItem>}

                                    {permissions?.includes("duplicate_assistant") && <MenuItem onClick={() => { handleDuplicate(); handleMenuClose(); }}>
                                        <CopyAll fontSize="small" style={{ marginRight: "0.5rem" }} /> Duplicate
                                    </MenuItem>}

                                    {permissions?.includes("assign_assistant_to_company") && <MenuItem onClick={() => {
                                        setAssignedModal(true)
                                        setMenuAnchorEl(null);
                                    }}>
                                        <AssistantOutlined fontSize="small" sx={{ mr: ".5rem" }} /> Assign Assistant
                                    </MenuItem>
                                    }

                                    {permissions?.includes("assign_assistant_to_demo") && <MenuItem>
                                        <Box style={{ marginLeft: '-0.5rem' }}>
                                            <Switch
                                                checked={isDemo}
                                                onChange={(e) => { setIsDemo(e.target.checked); toggleAssistant(); }}
                                                sx={{
                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                        color: '#02042d',
                                                    },
                                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                        backgroundColor: '#02042d',
                                                    },
                                                }}
                                            />

                                            Demo
                                        </Box>

                                    </MenuItem>}

                                </Menu>
                            </Box>
                        </Box>

                        <NewViewAssistantForm assistant={assistantDetails} actions={actions} />


                    </Box>
                )
            }

            <Modal open={assignedModal} onClose={() => setAssignedModal(false)}>
                <Box sx={{ width: "100dvw", display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>
                    <Box sx={{ width: "400px", bgcolor: "#FFF", padding: "2rem 1.5rem", borderRadius: "18.8px", display: "flex", flexDirection: "column", gap: "15.67px" }}>

                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h6" component="div">Assign Assistant</Typography>
                            <Close onClick={() => setAssignedModal(false)} />
                        </Box>

                        <TextField
                            variant="outlined"
                            size="small"
                            value={assistantDetails?.Name}
                        />

                        <FormControl>
                           
                            <TextField
                                labelId="company"
                                id="company"
                                
                                select
                                value={organization}
                                label="Selected Company"
                                size="small"
                                onChange={(e) => setOrganization(e.target.value)}
                            >
                                {organizationList.map((organization) => (
                                    <MenuItem key={organization._id} value={organization._id}>{organization.Name}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>

                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
                        <Button 
                            variant="outlined" 
                            onClick={() => setOrganization("")} 
                            sx={{ textWrap: "nowrap", fontSize: "12.537px", textTransform: "none", padding: "8px 16px" }}
                        >
                            Remove
                        </Button>
                        <Button 
                            variant="contained" 
                            disabled={loading} 
                            onClick={handleAssign} 
                            sx={{ textWrap: "nowrap", fontSize: "12.537px", bgcolor: "#02042D", textTransform: "none", padding: "8px 16px" }}
                        >
                            {loading ? (
                                <CircularProgress size={16} sx={{ color: "#fff" }} /> // Show a spinner when loading
                            ) : (
                                "Assign" // Default text when not loading
                            )}
                        </Button>
                    </Box>

                    </Box>
                </Box>
            </Modal>

            {/* delete confirm model */}

            <Modal open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
                <Box sx={{ width: "100dvw", display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>

                    <Box sx={{ width: "380px", bgcolor: "#FFF", padding: "10px 47px 47px 47px", borderRadius: "18.8px", display: "flex", flexDirection: "column", gap: "15.67px" }}>

                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div></div>
                            <Close onClick={() => setDeleteConfirmOpen(false)} />
                        </Box>

                        <div style={{ display: "flex", flexDirection: "column", gap: "18.8px", alignItems: "center" }}>

                            <HazardIcon sx={{ width: "60px", height: "60px" }} />

                            <Typography variant="body1" textAlign="center" fontWeight="500">
                                Are you sure you want to delete this Assistant you created?
                            </Typography>

                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    id="consent-checkbox"
                                    checked={consentChecked}
                                    onChange={(e) => setConsentChecked(e.target.checked)}
                                />
                                <label htmlFor="consent-checkbox" style={{ fontSize: "12.537px" }}>
                                    This will permanently delete the assistant and its associated data.
                                </label>
                            </Box>

                            <div style={{ width: "100%", display: "flex", gap: "1rem" }}>
                                <Button variant="contained" onClick={performDelete}
                                    disabled={!consentChecked}
                                    sx={{ textWrap: "nowrap", fontSize: "12.537px", bgcolor: "#02042D", textTransform: "none", padding: "8px 16px" }}>
                                    Yes, delete this assistant
                                </Button>

                                <Button onClick={() => setDeleteConfirmOpen(false)} variant="outlined" sx={{ fontSize: "12.537px", color: "#02042D", borderColor: "gray" }}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Modal>

            {/* Profile update modal */}
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>
                    <Box sx={{ width: "30rem", bgcolor: "#FFF", padding: "2rem", borderRadius: "8px" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h5">Update Assistant Profile</Typography>
                            <Close onClick={handleClose} />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", margin: "1rem 0" }}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="logo-upload"
                                type="file"
                                onChange={handleLogoUpload}
                            />
                            <label htmlFor="logo-upload">
                                <IconButton component="span">
                                    <Avatar
                                        alt={assistantDetails?.Name}
                                        src={logo}
                                        sx={{ width: 80, height: 80 }}
                                    />
                                </IconButton>
                            </label>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                            <TextField
                                label="Assistant Name"
                                fullWidth
                                variant="outlined"
                                value={assistantName}
                                onChange={(e) => setAssistantName(e.target.value)}
                                size="small"
                            />
                            <FormControl fullWidth>
                                <Select
                                    value={assistantType}
                                    onChange={(e) => setAssistantType(e.target.value)}
                                    size="small"
                                >
                                    <MenuItem value="inbound">Inbound</MenuItem>
                                    <MenuItem value="outbound">Outbound</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <Select
                                    value={assistantCategory}
                                    onChange={(e) => setAssistantCategory(e.target.value)}
                                    size="small"
                                >
                                    <MenuItem value="Sales">Sales</MenuItem>
                                    <MenuItem value="Customer Support">Customer Support</MenuItem>
                                    <MenuItem value="IT Support">IT Support</MenuItem>
                                    <MenuItem value="Human Resources">Human Resources</MenuItem>
                                    <MenuItem value="Healthcare / Service Industry">Healthcare / Service Industry</MenuItem>
                                    <MenuItem value="Banking Support">Banking Support</MenuItem>
                                </Select>
                                
                            </FormControl>
                            <TextField
                            label="Description"
                            fullWidth
                            multiline
                            variant="outlined"
                            maxRows={4}
                            value={assistantDescription}
                            onChange={(e) => {
                                const value = e.target.value;
                                setAssistantDescription(value);
                                setValidationError(value.length < 50);
                            }}
                            size="small"
                            error={validationError}
                            helperText={
                                validationError
                                ? "Description must be at least 50 characters long."
                                : ""
                            }
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                '&.Mui-disabled': {
                                    backgroundColor: '#f8f9fa',
                                },
                                },
                            }}
                            />

                            {validationError ? (
                            <Button variant="contained"  sx={{ bgcolor: "#02042D", color: "#FFF" }} onClick={() => {
                                handleAssistantProfileUpdate();
                                }}
                                disabled
                                >Save Changes</Button>
                            ) : (
                                <Button variant="contained" sx={{ bgcolor: "#02042D", color: "#FFF" }} onClick={() => {
                                    handleAssistantProfileUpdate();
                                    }}
                                    disabled={loading}>{loading ? "Saving..." : "Save Changes"}</Button>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default NewAssistantDetails;
