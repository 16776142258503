import React from 'react';
import { Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';
//New toolTip component to display the information icon with the tooltip

const TooltipCustom = ({ title, placement }) => {
  return (
    <Tooltip
      title={title}
      arrow
      placement={placement || 'right'}
      style={{display: 'inline', marginBottom: '-0.20rem'}}

      PopperProps={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'window',
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'white',
            color: '#374151', // gray-700
            border: '1px solid #E5E7EB', // gray-200
            padding: '16px',
            maxWidth: '300px',
            fontSize: '14px',
            lineHeight: '20px',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
          },
        },
        arrow: {
          sx: {
            color: 'white',
            '&:before': {
              border: '1px solid #E5E7EB',
            },
          },
        },
      }}
    >
        <Info fontSize='10' sx={{ color: '#6B7280' }} />

    </Tooltip>
  );
};

export default TooltipCustom;
