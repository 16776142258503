import { useState, useEffect } from 'react';

const useToken = () => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        const initialize = async () => {
            const t = JSON.parse(localStorage.getItem("token"))?.token;
            if (t) {
                setToken(t);
            }
        };
        initialize();
    }, []);

    return token;
};

export default useToken;
