import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CardContent, Chip, IconButton, Typography } from '@mui/material';
import { CallMade, CallReceived, VolumeUp, HeadsetMic } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { formatDateTime } from './formate';
import api from "../api";
import WebCall from '../pages/WebCall';

const AssistantCard = ({ name, category, Type, description, lastUpdated, fileUrl, onVolumeClick, buttonAction, assistant }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);  // State to manage modal
  const [assistantConfig, setAssistantConfig] = useState();
  const [token, setToken] = useState("");
  const [credit, setCredit] = useState(0);

  const handleWebCall = async () => {
    const response = await api.post("/assistants/initiate-web-call", {
      assistantId: assistant._id,
      organizationId: localStorage.getItem("Organization")
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })

    setAssistantConfig(response.data.data)
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);  // Close the modal when "End Call" is clicked
  };

  const getToken = () => {
    const t = JSON.parse(localStorage.getItem('token')).token;
    setToken(t);
  };

  useEffect(() => {
    getToken();
    const credit_local = localStorage.getItem('Credit');
    setCredit(credit_local)
  }, [token]);
  return (
    <>
      <Card className="assistantCard" variant="outlined" style={{ borderRadius: "1rem", border: "1px solid #CCCCCC" }}>
        <CardContent>
          <Box style={{ display: "flex", flexDirection: "column", columnGap: 10 }}>
            <Avatar
              alt={name}
              src={fileUrl}
              sx={{ width: 50, height: 50 }}
            />
            <Box style={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                style={{
                  color: "#343434",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  display: "block",
                  maxWidth: "100%",
                  fontSize: '1rem'
                }}>
                {name}
              </Typography>

              <Box sx={{ display: "flex", gap: "0.5rem", marginBottom: "1rem" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "5px", border: "1px solid #02042D", borderRadius: "1rem", padding: "0 5px", color: "#02042D", fontSize: "12px" }}>
                  <span style={{ textTransform: "capitalize" }}>
                    {Type}
                  </span>
                  {Type === "inbound" ? <CallMade sx={{ color: "#02042D", fontSize: "16px" }} /> : <CallReceived sx={{ color: "#02042D", fontSize: "16px" }} />}
                </div>

                <Chip
                  label={category}
                  variant="outlined"
                  size="small"
                  sx={{ borderColor: "#02042D", color: "#02042D", fontSize: "12px" }}
                />
              </Box>
            </Box>
          </Box>
          <Box mb={2}>
            <Typography
              variant="body2"
              style={{
                color: "#555",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "block",
                maxWidth: "100%"
              }}>
              {description}
            </Typography>
          </Box>
          <Typography variant="caption" style={{ color: "#868686" }}>
            Last Updated: {formatDateTime(lastUpdated)}
          </Typography>
          <Box style={{ display: "flex", columnGap: "0.25rem" }}>
            <Link style={{ width: "100%" }} to={buttonAction.url}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#02042d", color: "#fff", textTransform: "capitalize" }}
                fullWidth
              >
                {buttonAction.label}
              </Button>
            </Link>
            <IconButton style={{ color: "#02042d" }} onClick={handleWebCall} disabled={credit == 0}>
              <HeadsetMic />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
      {isModalOpen &&
        <WebCall
          open={isModalOpen}
          onClose={handleCloseModal}
          avatar={assistant.Logo.fileUrl}
          name={assistant.Name}
          wsUrl={`${process.env.REACT_APP_VOICE_URL}`}
          callConfig={assistantConfig}
        />
        // <WebCallModal
        //   open={isModalOpen}
        //   onClose={handleCloseModal}
        //   avatar={assistant.Logo.fileUrl}
        //   name={assistant.Name}
        //   wsUrl={`${process.env.REACT_APP_VOICE_URL}`}
        //   callConfig={assistantConfig}
        // />
      }
    </>
  );
};

export default AssistantCard;
