import { Avatar, Box, Button, Chip, Grid, InputAdornment, InputLabel, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../components/Navbar'
import { Close, Delete, Edit, PersonOutline, PrivacyTipOutlined, UploadFile, WarningOutlined } from '@mui/icons-material';
import api from "../api";
import { toast } from 'sonner';
import Loader from '../components/Loader';
import OtpBox from '../components/OtpBox';
import SecuritySettings from '../components/Password/SecuritySettings';

const Profile = () => {
  const [tab, setTab] = useState("general");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState({});
  const [confirmEmail, setConfirmEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [submitting, setSubmitting] = useState(true);

  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [otp, setOtp] = useState("");
  const [submitOtp, setSubmitOtp] = useState(false);

  const [image, setImage] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const token = JSON.parse(localStorage.getItem("token"))?.token;

  const handleFile = (file) => {
    if (!file) {
      return;
    }
    const allowedTypes = ["image/png", "image/jpg", "image/jpeg", "image/svg+xml", "image/gif"];
    const maxSize = 3 * 1024 * 1024;

    if (!allowedTypes.includes(file.type)) {
      toast.error("Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed.");
      return;
    }

    if (file.size > maxSize) {
      toast.error("File size should not exceed 3 MB.");
      return;
    }

    setImage(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];

    if (file) {
      handleFile(file);
    }
  }

  const getUser = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/users/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUser(response.data.user);

      setLoading(false);

    } catch (error) {
      console.error("Error fetching phone:", error);
    }
  }

  const updateProfile = async () => {
    if (!image && !name && !email) {
      toast.error("Please fill at least one field");
      return;
    }

    try {
      const formData = new FormData();
      if (image) formData.append("profile", image);
      if (name) formData.append("Name", name);
      if (email) formData.append("Email", email);

      const response = await api.put(`/users/update-profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setUser(response.data.user);
      localStorage.setItem("User", JSON.stringify(response.data.user));

      toast.success(response.data.message);
      setImage(null);

      getUser();

    } catch (error) {
      toast.error(error.response?.data?.message || "Error updating profile");
      console.error("Error updating profile:", error);
    }
  };

  const deleteProfile = async () => {
    if (confirmEmail !== user.Email) {
      return toast.error("Please enter your email to delete your profile");
    }

    try {
      const response = await api.delete(`/users/delete-profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      localStorage.removeItem("token");
      toast.success(response.data.message);
      window.location.reload();

    } catch (error) {
      toast.error(error.response?.data?.message || "Error deleting profile");
      console.error("Error deleting profile:", error);
    }
  }

  const handleEmailSubmit = async (e) => {
    setSubmitting(true)
    e.preventDefault();
    try {
      const response = await api.post(`/users/update-email`,
        {
          newEmail: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message);
      setSubmitting(false)
      setOpenEmailDialog(false);

      setOpenOtpDialog(true)
    }
    catch (error) {
      toast.error(error.response.data.message);
      setSubmitting(false)
      console.error("Error profile:", error);
    }
  }

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setSubmitOtp(true)
    try {
      const response = await api.post(`/users/verify-email`,
        {
          newEmail: email,
          otp: otp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUser(response.data.user);
      toast.success(response.data.message);
      localStorage.setItem("User", JSON.stringify(response.data.user));
      setSubmitOtp(false)
      window.location.reload();
    } catch (error) {
      toast.error(error.response.data.message);
      setSubmitOtp(false)
      console.error("Error profile:", error);
    }
  }

  useEffect(() => {
    if (token) {
      getUser();
    }
  }, [token])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Navbar title="Profile" />

      <Box style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1.5rem" }}>
        <Box>
          <Typography variant="h5">Account</Typography>
          <Typography variant="body2">Update your profile details.</Typography>
        </Box>

        <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Avatar
            alt={user.Name}
            src={user.ProfilePicture}
            style={{ width: 50, height: 50 }}
          />
          <Box>
            <Typography variant="h6">{user.Name}
            </Typography>
            <Box style={{ display: "flex", gap: "0.5rem" }}>

              <Chip
                label={user.Email}
                variant="outlined"
                size="small"
              />

              <Chip
                label={user.Role}
                variant="outlined"
                size="small"
              />

            </Box>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Box sx={{
              display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)", borderRadius: "8px", height: "fit-content"
            }}
            >

              <Box sx={{
                display: "flex", alignItems: "center", gap: "1rem",
                padding: "1rem", border: "2px solid #A9A9A9", borderRadius: "1rem",
                cursor: "pointer", bgcolor: tab === "general" ? "#020429" : "#FFF",
                color: tab === "general" ? "#FFF" : "#020429",
              }}
                onClick={() => setTab("general")}
              >
                <Box sx={{ display: "grid", placeItems: "center", bgcolor: tab === "general" ? "#020429" : "rgba(2, 4, 45, 0.05)", padding: "5px", borderRadius: "8px" }}>
                  <PersonOutline style={{ fontSize: "2rem" }} />
                </Box>
                <Box>
                  <Typography variant="h6">General</Typography>
                  <Typography variant="body2">Update profile and name of your account.</Typography>
                </Box>
              </Box>

              <Box sx={{
                display: "flex", alignItems: "center", gap: "1rem",
                padding: "1rem", border: "2px solid #A9A9A9", borderRadius: "1rem",
                cursor: "pointer", bgcolor: tab === "security" ? "#020429" : "#FFF",
                color: tab === "security" ? "#FFF" : "#020429",
              }}
                onClick={() => setTab("security")}
              >
                <Box sx={{ display: "grid", placeItems: "center", bgcolor: tab === "security" ? "#020429" : "rgba(2, 4, 45, 0.05)", padding: "5px", borderRadius: "8px" }}>
                  <PrivacyTipOutlined style={{ fontSize: "2rem" }} />
                </Box>
                <Box>
                  <Typography variant="h6">Security</Typography>
                  <Typography variant="body2">Update your login password.</Typography>
                </Box>
              </Box>

              <Box sx={{
                display: "flex", alignItems: "center", gap: "1rem",
                padding: "1rem", border: "2px solid #A9A9A9", borderRadius: "1rem",
                cursor: "pointer", bgcolor: tab === "danger" ? "#020429" : "#FFF",
                color: tab === "danger" ? "#FFF" : "#020429",
              }}
                onClick={() => setTab("danger")}
              >
                <Box sx={{ display: "grid", placeItems: "center", bgcolor: tab === "danger" ? "#020429" : "rgba(2, 4, 45, 0.05)", padding: "5px", borderRadius: "8px" }}>
                  <WarningOutlined style={{ fontSize: "2rem" }} />
                </Box>
                <Box>
                  <Typography variant="h6">Danger Zone</Typography>
                  <Typography variant="body2">Delete the account.</Typography>
                </Box>
              </Box>

            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>

            <Box>
              {tab === "general" && (
                <Box sx={{
                  display: "flex", flexDirection: "column", gap: "1.5rem", padding: "1rem", borderRadius: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)"
                }}
                >
                  <Typography variant="h6">General Setting</Typography>

                  <div style={{
                    width: "100%", height: "150px", display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center", justifyContent: "center", border: isDragging ? "2px dashed #1d4ed8" : "2px dashed #D9D9D9", borderRadius: "8px", cursor: "pointer", backgroundColor: isDragging ? "#eff6ff" : "#FFF",
                  }}
                    onDragEnter={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsDragging(true);
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsDragging(true);
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsDragging(false);
                    }}
                    onDrop={handleDrop}
                    onClick={() => fileInputRef.current?.click()}
                  >

                    <input
                      type="file"
                      accept="image/*"
                      id="upload-file"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={(e) => {
                        if (e.target.files) {
                          handleFile(e.target.files[0]);
                        }
                      }}
                    />
                    <UploadFile style={{ fontSize: "2rem" }} />

                    <label htmlFor="upload-file"
                      style={{ fontSize: "1rem", fontWeight: 500, cursor: "pointer", color: "GrayText" }}
                    >
                      <span style={{ textDecoration: "underline", color: "#02042D" }}>Upload Profile</span> or Drag here <br />
                      <span style={{ fontSize: "0.8rem", fontWeight: 400, color: "GrayText" }}>SVG, PNG, JPG or GIF (max. 3 MB)</span>
                    </label>

                  </div>

                  {image && (
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Preview"
                      style={{ width: "100px", height: "auto", objectFit: "cover" }}
                    />
                  )}

                  <TextField
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    value={name}
                    placeholder={user.Name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ width: "100%" }}
                  />

                  <TextField
                    type="email"
                    variant="outlined"
                    size="small"
                    value={user.Email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ width: "100%" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={() => { setOpenEmailDialog(true) }}>
                          <Edit sx={{ cursor: "pointer" }} />
                        </InputAdornment>
                      ),
                    }}
                  />


                  <TextField
                    type="email"
                    variant="outlined"
                    size="small"
                    value={user.Role}
                    disabled={true}
                    style={{ width: "100%" }}
                  />


                  <Button variant="contained" sx={{ bgcolor: "#010320", color: "#FFF" }} onClick={updateProfile}>
                    Update
                  </Button>

                </Box>

              )
              }
              {tab === "security" && (
                <SecuritySettings/>
              )}

              {tab === "danger" && (
                <Box sx={{
                  display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", borderRadius: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)"
                }}>
                  <Typography variant="h6">Security Setting</Typography>
                  <Typography variant="body2">
                    Permanently delete your account and all associated content. Any organizations without members will be immediately removed upon account deletion. Both the account and organizations will be irrecoverable. Please proceed with caution.
                  </Typography>

                  <Box>
                    <InputLabel id="demo-simple-select-label">To confirm, please enter your account Email: {user.Email}</InputLabel>
                    <TextField
                      type="email"
                      placeholder={user.Email}
                      variant="outlined"
                      size="small"
                      value={confirmEmail}
                      onChange={(e) => setConfirmEmail(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Box>

                  <Button variant="contained" sx={{ bgcolor: "#010320", color: "#FFF" }}
                    startIcon={<Delete />} onClick={deleteProfile}>
                    Delete
                  </Button>

                </Box>
              )}
            </Box>
          </Grid>
        </Grid >
      </Box>

      <Modal open={openEmailDialog} onClose={() => setOpenEmailDialog(false)}>
        <Box
          sx={{
            width: "100dvw",
            display: "grid",
            placeContent: "center",
            height: "100vh",
            backdropFilter: "blur(10px)"
          }}
        >
          <Box
            sx={{
              width: "400px",
              bgcolor: "#FFF",
              padding: "2rem",
              borderRadius: "18.8px",
              display: "flex",
              flexDirection: "column",
              gap: "15px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Typography variant="h6" component="div">
                Enter New Email Address
              </Typography>
              <Close onClick={() => setOpenEmailDialog(false)} />
            </Box>

            <TextField
              name="email"
              label="Email Address"
              type="email"
              size="small"
              fullWidth
              variant="outlined"
              required={true}
              value={email}
              onChange={(e) => {
                const value = e.target.value;
                setEmail(value);
                if (value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                  setSubmitting(false);
                }
              }}
            />

            <Button
              onClick={handleEmailSubmit}
              variant="contained"
              sx={{ bgcolor: "#02042D", color: "#FFF" }}
              disabled={submitting}
            >
              Send for Verification
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openOtpDialog} onClose={() => setOpenOtpDialog(false)}>
        <Box
          sx={{
            width: "100dvw",
            display: "grid",
            placeContent: "center",
            height: "100vh",
            backdropFilter: "blur(10px)"
          }}
        >
          <Box
            sx={{
              width: "400px",
              bgcolor: "#FFF",
              padding: "1.5rem",
              borderRadius: "18.8px",
              display: "flex",
              flexDirection: "column",
              gap: "15px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Typography variant="h6" component="div">
                Update Email Address
              </Typography>
              <Close onClick={() => setOpenOtpDialog(false)} />
            </Box>

            <span style={{ fontSize: "1rem", color: "#02042D", textAlign: "center" }}>
              Enter OTP received to your new Email
            </span>

            <Box sx={{ alignSelf: "center" }}>
              <OtpBox length={6} handleSubmit={setOtp} />
            </Box>

            <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center", marginTop: "10px" }}>

              <Button
                onClick={handleOtpSubmit}
                variant="contained"
                sx={{ bgcolor: "#02042D", color: "#FFF" }}
                disabled={otp.length !== 6 || submitOtp}
              >
                Verify Now
              </Button>

              <Button
                onClick={() => setOpenOtpDialog(false)}
                variant="outlined"
                size="small"
                sx={{ border: "1px solid #9A9A9A", color: "#9A9A9A" }}
              >
                Cancel
              </Button>

            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default Profile