import { Button } from "@mui/material";


export const ManageBillingStyledButton = ({ variant = 'outlined', ...props }) => (
    <Button
      variant={variant}
      sx={{
        fontWeight: "600",
        color: variant === 'contained' ? 'white' : 'black',
        bgcolor: variant === 'contained' ? '#02042D' : '#ffffff',
        borderRadius: "6px",
        border: variant === 'contained' ? 'none' : "1px solid #E5E7EB", // No border for contained
        transition: "background-color 0.2s ease, border-color 0.2s ease",
        cursor: "pointer",
        "&:hover": {
          bgcolor: "#f9fafb",
          borderColor: "#d1d5db",
        },
      }}
      {...props}
    />
  );