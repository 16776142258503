import React, { useState, useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Chip
} from '@mui/material';
import api from '../api';
import TemplateConfiguration from "../components/TemplateConfiguration"
import Navbar from "../components/Navbar";
import { capitalizeFirstLetter } from './utils/stringCapital';


const ViewAssistantTemplate = () => {
  const navigate = useNavigate();
  const [assistantData, setAssistantData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  

  const token = JSON.parse(localStorage.getItem('token')).token;
  const { id } = useParams();

  useEffect(() => {
    const fetchAssistantData = async () => {
      try {
        const {data:{data}} = await api.get(`/v1/template/${id}`, {
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        });
        if(data){
          setAssistantData(data);
        }
        
      } catch (error) {
        setError(error.response?.data?.message || 'Failed to fetch assistant data');
      }finally{
      setLoading(false)
      }
    };
    if(token) fetchAssistantData();
  }, [id, token]);

  const handleUseTemplate = () => {
    navigate(`/assistant/create/template/${id}`, { replace: true });
  };

  if (loading) {
    return ( <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh"> <Box className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" /> </Box>
    );
  }

  if (!assistantData) {
    return ( <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh"> <Typography color="error">
       Templates not found or no longer available.
            </Typography> </Box>
    );
  }

  return (
    <>
      <Navbar title="View Assistant" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          p: { xs: 2, sm: 4 },
          gap: 2,
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            width: "100%",
            px: { xs: 2, sm: 4 },
            py: { xs: 2, sm: 4 },
            bgcolor: "#ffffff",
            display: "flex",
            flexDirection: "row",
            justifyContent: 'space-between',
            alignItems: 'center',
            borderColor: "#E5E7EB",
            borderWidth: "1px",
            borderRadius: "12px",
            borderStyle: "solid",
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
          }}>
            <Box
            component={"img"}
              src={assistantData.logo_file_url}
              sx={{
                width: 80,
                height: 80,
                borderRadius: 999,
              }}
            />
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: 'center',
              gap: 1,
            }}>
              <Typography variant="h5">
                {assistantData.name}
              </Typography>

              <Box display="flex" gap={1}>
                
                  <Chip
                    label={capitalizeFirstLetter(assistantData.type)}
                    variant="outlined"
                    size="small"
                    sx={{
                      bgcolor: '#EEF0F2',
                      py: 1,
                      px: 2,
                      fontSize: '14px',
                      fontWeight: '500',
                      borderColor: '#E5E7EB',
                      '& .MuiChip-label': {
                        display: 'flex',
                        alignItems: 'center',
                      },
                      '& .MuiChip-icon': {
                        color: '#000000',
                        marginLeft: '-4px',
                        marginRight: '4px',
                        fontSize: '14px'
                      },
                    }}
                  />
                   <Chip
                    label={assistantData.category}
                    variant="outlined"
                    size="small"
                    sx={{
                      bgcolor: '#EEF0F2',
                      py: 1,
                      px: 2,
                      fontSize: '14px',
                      fontWeight: '500',
                      borderColor: '#E5E7EB',
                      '& .MuiChip-label': {
                        display: 'flex',
                        alignItems: 'center',
                      },
                      '& .MuiChip-icon': {
                        color: '#000000',
                        marginLeft: '-4px',
                        marginRight: '4px',
                        fontSize: '14px'
                      },
                    }}
                  />
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              size="small"
              onClick={handleUseTemplate}
              sx={{
                py: 1.5,
                px: 3,
                bgcolor: '#02042D',
                color: 'white',
                borderRadius: 2,
                fontWeight: 600,
                fontSize: '12px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  bgcolor: '#3C4A6A',
                },
              }}
            >
              <Typography sx={{ fontSize: '14px', color: 'white', fontWeight: '500' }}>
                Use Assistant
              </Typography>
            </Button>
          </Box>
        </Box>

        {/* Description Card */}
        <Box
          sx={{
            width: "100%",
            px: { xs: 2, sm: 4 },
            py: { xs: 2, sm: 4 },
            bgcolor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderColor: "#E5E7EB",
            borderWidth: "1px",
            borderRadius: "12px",
            borderStyle: "solid",
          }}
        >
          <Typography variant="h5">Description</Typography>
          <Typography sx={{ fontSize: '1rem', color: '#7C7C83', fontWeight: '500' }}>
            {assistantData.description}
          </Typography>
        </Box>

        {/* Two Column Layout */}
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
            gap: 2,
          }}
        >
          {/* Assistant Profile Column */}
          <Box
            sx={{
              px: { xs: 2, sm: 4 },
              py: { xs: 2, sm: 4 },
              bgcolor: "#ffffff",
              display: "flex",
              flexDirection: "column",
              gap: 4,
              borderColor: "#E5E7EB",
              borderWidth: "1px",
              borderRadius: "12px",
              borderStyle: "solid",
            }}
          >
            <Typography variant="h5">Assistant Profile</Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box sx={{
                px: 3,
                py: 2,
                bgcolor: '#ffffff',
                borderColor: '#E5E7EB',
                borderWidth: '1px',
                borderRadius: '12px',
                borderStyle: 'solid'
              }}>
                <Typography variant="subtitle1" fontWeight="medium" mb={1}>
                  First Message
                </Typography>
                <Typography color="text.secondary">
                  {assistantData.initial_msg}
                </Typography>
              </Box>

              <Box sx={{
                px: 1,
                py: 2,
                bgcolor: '#ffffff',
                borderColor: '#E5E7EB',
                maxHeight: '400px',
                borderWidth: '1px',
                borderRadius: '12px',
                borderStyle: 'solid',
                overflow: 'hidden',
              }}>
                <Box sx={{
                    px:2,
                }}>
                <Typography variant="subtitle1" fontWeight="medium" mb={1}>
                  Prompt
                </Typography>
                </Box>
                <Box sx={{
                  overflowY: 'auto',
                  maxHeight: 'calc(400px - 4rem)',
                  px:2,
                  '&::-webkit-scrollbar': {
                    width: '6px', // Set the width of the scrollbar
                    },
                    '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#C4C4C4', // Customize the scrollbar thumb color
                    borderRadius: '4px', // Add rounded corners to the thumb
                    },
                    '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F4F4F4', // Customize the scrollbar track color
                    },
                }}>
                  <Typography color="text.secondary" style={{ whiteSpace: 'pre-wrap' }}>
                    {assistantData.prompt}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{
                px: 3,
                py: 2,
                bgcolor: '#ffffff',
                borderColor: '#E5E7EB',
                borderWidth: '1px',
                borderRadius: '12px',
                borderStyle: 'solid'
              }}>
                <Typography variant="subtitle1" fontWeight="medium" mb={1}>
                  End Message
                </Typography>
                <Typography color="text.secondary">
                  {assistantData.call_end_text}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Configurations Column */}
          <Box
            sx={{
              px: { xs: 2, sm: 4 },
              py: { xs: 2, sm: 4 },
              bgcolor: "#ffffff",
              display: "flex",
              flexDirection: "column",
              gap: 4,
              borderColor: "#E5E7EB",
              borderWidth: "1px",
              borderRadius: "12px",
              borderStyle: "solid",
            }}
          >
            <Typography variant="h5">Assistant Configurations</Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              <TemplateConfiguration title="LLM Provider" value={assistantData.llm_provider_name} />
              <TemplateConfiguration title="LLM Model" value={assistantData.llm_model_name} />
              <TemplateConfiguration title="Transcriber Provider" value={assistantData.transcriber_provider_name} />
              <TemplateConfiguration title="Transcriber Language" value={assistantData.transcriber_language_name} />
              <TemplateConfiguration title="Voice Provider" value={assistantData.voice_provider_name} />
              <TemplateConfiguration title="Voice" value={assistantData.voice_language_name} />

            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewAssistantTemplate;