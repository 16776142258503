import { Box, Button, Chip, Typography } from '@mui/material';
import React from 'react'
import { VscCallIncoming } from "react-icons/vsc";
import { VscCallOutgoing } from "react-icons/vsc";
import LanguageIcon from '@mui/icons-material/Language';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../pages/utils/stringCapital';



const AssistantTemplate = ({ name, type, category, description , onChipClick, setModalOpen, data }) => {
    const navigate = useNavigate();
    const displayType = capitalizeFirstLetter(type);

    const handleViewTemplateAssistant = () => {
        setModalOpen(false)
        navigate(`/assistant/template/${data?.id}`)
    }

    const handleCreateAssistant = () => {
        setModalOpen(false)
        navigate(`/assistant/create/template/${data?.id}`)
    }
    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            gap:3,
            width:'100%',
            height:'auto',
            justifyContent:'space-between',
            borderStyle:'solid',
            borderWidth:1,
            borderColor:'#E1E3E9',
            borderRadius:4,
            p:4
        }}>

                <Box sx={{ 
                    display: "flex", 
                    alignItems: "flex-start", 
                    flexDirection:'column',
                    gap: 1.5 }}>
                    
                    <Typography variant="h5">
                        {name}
                    </Typography>
                        
                        <Box style={{ display: "flex", gap: "0.75rem" }}>
                        <Chip
                            label={displayType}
                            size="small"
                            onClick={() => onChipClick(type)}
                            icon={
                                type.toLowerCase() === "inbound" ? ( <VscCallIncoming />
                                ) : type.toLowerCase() === "outbound" ? ( <VscCallOutgoing />
                                ) : ( <LanguageIcon />
                                )
                            }
                            sx={{
                                bgcolor: '#EEF0F2',
                                py: 1,
                                px: 2,
                                fontSize: '14px',
                                fontWeight: '500',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '& .MuiChip-label': {
                                display: 'flex',
                                alignItems: 'center',
                                },
                                '& .MuiChip-icon': {
                                color: '#000000', 
                                marginLeft: '-4px',
                                marginRight:'4px',
                                fontSize:'14px'
                                
                                },
                            }}
                            />
                            <Chip
                                label={category}
                                size="small"
                                onClick={() => onChipClick(category)}
                                sx={{
                                    bgcolor: '#EEF0F2',
                                    py: 1,
                                    px: 2,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '& .MuiChip-label': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    },
                                }}
                            />
                        </Box>
                        <Typography sx={{fontSize:'1rem', color:'#7C7C83', fontWeight:'400'}}gutterBottom>
                        {description}
                        </Typography>

                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>

                <Button
                    size="small"
                    sx={{
                        width: "100%",
                        bgcolor: 'white',
                        borderColor: '#E5E7EB',
                        color: 'black',
                        borderRadius: 2,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        '&:hover': {
                            bgcolor: '#F3F4F6', // Light greyish hover effect
                            borderColor: '#D1D5DB',
                        },
                    }}
                    onClick={handleViewTemplateAssistant}
                >
                    View Assistant
                </Button>

                <Button
                    sx={{
                        width: "100%",
                        bgcolor: '#31364A', // Lighter shade of #02042D
                        color: 'white',
                        borderRadius: 2,
                        '&:hover': {
                            bgcolor: '#02042D', // Dark shade on hover
                        },
                    }}
                    onClick={handleCreateAssistant}
                >
                    Create Assistant
                </Button>


                </Box>
           
        </Box>
    )
}

export default AssistantTemplate;