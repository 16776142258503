import React, { useState, useCallback } from 'react';
import { Dialog, DialogContent, Button, Box, Fade, Slide } from '@mui/material';
import ShareAssistantForm from './ShareAssistantForm';
import ShareAssistantLinkGeneration from './ShareAssistantLinkGeneration';
import ManageSharedAccess from './ManageSharedAccess'; // Import the new component for managing access
import api from '../api';
import { toast } from 'sonner';

const ModalShare = ({ isOpen, onClose, assistantId }) => {
  const [isPasswordRequired, setIsPasswordRequired] = useState(false);
  const [password, setPassword] = useState(null);
  const [credits, setCredits] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [showGeneratedLink, setShowGeneratedLink] = useState(false); // Controls whether to show form or link
  const [generatedLinkData, setGeneratedLinkData] = useState(null);
  const [loading, setLoading] = useState(false)
  const [viewMode, setViewMode] = useState('form'); // Manage view modes: 'form', 'link', 'manageAccess'
  const token = JSON.parse(localStorage.getItem("token")).token;
  
  // Function to reset states when modal is closed
  const handleClose = useCallback(() => {
    setIsPasswordRequired(false);
    setPassword(null)
    setCredits(null);
    setDate(null);
    setTime(null);
    setShowGeneratedLink(false); // Reset to show the form on modal open again
    setGeneratedLinkData(null); // Reset the generated link state
    setViewMode('form'); // Reset the view to the form
    onClose(); // Ensure the parent component receives the close signal
  }, [onClose]);


  // Function to handle form submission (to generate link)
  const handleSubmit = async () => {
    if (!date || !time) {
        return toast.error("Date and time are required");
    }

    const formatDate = new Date(date);
    const formatTime = new Date(time);

    if (Number.isNaN(formatDate.getTime()) || Number.isNaN(formatTime.getTime())) {
        return toast.error("Invalid date or time format");
    }

    const combinedDateTime = new Date(
        formatDate.getFullYear(),
        formatDate.getMonth(),
        formatDate.getDate(),
        formatTime.getHours(),
        formatTime.getMinutes(),
        formatTime.getSeconds()
    );

    if (Number.isNaN(combinedDateTime.getTime())) {
        return toast.error("Invalid combined date and time");
    }

    // Convert to ISO 8601 format in UTC
    const expirationDateUTC = combinedDateTime.toISOString();

    const payload = {
        password_required: isPasswordRequired,
        expiration_date: expirationDateUTC,
        max_credit_allowed: credits,
        password,
    };

    setLoading(true);
    try {
        const { data } = await api.post(`/v1/assistant/create/share/${assistantId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        setGeneratedLinkData(data?.data); // Set the generated link
        setViewMode("link"); // Switch to link generation view
        toast.success(data?.message);
    } catch (error) {
        const errorMessage = error?.response?.data?.details || 
        (error?.response?.status === 401 ? 'Unauthorized access' : 
        'Failed to generate share link. Please try again.');
    toast.error(errorMessage);
    } finally {
        setLoading(false);
    }
};

  // Function to handle "Manage Shared Access" button click
  const handleManageAccessClick = () => {
    setViewMode('manageAccess'); // Switch to manage shared access view
  };

  // Function to navigate back to the form view
  const backToShareForm = () => {
    setViewMode('form'); // Switch back to the form view
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "auto",
        height: "auto",
      }}
    >
      <DialogContent sx={{ maxWidth: "700", padding: "24px", width: "100%" }}>
        {/* Fade transition to switch between views smoothly */}
        {viewMode === "form" && (
          <Fade in={viewMode === "form"} timeout={500}>
            <div>
              <ShareAssistantForm
                isPasswordRequired={isPasswordRequired}
                setIsPasswordRequired={setIsPasswordRequired}
                setPassword={setPassword}
                password={password}
                credits={credits}
                setCredits={setCredits}
                date={date}
                setDate={setDate}
                time={time}
                setTime={setTime}
                handleGenerateLink={handleSubmit} // Ensure handleSubmit triggers form submission
                handleManageAccessClick={handleManageAccessClick} // Pass the handleManageAccessClick function
                onClose={handleClose} // Close the modal
                loading={loading} // Show loading spinner while generating link
              />
            </div>
          </Fade>
        )}

        {viewMode === "manageAccess" && (
          <Fade in={viewMode === "manageAccess"} timeout={500}>
            <div>
              <ManageSharedAccess
                onClose={() => setViewMode("form")} // Close manage access and go back to form
                assistantId={assistantId} // Pass the assistant ID to the manage access component
              />
            </div>
          </Fade>
        )}

        {viewMode === "link" && !loading && (
          <Fade in={viewMode === "link"} timeout={500}>
            <div>
              <ShareAssistantLinkGeneration
                onClose={backToShareForm}
                generatedLink={generatedLinkData ? generatedLinkData : null}
              />
            </div>
          </Fade>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalShare;
