
import React, { useState, useEffect, useMemo, Suspense, useRef } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Collapse,
  Paper,
  Button,
  Tooltip,
} from '@mui/material';
import { ExpandLess, ExpandMore, Assistant, DashboardCustomize, CallOutlined, ListAltOutlined, SpaceDashboardOutlined, AssistantOutlined, WorkOutline, StorageOutlined, Description, Bolt } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import '../styles/sidebarLayout.css';
import Loader from './Loader';
import Credit from './Credit';
import { AutoMode } from '@mui/icons-material';  
import { fetchConfig } from '../pages/utils/fetchConfig';
import { useGetAllPermissions } from '../pages/utils/getAllPermissions';


const SidebarLayout = ({ children }) => {
  const [token, setToken] = useState(() => {
    const tokenData = localStorage.getItem("token");
    return tokenData ? JSON.parse(tokenData).token : null;
  });
  const { permissions, loading, getAllPermissions } = useGetAllPermissions();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [textVisible, setTextVisible] = useState(true);
  const [companyId, setCompanyId] = useState("");
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const [defaultData, setDefaultData] = useState({});
  const userRole = localStorage.getItem("Role");  
  const CONFIG = [
    'n8n_url'
  ];
  const canViewworkflows = permissions?.includes("workflows") ?? false;


  
  const getDefaultConfig = async () => {
      try {
          await fetchConfig(CONFIG,(data) => {
            if (data) {
              setDefaultData(data)
            }
          });
      } catch (error) {
          console.error("Error getting default config:", error);
      }
    };
  useEffect(() => {
    getAllPermissions();
    getDefaultConfig();
  }, [token, getAllPermissions]);
  
  useEffect(() => {
    const checkSidebarState = () => {
      const storedSidebarState = localStorage.getItem('sidebarOpen');
      if (storedSidebarState !== null) {
        setSidebarOpen(JSON.parse(storedSidebarState));
        setTextVisible(JSON.parse(storedSidebarState));
      }
      setCompanyId(localStorage.getItem('Organization'));
    };

    checkSidebarState();

    const intervalId = setInterval(() => {
      checkSidebarState();
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  const logo = useMemo(() => (
    <img src={textVisible ? "/white-logo.png" : "/favicon.png"} alt="logo" />
  ), [textVisible]);

  const handleDropdownClick = (index) => {
    setOpenDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  let sidebarItems = [
    { type: 'link', label: 'Overview', url: '/overview', icon: <SpaceDashboardOutlined /> },
    { type: 'link', label: 'Assistant', url: '/assistants', icon: <AssistantOutlined /> },
    { type: 'link', label: 'Phone number', url: '/phone-number', icon: <CallOutlined /> },
    canViewworkflows && { 
      type: 'externalLink', 
      label: 'Workflows (Coming Soon)', 
      // url:'defaultData.n8n_url',
      icon: <AutoMode />, 
      disabled: true 
    }
  ].filter(Boolean);
  
  sidebarItems.push(
    { type: 'link', label: 'Logs', url: '/logs', icon: <ListAltOutlined /> },
    { type: 'link', label: 'Company', url: '/company', icon: <WorkOutline /> },
    {
      type: 'dropdown',
      label: 'Forms',
      icon: <StorageOutlined />,
      items: [
        { label: 'Transcriber', url: '/transcriber', icon: <Description /> },
        { label: 'Transcriber Provider', url: '/transcriber-provider', icon: <Description /> },
        { label: 'Transcriber Language', url: '/transcriber-language', icon: <Description /> },
        { label: 'Voice Provider', url: '/voice-provider', icon: <Description /> },
        { label: 'Voice', url: '/voice', icon: <Description /> },
        { label: 'Voice Lanuage', url: '/voice-language', icon: <Description /> },
        { label: 'LLM Provider', url: '/llm-provider', icon: <Description /> },
        { label: 'LLM', url: '/llm', icon: <Description /> },
        { label: 'Phone Provider', url: '/phone-provider', icon: <Description /> },
      ],
    }
  );

  let adminItems = [
      { type: 'link', label: 'Overview', url: `/company/${companyId}/overview`, icon: <DashboardCustomize /> },
      { type: 'link', label: 'Assistant', url: `/company/${companyId}/assistants`, icon: <Assistant /> },
      { type: 'link', label: 'Phone number', url: `/company/${companyId}/phone-number`, icon: <CallOutlined /> },
      canViewworkflows && { type: 'externalLink', label: 'Workflows (Coming Soon)',
         //url:'defaultData.n8n_url', 
         icon: <AutoMode />, disabled: true },
      { type: 'link', label: 'Logs', url: `/company/${companyId}/logs`, icon: <ListAltOutlined /> },
  ].filter(Boolean); // This removes `false` values if `canManageBilling` is false.
  
  // const handleLogout = () => {
  //   setOpen((prev) => !prev);
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   window.location.href = "/login";
  //   setTimeout(() => {
  //     setToken(null);
  //   }, 0);
  // };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          variant="permanent"
          open={sidebarOpen}
          sx={{
            flexShrink: 0,
            width: textVisible ? 240 : 85,
            transition: 'width 0.3s',
            '& .MuiDrawer-paper': {
              width: textVisible ? 240 : 85,
              boxSizing: 'border-box',
            },
          }}
        >
          <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column', padding: "0 1rem", borderRadius: 0 }} className='color-prim-cetacean-blue-500' style={{ height: "110vh", overflowY: 'auto' }}
            onClick={(e) => {
              if (e && e.target instanceof Node) {
                if (ref.current && !ref.current.contains(e.target)) {
                  setOpen(false);
                }
              }
            }}
          >
            <Link to="/overview" style={{ flexShrink: 0 }}>
              <Box className="logo">
                {logo}
              </Box>
            </Link>
            <List>
              {(userRole === "Admin" || userRole === "User") ? adminItems.map((item, index) => (
                <React.Fragment key={index}>
                  {item.type === 'link' ? (
                    <ListItem
                      button
                      component={Link}
                      to={item.url}
                      className={location.pathname.includes(item.url) ? 'sideLinksActive' : 'sideLinks'}
                    >
                      {item.icon}
                      {textVisible && <ListItemText className="sideLinkText" primary={item.label} />}
                    </ListItem>
                  ) : item.type === 'externalLink' ? (
                    <React.Fragment>
                          <ListItem
                            button
                            component="a"
                            href={item.disabled ? undefined : item.url}
                            target={item.disabled ? undefined : "_blank"}
                            rel={item.disabled ? undefined : "noopener noreferrer"}
                            className='sideLinks'
                            style={{
                              opacity: item.disabled ? 0.5 : 1,
                              pointerEvents: item.disabled ? 'none' : 'auto'
                            }}
                          >
                            {item.icon}
                            {textVisible && <ListItemText className="sideLinkText" primary={item.label} />}
                          </ListItem>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ListItem className='sideLinks' button onClick={() => handleDropdownClick(index)}>
                        {item.icon}
                        {textVisible && <ListItemText primary={item.label} />}
                        {openDropdown === index ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openDropdown === index} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {item?.items?.map((subItem, subIndex) => (
                            <ListItem
                              button
                              component={Link}
                              to={subItem.url}
                              key={subIndex}
                              className={location.pathname === subItem.url ? 'sideLinksActive' : 'sideLinks'}
                              sx={{ pl: 4 }}
                            >
                              {subItem.icon}
                              {textVisible && <ListItemText className="sideLinkText" primary={subItem.label} />}
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )) : sidebarItems.map((item, index) => (
                <React.Fragment key={index}>
                  {item.type === 'link' ? (
                    <ListItem
                      button
                      component={Link}
                      to={item.url}
                      className={location.pathname.includes(item.url) ? 'sideLinksActive' : 'sideLinks'}
                    >
                      {item.icon}
                      {textVisible && <ListItemText className="sideLinkText" primary={item.label} />}
                    </ListItem>
                  ) : item.type === 'externalLink' ? (
                    <React.Fragment>
                      <ListItem
                        button
                        component="a"
                        href={item.disabled ? undefined : item.url}
                        target={item.disabled ? undefined : "_blank"}
                        rel={item.disabled ? undefined : "noopener noreferrer"}
                        className='sideLinks'
                        style={{
                          opacity: item.disabled ? 0.5 : 1,
                          pointerEvents: item.disabled ? 'none' : 'auto'
                        }}
                      >
                        {item.icon}
                        {textVisible && <ListItemText className="sideLinkText" primary={item.label} />}
                      </ListItem>
                      {item.disabled && (
                        <Tooltip title="Coming Soon" placement="right"/>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ListItem className='sideLinks' button onClick={() => handleDropdownClick(index)}>
                        {item.icon}
                        {textVisible && <ListItemText style={{ marginLeft: '1rem' }} primary={item.label} />}
                        {openDropdown === index ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openDropdown === index} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {item.items.map((subItem, subIndex) => (
                            <ListItem
                              button
                              component={Link}
                              to={subItem.url}
                              key={subIndex}
                              className={location.pathname === subItem.url ? 'sideLinksActive' : 'sideLinks'}
                              sx={{ pl: 4 }}
                            >
                              {subItem.icon}
                              {textVisible && <ListItemText className="sideLinkText" primary={subItem.label} />}
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </List>


            <Box sx={{ display:'flex', marginTop: "auto", marginBottom: '1rem', p:sidebarOpen ? '1rem' : '', gap: '12px',flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: sidebarOpen &&permissions &&permissions.includes("view_plan_details") ?  'rgba(38,41,146,0.30)' : '', borderRadius: '12px' }}>
            
            {permissions && permissions.includes("view_plan_details") && <Credit sidebarHidden={!textVisible} />}

            {permissions && permissions.includes("manage_billing") && (
              <Button
                component={Link}
                to={`/company/${companyId}/pricing`}
                className={location.pathname.includes(`/company/${companyId}/pricing`) ? 'sideLinksActive' : 'sideLinks'}
                sx={{
                  background: "linear-gradient(to right, #4A90E2, #00D1FF)",
                  marginBottom: '0.5rem',
                  marginTop: '0.5rem',
                  textAlign: 'center',
                  padding: '8px',
                  color: "#fff",
                  textDecoration: 'none',
                  width: sidebarOpen ? '100%' : '36px',
                  height: sidebarOpen ? 'auto' : '36px',
                  flexShrink: 0,
                  minWidth: 0,
                  borderRadius: sidebarOpen ? '999px' : '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Bolt />
                {textVisible && sidebarOpen && "Upgrade Plan"}
              </Button>
            )}
            </Box>

            {/* <Button
              onClick={() => setOpen(prev => !prev)}
              sx={{
                backgroundColor: '#353657',
                marginBottom: '0.5rem',
                textAlign: 'center',
                padding: '0.5rem',
                color: '#fff',
                textDecoration: 'none',
                width: '100%',
                flexShrink: 0,
                minWidth: 0,
              }}
              variant="contained"
            >
              <PersonOutlined />
              {textVisible && "Profile"}
            </Button>
            {open && (
              <div style={{ position: "absolute", width: textVisible ? "13rem" : "fit-content", bottom: "3.5rem", backgroundColor: "#FFF", zIndex: "100", borderRadius: "8px" }}>
                <div ref={ref} style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px 0px" }}>
                  {textVisible && (
                    <>
                      <div style={{ width: "100%", padding: "0px 16px" }}>
                        <p style={{ fontSize: "1rem", fontWeight: "500" }}>{user?.Name?.length > 12 ? `${user?.Name.slice(0, 10)}...` : user?.Name}</p>
                        <p color="#8A8A8A">{`${user?.Email?.length > 12 ? `${user?.Email.slice(0, 18)}...` : user?.Email}`}</p>
                      </div>
                      <hr style={{ border: "1px solid lightgray" }} />
                    </>
                  )}

                  <Link to="/profile" style={{ display: "flex", alignItems: "center", gap: "10px", color: "#02042D", textDecoration: "none", padding: "6px 16px" }}>
                    <SettingsOutlined /> <span style={{ color: "#02042D", fontSize: "16px", fontWeight: "500" }}>{textVisible && "Account"}</span>
                  </Link>

                  <Link to={(userRole === "Admin" || userRole === "User") ? `/company/${id}/member` : `/company/${id}?tab=members`} style={{ display: "flex", alignItems: "center", gap: "10px", color: "#02042D", textDecoration: "none", padding: "6px 16px" }}>
                    <GroupOutlined /> <span style={{ color: "#02042D", fontSize: "16px", fontWeight: "500" }}>{textVisible && "Members"}</span>
                  </Link>

                  <Link to="/api-key-management" style={{ display: "flex", alignItems: "center", gap: textVisible ? "10px" : 0, color: "#02042D", textDecoration: "none", padding: "6px 16px" }}>
                    <KeyOutlined /> <span style={{ color: "#02042D", fontSize: "16px", fontWeight: "500" }}>{textVisible && " API Keys"}</span>
                  </Link>

                  {permissions?.includes("manage_billing") && (
                    <Link to={`/company/${id}/manage-billing`} style={{ display: "flex", alignItems: "center", gap: textVisible ? "10px" : 0, color: "#02042D", textDecoration: "none", padding: "6px 16px" }}>
                      <Bolt /> <span style={{ color: "#02042D", fontSize: "16px", fontWeight: "500" }}>{textVisible && (planId ? "Manage Billing" : "Upgrade Plan")}</span>
                    </Link>
                  )}
                  
                  <hr style={{ border: "1px solid lightgray" }} />

                  <button 
                    style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: textVisible ? "10px" : 0, color: "#02042D", outline: "none", border: "none", cursor: "pointer", padding: "6px 16px", background: "none" }}
                    onClick={handleLogout}
                  >
                    <LogoutOutlined /> <span style={{ color: "#02042D", fontSize: "16px", fontWeight: "500" }}>{textVisible && "Logout"}</span>
                  </button>
                </div>
              </div>
            )} */}
          </Paper>
        </Drawer>
        <Box
          component="main"
          sx={{ height: "100dvh", flexGrow: 1, bgcolor: "#FAFAFA", overflowX: 'hidden' }}
          onClick={(e) => {
            if (e && e.target instanceof Node) {
              if (ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
              }
            }
          }}
        >
          <Suspense fallback={<Loader />}>
            {children}
          </Suspense>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
