import React, { useState } from 'react';
import { Button,Box, TextField } from '@mui/material';


const PriceCardForm = ({onSubmit , onClose }) => {
  const [formData, setFormData] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    cardHolder: ''
  });

  const [errors, setErrors] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    cardHolder: ''
  });
  
  const handleSubmit = () => {
    // Validate the form before submission (if needed)
    if (onSubmit) {
      onSubmit({
        cardNumber: formData.cardNumber,
        expiryDate: formData.expiryDate,
        cardHolder: formData.cardHolder,
      });
    }
  };


  // Validate card number (Luhn algorithm)
  const validateCardNumber = (number) => {
    const cleaned = number.replace(/\s/g, '');
    if (!/^\d{16}$/.test(cleaned)) {
      return 'Card number must be 16 digits';
    }
    
  const digits = cleaned.split('').map(Number);
   const checksum = digits
     .reverse()
     .map((digit, index) => {
      if (index % 2 === 1) {
         const doubled = digit * 2;
         return doubled > 9 ? doubled - 9 : doubled;
       }
       return digit;
     })
     .reduce((sum, digit) => sum + digit, 0);
    
return checksum % 10 === 0 ? '' : 'Invalid card number';
  };

  // Validate expiry date
  const validateExpiryDate = (date) => {
    if (!/^\d{2}\/\d{2}$/.test(date)) {
      return 'Use MM/YY format';
    }

    const [month, year] = date.split('/');
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100;
    const currentMonth = currentDate.getMonth() + 1;
    const MAX_VALID_YEAR = currentYear + 10;

    if (parseInt(month) < 1 || parseInt(month) > 12) {
      return 'Invalid month';
    }
    const expYear = parseInt(year);
       if (expYear > MAX_VALID_YEAR) {
         return 'Invalid expiry year';
       }
       if (expYear < currentYear || 
      (expYear === currentYear && parseInt(month) < currentMonth)) {
      return 'Card has expired';
    }

    return '';
  };

  // Validate CVV
  const validateCVV = (cvv) => {
    if (!/^\d{3,4}$/.test(cvv)) {
      return 'CVV must be 3 or 4 digits';
    }
    return '';
  };

  // Validate cardholder name
  const validateCardHolder = (name) => {
    if (!/^[a-zA-Z\s]{2,}$/.test(name)) {
      return 'Enter a valid name';
    }
    return '';
  };

  const formatCardNumber = (value) => {
    const cleaned = value.replace(/\s/g, '');
    const groups = cleaned.match(/.{1,4}/g);
    return groups ? groups.join(' ') : cleaned;
  };

  const handleChange = (field) => (event) => {
    let value = event.target.value;
    let error = '';

    switch (field) {
      case 'cardNumber':
        value = formatCardNumber(value.replace(/[^\d\s]/g, '').substring(0, 19));
        error = validateCardNumber(value);
        break;
      case 'expiryDate':
        value = value
          .replace(/[^\d]/g, '')
          .substring(0, 4)
          .replace(/(\d{2})(\d{2})/, '$1/$2')
          .substring(0, 5);
        error = validateExpiryDate(value);
        break;
      case 'cvv':
        value = value.replace(/[^\d]/g, '').substring(0, 4);
        error = validateCVV(value);
        break;
      case 'cardHolder':
        value = value.replace(/[^a-zA-Z\s]/g, '');
        error = validateCardHolder(value);
        break;
    }

    setFormData(prev => ({ ...prev, [field]: value }));
    setErrors(prev => ({ ...prev, [field]: error }));
  };

  return (
    <Box sx={{ maxWidth: 'md', width: '100%' }}>
      <TextField
        label="Card Number"
        fullWidth
        margin="normal"
        value={formData.cardNumber}
        onChange={handleChange('cardNumber')}
        error={!!errors.cardNumber}
        helperText={errors.cardNumber}
        placeholder="1234 5678 9012 3456"
        InputLabelProps={{ shrink: true }}
      />

      <Box display="flex" gap={2}>
        <TextField
          label="Expiry Date"
          margin="normal"
          value={formData.expiryDate}
          onChange={handleChange('expiryDate')}
          error={!!errors.expiryDate}
          helperText={errors.expiryDate}
          placeholder="MM/YY"
          InputLabelProps={{ shrink: true }}
          sx={{ flex: 1 }}
        />
        <TextField
          label="CVV"
          margin="normal"
          value={formData.cvv}
          onChange={handleChange('cvv')}
          error={!!errors.cvv}
          helperText={errors.cvv}
          placeholder="123"
          InputLabelProps={{ shrink: true }}
          sx={{ flex: 1 }}
        />
      </Box>

      <TextField
        label="Card Holder Name"
        fullWidth
        margin="normal"
        value={formData.cardHolder}
        onChange={handleChange('cardHolder')}
        error={!!errors.cardHolder}
        helperText={errors.cardHolder}
        placeholder="John Doe"
        InputLabelProps={{ shrink: true }}
      />
      <Box sx={{
        mt:2,
        display:'flex',
        gap:1.5,
        alignContent:'center',
        justifyContent:'flex-end'
      }}>
      <Button 
          variant="outlined"
          onClick={onClose} 
          sx={{
            fontWeight: "600",
            color: "black",
            bgcolor: "#ffffff",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: "#E5E7EB",
            transition: "background-color 0.2s ease, border-color 0.2s ease",
            cursor: "pointer",
            "&:hover": {
            bgcolor: "#f9fafb", // Slightly lighter background
            borderColor: "#d1d5db", // Slightly darker border
            },
          }}
        >
          Back
        </Button>
        <Button 
          variant="contained" 
          onClick={handleSubmit}
          sx={{
            bgcolor: '#02042D',
            '&:hover': {
              bgcolor: 'darknavy'
            }
          }}
        >
          Update Card
        </Button>
        </Box>
    </Box>
  );
};

export default PriceCardForm;