import { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Collapse,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import LinkIcon from '@mui/icons-material/Link';

const boxStyles = {
  width: "100%",
  height: 'auto',
  p: { xs: 3, sm: 4 },
  bgcolor: "#ffffff",
  display: "flex",
  flexDirection: "column",
  gap: 4,
  borderRadius: "12px",
  borderColor: "#E5E7EB",
  borderWidth: "1px",
  borderStyle: "solid",
};

const stepContentStyles = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 3,
};

const requirementsBoxStyles = {
  width: "100%",
  bgcolor: "#f8f9fc",
  borderRadius: "12px",
  p: 3,
  borderColor: "#E5E7EB",
  borderWidth: "1px",
  borderStyle: "solid",
};

export default function CustomDomain({ domainValidation, handleInputChange, formData }) {
  // Track active step and completed steps
  const [activeStep, setActiveStep] = useState(1);
  const [stepsCompleted, setStepsCompleted] = useState({
    step1: false,
    step2: false,
    step3: false
  });

  // Click handlers for step navigation
  const handleStepClick = (stepNumber) => {
    // Only allow navigating to completed steps or the next available step
    if (stepNumber <= activeStep || stepsCompleted[`step${stepNumber - 1}`]) {
      setActiveStep(stepNumber);
    }
  };

  // Handle domain connection
  const handleConnectDomain = () => {
    if (Object.values(domainValidation).every(val => val)) {
      setStepsCompleted(prev => ({ ...prev, step1: true }));
      setActiveStep(2);
    }
  };

  // Handle DNS verification
  const handleVerifyDNS = () => {
    setStepsCompleted(prev => ({ ...prev, step2: true }));
    setActiveStep(3);
  };

  const StepIcon = ({ active, completed }) => (
    completed ? <CheckCircleIcon color="success" /> : 
    active ? <CheckCircleIcon color="success" /> : 
    <RadioButtonUncheckedIcon color="disabled" />
  );

  return (
    <Box sx={boxStyles}>
      {/* Header remains the same */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5" sx={{ fontWeight: '600' }}>
          Custom Domain
        </Typography>
        <Typography sx={{ fontSize: '1rem', color: '#7C7C83', fontWeight: '500' }}>
          Please read carefully the instructions to add your custom domain.
        </Typography>
      </Box>

      <Box sx={stepContentStyles}>
        {/* Step 1: Domain Input */}
        <Box>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 2,
              cursor: 'pointer' 
            }}
            onClick={() => handleStepClick(1)}
          >
            <StepIcon active={activeStep === 1} completed={stepsCompleted.step1} />
            <Typography sx={{ fontSize: '1rem', fontWeight: '600', ml: 1.5 }}>
              Add your Domain
            </Typography>
          </Box>
          <Collapse in={activeStep === 1}>
            <Box sx={{ mt: 1 }}>
              <Typography sx={{fontSize:'1rem', color:'#000000', fontWeight:'500', mb:1}}>
             Custom Domain
            </Typography>
              <Box sx={{ display: 'flex', gap: 2,height:'fit-content' }}>
                 <TextField
                    fullWidth
                    name="customDomain"
                    placeholder="customdomain.site"
                    value={formData.customDomain}
                    onChange={handleInputChange}
                    helperText="Enter your custom domain name. If you don't own a domain yet, you'll need to purchase one from a domain registrar."
                    FormHelperTextProps={{
                      sx: {
                        fontSize: "0.75rem",
                        color: "#A1A1B0", // Softer gray for better contrast with #02042D
                        fontWeight: "500",
                        margin:0,
                        mt:1
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        borderRadius: "8px", // Smooth rounded corners
                        backgroundColor: "#FFFFFF", // Light gray background for contrast
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E5E7EB", // Muted border color for a softer look
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E5E7EB", // Darker shade on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E5E7EB", // Deep blue for strong focus
                        borderWidth: "2px",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#7C7C83", // Label matches the muted theme
                        fontWeight: "500",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#02042D",
                        backgroundColor:'#FFFFFF', // Label turns primary color on focus
                      },
                    }}
                  />

                <Button
                  disabled={!Object.values(domainValidation).every(val => val)}
                  onClick={handleConnectDomain}
                  startIcon={<LinkIcon />}
                  sx={{ 
                    textTransform: 'none', 
                    px: 4, 
                    py: 2, 
                    width: 'auto', 
                    height: 'fit-content', 
                    whiteSpace: 'nowrap', 
                    bgcolor: '#02042D', 
                    color: 'white', 
                    borderRadius: 2,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      bgcolor: '#02042D', // Keeping the background color same
                      filter: 'brightness(1.2)', // Lighten effect
                      transform: 'scale(1.05)', // Slightly larger on hover
                    },
                    '&:disabled': {
                      bgcolor: '#b0b3c1', // Dimmed color for disabled state
                      color: '#f0f0f0',
                      cursor: 'not-allowed',
                      filter: 'none',
                      transform: 'none',
                    }
                  }}
                >
                  Connect Domain
                </Button>

              </Box>
              
              {/* Requirements Box */}
              <Box sx={requirementsBoxStyles} mt={3}>
                <Typography 
                  sx={{ 
                    fontSize: '1rem', 
                    fontWeight: '600', 
                    mb: 2,
                    color: '#1a2027',
                    letterSpacing: '0.01em'
                  }}
                >
                  Domain Requirements:
                </Typography>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 1.5,
                    px: 0.5 // Add slight padding to container
                  }}
                >
                  {[
                    { key: 'minLength', label: "Must be at least 3 characters long" },
                    { key: 'validTLD', label: "Must end with a valid TLD (e.g., .com, .org)" },
                    { key: 'noHyphenEnds', label: "Cannot start or end with a hyphen" },
                    { key: 'hasDot', label: "Must contain at least one dot (.)" }
                  ].map(({ key, label }) => (
                    <FormControlLabel
                      key={key}
                      control={<Checkbox checked={domainValidation[key]} disabled />}
                      label={label}
                      sx={{
                        bgcolor: domainValidation[key] ? "#e6f4ea" : "transparent",
                        borderRadius: 1.5,
                        px: 1.5,
                        py: 0.75,
                        transition: "all 0.2s ease-in-out",
                        color: domainValidation[key] ? "#1e4620" : "#374151",
                        border: `1px solid ${domainValidation[key] ? '#a8dab5' : '#e5e7eb'}`,
                        '&:hover': {
                          bgcolor: domainValidation[key] ? "#d7efe0" : "#f9fafb",
                          transform: 'translateY(-1px)',
                          boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                        },
                        '& .MuiCheckbox-root': {
                          color: domainValidation[key] ? "#2e7d32" : "#9ca3af",
                          transition: "color 0.2s ease-in-out",
                          '&:hover': {
                            backgroundColor: 'transparent'
                          }
                        },
                        '& .MuiTypography-root': {
                          fontSize: '0.9375rem',
                          fontWeight: domainValidation[key] ? 500 : 400,
                          transition: "font-weight 0.2s ease-in-out",
                        },
                        boxShadow: domainValidation[key] 
                          ? '0 1px 3px rgba(46,125,50,0.1)' 
                          : 'none'
                      }}
                    />
                  ))}
                </Box>
              </Box>

            </Box>
          </Collapse>
        </Box>

        <Box>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 2,
              cursor: 'pointer' 
            }}
            onClick={() => handleStepClick(2)}
          >
            <StepIcon active={activeStep === 2} completed={stepsCompleted.step2} />
            <Typography sx={{ fontSize: '1rem', fontWeight: '600', ml: 1.5 }}>
              Add your DNS records
            </Typography>
          </Box>
          <Collapse in={activeStep === 2}>
            <Box sx={{ 
              p: 3, 
              bgcolor: "#f8f9fc", 
              borderRadius: 2,
              borderColor: "#E5E7EB",
              borderWidth: "1px",
              borderStyle: "solid"
            }}>
              <Typography variant="body2">Configure your DNS settings with the following records:</Typography>
              <Button
                startIcon={<LinkIcon />}
                sx={{ 
                  mt:2,
                  textTransform: 'none', 
                  px: 3, // Reduce side padding slightly for a compact look
                  py: 1, // Reduce vertical padding to make it slimmer
                  minHeight: '32px', // Ensures it stays visually balanced
                  fontSize: '0.875rem', // Slightly smaller text for compact feel
                  width: 'auto', 
                  height: 'fit-content', 
                  whiteSpace: 'nowrap', 
                  bgcolor: '#02042D', 
                  color: 'white', 
                  borderRadius: 2,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    bgcolor: '#02042D', // Keeping the background color same
                    filter: 'brightness(1.2)', // Lighten effect
                    transform: 'scale(1.05)', // Slightly larger on hover
                  },
                  '&:disabled': {
                    bgcolor: '#b0b3c1', // Dimmed color for disabled state
                    color: '#f0f0f0',
                    cursor: 'not-allowed',
                    filter: 'none',
                    transform: 'none',
                  }
                }}
                onClick={handleVerifyDNS}
              >
                Verify DNS Records
              </Button>

            </Box>
          </Collapse>
        </Box>

        {/* Step 3: Metatags */}
        <Box>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 2,
              cursor: 'pointer' 
            }}
            onClick={() => handleStepClick(3)}
          >
            <StepIcon active={activeStep === 3} completed={stepsCompleted.step3} />
            <Typography sx={{ fontSize: '1rem', fontWeight: '600', ml: 1.5 }}>
              Add metatags
            </Typography>
          </Box>
          <Collapse in={activeStep === 3}>
            <Box sx={{ 
              p: 3, 
              bgcolor: "#f8f9fc", 
              borderRadius: 2,
              borderColor: "#E5E7EB",
              borderWidth: "1px",
              borderStyle: "solid"
            }}>
              <Typography variant="body2">Add the following metatags to your website:</Typography>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}