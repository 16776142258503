import React from 'react';
export const GOAL_VALIDATION = {
    // Length constraints
    MINIMUM_LENGTH: 5,
    MAXIMUM_LENGTH: 260,
  
    // Patterns
    PATTERNS: {
      ALLOWED_CHARS: /^[a-zA-Z0-9\s.,'"()-]+$/,
      MEANINGLESS_PATTERNS: [
        /^[a-z]{1,2}[0-9]{1,3}$/i, // like "ab123"
        /^[0-9]+$/, // only numbers
        /^(.)\1+$/, // repeated characters like "aaaa"
        /^[qwerty]+$/i, // keyboard mashing
        /^[asdfgh]+$/i, // keyboard mashing
        /^test123?$/i, // test inputs
      ],
    },
  
    // Messages
    MESSAGES: {
      TOO_SHORT: `Goal must be at least 5 characters long.`,
      TOO_LONG: `Goal must be within 260 characters.`,
      INVALID_CHARS: 'Goal contains invalid characters.',
      DUPLICATE: 'New goal must be different from the current goal.',
      MEANINGLESS: 'Please enter a meaningful goal.',
      INVALID_SPACING: 'Goal cannot have leading or trailing spaces.',
    }
  };
  
  // Main validation function
  export const validateGoal = (value, currentGoal = '') => {
    // Check for leading/trailing spaces before trimming
    if (value !== value.trim()) {
      return {
        isValid: false,
        errorMessage: GOAL_VALIDATION.MESSAGES.INVALID_SPACING
      };
    }

    // Trim the input value
    const trimmedValue = value.trim();
  
    // Early return if the field is optional and empty
    if (!trimmedValue) {
      return { isValid: true, errorMessage: '' };
    }
  
    // Check minimum length
    if (trimmedValue.length < GOAL_VALIDATION.MINIMUM_LENGTH) {
      return {
        isValid: false,
        errorMessage: GOAL_VALIDATION.MESSAGES.TOO_SHORT
      };
    }
  
    // Check maximum length
    if (trimmedValue.length > GOAL_VALIDATION.MAXIMUM_LENGTH) {
      return {
        isValid: false,
        errorMessage: GOAL_VALIDATION.MESSAGES.TOO_LONG
      };
    }
  
    // Check for invalid characters
    if (!GOAL_VALIDATION.PATTERNS.ALLOWED_CHARS.test(trimmedValue)) {
      return {
        isValid: false,
        errorMessage: GOAL_VALIDATION.MESSAGES.INVALID_CHARS
      };
    }
  
    // Check for duplicate if currentGoal is provided
    if (currentGoal && trimmedValue.toLowerCase() === currentGoal.toLowerCase()) {
      return {
        isValid: false,
        errorMessage: GOAL_VALIDATION.MESSAGES.DUPLICATE
      };
    }
  
    // Check for meaningless input
    if (isMeaninglessGoal(trimmedValue)) {
      return {
        isValid: false,
        errorMessage: GOAL_VALIDATION.MESSAGES.MEANINGLESS
      };
    }
  
    return { isValid: true, errorMessage: '' };
  };
  
  // Helper function to check for meaningless goals
  const isMeaninglessGoal = (value) => {
    return GOAL_VALIDATION.PATTERNS.MEANINGLESS_PATTERNS.some(pattern => pattern.test(value));
  };
  
  // Custom hook for goal validation
  export const useGoalValidation = (initialValue = '') => {
    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState('');
  
    const validateValue = React.useCallback((newValue) => {
      const { isValid, errorMessage } = validateGoal(newValue);
      setError(isValid ? '' : errorMessage);
      return isValid;
    }, []);
  
    return {
      value,
      error,
      setValue: (newValue) => {
        setValue(newValue);
        validateValue(newValue);
      },
      isValid: () => validateValue(value)
    };
  };