import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  Box, 
  IconButton, 
  InputAdornment,
  Avatar,
  Tooltip,
  Menu as MenuComponent,
  ListItemIcon,
  Popover,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Button,
} from '@mui/material';
import { 
  Person,
  Logout,
  People as PeopleIcon,
  Key as KeyIcon,
  Settings as SettingsIcon,
  ChevronRight as ChevronRightIcon
} from "@mui/icons-material";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useGetAllPermissions } from '../pages/utils/getAllPermissions';
import favicon from "../common/favicon.png";
import { Close as CloseIcon } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import CURRENCIES from '../components/Navbar/currencies';

const Navbar = ({ 
  title, 
  searchOptions = [], 
  onSearchOptionChange = () => { }, 
  onSearchValueChange = () => { }, 
  searchOption = '', 
  searchValue = '' ,
}) => {
  
  const { permissions, getAllPermissions } = useGetAllPermissions();
  const userRole = localStorage.getItem("Role");
  const user = JSON.parse(localStorage.getItem("User"));
  const [menuOpen, setMenuOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [organizationAnchorEl, setOrganizationAnchorEl] = useState(null);
  const isOrganizationMenuOpen = Boolean(organizationAnchorEl);
  const canManageBilling = permissions?.includes("manage_billing") ?? false;
  const canManageMembers = permissions?.includes("manage_members") ?? false;
  const canManageApiKeys = permissions?.includes("manage_api_keys") ?? false;
  const hasOrgSettings = canManageMembers || canManageApiKeys || canManageBilling;
  const id = localStorage.getItem("Organization");


  const handleOrganizationSettingsClick = (event) => {
    setOrganizationAnchorEl(event.currentTarget);
  };

  const handleOrganizationSettingsClose = () => {
    setOrganizationAnchorEl(null);
  };
  
  const [token, setToken] = useState(() => {
      const tokenData = localStorage.getItem("token");
      return tokenData ? JSON.parse(tokenData).token : null;
    });
    
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    return localStorage.getItem('sidebarOpen') === 'true';
  });

  const renderOrganizationSettingsMenu = () => (

    hasOrgSettings && (
      <Popover
        anchorEl={organizationAnchorEl}
        open={isOrganizationMenuOpen}
        onClose={handleOrganizationSettingsClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            borderRadius: '14px',
            boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
            width: '250px',
            mt: 1,
            ml: -2 // Negative margin to move it more to the left
          }
        }}
      >
        <Box sx={{ p: 1 }}>
          
        {/* <MenuItem 
            sx={{ 
              borderRadius: '7px',
              p: 1,
              gap: 1,
              '&:hover': { 
                bgcolor: 'action.hover',
                '& .MuiListItemIcon-root': {
                  color: '#02042D',
                }
              }
            }}
            component={Link}
            to={`/organisation-settings`}
          >
            <ListItemIcon>
              <SettingsIcon sx={{ fontSize: 18 }} />
            </ListItemIcon>
            <Typography sx={{ fontWeight: 500 }}>Settings</Typography>
          </MenuItem> */}

        {/* Member Settings - Only show if user has permission */}
        {canManageMembers && (
          <MenuItem 
            sx={{ 
              borderRadius: '7px',
              p: 1,
              gap: 1,
              '&:hover': { 
                bgcolor: 'action.hover',
                '& .MuiListItemIcon-root': {
                  color: '#02042D',
                }
              }
            }}
            component={Link}
            to={`/company/${id}/member`}
          >
            <ListItemIcon>
              <PeopleIcon sx={{ fontSize: 18 }} />
            </ListItemIcon>
            <Typography sx={{ fontWeight: 500 }}>Members</Typography>
          </MenuItem>
        )}

        {/* API Keys - Only show if user has permission */}
        {canManageApiKeys && (
          <MenuItem 
            sx={{ 
              borderRadius: '7px',
              p: 1,
              gap: 1,
              '&:hover': { 
                bgcolor: 'action.hover',
                '& .MuiListItemIcon-root': {
                  color: '#02042D',
                }
              }
            }}
            component={Link}
            to="/api-key-management"
          >
            <ListItemIcon>
              <KeyIcon sx={{ fontSize: 18 }} />
            </ListItemIcon>
            <Typography sx={{ fontWeight: 500 }}>API Keys</Typography>
          </MenuItem>
        )}

        {/* Upgrade Plan - Keep existing permission check */}
        {canManageBilling && (
          <>
          <MenuItem 
            sx={{ 
              borderRadius: '7px',
              p: 1,
              gap: 1,
              '&:hover': { 
                bgcolor: 'action.hover',
                '& .MuiListItemIcon-root': {
                  color: '#02042D',
                }
              }
            }}
            component={Link}
            to={`/manage-billing`}
          >
            <ListItemIcon>
              <ElectricBoltIcon sx={{ fontSize: 18 }} />
            </ListItemIcon>
            <Typography sx={{ fontWeight: 500 }}>Upgrade Plan</Typography>
          </MenuItem>
          <MenuItem 
            disabled
                sx={{ 
                  m: 0.4,
                  borderRadius: '7px',
                  p: 1, // Reduced padding
                  gap: 0.5,
                  opacity: 0.6, // Reduce opacity to indicate it's disabled
                  cursor: "not-allowed", // Show 'not allowed' cursor
                  bgcolor: "action.disabledBackground", // Use a disabled background color
                  '& .MuiListItemIcon-root': {
                    color: 'action.disabled', // Faded icon color
                  }
                }}
            component={Link}
            // to={`/organisation-settings`}
          >
            <ListItemIcon>
              <SettingsIcon sx={{ fontSize: 18 }} />
            </ListItemIcon>
            <Box display="flex" flexDirection="column">
            <Typography sx={{ fontWeight: 500 }}>Settings</Typography>
                  {/* <Typography variant="caption" color="text.secondary">
                    Coming Soon
                  </Typography> */}
                </Box>
        </MenuItem>
          <MenuItem 
            disabled
                sx={{ 
                  m: 0.4,
                  borderRadius: '7px',
                  p: 1, // Reduced padding
                  gap: 0.5,
                  opacity: 0.6, // Reduce opacity to indicate it's disabled
                  cursor: "not-allowed", // Show 'not allowed' cursor
                  bgcolor: "action.disabledBackground", // Use a disabled background color
                  '& .MuiListItemIcon-root': {
                    color: 'action.disabled', // Faded icon color
                  }
                }}
            component={Link}
            // to={`/buy-calling-minutes`}
          >
            <ListItemIcon>
              <AccountBalanceWalletIcon sx={{ fontSize: 18 }} />
            </ListItemIcon>
            <Box display="flex" flexDirection="column">
                  <Typography sx={{ fontWeight: 500 }}>Get Minutes</Typography>
                  {/* <Typography variant="caption" color="text.secondary">
                    Coming Soon
                  </Typography> */}
                </Box>
          </MenuItem>
          </>
        )}
      </Box>
    </Popover>
  )
  );

  useEffect(() => {
      getAllPermissions();
    }, [token, getAllPermissions]);

  useEffect(() => {
    // Retrieve the profile picture from localStorage
    const storedUser = JSON.parse(localStorage.getItem('User'));

    // If the User object exists and has a ProfilePicture, set it as the state
    setImageUrl(storedUser?.ProfilePicture && storedUser.ProfilePicture !== "null" ? storedUser.ProfilePicture : favicon);
  }, []);

  //Handle Logout
  const handleLogout = () => {
    setMenuOpen((prev) => !prev);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login";
    setTimeout(() => {
      setToken(null);
    }, 0);
  };

  const handleLogoutClick = () => {
    setLogoutDialogOpen(true);
    handleProfileClose(); // Close the profile menu
  };
  
  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  //Handle Invite Form
  // const openInviteForm = () => {
  //   setMenuOpen((prev) => !prev);
  //   window.location.href = "/invite";
  // };

  // Profile menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  //Currency State
  const [currency, setCurrency] = useState(() => {
    const savedCurrency = localStorage.getItem('currency');
    return savedCurrency || 'USD'; // Default to USD if no saved preference
  });

  // Update currency handler
  const handleCurrencyChange = (event) => {
    const newCurrency = event.target.value;
    setCurrency(newCurrency);
    localStorage.setItem('currency', newCurrency);
    // handleProfileClose();
  };
  
  // Sidebar Toggle
  const toggleSidebar = () => {
    const newState = !isSidebarOpen;
    setIsSidebarOpen(newState);
    localStorage.setItem('sidebarOpen', newState);
  };

  useEffect(() => {
    const savedState = localStorage.getItem('sidebarOpen') === 'true';
    if (savedState !== isSidebarOpen) {
      setIsSidebarOpen(savedState);
    }

    const handleStorageChange = (e) => {
      if (e.key === 'sidebarOpen') {
        setIsSidebarOpen(e.newValue === 'true');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isSidebarOpen]);


  return (
    <AppBar 
      position="static" 
      className='appBar' 
      sx={{ 
        boxShadow: 0, 
        borderBottom: "1px solid #F0F0F0",
        bgcolor: 'white' 
      }}
    >
      <Toolbar className='toolBar' sx={{ minHeight: { xs: '64px', sm: '70px' } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.8rem', flexGrow: 1 }}>
          <Tooltip title={isSidebarOpen ? "Collapse Sidebar" : "Expand Sidebar"} arrow>
            <IconButton
              size="large"
              edge="start"
              aria-label={isSidebarOpen ? "Collapse Sidebar" : "Expand Sidebar"}
              onClick={toggleSidebar}
              sx={{
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.04)',
                  transform: 'scale(1.05)'
                }
              }}
            >
                <MenuOpenIcon 
                    style={{ 
                      color: 'black', 
                      fontSize: '24px', 
                      transform: isSidebarOpen ? 'rotate(0deg)' : 'rotate(180deg)', 
                      transition: 'transform 0.3s ease-out' 
                    }} 
                  />
            </IconButton>
          </Tooltip>
          <Typography 
            variant='h5' 
            color="text.primary"
            sx={{ 
              fontWeight: 600,
              letterSpacing: '-0.5px'
            }}
          >
            {title}
          </Typography>
        </Box>

        {/* Search Section */}
        {searchOptions.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              justifyContent: 'flex-end',
              maxWidth: '400px',
              borderRadius: '8px',
             
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchValue}
              onChange={onSearchValueChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FormControl variant="outlined" size="small">
                      <Select
                        value={searchOption}
                        onChange={onSearchOptionChange}
                        size="small"
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': { border: 'none' },
                          minWidth: '120px',
                          backgroundColor: 'rgba(0, 0, 0, 0.04)',
                          borderRadius: '6px',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.06)',
                          },
                        }}
                      >
                        <MenuItem value="All">
                          <Typography sx={{ fontWeight: 500 }}>Search By</Typography>
                        </MenuItem>
                        {searchOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <Typography sx={{ fontWeight: 500 }}>
                              {option.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </InputAdornment>
                ),
              }}
              sx={{
                flexGrow: 1,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#FFFFFF',
                  padding: '6px 10px',
                  borderRadius: '6px',
                  transition: 'background-color 0.3s, transform 0.2s ease',
                  borderColor: '#E5E7EB', // Apply border color here for the default state
                  '&:hover': {
                    borderWidth: '1px',
                    borderColor: '#E5E7EB', // Faded border color on hover
                    transform: 'scale(1.005)', // Subtle scale effect
                  },
                  '&.Mui-focused': {
                    backgroundColor: '#FFFFFF',
                    transform: 'scale(1.01)', // Slight scale on focus
                    borderColor: '#E5E7EB', // Faded border color on focus
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E5E7EB', // Apply border color here for the focused state
                      borderWidth: '1px',
                    },
                  },
                },
              }}              
            />
          </Box>
        )}

       

        {/* Profile Section */}
        <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Account settings" arrow >
            <IconButton
             onClick={handleProfileClick}
              size="small"
              sx={{
                ml: 2,
                p: '8px',
                borderRadius: '8px',
                border: '1px solid',
                borderColor: '#dadce0',
                backgroundColor: 'rgba(255,255,255,.88)',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: 'rgba(244, 244, 244, 0.06)',
                  borderColor: 'rgb(211, 233, 250)',
                  transform: 'translateY(-1px)'
                }
              }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar
                sx={{
                  width: 38,
                  height: 38,
                  bgcolor: 'transparent',
                  border: '2px solid transparent',
                  transition: 'all 0.2s ease-in-out',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  '&:hover': {
                    borderColor: '#E5E7EB',
                    transform: 'scale(1.02)',
                  },
                }}
              >
                <img
                 src={imageUrl} 
                  alt="Profile"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '50%',
                  }}
                />
              </Avatar>
                <Box sx={{ 
                  display: { xs: 'none', sm: 'block' },
                  textAlign: 'left'
                }}>
                  <Typography 
                    sx={{ 
                      fontWeight: 600,
                      fontSize: '0.95rem',
                      lineHeight: 1.2,
                      color: 'text.primary'
                    }}
                  >
                    {user?.Name?.length > 12 ? `${user?.Name.slice(0, 10)}...` : user?.Name}
                  </Typography>
                  <Typography 
                    sx={{ 
                      fontSize: '0.75rem',
                      color: 'text.secondary',
                      lineHeight: 1.2
                    }}
                  >
                    {userRole ? `${userRole}` : ""}
                  </Typography>
                </Box>
                {/* <MoreVertIcon sx={{fontSize:'20px'}}/> */}
              </Box>
            </IconButton>
          </Tooltip>
        </Box>
        
        {/* Profile Menu */}
        <MenuComponent
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleProfileClose}
            PaperProps={{
              elevation: 3,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 4px 12px rgba(0,0,0,0.1))',
                mt: 1.2, // Slightly reduced margin top
                borderRadius: '14px', // Slightly tighter border radius
                minWidth: '200px', // Reduced width
                '& .MuiList-root': {
                  padding: '6px' // Slightly reduced padding
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 18,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {/* Profile Header */}
            <Box 
              sx={{ 
                p: 2, // Reduced padding
                pb: 1.8, // Slightly smaller bottom padding
                borderBottom: '1px solid',
                borderColor: 'divider',
                bgcolor: 'rgba(0, 0, 0, 0.01)'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Avatar 
                  sx={{ 
                    width: 40, // Reduced avatar size
                    height: 40, 
                    bgcolor: 'transparent',
                    border: '2px solid',
                    borderColor: '#E5E7EB',
                  }}
                >
                 <img
                 src={imageUrl} 
                  alt="Profile"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '50%',
                  }}
                />
                </Avatar>
                <Box>
                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      fontWeight: 600,
                      color: 'text.primary',
                      lineHeight: 1.2,
                    }}
                  >
                    {user?.Name?.length > 12 ? `${user?.Name.slice(0, 10)}...` : user?.Name}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: '0.8rem' // Slightly reduced font size
                    }}
                  >
                    {`${user?.Email?.length > 12 ? `${user?.Email.slice(0, 18)}...` : user?.Email}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
             {/* View Profile */}
             <MenuItem 
              component={Link}
              to="/profile"
              sx={{ 
                m: 0.4,
                borderRadius: '7px',
                p: 1, // Reduced padding
                gap: 0.5,
                '&:hover': { 
                  bgcolor: 'action.hover',
                  '& .MuiListItemIcon-root': {
                    color: '#02042D',
                    transform: 'scale(1.08)'
                  }
                }
              }}>
              <ListItemIcon>
                <Person sx={{ fontSize: 18 }} />
              </ListItemIcon>
              <Typography sx={{ fontWeight: 500 }}>View Profile</Typography>
            </MenuItem>

               {/* Organization Settings */}
            <MenuItem 
              onClick={handleOrganizationSettingsClick}
              sx={{ 
                m: 0.4,
                borderRadius: '7px',
                p: 1,
                gap: 0.5,
                '&:hover': { 
                  bgcolor: 'action.hover',
                  '& .MuiListItemIcon-root': {
                    color: '#02042D',
                    transform: 'scale(1.08)'
                  }
                }
              }}
            >
              <ListItemIcon>
              <CorporateFareIcon sx={{ fontSize: 18 }} />
              </ListItemIcon>
              <Typography sx={{ fontWeight: 500 }}>Organization</Typography>
              <ChevronRightIcon sx={{ ml: 'auto', fontSize: 18 }} />
            </MenuItem>
            {/* Currency Toggle Menu Item */}
            {/* <MenuItem 
            sx={{ 
              m: 0.4, 
              borderRadius: '7px',
              p: 1,
              
              gap: 0.5,
              '&:hover': { 
                bgcolor: 'action.hover',
                '& .MuiListItemIcon-root': {
                  color: '#02042D',
                  transform: 'scale(1.08)'
                }
              },
              align: 'center'
            }}
          >
            <ListItemIcon>
              {React.createElement(CURRENCIES[currency].icon, { style: { fontSize: 18 } })}
            </ListItemIcon>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              width: '100%'
            }}>
              <Typography sx={{ fontWeight: 500 }}>Currency</Typography>
              <Select
                value={currency}
                onChange={handleCurrencyChange}
                size="small"
                sx={{
                  ml: 2,
                  '.MuiSelect-select': {
                    py: 0.5,
                    pl: 1.5,
                    pr: 3,
                    fontSize: '0.875rem',
                    fontWeight: 500
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#02042D'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#02042D'
                  }
                }}
              >
                {Object.values(CURRENCIES).map((curr) => (
                  <MenuItem key={curr.code} value={curr.code}>
                    {curr.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </MenuItem> */}

          <MenuItem 
                component={Link}
                // to="#" // Prevent navigation since the feature is not available yet
                disabled
                sx={{ 
                  m: 0.4,
                  borderRadius: '7px',
                  p: 1, // Reduced padding
                  gap: 0.5,
                  opacity: 0.6, // Reduce opacity to indicate it's disabled
                  cursor: "not-allowed", // Show 'not allowed' cursor
                  bgcolor: "action.disabledBackground", // Use a disabled background color
                  '& .MuiListItemIcon-root': {
                    color: 'action.disabled', // Faded icon color
                  }
                }}
              >
                <ListItemIcon>
                  <NewReleasesIcon sx={{ fontSize: 18 }} />
                </ListItemIcon>
                <Box display="flex" flexDirection="column">
                <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'space-between',
                      width: '100%'
                    }}>
                  <Typography sx={{ fontWeight: 500 }}>Currency</Typography>
                  <Select
                    value={currency}
                    onChange={handleCurrencyChange}
                    size="small"
                    sx={{
                      ml: 2,
                      '.MuiSelect-select': {
                        py: 0.5,
                        pl: 1.5,
                        pr: 3,
                        fontSize: '0.875rem',
                        fontWeight: 500
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'divider'
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#02042D'
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#02042D'
                      }
                    }}
                  >
                    {Object.values(CURRENCIES).map((curr) => (
                      <MenuItem key={curr.code} value={curr.code}>
                        {curr.label}
                      </MenuItem>
                    ))}
                  </Select>
                  </Box>
                  <Typography variant="caption" color="text.secondary">
                    Coming Soon
                  </Typography>
                </Box>
              </MenuItem>

              {/* Release Logs */}
            {/* <MenuItem 
              component={Link}
              to="/release-logs"
              sx={{ 
                m: 0.4,
                borderRadius: '7px',
                p: 1, // Reduced padding
                gap: 0.5,
                '&:hover': { 
                  bgcolor: 'action.hover',
                  '& .MuiListItemIcon-root': {
                    color: '#02042D',
                    transform: 'scale(1.08)'
                  }
                }
              }}>
              <ListItemIcon>
                <NewReleasesIcon sx={{ fontSize: 18 }} />
              </ListItemIcon>
              <Typography sx={{ fontWeight: 500 }}>Release logs</Typography>
            </MenuItem> */}

            {/* Release Logs Coming Soon */}
            <MenuItem 
                component={Link}
                // to="#" // Prevent navigation since the feature is not available yet
                disabled
                sx={{ 
                  m: 0.4,
                  borderRadius: '7px',
                  p: 1, // Reduced padding
                  gap: 0.5,
                  opacity: 0.6, // Reduce opacity to indicate it's disabled
                  cursor: "not-allowed", // Show 'not allowed' cursor
                  bgcolor: "action.disabledBackground", // Use a disabled background color
                  '& .MuiListItemIcon-root': {
                    color: 'action.disabled', // Faded icon color
                  }
                }}
              >
                <ListItemIcon>
                  <NewReleasesIcon sx={{ fontSize: 18 }} />
                </ListItemIcon>
                <Box display="flex" flexDirection="column">
                  <Typography sx={{ fontWeight: 500 }}>Release Logs</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Coming Soon
                  </Typography>
                </Box>
              </MenuItem>

            {/* Logout */}
            <Box sx={{ px: 0.4, pb: 0.4 }}>
            <MenuItem 
                sx={{ 
                  borderRadius: '7px',
                  p: 1.2,
                  mt: 1,
                  gap: 1.2,
                  color: 'error.main',
                  borderTop: '1px solid',
                  borderColor: 'divider',
                  '&:hover': { 
                    bgcolor: 'error.lighter',
                    '& .MuiListItemIcon-root': {
                      transform: 'scale(1.08)'
                    }
                  } 
                }}
                onClick={handleLogoutClick}
              >
                <ListItemIcon>
                  <Logout sx={{ fontSize: 18 }} />
                </ListItemIcon>
                <Typography sx={{ fontWeight: 500 }}>Sign Out</Typography>
              </MenuItem>
            </Box>

          </MenuComponent>
          
     </Toolbar>
     
      {renderOrganizationSettingsMenu()}

      {/* Add this Dialog component just before the closing tag of your Navbar component */}
    <Dialog
      open={logoutDialogOpen}
      onClose={handleLogoutCancel}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          p: 1,
        }
      }}
    >
      {/* Close button in top-right corner */}
      <IconButton
        aria-label="close"
        onClick={handleLogoutCancel}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: 'grey.500',
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle sx={{ pb: 1, pt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mb: 1 }}>
          <Typography variant="h5" component="span" fontWeight={600}>
            Sign Out
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ fontWeight: 500 }}
        >
          Are you sure you want to sign out? Any unsaved changes will be lost.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2, gap: 1 }}>
        <Button
          onClick={handleLogoutCancel}
          variant="outlined"
          sx={{
            borderRadius: 2,
            px: 3,
            py: 1,
            textTransform: 'none',
            fontWeight: 600,
            borderColor: 'grey.300',
            color: 'text.primary',
            '&:hover': {
              borderColor: 'grey.400',
              backgroundColor: 'grey.50',
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleLogout}
          variant="contained"
          color="error"
          sx={{
            borderRadius: 2,
            px: 3,
            py: 1,
            textTransform: 'none',
            fontWeight: 600,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: 'error.dark',
            }
          }}
        >
          Sign Out
        </Button>
      </DialogActions>
    </Dialog>

    </AppBar>
  );
};

export default Navbar;
