// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* generateprompt.css */

/* Variables for reusable values */
:root {
  --brand-color: #02042d;
}

.generate-button {
  background-color: var(--brand-color);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0 24px;
  cursor: pointer;
  min-width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.generate-button:hover {
  background-color: var(--brand-color);
  opacity: 0.9;
}

.generate-button:disabled {
  background-color: var(--brand-color);
  opacity: 0.5;
  cursor: not-allowed;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInAlert {
  animation: fadeIn 0.3s ease-out;
  animation-fill-mode: forwards;
}`, "",{"version":3,"sources":["webpack://./src/styles/generatePrompt.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB,kCAAkC;AAClC;EACE,sBAAsB;AACxB;;AAEA;EACE,oCAAoC;EACpC,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;EACR,eAAe;EACf,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,+BAA+B;EAC/B,6BAA6B;AAC/B","sourcesContent":["/* generateprompt.css */\n\n/* Variables for reusable values */\n:root {\n  --brand-color: #02042d;\n}\n\n.generate-button {\n  background-color: var(--brand-color);\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 0 24px;\n  cursor: pointer;\n  min-width: 120px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  font-size: 14px;\n  opacity: 1;\n  transition: opacity 0.2s ease;\n}\n\n.generate-button:hover {\n  background-color: var(--brand-color);\n  opacity: 0.9;\n}\n\n.generate-button:disabled {\n  background-color: var(--brand-color);\n  opacity: 0.5;\n  cursor: not-allowed;\n}\n\n@keyframes fadeIn {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.fadeInAlert {\n  animation: fadeIn 0.3s ease-out;\n  animation-fill-mode: forwards;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
