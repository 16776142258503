import api from "../../api";
import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField, Select, MenuItem, FormControl, Typography, Slider, Grid } from '@mui/material';
import Loader from '../Loader';
import TooltipCustom from '../TooltipCustom';
import { UploadFile } from '@mui/icons-material';
import NoDataFound from "../NoDataFound";

const NewPrompt = () => {
    const [token, setToken] = useState("");
    const [temperature, setTemperature] = useState(0.3);
    const [firstMessage, setFirstMessage] = useState('');
    const [prompt, setPrompt] = useState('');
    const [llmProvider, setLlmProvider] = useState('');
    const [llmModel, setLlmModel] = useState('');
    const [knowledgeBase, setKnowledgeBase] = useState(null);
    const [maxToken, setMaxToken] = useState(1000);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [callEndText, setCallEndText] = useState("");
    const [image, setImage] = useState();
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const [llmProviderList, setLlmProviderList] = useState([]);
    const [llmList, setLlmList] = useState([]);

    const permissions = JSON.parse(localStorage.getItem('permissions'))
    // Check if user has required permissions
    const hasRequiredPermissions = permissions.includes("fetch_llm_model") && permissions.includes("fetch_LLM_providers");

    useEffect(() => {
        const initialize = async () => {
            const t = JSON.parse(localStorage.getItem("token"))?.token;
            if (t) {
                setToken(t);
            }
        };
        initialize();
    }, []);

    // Only fetch data if user has required permissions
    useEffect(() => {
        const initializeData = async () => {
            if (hasRequiredPermissions && token) {
                setLoading(true);
                try {
                    await fetchLLMProviders(token);
                    await loadSavedValues();
                } catch (error) {
                    console.error("Error initializing data:", error);
                } finally {
                    setLoading(false);
                }
            }
        };

        initializeData();
    }, [token, hasRequiredPermissions]);


    const loadSavedValues = () => {
        // Get the assistant object from localStorage or create a new one if it doesn't exist
        const assistant = JSON.parse(localStorage.getItem("assistant")) || {};

        // Set state with values from assistant or default values
        setFirstMessage(assistant.InitialMsg || '');
        setPrompt(assistant.Prompt || '');
        setLlmProvider(assistant.LLMProvider || '');
        if (assistant.LLMProvider) {
            fetchLLMsList(assistant.LLMProvider);
        }
        setLlmModel(assistant.LLMModel || '');
        setMaxToken(assistant.MaxToken || 1000);
        setTemperature(assistant.Temperature || 0.3);
        setCallEndText(assistant.CallEndText || "");
        if (localStorage.getItem('KnowledgeBase')) {
            setKnowledgeBase(JSON.parse(localStorage.getItem('KnowledgeBase')));
        }
    };

    const saveToLocalStorage = (key, value) => {
        // Get the current assistant object or create a new one
        const assistant = JSON.parse(localStorage.getItem("assistant")) || {};
        assistant[key] = value;

        // Save the updated assistant object back to localStorage
        localStorage.setItem("assistant", JSON.stringify(assistant));
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];
        if (file) {
            setImage(file);
            handleFileChange({ target: { files: [file] } });
        }
    };

    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = function () {
                const fileData = {
                    name: selectedFile.name,
                    size: selectedFile.size,
                    type: selectedFile.type,
                    content: reader.result // Base64 encoded string
                };
                localStorage.setItem("KnowledgeBase", JSON.stringify(fileData));
            };
            reader.readAsDataURL(selectedFile);
            setKnowledgeBase(selectedFile);
        }
    };

    const handleSliderChange = (event, newValue) => {
        setTemperature(newValue);
        saveToLocalStorage("Temperature", newValue);
    };

    const fetchLLMProviders = async (token) => {
        if (!hasRequiredPermissions) return;
        
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        const response = await api.get("/llm-provider?limit=999", config);
        setLlmProviderList(response.data.data.providers);
    };

    const fetchLLMsList = async (provider_id) => {
        if (!hasRequiredPermissions) return;
        
        setLoading1(true);
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };
        try {
            const response = await api.get(`/llm/models/provider/${provider_id}`, config);
            setLlmList(response.data.data);
        } catch (error) {
            console.error("Error fetching LLM list:", error);
        } finally {
            setLoading1(false);
        }
    };

    // Early return if permissions are not met
    if (!hasRequiredPermissions) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "70dvh" }}>
                <NoDataFound
                    title="Insufficient Permissions"
                    subtitle="You do not have the permissions to view assistants."
                />
            </Box>
        );
    }


    if (loading || loading1) {
        return <Loader />;
    }

    return (
        <Grid container spacing={2}>
        <Grid item xs={6}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem", bgcolor: "rgba(177, 177, 190, 0.15)", padding: "1rem", borderRadius: "0.5rem" }}>
                <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>First Message <TooltipCustom title="The initial greeting or introductory message the assistant uses to engage with the user when a call or chat begins, setting the tone for the conversation." /></Typography>
                    <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={firstMessage}
                        onChange={(e) => {
                            setFirstMessage(e.target.value);
                            saveToLocalStorage("InitialMsg", e.target.value); // Save first message to assistant in localStorage
                        }}
                        sx={{ bgcolor: "white" }}
                    />
                </Box>
                <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>Prompt <TooltipCustom title=" Initial or ongoing input prompts used to guide the assistant’s responses. This field can be customized to shape the conversational style or inject specific responses to common queries." /></Typography>
                    <TextField
                        size='small'
                        fullWidth
                        variant="outlined"
                        value={prompt}
                        onChange={(e) => {
                            setPrompt(e.target.value);
                            saveToLocalStorage("Prompt", e.target.value); // Save prompt to assistant in localStorage
                        }}
                        multiline
                        rows={8}
                        sx={{ bgcolor: "white" }}
                    />
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="body1">End Message <TooltipCustom title="When this setting is active, Lia will automatically end the session if a user says goodbye or any similar farewell phrase. It streamlines the conversation flow by reducing unnecessary prompts after a clear end signal." /></Typography>
                    <TextField
                        size='small'
                        fullWidth
                        variant="outlined"
                        value={callEndText}
                        onChange={(e) => { setCallEndText(e.target.value); saveToLocalStorage("CallEndText", e.target.value) }}
                        sx={{ bgcolor: "white" }}
                    />
                </Box>
            </Box>
        </Grid>
        <Grid item xs={6}>

            <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem", bgcolor: "rgba(177, 177, 190, 0.15)", padding: "1rem", borderRadius: "0.5rem" }}>
                <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>Provider <TooltipCustom title="The platform supplying the Large Language Model that powers the assistant’s responses, impacting conversation style and accuracy." /></Typography>
                    <FormControl fullWidth size='small'>
                        <Select
                            value={llmProvider}
                            onChange={(e) => {
                                setLlmProvider(e.target.value);
                                saveToLocalStorage("LLMProvider", e.target.value); // Save LLM provider to assistant in localStorage
                                fetchLLMsList(e.target.value);
                            }}
                            sx={{ bgcolor: "white" }}
                        >
                            {
                                llmProviderList.map((provider, key) => (
                                    <MenuItem value={provider._id} key={key}>{provider.Name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>Model <TooltipCustom title="The specific language model used to generate the assistant’s responses, which impacts the assistant's conversational abilities, accuracy, and response quality." /></Typography>
                    <FormControl fullWidth size='small'>
                        <Select
                            value={llmModel}
                            onChange={(e) => {
                                setLlmModel(e.target.value);
                                saveToLocalStorage("LLMModel", e.target.value); // Save LLM model to assistant in localStorage

                            }}
                            sx={{ bgcolor: "white" }}
                        >
                            {
                                llmList.map((llm, key) => (
                                    <MenuItem value={llm._id} key={key}>{llm.Name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>Knowledge Base File <TooltipCustom title="An option to upload a document or data file that contains reference material or specific knowledge the assistant can use to improve response accuracy and provide detailed information." /></Typography>
                    <div style={{
                        width: "100%", height: "150px", display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center", justifyContent: "center", border: isDragging ? "2px dashed #1d4ed8" : "2px dashed #D9D9D9", borderRadius: "8px", cursor: "pointer", backgroundColor: isDragging ? "#eff6ff" : "",
                    }}
                        onDragEnter={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsDragging(true);
                        }}
                        onDragOver={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsDragging(true);
                        }}
                        onDragLeave={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsDragging(false);
                        }}
                        onDrop={handleDrop}
                        onClick={() => fileInputRef.current?.click()}
                    >

                        <input
                            type="file"
                            id="upload-file"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={(e) => {
                                if (e.target.files) {
                                    setImage(e.target.files[0]);
                                    handleFileChange(e)
                                }
                            }}
                        />
                        <UploadFile style={{ fontSize: "2rem" }} />

                        <label htmlFor="upload-file"
                            style={{ fontSize: "1rem", fontWeight: 500, cursor: "pointer", color: "GrayText" }}
                        >
                            <span style={{ textDecoration: "underline", color: "#02042D" }}>Upload File</span> or Drag here <br />
                            <span style={{ fontSize: "0.8rem", fontWeight: 400, color: "GrayText", textAlign: 'center' }}>All Formats are Supported</span>
                        </label>
                        {image && <p>{image.name}</p>}
                        {!image && knowledgeBase && <p>{knowledgeBase.name}</p>}
                    </div>
                </Box>
                <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>Max Token <TooltipCustom title=" The maximum number of tokens (words, parts of words, punctuation) the assistant can use in a single response. This helps manage response length and resource usage." /></Typography>
                    <TextField
                        size='small'
                        fullWidth
                        variant="outlined"
                        value={maxToken}
                        onChange={(e) => {
                            setMaxToken(e.target.value);
                            saveToLocalStorage("MaxToken", e.target.value); // Save max token to assistant in localStorage
                        }}
                        sx={{ bgcolor: "white" }}
                    />
                </Box>
                <Box sx={{ mb: 2, display: 'flex', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>Temperature <TooltipCustom title="A setting that determines the randomness or creativity in the assistant’s responses. Lower values make responses more deterministic, while higher values introduce variability." /></Typography>
                    <Box display="flex" alignItems="center">
                        <Slider
                            value={temperature}
                            onChange={handleSliderChange}
                            min={0}
                            max={1}
                            step={0.1}
                            valueLabelDisplay="auto"
                            sx={{
                                color: "#02042D",
                            }}
                        />
                        <div style={{ backgroundColor: "#02042D", color: "white", borderRadius: "8px", marginLeft: "1rem", width: "2rem", height: "2rem", display: "flex", alignItems: "center", justifyContent: "center", }}>
                            {temperature}
                        </div>
                    </Box>
                </Box>
            </Box>
        </Grid>

    </Grid>
    );
};

export default NewPrompt;
