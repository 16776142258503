import { Box, Button, Input, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import {
    Chip,
    FormControl,
} from "@mui/material";
import api from "../api";
import { toast } from 'sonner';

const Outbound = ({ assistantId, phone }) => {
    const [values, setValues] = useState([]);
    const [currValue, setCurrValue] = useState("");
    const [selected, setSelected] = useState("")
    const [assistants, setAssistants] = useState([])
    const [token, setToken] = useState("");

    const handleKeyUp = (e) => {
        console.log(e.keyCode);
        if (e.keyCode === 32 || e.key === 'Enter') {
            setValues((oldState) => [...oldState, e.target.value]);
            setCurrValue("");
        }
    };

    const handleChange = (e) => {
        setCurrValue(e.target.value);
    };

    const handleDelete = (item, index) => {
        let arr = [...values]
        arr.splice(index, 1)
        setValues(arr)
    }

    const handleSelect = (e) => {
        setSelected(e.target.value)
    }

    const getToken = () => {
        const t = JSON.parse(localStorage.getItem('token')).token
        setToken(t)
    }


    const fetchAllAssistants = async () => {
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }

        const response = await api.get("/assistants?limit=9999", config)
        setAssistants(response.data.data.assistants)
    }

    const startOutboundCall = async () => {
        try {
            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }

            const response = await api.post("/assistants/initiate-outbound-call", { assistantId: assistantId, OutboundPhone: values, associatedPhone: phone.PhoneNumber }, config)
            toast.success("Call Initiated")
        } catch (error) {
            toast.error("Failed to initiate call.")
        }
    }

    useEffect(() => {
        getToken();
        if (token) {
            fetchAllAssistants()
        }
    }, [token])

    if (!phone?.OutboundAssistantId) {
        return (
            <Typography variant="h5">first assign the assistant to initiate outbound call</Typography>
        );
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2rem', width: "100%" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: "1rem", bgcolor: "#EEE", width: 550, borderRadius: "8px" }}>
                <Typography variant="h5">Outbound Call</Typography>
                <Typography variant="body1">Add numbers to the list</Typography>

                <FormControl>
                    <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                        {values.map((item, index) => (
                            <Chip key={index} size="small" onDelete={() => handleDelete(item, index)} label={item} />
                        ))}
                    </Box>
                    <Input
                        label="Add New Item"
                        value={currValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyUp}
                        variant="outline"
                    />
                </FormControl>

                <Button variant='contained' sx={{ bgcolor: "#020429", color: "white" }} onClick={startOutboundCall}>Start Call</Button>
            </Box>
        </Box>
    )
}

export default Outbound;