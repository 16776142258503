import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField, Select, MenuItem, FormControl, Typography, Grid } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import { toast } from 'sonner';

const InitialDetails = ({ assistantId }) => {
    const [token, setToken] = useState("");
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [type, setType] = useState('');
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');

    const [image, setImage] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const getToken = async () => {
        const tokenData = localStorage.getItem("token");
        if (tokenData) {
            const t = JSON.parse(tokenData).token;
            setToken(t);
        } else {
            console.error("Token not found in local storage");
        }
    };

    useEffect(() => {
        getToken();
        loadSavedValues();
    }, []);

    const loadSavedValues = () => {
        const savedAssistant = JSON.parse(localStorage.getItem("assistant")) || {};

        setName(savedAssistant.Name || '');
        setCategory(savedAssistant.Category || '');
        setType(savedAssistant.Type || '');
        const savedLogo = localStorage.getItem('Logo');
        if (savedLogo) {
            const logoData = JSON.parse(savedLogo);
            // Create a file-like object for preview
            fetch(logoData.content)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], logoData.name, { type: logoData.type });
                    setImage(file);
                });
        }
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        updateAssistantInLocalStorage("Name", newName);
    };

    const handleDescriptionChange = (e) => {
        const newDescription = e.target.value;
        setDescription(newDescription);
        updateAssistantInLocalStorage("Description", newDescription);
    };

    const handleCategoryChange = (e) => {
        const newCategory = e.target.value;
        setCategory(newCategory);
        updateAssistantInLocalStorage("Category", newCategory);
    };

    const handleTypeChange = (e) => {
        const newType = e.target.value;
        setType(newType);
        updateAssistantInLocalStorage("Type", newType);
    };

    const updateAssistantInLocalStorage = (key, value) => {
        const savedAssistant = JSON.parse(localStorage.getItem("assistant")) || {};
        savedAssistant[key] = value;
        localStorage.setItem("assistant", JSON.stringify(savedAssistant));
    };

    const handleFile = (file) => {
        if (!file) {
            return;
        }

        const allowedTypes = ["image/png", "image/jpg", "image/jpeg", "image/svg+xml", "image/gif"];
        const maxSize = 3 * 1024 * 1024;

        if (!allowedTypes.includes(file.type)) {
            toast.error("Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed.");
            return;
        }

        if (file.size > maxSize) {
            toast.error("File size should not exceed 3 MB.");
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            const base64Image = reader.result;
            setImage(file); // For preview

            // Store both the file metadata and content
            const logoData = {
                content: base64Image,
                name: file.name,
                type: file.type
            };
            localStorage.setItem('Logo', JSON.stringify(logoData));
        };
        reader.readAsDataURL(file);
    };


    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];

        if (file) {
            handleFile(file);
        }
    }

    useEffect(() => {
        if (image) {
            const objectUrl = URL.createObjectURL(image);
            return () => URL.revokeObjectURL(objectUrl); // Cleanup function to release the object URL
        }
    }, [image]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem", bgcolor: "rgba(177, 177, 190, 0.11)", padding: "1rem", borderRadius: "0.5rem" }}>
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Name</Typography>
                        <TextField
                            size='small'
                            fullWidth
                            variant="outlined"
                            value={name}
                            onChange={handleNameChange}
                            sx={{ bgcolor: "white" }}
                        />
                    </Box>
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Category</Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={category}
                                onChange={handleCategoryChange}
                                sx={{ bgcolor: "white" }}
                            >
                                <MenuItem value="Sales">Sales</MenuItem>
                                <MenuItem value="Customer Support">Customer Support</MenuItem>
                                <MenuItem value="IT Support">IT Support</MenuItem>
                                <MenuItem value="Human Resources">Human Resources</MenuItem>
                                <MenuItem value="Healthcare / Service Industry">Healthcare / Service Industry</MenuItem>
                                <MenuItem value="Banking Support">Banking Support</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Type</Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={type}
                                onChange={handleTypeChange}
                                sx={{ bgcolor: "white" }}
                            >
                                <MenuItem value="inbound">Inbound</MenuItem>
                                <MenuItem value="outbound">Outbound</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Description</Typography>
                        <TextField
                            size='small'
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            value={description}
                            onChange={(handleDescriptionChange)}
                            sx={{ bgcolor: "white" }}
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem", bgcolor: "rgba(177, 177, 190, 0.11)", padding: "1rem", borderRadius: "0.5rem", height: "fit-content" }}>

                    <div style={{
                        width: "100%", height: "150px", display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center", justifyContent: "center", border: isDragging ? "2px dashed #1d4ed8" : "2px dashed #D9D9D9", borderRadius: "8px", cursor: "pointer", backgroundColor: isDragging ? "#eff6ff" : "",
                    }}
                        onDragEnter={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsDragging(true);
                        }}
                        onDragOver={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsDragging(true);
                        }}
                        onDragLeave={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsDragging(false);
                        }}
                        onDrop={handleDrop}
                        onClick={() => fileInputRef.current?.click()}
                    >

                        <input
                            type="file"
                            accept="image/*"
                            id="upload-file"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={(e) => {
                                handleFile(e.target.files?.[0]);
                            }}
                        />
                        <UploadFile style={{ fontSize: "2rem" }} />

                        <label htmlFor="upload-file"
                            style={{ fontSize: "1rem", fontWeight: 500, cursor: "pointer", color: "GrayText" }}
                        >
                            <span style={{ textDecoration: "underline", color: "#02042D" }}>Upload Profile</span> or Drag here <br />
                            <span style={{ fontSize: "0.8rem", fontWeight: 400, color: "GrayText" }}>SVG, PNG, JPG or GIF (max. 3 MB)</span>
                        </label>

                    </div>

                    {image && (
                        <Box sx={{ mt: 2 }}>
                            <img
                                src={typeof image === 'string' ? image : URL.createObjectURL(image)}
                                alt="Preview"
                                style={{ width: "100px", height: "auto", objectFit: "cover" }}
                            />
                        </Box>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default InitialDetails;
