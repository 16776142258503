import React from 'react';
import { Button, FormLabel, Switch, TextField, Box, FormControl, CircularProgress, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState, useEffect } from 'react';
import api from "../api";
import TooltipCustom from '../components/TooltipCustom';
   

const ShareAssistantForm = ({
    isOpen,
    onClose,
    isPasswordRequired,
    setIsPasswordRequired,
    setPassword,
    password,
    credits,
    setCredits,
    date,
    setDate,
    time,
    setTime,
    handleGenerateLink,
    handleManageAccessClick,
    loading
  }) => {
    const token = JSON.parse(localStorage.getItem("token"))?.token;
    const [isLoadingConfig, setIsLoadingConfig] = useState(true);
    const [Max_Credits, setMaxCredits] = useState(null); 
    const [Min_Credits, setMinCredits] = useState(null);
    useEffect(() => {
        fetchCreditLimits();
        }, []);

        const fetchCreditLimits = async () => {
              setIsLoadingConfig(true);
              try {
                  const response = await api.post('v1/users/permissions/getconfig', {
                      configKeys: ['Max_Credits', 'Min_Credits']
                  }, {
                      headers: {
                          Authorization: `Bearer ${token}`
                      }
                  });
          
                  if (response.data.success) {
                      const { Max_Credits, Min_Credits } = response.data.data;
                      if (Max_Credits) {
                          setMaxCredits(Number.parseInt(Max_Credits, 10));
                      }
                      if (Min_Credits) {
                          setMinCredits(Number.parseInt(Min_Credits, 10));
                      }
                  }
              } catch (error) {
                  console.error('Error fetching calling minutes limits:', error);
                  // Consider showing user-friendly error message
              } finally {
                  setIsLoadingConfig(false);
              }
          };

  const [showPassword, setShowPassword] = useState(false);
  const [creditError, setCreditError] = useState('');

  const handleCreditChange = (e) => {
    const value = e.target.value;
    setCredits(value);

    // Validate calling Minutes on change
    const numCredits = Number.parseInt(value, 10);
    if (value === '' || Number.isNaN(numCredits)) {
      setCreditError('Calling minutes must be a number');
    } else if (numCredits < Min_Credits) {
      setCreditError(`Minimum calling minutes allotment required is ${Min_Credits} minutes`);
    } else if (numCredits > Max_Credits) {
      setCreditError(`Maximum calling minutes allotment is ${Max_Credits} minutes`);
    } else {
      setCreditError('');
    }
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  
  
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 1,
          gap: "90px",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {/* <Button variant="text" onClick={onClose} sx={{ minWidth: "auto" }}>
          <TiArrowBack style={{ width: "28px", height: "28px", color: "black" }} />
          </Button> */}
          <h6 sx={{ fontWeight: 600 }}>Share Assistant Access</h6>
        </Box>
        <Button
          variant="outlined"
          onClick={handleManageAccessClick}
          sx={{
            borderColor: "rgba(10, 11, 28, 0.9)",
            color: "rgba(10, 11, 28, 0.9)",
            fontSize: 12,
            fontWeight: 700,
          }}
        >
          Manage Shared Access
        </Button>
      </Box>

      <Box sx={{ paddingTop: "12px", gap: "12px" }}>
        {/* Additional Security Section */}
        <Box sx={{ marginBottom: 2 }}>
          <Typography sx={{ fontSize: 16 }}>Additional Security <TooltipCustom title="Enable this option to add an additional layer of security when sharing the assistant." /></Typography>
          <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
            <Switch
              checked={isPasswordRequired}
              onChange={(e) => setIsPasswordRequired(e.target.checked)}
            />
            <span sx={{ fontSize: 16 }}>Password Required</span>
          </Box>
        </Box>

        {/* Password Field */}
        {isPasswordRequired && (
          <Box sx={{ marginBottom: 2 }}>
            <FormLabel htmlFor="password" sx={{ fontSize: 16 }}>
              Password
            </FormLabel>
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel sx={{ fontSize: 16, mb: 1 }}>
              Expiration Date <TooltipCustom title="Select the date when the shared access will expire." />
              </FormLabel>
              <DatePicker
                value={date}
                onChange={(newValue) => setDate(newValue)}
                format="dd/MM/yyyy"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                    variant: "outlined",
                  },
                }}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel sx={{ fontSize: 16, mb: 1 }}>
              Expiration Time <TooltipCustom title="Specify the time (in the user's local time zone) when the shared access will expire." />
              </FormLabel>
              <TimePicker
                value={time}
                label="HH:MM AM/PM"
                ampm={true}
                onChange={(newValue) => setTime(newValue)}
                
                slotProps={{
                  textField: {
                    name:'hh:mm',
                    placeholder:'HH:MM AM/PM',
                    fullWidth: true,
                    size: "small",
                    variant: "outlined",
                  },
                }}
              />
            </FormControl>
          </Box>
        </LocalizationProvider>

        <Box sx={{ marginTop: 2 }}>
        <FormLabel sx={{ fontSize: 16 }}>Calling Minutes Allotted <TooltipCustom title={`Allocate between ${Min_Credits} and ${Max_Credits} minutes for shared access to ensure sufficient usage.`} /></FormLabel>
        <TextField
          type="number"
          value={credits}
          onChange={handleCreditChange}
          placeholder={`Max ${Max_Credits}`}
          fullWidth
          sx={{
            mt:1
          }}
          error={!!creditError}
          helperText={creditError}
          inputProps={{
            min: Min_Credits,
            max: Max_Credits
          }}
        />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 2,
            paddingTop: 2,
          }}
        >
          <Button
            variant="outlined"
            sx={{ borderColor: "#A9A9A9", color: "black", padding: "8px 24px" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: (loading || creditError) ? "#A9A9A9" : "#0A0B1C",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: 200,
              padding: "8px 24px",
            }}
            onClick={handleGenerateLink}
            disabled={loading || creditError}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgress size={20} sx={{ color: "white" }} />
              </Box>
            ) : (
              "Generate and Copy Link"
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default ShareAssistantForm;
