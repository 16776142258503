import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import NewAssistantForm from '../components/NewAssistantForm';
import api from '../api';
import { capitalizeFirstLetter } from './utils/stringCapital';
import Loader from '../components/Loader';
import useToken from '../hooks/useToken';


const CreateAssistantFromTemplate = () => {
    const { template_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const token = useToken();

    useEffect(() => {
        const fetchTemplateData = async () => {
            if (!template_id || !token) {
                console.error('No template ID provided in URL or no token available');
                setError('No template ID provided or no token available');
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                const { data: { data } } = await api.get(`/v1/template/${template_id}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                });
                
                if (data) {
                    loadSavedValues(data);
                } else {
                    throw new Error('No data received from API');
                }
            } catch (error) {
                console.error('Error fetching template data:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchTemplateData();
        }
    }, [template_id, token]);

    const loadSavedValues = (data) => {
        // Save to localStorage
        const assistant = {
            _id: data._id,
            InitialMsg: data.initial_msg || '',
            Prompt: data.prompt || '',
            LLMProvider: data.llm_provider_id || '',
            LLMModel: data.llm_model_id || '',
            MaxToken: data.max_token || 1000,
            Temperature: data.temperature || 0.3,
            CallEndText: data.call_end_text || "",
            VoiceProvider: data.voice_provider_id || '',
            Voice: data.voice_id || '',
            VoiceLanguage: data.voice_language_id || '',
            TranscriberProvider: data.transcriber_provider_id || '',
            TranscriberLanguage: data.transcriber_language_id || '',
            Transcriber: data.transcriber_id || '',
            Cutoff: data.cutoff ? 1 : 0,
            EndConversationOnGoodbye: data.end_conversation_on_goodbye ? 1 : 0,
            FilterAudio: data.filter_audio || 1,
            IdealTime: data.ideal_time || 0,
            Interruption: data.interruption 
                ? capitalizeFirstLetter(data.interruption) 
                : '',
            NumOfHumanCheck: data.num_of_human_check || 0,
        };
        localStorage.setItem("assistant", JSON.stringify(assistant));
         localStorage.removeItem("Logo")
    };

    if (loading) {
        return (
           <Loader/>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }
    return (
                <Box>
                    <Navbar
                        title="Create Assistant"
                    />
                   <NewAssistantForm />
                </Box>
            )
};

export default CreateAssistantFromTemplate;
