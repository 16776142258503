// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assistantCardDetails {
    color: #fff !important;
}

.assistantCardDetails .boxHeading {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/styles/assistants.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,wBAAwB;IACxB,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".assistantCardDetails {\n    color: #fff !important;\n}\n\n.assistantCardDetails .boxHeading {\n    display: flex !important;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
