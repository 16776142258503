import api from "../../api";
import React, { useEffect, useState } from 'react';
import { Box, TextField, Select, MenuItem, FormControl, Typography, Slider, Grid, IconButton, Modal, Button } from '@mui/material';
import Loader from '../Loader';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import TooltipCustom from '../TooltipCustom';
import NoDataFound from "../NoDataFound";
import KnowledgeBaseUpload from './KnowledgeBaseUpload';
import GeneratePrompt from './GeneratePrompt';
import { useGetAllPermissions } from "../../pages/utils/getAllPermissions";
import { validateGoal } from '../../config/goalValidation';

const NewPrompt = () => {
    const [token, setToken] = useState("");
    const [temperature, setTemperature] = useState(0.3);
    const [firstMessage, setFirstMessage] = useState('');
    const [prompt, setPrompt] = useState('');
    const [llmProvider, setLlmProvider] = useState('');
    const [llmModel, setLlmModel] = useState('');
    const [knowledgeBase, setKnowledgeBase] = useState(null);
    const [maxToken, setMaxToken] = useState(1000);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [callEndText, setCallEndText] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [expandedPrompt, setExpandedPrompt] = useState('');
    const [llmProviderList, setLlmProviderList] = useState([]);
    const [llmList, setLlmList] = useState([]);
    const [assistantId, setAssistantId] = useState(null);
    const { permissions, permissionsLoading, getAllPermissions } = useGetAllPermissions();
    const [goalError, setGoalError] = useState('');
    const [AssistantGoal, setAssistantGoal] = useState('');

    useEffect(() => {
        getAllPermissions();
    }, []);

    // Check if user has required permissions
    const hasRequiredLLMPermissions = permissions?.includes("fetch_llm_model") && permissions?.includes("fetch_LLM_providers");

    useEffect(() => {
        const initialize = async () => {
            const t = JSON.parse(localStorage.getItem("token"))?.token;
            if (t) {
                setToken(t);
            }
        };
        initialize();
    }, []);

    // Only fetch data if user has required permissions
    useEffect(() => {
        const initializeData = async () => {
            if (hasRequiredLLMPermissions && token) {
                setLoading(true);
                try {
                    await fetchLLMProviders(token);
                    await loadSavedValues();
                    const storedAssistant = localStorage.getItem('assistant');
                        if (storedAssistant) {
                            const parsedAssistant = JSON.parse(storedAssistant);
                            if (parsedAssistant && parsedAssistant._id) {
                                setAssistantId(parsedAssistant._id);
                            }
                    }
                } catch (error) {
                    console.error("Error initializing data:", error);
                } finally {
                    setLoading(false);
                }
            }
        };

        initializeData();
    }, [token, hasRequiredLLMPermissions]);


    const loadSavedValues = () => {
        // Get the assistant object from localStorage or create a new one if it doesn't exist
        const assistant = JSON.parse(localStorage.getItem("assistant")) || {};

        // Set state with values from assistant or default values
        setFirstMessage(assistant.InitialMsg || '');
        setAssistantGoal(assistant.AssistantGoal || '');
        setPrompt(assistant.Prompt || '');
        setLlmProvider(assistant.LLMProvider || '');
        if (assistant.LLMProvider) {
            fetchLLMsList(assistant.LLMProvider);
        }
        setLlmModel(assistant.LLMModel || '');
        setMaxToken(assistant.MaxToken || 1000);
        setTemperature(assistant.Temperature || 0.3);
        setCallEndText(assistant.CallEndText || "");
        const savedKnowledgeBase = localStorage.getItem('KnowledgeBase');
        if (savedKnowledgeBase) {
            try {
                const parsedKnowledgeBase = JSON.parse(savedKnowledgeBase);
                // Check if name is an empty string or name doesn't exist
                if (!parsedKnowledgeBase.name || parsedKnowledgeBase.name.trim() === "") {
                    setKnowledgeBase(null);  // Show upload UI
                    localStorage.removeItem('KnowledgeBase');  // Clean up localStorage
                } else {
                    setKnowledgeBase({
                        name: parsedKnowledgeBase.name,
                        size: parsedKnowledgeBase.size,
                        type: parsedKnowledgeBase.type
                    });
                }
            } catch (error) {
                console.error('Error parsing knowledge base:', error);
                setKnowledgeBase(null);
                localStorage.removeItem('KnowledgeBase');
            }
        }
    };

    const saveToLocalStorage = (key, value) => {
        // Get the current assistant object or create a new one
        const assistant = JSON.parse(localStorage.getItem("assistant")) || {};
        assistant[key] = value;

        // Save the updated assistant object back to localStorage
        localStorage.setItem("assistant", JSON.stringify(assistant));


    };

    const handleKnowledgeBaseUpload = (fileData) => {
        setKnowledgeBase(fileData);
    };

    const handleKnowledgeBaseDelete = () => {
        setKnowledgeBase(null);
        localStorage.removeItem('KnowledgeBase');
    };

    const handleExpandClick = () => {
        setExpandedPrompt(prompt);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleSaveExpandedPrompt = () => {
        setPrompt(expandedPrompt);
        saveToLocalStorage("Prompt", expandedPrompt);
        setIsModalOpen(false);
    };

    const handleExpandedPromptChange = (e) => {
        setExpandedPrompt(e.target.value);
    };

    

    const handleSliderChange = (event, newValue) => {
        setTemperature(newValue);
        saveToLocalStorage("Temperature", newValue);
    };

    const fetchLLMProviders = async (token) => {
        if (!hasRequiredLLMPermissions) return;
        
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };

        const response = await api.get("/llm-provider?limit=999", config);
        setLlmProviderList(response.data.data.providers);
    };

    const fetchLLMsList = async (provider_id) => {
        if (!hasRequiredLLMPermissions) return;
        
        setLoading1(true);
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };
        try {
            const response = await api.get(`/llm/models/provider/${provider_id}`, config);
            setLlmList(response.data.data);
        } catch (error) {
            console.error("Error fetching LLM list:", error);
        } finally {
            setLoading1(false);
        }
    };

    // Early return if permissions are not met
    if (!hasRequiredLLMPermissions) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "70dvh" }}>
                <NoDataFound
                    title="Insufficient Permissions"
                    subtitle="You do not have the permissions to view assistants."
                />
            </Box>
        );
    }


    if (permissionsLoading || loading || loading1) {
        return <Loader />;
    }

    return (
        <Grid container spacing={2}>
            {/* Left Column */}
            <Grid item xs={6}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem", bgcolor: "rgba(177, 177, 190, 0.15)", padding: "1rem", borderRadius: "0.5rem" }}>
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>First Message <TooltipCustom title="The initial greeting or introductory message the assistant uses to engage with the user when a call or chat begins, setting the tone for the conversation." /></Typography>
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={firstMessage}
                            onChange={(e) => {
                                setFirstMessage(e.target.value);
                                saveToLocalStorage("InitialMsg", e.target.value); // Save first message to assistant in localStorage
                            }}
                            sx={{ bgcolor: "white" }}
                        />
                    </Box>
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>Prompt <TooltipCustom title=" Initial or ongoing input prompts used to guide the assistant’s responses. This field can be customized to shape the conversational style or inject specific responses to common queries." /></Typography>
                        <Box sx={{ position: 'relative', width: '100%' }}>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                value={prompt}
                                onChange={(e) => {
                                    setPrompt(e.target.value);
                                    saveToLocalStorage("Prompt", e.target.value); // Save prompt to assistant in localStorage
                                }}
                                multiline
                                rows={8}
                                sx={{ bgcolor: "white" }}
                            />
                            <IconButton 
                                onClick={handleExpandClick} 
                                sx={{ 
                                    position: 'absolute', 
                                    right: 8, 
                                    bottom: 8, 
                                    bgcolor: 'white',
                                    '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' }
                                }}
                            >
                                <ZoomOutMapIcon sx={{ fontSize: 24, color: '#02042d' }} />
                            </IconButton>
                        </Box>
                        <GeneratePrompt 
                            token={token}
                            setPrompt={setPrompt}  // Pass the setPrompt function
                            saveToLocalStorage={saveToLocalStorage}  // Pass the save function
                        />
                    </Box>
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>
                            Set Assistant Goal  <TooltipCustom title="Enter a clear, specific goal for this assistant (e.g., 'Increase customer satisfaction'). This will help guide the assistant's interactions and performance metrics." />
                        </Typography>
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={AssistantGoal}
                            aria-label="Assistant Goal Input"
                            onChange={(e) => {
                                const newValue = e.target.value;
                                const { isValid, errorMessage } = validateGoal(newValue);
                                
                                setAssistantGoal(newValue);
                                setGoalError(errorMessage);
                                
                                if (isValid) {
                                    saveToLocalStorage("AssistantGoal", newValue);
                                }
                            }}
                            error={!!goalError}
                            helperText={goalError}
                            placeholder="Enter assistant goal"
                            sx={{
                                '& .MuiInputBase-root': {
                                    bgcolor: 'white',
                                },
                                '& .MuiFormHelperText-root': {
                                    bgcolor: 'transparent',
                                    margin: 0,
                                    marginTop: '4px'
                                }
                            }}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1">End Message <TooltipCustom title="When this setting is active, Lia will automatically end the session if a user says goodbye or any similar farewell phrase. It streamlines the conversation flow by reducing unnecessary prompts after a clear end signal." /></Typography>
                        <TextField
                            size='small'
                            fullWidth
                            variant="outlined"
                            value={callEndText}
                            onChange={(e) => { setCallEndText(e.target.value); saveToLocalStorage("CallEndText", e.target.value) }}
                            sx={{ bgcolor: "white" }}
                        />
                    </Box>
                </Box>
            </Grid>
        <Grid item xs={6}>

            <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem", bgcolor: "rgba(177, 177, 190, 0.15)", padding: "1rem", borderRadius: "0.5rem" }}>
                <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>Provider <TooltipCustom title="The platform supplying the Large Language Model that powers the assistant’s responses, impacting conversation style and accuracy." /></Typography>
                    <FormControl fullWidth size='small'>
                        <Select
                            value={llmProvider}
                            onChange={(e) => {
                                setLlmProvider(e.target.value);
                                saveToLocalStorage("LLMProvider", e.target.value); // Save LLM provider to assistant in localStorage
                                fetchLLMsList(e.target.value);
                            }}
                            sx={{ bgcolor: "white" }}
                        >
                            {
                                llmProviderList.map((provider, key) => (
                                    <MenuItem value={provider._id} key={key}>{provider.Name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>Model <TooltipCustom title="The specific language model used to generate the assistant’s responses, which impacts the assistant's conversational abilities, accuracy, and response quality." /></Typography>
                    <FormControl fullWidth size='small'>
                        <Select
                            value={llmModel}
                            onChange={(e) => {
                                setLlmModel(e.target.value);
                                saveToLocalStorage("LLMModel", e.target.value); // Save LLM model to assistant in localStorage

                            }}
                            sx={{ bgcolor: "white" }}
                        >
                            {
                                llmList.map((llm, key) => (
                                    <MenuItem value={llm._id} key={key}>{llm.Name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100 }}>Knowledge Base File <TooltipCustom title="An option to upload a document or data file that contains reference material or specific knowledge the assistant can use to improve response accuracy and provide detailed information." /></Typography>
                        <KnowledgeBaseUpload
                            existingFile={knowledgeBase}
                            onFileUpload={handleKnowledgeBaseUpload}
                            onFileDelete={handleKnowledgeBaseDelete}
                            assistantId={assistantId}
                        />
                    </Box>
                <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>Max Token <TooltipCustom title=" The maximum number of tokens (words, parts of words, punctuation) the assistant can use in a single response. This helps manage response length and resource usage." /></Typography>
                    <TextField
                        size='small'
                        fullWidth
                        variant="outlined"
                        value={maxToken}
                        onChange={(e) => {
                            setMaxToken(e.target.value);
                            saveToLocalStorage("MaxToken", e.target.value); // Save max token to assistant in localStorage
                        }}
                        sx={{ bgcolor: "white" }}
                    />
                </Box>
                <Box sx={{ mb: 2, display: 'flex', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ minWidth: 100 }}>Temperature <TooltipCustom title="A setting that determines the randomness or creativity in the assistant’s responses. Lower values make responses more deterministic, while higher values introduce variability." /></Typography>
                    <Box display="flex" alignItems="center">
                        <Slider
                            value={temperature}
                            onChange={handleSliderChange}
                            min={0}
                            max={1}
                            step={0.1}
                            valueLabelDisplay="auto"
                            sx={{
                                color: "#02042D",
                            }}
                        />
                        <div style={{ backgroundColor: "#02042D", color: "white", borderRadius: "8px", marginLeft: "1rem", width: "2rem", height: "2rem", display: "flex", alignItems: "center", justifyContent: "center", }}>
                            {temperature}
                        </div>
                    </Box>
                </Box>
            </Box>
        </Grid>
        <Modal
                open={isModalOpen}
                onClose={handleModalClose}
                aria-labelledby="expanded-prompt-modal"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 700,
                    bgcolor: 'white',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography variant="h6" component="h2" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Prompt
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={16}
                        value={expandedPrompt}
                        onChange={handleExpandedPromptChange}
                        variant="outlined"
                        sx={{ mb: 2 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                        <Button 
                            onClick={handleModalClose} 
                            variant="outlined"
                            sx={{ 
                                color: '#0F0B46', 
                                borderColor: '#0F0B46',
                                '&:hover': {
                                    backgroundColor: 'rgba(15, 11, 70, 0.04)',
                                    borderColor: '#0F0B46',
                                },
                            }}
                        >
                            Back
                        </Button>
                        <Button 
                            onClick={handleSaveExpandedPrompt} 
                            variant="contained"
                            sx={{ 
                                bgcolor: '#0F0B46', 
                                '&:hover': { bgcolor: '#1A1652' },
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Modal>
    </Grid>
    );
};

export default NewPrompt;
