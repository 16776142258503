import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import { Avatar, Box, Button, Chip, FormControl, Grid, IconButton, MenuItem, Modal, Select, TextField, Typography,InputAdornment } from '@mui/material';
import api from "../../api";
import { toast } from "sonner";
import { useParams } from 'react-router-dom';
import { Close, Edit, FilterList, ReportProblem} from '@mui/icons-material';
import CallIcon from '@mui/icons-material/Call';
import { formatDate, formatDuration } from '../../components/Format';
import DataCard from '../../components/DataCard';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { ChartContainer } from '../../components/Chart';
import ClipboardBox from '../../components/ClipboardBox';
import Loader from '../../components/Loader';
import { CustomRadarChart } from '../../components/CustomRadarChart';
import { downloadAIToHumanTransferCSV, downloadAvgDurationByIssueTypeCSV, downloadCallAnalysisReport, downloadFirstCallResolutionCSV, downloadPeakHourTrafficCSV, downloadTotalSpentCSV } from "../../components/download";

const CustomTick = ({ x, y, payload, width }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <foreignObject x={-width / 2} y={0} width={width} height={100}>
                <div style={{
                    width: `${width - 10}px`,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',  // Ensures word wrapping
                    textAlign: 'center',
                    fontSize: 12
                }}>
                    {payload.value}
                </div>
            </foreignObject>
        </g>
    );
};

const AssistantDetailAdmin = () => {
    const [assistantDetails, setAssistantDetails] = useState({});
    const [AssistantOverviewData, setAssistantOverviewData] = useState({});
    const [filter, setFilter] = useState("weekly");
    const [open, setOpen] = useState(false);
    const [logo, setLogo] = useState(null);
    const [assistantCategory, setAssistantCategory] = useState("");
    const [assistantName, setAssistantName] = useState("");
    const [assistantType, setAssistantType] = useState("");
    const [loading, setLoading] = useState(false);
    const token = JSON.parse(localStorage.getItem("token"))?.token;
    const { id, company_id } = useParams();
    const [assistantDescription, setAssistantDescription] = useState('');
    const [validationError, setValidationError] = useState(false);


    const formatDurationToHHMMSS = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    
    const getAssistant = useCallback(async () => {
        try {
            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };
            const response = await api.get(`/assistants/${id}`, config);
            setAssistantDetails(response.data.assistant);
            setLogo(response.data.assistant.Logo?.fileUrl);
            setAssistantName(response.data.assistant.Name);
            setAssistantType(response.data.assistant.Type);
            setAssistantCategory(response.data.assistant.Category);
            setAssistantDescription(response.data.assistant.Description);
        } catch (error) {
            toast.error("Error While Fetching assistant:", error);
            console.error("Error fetching assistant:", error);
        }
    }, [token, id]);

    const getAssistantOverview = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.get(`/call-logs/assistant-metrics/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        filter: filter,
                        organizationId: company_id,
                    }
                }
            );

            setAssistantOverviewData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching assistant overview:", error);
            setLoading(false);
        }
    }, [filter, id, token]);

    const handleLogoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogo(file);
            setLogo(URL.createObjectURL(file));
        }
    };

    const isIncrease = (percentageChange) => {
        return percentageChange && parseFloat(percentageChange) >= 0;
    };

    const handleAssistantProfileUpdate = async () => {
        try {
            const config = {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                }
            };

            const formData = new FormData();
            formData.append("Name", assistantName);
            formData.append("Type", assistantType);
            formData.append("Category", assistantCategory);
            formData.append("Description",assistantDescription);

            if (logo) {
                formData.append("logo", logo);
            }

            const response = await api.patch(`/v1/assistant/update/${id}`, formData, config);
            getAssistant();
            setOpen(false);
            toast.success(response.data?.message);
        } catch (error) {
            const errorMessage = error.response?.data?.details;
            toast.error(errorMessage);
            console.error("Error updating assistant:", errorMessage);
        }
    };

    useEffect(() => {
        if (token) {
            getAssistant();
            getAssistantOverview();
        }
    }, [getAssistant, getAssistantOverview, token]);

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Navbar title="Assistant Overview" />

            <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "1rem" }}>

                <Box sx={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                    <Box style={{ display: 'flex', columnGap: 10, alignItems: 'center', width: '100%' }} >
                        <Avatar
                            alt={assistantDetails?.Name}
                            src={assistantDetails.Logo?.fileUrl}
                            sx={{ width: 50, height: 50 }}
                        />
                        <Box>
                            <Typography variant="h6">
                                <Box style={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                                    <Box style={{
                                        maxWidth: '40vw',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {assistantDetails.Name}
                                        <Edit
                                            fontSize="small"
                                            sx={{ marginLeft: "0.5rem", cursor: "pointer" }}
                                            onClick={() => {
                                                setOpen(true)
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Typography>

                            <Box sx={{ display: "flex", alignItems: "center", columnGap: ".5rem" }}>
                                <ClipboardBox id={assistantDetails._id} />
                                <Chip
                                    variant="outlined"
                                    size="small"
                                    label={assistantDetails.Category}
                                    sx={{ color: "#02042D", borderColor: "#02042D", borderRadius: "8px", textTransform: "capitalize", fontSize: "1rem" }}
                                />
                                <Chip
                                    variant="outlined"
                                    size="small"
                                    label={assistantDetails.Type}
                                    sx={{ color: "#02042D", borderColor: "#02042D", borderRadius: "8px", textTransform: "capitalize", fontSize: "1rem" }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box>

                    <FormControl size='small' sx={{ width: "8rem", bgcolor: "#FFF", height: 'fit-content' }}>
                        <Select
                            labelId="filter"
                            id="filter"
                            value={filter}
                            label="Filter"
                            onChange={(e) => setFilter(e.target.value)}
                            startAdornment={<FilterList />}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="yearly">Yearly</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={3}>
                        <DataCard
                            title="Total Calls"
                            value={AssistantOverviewData.totalCalls?.totalCalls}
                            icons={<CallIcon />}
                            change={AssistantOverviewData.totalCalls?.percentageChange}
                            inc={isIncrease(AssistantOverviewData.totalCalls?.percentageChange)}
                            subtitle="Total number of calls across the selected assistants over the selected time period."
                            chart={
                                <ResponsiveContainer width={"100%"} height={"90%"}>
                                    <AreaChart data={AssistantOverviewData.totalCalls?.dataPoints} >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={formatDate}
                                            interval="preserveStartEnd"
                                            minTickGap={50}
                                            angle={-45}
                                            textAnchor="end"
                                            height={100}
                                        />
                                        <YAxis
                                            tickFormatter={(value) => (value)}
                                        />
                                        <Tooltip labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                            formatter={(value) => [(value), 'Total Calls']} />
                                        <Area
                                            type="monotone"
                                            dataKey="totalCalls"
                                            stroke="#02042D"
                                            fill="#A5A8E6"
                                            fillOpacity={0.3}
                                            activeDot={{ r: 4 }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <DataCard
                            title="Average Handle Time (sec)"
                            value={AssistantOverviewData.avgCallMinutes?.avgDuration}
                            icons={<AccessTimeFilledIcon />}
                            change={AssistantOverviewData.avgCallMinutes?.percentageChange}
                            inc={isIncrease(AssistantOverviewData.avgCallMinutes?.percentageChange)}
                            chart={
                                <ResponsiveContainer width={"100%"} height={"90%"}>
                                    <AreaChart data={AssistantOverviewData.avgCallMinutes?.dataPoints} >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={formatDate}
                                            interval="preserveStartEnd"
                                            minTickGap={50}
                                            angle={-45}
                                            textAnchor="end"
                                            height={100}
                                            width={110}
                                        />
                                        <YAxis
                                            tickFormatter={(value) => (value)}
                                        />
                                        <Tooltip
                                            labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                            formatter={(value) => [formatDuration(value), 'Average Handle Time']}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="avgDuration"
                                            stroke="#02042D"
                                            fill="#A5A8E6"
                                            fillOpacity={0.3}
                                            activeDot={{ r: 4 }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>

                        <DataCard
                            title="Abandonment Rate"
                            value={`${AssistantOverviewData?.abandonmentRate?.rate || 0}%`}
                            icons={<ReportProblem />}
                            change={AssistantOverviewData.abandonmentRate?.percentageChange}
                            inc={isIncrease(AssistantOverviewData.abandonmentRate?.percentageChange)}
                            chart={
                                <ResponsiveContainer width={"100%"} height={"90%"}>
                                    <AreaChart data={AssistantOverviewData.abandonmentRate?.dataPoints} >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={formatDate}
                                            interval="preserveStartEnd"
                                            minTickGap={50}
                                            angle={-45}
                                            textAnchor="end"
                                            height={100}
                                        />
                                        <YAxis
                                            tickFormatter={(value) => (value)}
                                        />
                                        <Tooltip
                                            labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                            formatter={(value) => [(value), 'Abandonment Rate']}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="abandonmentRate"
                                            stroke="#02042D"
                                            fill="#A5A8E6"
                                            fillOpacity={0.3}
                                            activeDot={{ r: 4 }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <DataCard
                            title="Total Cost"
                            value={AssistantOverviewData.totalCost?.total}
                            icons={<AttachMoneyIcon />}
                            change={AssistantOverviewData.totalCost?.percentageChange}
                            inc={isIncrease(AssistantOverviewData.totalCost?.percentageChange)}
                            subtitle="Total credits spent per call aggregated over the selected period."
                            chart={
                                <ResponsiveContainer width={"100%"} height={"90%"}>
                                    <AreaChart data={AssistantOverviewData.totalCost?.dataPoints} >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={formatDate}
                                            interval="preserveStartEnd"
                                            minTickGap={50}
                                            angle={-45}
                                            textAnchor="end"
                                            height={100}
                                        />
                                        <YAxis
                                            tickFormatter={(value) => (value)}
                                        />
                                        <Tooltip
                                            labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                            formatter={(value) => [`$ ${value}`, 'Total Cost']}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="totalCost"
                                            stroke="#02042D"
                                            fill="#A5A8E6"
                                            fillOpacity={0.3}
                                            activeDot={{ r: 4 }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        />
                    </Grid>

                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <ChartContainer
                            title="Call Analysis"
                            subtitle="Insights on call performance on various factors."
                            download={() => downloadCallAnalysisReport({ filter, assistant: id, organization: company_id })}
                            chart={
                                AssistantOverviewData.callAnalysis?.transferedResult || AssistantOverviewData.callAnalysis?.goalAchievementResult || AssistantOverviewData.callAnalysis?.userSatisfactionResult || AssistantOverviewData.callAnalysis?.scriptAdherenceResult || AssistantOverviewData.callAnalysis?.callAbandonmentResult ?
                                    <CustomRadarChart data={[
                                        { name: "Call Transferred", value: AssistantOverviewData.callAnalysis?.transferedResult || 0 },
                                        { name: "Goal Achievement", value: AssistantOverviewData.callAnalysis?.goalAchievementResult || 0 },
                                        { name: "User Satisfaction", value: AssistantOverviewData.callAnalysis?.userSatisfactionResult || 0 },

                                        { name: "SOP Adherence (%)", value: AssistantOverviewData.callAnalysis?.scriptAdherenceResult || 0 },
                                        { name: "Call Abandonment", value: (AssistantOverviewData.callAnalysis?.callAbandonmentResult || 0).toFixed(2) },

                                    ]}
                                    /> :
                                    "No data found for applied filters."
                            }
                        />


                    </Grid>

                    <Grid item xs={12} md={6}>
                        <ChartContainer
                            title={"Total Spent ($)"}
                            subtitle="Total spending view on call operations for budgeting and cost tracking."
                            download={() => downloadTotalSpentCSV({ filter, assistant: id, organization: company_id })}
                            chart={
                                AssistantOverviewData.totalCost?.dataPoints?.length > 0 ?
                                    <AreaChart data={AssistantOverviewData.totalCost?.dataPoints}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={formatDate}
                                            interval="preserveStartEnd"
                                            minTickGap={50}
                                            angle={-50}
                                            textAnchor="end"
                                            height={100}
                                        />
                                        <YAxis
                                            tickFormatter={(value) => (value)}
                                        />
                                        <Tooltip
                                            formatter={(value) => [`$ ${value.toFixed(2)}`, 'Total Cost']}
                                            labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                            contentStyle={{ backgroundColor: '#FFF', borderColor: '#CCC' }}
                                            itemStyle={{ color: '#02042D' }}
                                            cursor={{ stroke: '#02042D', strokeWidth: 1 }}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="totalCost"
                                            stroke="#02042D"
                                            fill="#A5A8E6"
                                            fillOpacity={1}
                                            dot={false}
                                            activeDot={true}
                                        />
                                    </AreaChart>
                                    :
                                    "No data found for applied filters."
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <ChartContainer
                            title={"First Call Resolution"}
                            subtitle="Percentage of issues resolved on the first call to enhance customer satisfaction."
                            download={() => downloadFirstCallResolutionCSV({ filter, assistant: id, organization: company_id })}
                            chart={
                                Object.keys(AssistantOverviewData).length > 0 ?
                                    <PieChart>
                                        <Pie
                                            data={[
                                                {
                                                    name: "Resolved",
                                                    value: AssistantOverviewData.firstCallResolution.firstCallResolutionCount
                                                },
                                                {
                                                    name: "Not Resolved",
                                                    value: AssistantOverviewData.firstCallResolution.nonFirstCallResolutionCount
                                                }

                                            ]}
                                            innerRadius={50}
                                            outerRadius={80}
                                            fill="#AAA"
                                            dataKey="value"
                                        >
                                            {["#02042D", "#CCCCCC"].map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={["#02042D", "#CCCCCC"][index]} />
                                            ))}
                                        </Pie>
                                        <Legend />
                                        <Tooltip />
                                    </PieChart>
                                    : "No data found for applied filters."
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <ChartContainer
                            title={"AI to Human Handover Rate"}
                            subtitle="Rate of interactions transferred from AI to human agents for resolution."
                            download={() => downloadAIToHumanTransferCSV({ filter, assistant: id, organization: company_id })}
                            chart={
                                AssistantOverviewData?.aiToHumanTransfer?.transferred || AssistantOverviewData?.aiToHumanTransfer?.notTransferred ?
                                    <PieChart>
                                        <Pie
                                            data={[
                                                { name: "Transferred", value: AssistantOverviewData?.aiToHumanTransfer?.transferred },
                                                { name: "Not Transferred", value: AssistantOverviewData?.aiToHumanTransfer?.notTransferred }
                                            ]}
                                            innerRadius={50}
                                            outerRadius={80}
                                            fill="#AAA"
                                            dataKey="value"
                                        >
                                            {["#02042D", "#CCCCCC"].map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={["#02042D", "#CCCCCC"][index]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                    : "No data found for applied filters."
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <ChartContainer
                            title={"Peak Hours Traffic"}
                            subtitle="Call volume trends by hour for optimal staffing"
                            download={() => downloadPeakHourTrafficCSV({ filter, assistant: id, organization: company_id })}
                            chart={
                                <ResponsiveContainer width={"100%"} height={"90%"}>

                                    {
                                        AssistantOverviewData?.peakHourTraffic?.length > 0 ?
                                            <AreaChart data={AssistantOverviewData?.peakHourTraffic} width={500} height={300}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="hour">
                                                    <Label value="Time (24 Hours)" position="insideBottom" offset={-5} />
                                                </XAxis>
                                                <YAxis>
                                                    <Label value="Number of calls" angle={-90} style={{ textAnchor: "middle" }} offset={10} />
                                                </YAxis>
                                                <Tooltip />
                                                <Area type="monotone" dataKey="callCount" stroke="#02042D" fill="#A5A8E6" />
                                            </AreaChart>

                                            : "No data found for applied filters."
                                    }
                                </ResponsiveContainer>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <ChartContainer
                        title={"Average Handle Time by Issue Type"}
                        subtitle="Average resolution time per issue type."
                        download={() => downloadAvgDurationByIssueTypeCSV({ filter, assistant: id, organization: company_id })}
                        chart={
                            AssistantOverviewData?.avgCallMinutesByIssueType?.length > 0 ? (
                                <BarChart data={AssistantOverviewData?.avgCallMinutesByIssueType} width={500} height={300}>
                                    <CartesianGrid stroke="#f5f5f5" />
                                    <XAxis
                                        dataKey="issueType"
                                        height={100}
                                        tick={<CustomTick width={400 / AssistantOverviewData.avgCallMinutesByIssueType.length} />}
                                    />
                                    <YAxis>
                                        <Label
                                            value="Avg. Duration (hh:mm:ss)"
                                            angle={-90}
                                            position="insideLeft"
                                            style={{ textAnchor: "middle" }}
                                        />
                                    </YAxis>
                                    <Tooltip
                                        formatter={(value, name) => [
                                            formatDurationToHHMMSS(value), // Call the formatting function
                                            "Average Duration",
                                        ]}
                                        cursor={{ fill: "transparent" }}
                                    />
                                    <Bar
                                        dataKey="avgDuration"
                                        fill="#02042D"
                                        label={({ value }) => formatDurationToHHMMSS(value)} // Display formatted label
                                    />
                                </BarChart>
                            ) : (
                                "No data found for applied filters."
                            )
                        }
                    />

                    </Grid>

                </Grid >
            </Box>

            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={{ display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>
                    <Box sx={{ width: "30rem", bgcolor: "#FFF", padding: "2rem", borderRadius: "8px" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h5">Update Assistant Profile</Typography>
                            <Close onClick={() => setOpen(false)} />
                        </Box>
                        
                        <Box sx={{ display: "flex", justifyContent: "center", margin: "1rem 0" }}>
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="logo-upload"
                                            type="file"
                                            onChange={handleLogoUpload}
                                        />
                                        <label htmlFor="logo-upload">
                                            <IconButton component="span">
                                                <Avatar
                                                    alt={assistantDetails.Name}
                                                    src={logo}
                                                    sx={{ width: 80, height: 80 }}
                                                />
                                            </IconButton>
                                        </label>
                                    </Box>

                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                    <TextField
                                        label="Assistant Name"
                                        fullWidth
                                        variant="outlined"
                                        value={assistantName}
                                        onChange={(e) => setAssistantName(e.target.value)}
                                        size="small"
                                    />
                                    <FormControl fullWidth>
                                        <Select
                                        value={assistantType}
                                        onChange={(e) => setAssistantType(e.target.value)}
                                        size="small"
                                        >
                                        <MenuItem value="inbound">Inbound</MenuItem>
                                        <MenuItem value="outbound">Outbound</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <Select
                                        value={assistantCategory}
                                        onChange={(e) => setAssistantCategory(e.target.value)}
                                        size="small"
                                        >
                                        <MenuItem value="Sales">Sales</MenuItem>
                                        <MenuItem value="Customer Support">Customer Support</MenuItem>
                                        <MenuItem value="IT Support">IT Support</MenuItem>
                                        <MenuItem value="Human Resources">Human Resources</MenuItem>
                                        <MenuItem value="Healthcare / Service Industry">Healthcare / Service Industry</MenuItem>
                                        <MenuItem value="Banking Support">Banking Support</MenuItem>
                                        </Select>
                                    </FormControl>
                                    
                                    <TextField
                                        label="Description"
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        maxRows={4}
                                        value={assistantDescription}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setAssistantDescription(value);
                                            setValidationError(value.length < 50);
                                        }}
                                        size="small"
                                        error={validationError}
                                        helperText={
                                            validationError
                                            ? "Description must be at least 50 characters long."
                                            : ""
                                        }
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                            '&.Mui-disabled': {
                                                backgroundColor: '#f8f9fa',
                                            },
                                            },
                                        }}
                                        />

                                    <Button 
                                        variant="contained" 
                                        sx={{ bgcolor: "#02042D", color: "#FFF" }} 
                                        onClick={() => {
                                            handleAssistantProfileUpdate();
                                          }}
                                    disabled={loading}
                                    >
                                        {loading ? "Saving..." : "Save Changes"}
                                    </Button>
                                    </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default AssistantDetailAdmin;
