import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Typography,
  IconButton,
  Avatar,
  Button
} from '@mui/material';
import {
  CallEnd as CallEndIcon,
  Call as CallIcon
} from '@mui/icons-material';
import { MicOff } from "@mui/icons-material";
import MicIcon from '@mui/icons-material/Mic';
import { toast } from 'sonner';

const WebCallDrawer = ({ open, onClose, avatar, name, wsUrl, callConfig, handleWebCall }) => {
  const [callState, setCallState] = useState({
    isConnected: false,
    isCallActive: false,
    isUserSpeaking: false,
    isBotSpeaking: false,
    isMuted: false,
    error: null,
});
const [isButtonClicked, setIsButtonClicked] = useState(false);

// Refs for WebSocket and audio handling
const wsRef = useRef(null);
const mediaStreamRef = useRef(null);
const mediaRecorderRef = useRef(null);
const audioContextRef = useRef(null);
const audioQueueRef = useRef([]);
const isPlayingRef = useRef(false);

// Initialize WebSocket connection
useEffect(() => {
    console.log(open , wsUrl , isButtonClicked , callConfig);
    if (open && wsUrl && isButtonClicked && callConfig) {
      initializeWebSocket();
    }
    else {
    return () => cleanupConnection();
    }

  }, [open, wsUrl, isButtonClicked, callConfig]);


// Initialize audio context
useEffect(() => {
    if (open) {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        return () => {
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }
}, [open]);

const initializeWebSocket = () => {
  try {
    wsRef.current = new WebSocket(wsUrl);
    let isConnectionSuccessful = false; // Flag to track connection status

    // Start a timer to track connection timeout
    const connectionTimeout = setTimeout(() => {
      if (!isConnectionSuccessful) {
        console.error(
          "WebSocket connection failed to establish within the timeout."
        );
        setCallState((prev) => ({
          ...prev,
          isConnected: false,
          error: "Connection timeout",
        }));
        setIsButtonClicked(false);
        if (
          wsRef.current &&
          wsRef.current.readyState !== WebSocket.CLOSED
        ) {
          wsRef.current.close();
        }
      }
    }, 5000); // Wait for 5 seconds

    wsRef.current.onopen = async () => {
      isConnectionSuccessful = true; // Mark connection as successful
      clearTimeout(connectionTimeout); // Clear the timeout
      setCallState((prev) => ({ ...prev, isConnected: true }));
      toast.success("Webcall initiated successfully!");
      if (callConfig) {
        wsRef.current.send(
          JSON.stringify({
            action: "start_call",
            data: callConfig,
          })
        );
        await initializeAudioCapture();
      }
    };

    wsRef.current.onerror = (error) => {
      clearTimeout(connectionTimeout); // Clear the timeout
      console.error("WebSocket error:", error);
      setCallState((prev) => ({
        ...prev,
        isConnected: false,
        error: "Connection error occurred",
      }));
      if (!isConnectionSuccessful) {
        toast.error(
          "Connection failed. Please check your network or server settings."
        );
      }
    };

    wsRef.current.onclose = () => {
      clearTimeout(connectionTimeout); // Clear the timeout
      setCallState((prev) => ({
        ...prev,
        isConnected: false,
        isCallActive: false,
      }));
      if (isConnectionSuccessful) {
        toast.info("Connection closed successfully!");
      }
    };

    wsRef.current.onmessage = handleWebSocketMessage;
  } catch (error) {
    console.error("WebSocket initialization error:", error);
    toast.error(
      "Unable to connect. Please check your network and try again."
    );
    setCallState((prev) => ({
      ...prev,
      error: "Failed to initialize connection",
    }));
  }
};

const handleWebSocketMessage = async (event) => {
    try {
        const message = JSON.parse(event.data);

        switch (message.type) {
            case 'call_started':
                setCallState(prev => ({
                    ...prev,
                    isCallActive: true,
                }));
                break;

            case 'assistant_audio_chunk':
                if (message.chunk) {
                    const audioData = base64ToUint8Array(message.chunk);
                    await playAudioChunk(audioData);
                }
                break;

            case 'speaking_status':
                handleSpeakingStatus(message.status);
                break;

            case 'error':
                console.error('⚠️ Received error from server:', message.message);
                setCallState(prev => ({
                    ...prev,
                    error: message.message,
                }));
                break;

            default:
        }
    } catch (error) {
        console.error('❌ Error handling WebSocket message:', error);
    }
};


const initializeAudioCapture = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: {
                channelCount: 1,
                sampleRate: 16000,
                echoCancellation: true,
                noiseSuppression: true,
            }
        });

        mediaStreamRef.current = stream;

        // Create AudioContext to handle format conversion
        const audioContext = new AudioContext({
            sampleRate: 16000,
        });

        const source = audioContext.createMediaStreamSource(stream);
        const processor = audioContext.createScriptProcessor(1024, 1, 1);

        processor.onaudioprocess = (e) => {
            if (wsRef.current?.readyState === WebSocket.OPEN) {
                const inputData = e.inputBuffer.getChannelData(0);
                // Convert Float32Array to Int16Array
                const pcmData = new Int16Array(inputData.length);
                for (let i = 0; i < inputData.length; i++) {
                    pcmData[i] = inputData[i] * 32767;
                }

                // Send as base64
                const base64Audio = btoa(String.fromCharCode(...new Uint8Array(pcmData.buffer)));
                wsRef.current.send(JSON.stringify({
                    type: 'human_audio_chunk',
                    chunk: base64Audio
                }));
            }
        };

        source.connect(processor);
        processor.connect(audioContext.destination);

    } catch (error) {
        console.error('Audio capture initialization failed:', error);
        setCallState(prev => ({
            ...prev,
            error: 'Microphone access denied',
        }));
    }
};

const base64ToUint8Array = (base64) => {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
};

const playAudioChunk = async (audioData) => {
    setCallState(prev => ({
        ...prev,
        isUserSpeaking: false,
        isBotSpeaking: true,
    }));
    // Ensure the AudioContext is running (resume if suspended)
    if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
    }
    if (!audioContextRef.current) {
        console.warn('⚠️ AudioContext not initialized, skipping playback');
        return;
    }

    audioQueueRef.current.push(audioData);

    if (isPlayingRef.current) {
        return;
    }

    isPlayingRef.current = true;

    try {
        while (audioQueueRef.current.length > 0) {
            const nextChunk = audioQueueRef.current.shift();

            const audioBuffer = await audioContextRef.current.decodeAudioData(nextChunk.buffer);

            await new Promise((resolve, reject) => {
                const source = audioContextRef.current.createBufferSource();
                source.buffer = audioBuffer;
                source.connect(audioContextRef.current.destination);
                source.onended = () => {
                    resolve();
                };
                source.start(0);
            }); 
        }
    } catch (error) {
        console.error('❌ Error during audio playback:', error);
    } finally {
        isPlayingRef.current = false;
    }

    setCallState(prev => ({
        ...prev,
        isUserSpeaking: true,
        isBotSpeaking: false,
    }));
};

const handleSpeakingStatus = async (status) => {
    setCallState(prev => ({
        ...prev,
        isUserSpeaking: status !== 'bot_started',
        isBotSpeaking: status === 'bot_started',
    }));
};

const handleMuteToggle = () => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
        const newMutedState = !callState.isMuted;
        wsRef.current.send(JSON.stringify({
            action: 'mute',
            value: newMutedState,
        }));
        setCallState(prev => ({
            ...prev,
            isMuted: newMutedState,
        }));

        if (newMutedState) {
            stopAudioCapture();
        } else {
            initializeAudioCapture();
        }

    }
};

const handleEndCall = () => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({
        action: 'end_call'
      }));
    }
    setCallState(prev => ({
      ...prev,
      isConnected: false,
      isCallActive: false,
    }));
    setIsButtonClicked(false);
    cleanupConnection();
    toast.info("Call ended");
  };


const stopAudioCapture = () => {
    if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach(track => track.stop());
        mediaStreamRef.current = null;
    }
    if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
    }
}

const cleanupConnection = () => {
    if (mediaRecorderRef.current?.state === 'recording') {
        mediaRecorderRef.current.stop();
    }

    if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach(track => track.stop());
    }

    if (wsRef.current) {
        wsRef.current.close();
    }

    setCallState({
        isConnected: false,
        isCallActive: false,
        isUserSpeaking: false,
        isBotSpeaking: false,
        isMuted: false,
        error: null,
    });
}

const glowingBorderStyle = {
    borderRadius: '50%',
    width: '10rem',
    height: '10rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    animation: callState.isBotSpeaking ? 'pulse 1.5s infinite ease-in-out' : 'none',
};

const handleStartCall = () => {
  handleWebCall();
  setIsButtonClicked(true);
};


useEffect(() => {
    const styleSheet = document.styleSheets[0];
    const keyframes =
        `@keyframes pulse {
            0% {
                box-shadow: 0 0 0 0 rgba(2, 4, 45, 0.7);
            }
            50% {
                box-shadow: 0 0 15px 10px rgba(2, 4, 45, 0.3);
            }
            100% {
                box-shadow: 0 0 0 0 rgba(2, 4, 45, 0.7);
            }
        }`;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
}, []);
  

  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        height: '100%',
        gap: 4,
        position: 'relative'
    }}>
     {!callState.isCallActive ? (
        // Start Call Button
        <>
        <Box
        sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        height: 'auto',
        gap: 2,
        position: 'relative'
        }}
        >
        <div style={glowingBorderStyle}>
        <Avatar
            src={avatar}
            sx={{
                width: '10rem',
                height: '10rem',
                border: '4px solid white',
                position: 'relative',
                zIndex: 1
            }}
        />
        </div>
        <Typography variant="h6" sx={{ position: 'relative', zIndex: 1 }}>{name}</Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            startIcon={<CallIcon />}
            onClick={handleStartCall}
            sx={{
              backgroundColor: '#02042D',
              color: 'white',
              borderRadius: '24px',
              padding: '10px 24px',
              '&:hover': {
                backgroundColor: '#02042D',
                opacity: 0.9,
              },
            }}
          >
            Start Call with {name}
          </Button>
        </Box>
        </>
      ) : (
        <>
        <div style={glowingBorderStyle}>
        <Avatar
            src={avatar}
            sx={{
                width: '10rem',
                height: '10rem',
                border: '4px solid white',
                position: 'relative',
                zIndex: 1
            }}
        />
        </div>
        <Typography variant="h6" sx={{ position: 'relative', zIndex: 1 }}>{name}</Typography>
        
        <Box sx={{ display: 'flex', gap: 3, position: 'relative', zIndex: 1 }}>
            <IconButton
                onClick={handleMuteToggle}
                style={{
                    width: "3rem",
                    height: "3rem",
                    backgroundColor: callConfig ? "#02042D" : "#0000ff",
                    color: "#ffffff",
                    opacity: "0.9",
                    transition: "opacity 0.3s ease-in-out",
                }}
            >
                {callState.isMuted ? <MicOff /> : <MicIcon />}
            </IconButton>
            <IconButton
                style={{
                    width: "3rem",
                    height: "3rem",
                    backgroundColor: "#ff0000",
                    color: "#ffffff",
                    opacity: "0.9",
                    transition: "opacity 0.3s ease-in-out",
                }}
                onClick={() => { onClose(); handleEndCall(); }}
            >
                <CallEndIcon />
            </IconButton>
        </Box>
        </>
    )}
    </Box>
  );
};

export default WebCallDrawer;