import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import { Box, FormControl, Grid, InputLabel, Menu,Collapse, 
    IconButton, MenuItem, Select, Table,TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { FilterList, AnalyticsOutlined, MoreVert } from '@mui/icons-material';
import { Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid, AreaChart, Area } from "recharts";
import api from "../../api";
import { ChartContainer } from '../../components/Chart';
import { formatDate } from '../../components/Format';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { downloadAssistantPerformanceCSV, downloadAvgDurationByIssueTypeCSV, downloadCallAnalysisReport, downloadTotalSpentCSV } from '../../components/download';
import { CustomRadarChart } from '../../components/CustomRadarChart';
import { Label } from 'recharts';
import { 
    KeyboardArrowDown,
    KeyboardArrowUp 
} from '@mui/icons-material';
import ShimmerLoader from '../../components/ShimmerLoader';
import SetPassword from '../../models/SetPassword';

const CustomTick = ({ x, y, payload, width }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <foreignObject x={-width / 2} y={0} width={width} height={100}>
                <div style={{
                    width: `${width - 10}px`,
                    whiteSpace: 'break-spaces',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',  // Ensures word wrapping
                    textAlign: 'center',
                    fontSize: 10
                }}>
                    {payload.value}
                </div>
            </foreignObject>
        </g>
    );
};

// Helper function to update loading states
const setAllLoading = (prevState, isLoading) => {
    const updatedState = { ...prevState };
    Object.keys(updatedState).forEach(key => {
        updatedState[key] = { ...updatedState[key], loading: isLoading };
    });
    return updatedState;
};

// Initial dashboard state
const initialDashboardState = {
    callAnalysisMetrics: { loading: true, data: {} },
    totalCost: { loading: true, data: {} },
    avgCallMinutesByIssueType: { loading: true, data: [] },
    assistantPerformance: { loading: true, data: [] },
    phoneNumberPerformance: { loading: true, data: {} },
};

const Overview = () => {
    const [assistantList, setAssistantList] = useState([])
    const [assistant, setAssistant] = useState("All")
    const [filter, setFilter] = useState("monthly");
    const [organization, setOrganization] = useState({});
    const { company_id } = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [dashboardData, setDashboardData] = useState(initialDashboardState);
    const [phones, setPhones] = useState([]);

    const token = JSON.parse(localStorage.getItem("token"))?.token;
    const [openAssistantTable, setOpenAssistantTable] = useState(false);
    const [openPhoneNumberTable, setPhoneNumberTable] = useState(false);


    const location = useLocation();
    const isFirstSignup = new URLSearchParams(location.search).get('isFirstSignup');
    const { isPasswordSet, isGoogleSignup } = JSON.parse(localStorage.getItem("User"));
    const [openSetPasswordModel, setOpenPasswordModel] = useState(false)

    const getAllAssistants = useCallback(async () => {
        try {

            const response = await api.post(`/organizations/assistants-by-company`,
                {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );
            setAssistantList(response.data.data);
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    }, [token]);

    const getOverview = useCallback(async () => {
        try {
            const params = {
                filter: filter
            };
            if (assistant !== 'All') {
                params.assistantId = assistant;
            }

            setDashboardData(prevState => setAllLoading(prevState, true));

            const response = await api.post(`/organizations/overview`,
                {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                    params
                }
            );

            setDashboardData({
                callAnalysisMetrics: { loading: false, data: response.data.data.callAnalysisMetrics },
                totalCost: { loading: false, data: response.data.data.totalCost },
                avgCallMinutesByIssueType: { loading: false, data: response.data.data.avgCallMinutesByIssueType },
                assistantPerformance: { loading: false, data: response.data.data.assistantPerformance },
                phoneNumberPerformance: { loading: false, data: response.data.data.phoneNumberPerformance },
            });

            setTotalRows(response.data.totalRows || 0);
            setTotalPages(response.data.totalPages || 0);
        } catch (error) {
            console.error("Error fetching overview:", error);
            setDashboardData(prevState => setAllLoading(prevState, false));
        }
    }, [assistant, filter, token]);

    const getOrganization = useCallback(async () => {
        try {
            const response = await api.get(`/organizations/${company_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            });
            setOrganization(response.data);
        } catch (error) {
            console.log(error);
        }
    }, [company_id, token]);

    const getAllPhoneNumbers = useCallback(async () => {
        try {
            const response = await api.post(`/organizations/phones-by-company?limit=999`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

            setPhones(response.data)
        } catch (error) {
            console.log(error);
        }
    }, [token])

    const getAllDemoAssistants = useCallback(async () => {
        try {
            const response = await api.post(`/assistants/demo-assistants?limit=999`, {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );

            setAssistantList(response.data.data?.demoAssistants);
        } catch (error) {
            console.log(error);
        }
    }, [token]);

    useEffect(() => {
        if (isFirstSignup && isGoogleSignup && !isPasswordSet) {
            setOpenPasswordModel(true);
        }
        if (token) {
            getOrganization();
            getOverview();
            getAllPhoneNumbers();
        }
    }, [token, getOrganization, getOverview, getAllPhoneNumbers]);

    useEffect(() => {
        if (organization?.ShowDemo === true) {
            getAllDemoAssistants();
        } else if (organization?.ShowDemo === false) {
            getAllAssistants();
        }
    }, [token, getOrganization, getAllAssistants, getAllDemoAssistants, organization?.ShowDemo]);

    return (
        <>
            <Navbar title={`Overview | ${organization?.Name}`} />

            <Box sx={{ padding: "1.5rem" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1.8rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="h4">Dashboard Summary</Typography>

                        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <FormControl size='small' sx={{ width: "12rem", bgcolor: "#FFF", height: 'fit-content' }}>
                                <InputLabel id="assistant">Assistants</InputLabel>
                                <Select
                                    labelId="Assistant"
                                    id="assistant"
                                    value={assistant}
                                    label="Assistant"
                                    onChange={(e) => setAssistant(e.target.value)}
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {assistantList.map((assistant) => (
                                        <MenuItem value={assistant._id} key={assistant._id}>{assistant.Name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                            <FormControl size='small' sx={{ width: "8rem", bgcolor: "#FFF", height: 'fit-content' }}>
                                <InputLabel id="filter"><FilterList /></InputLabel>
                                <Select
                                    labelId="filter"
                                    id="filter"
                                    value={filter}
                                    label="Filter"
                                    onChange={(e) => setFilter(e.target.value)}
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="weekly">Weekly</MenuItem>
                                    <MenuItem value="monthly">Monthly</MenuItem>
                                    <MenuItem value="yearly">Yearly</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                    </Box>

                    <Grid container spacing={2}>


                        {/* Assistant Section */}

                        <Grid item xs={12} md={12} lg={6}>
                            <Box sx={{ 
                                display: "flex", 
                                flexDirection:'column',
                                alignItems: "center",  
                                bgcolor: 'white', 
                                borderWidth: 1, 
                                borderStyle: 'solid', 
                                borderColor: '#E5E7EB', 
                                borderRadius: '16px'
                            }}>
                                <Box sx={{ p: 4, width:'100%'}}>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                        <Box sx={{display:'flex', flexDirection:'row', gap:2,justifyItems:'center', alignItems:'center' }}>
                                            <AnalyticsOutlined sx={{ fontSize: "2rem" }} />
                                            <Box>
                                                <Typography variant="h6">Assistant Performance</Typography>
                                                <Typography sx={{ fontSize: ".8rem" }} color="#6A6A6A">
                                                    Overview of each assistant's call count, duration, and cost.
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <IconButton onClick={() => setOpenAssistantTable(!openAssistantTable)}>
                                                {openAssistantTable ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                            <MoreVert sx={{ cursor: "pointer" }} onClick={(e) => setAnchorEl(e.currentTarget)} />
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={() => setAnchorEl(null)}
                                                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                                            >
                                                <MenuItem onClick={() => {
                                                    toast.promise(downloadAssistantPerformanceCSV({ filter, assistant, organization: company_id }), {
                                                        loading: "Downloading CSV File",
                                                        success: "CSV File Downloaded Successfully",
                                                        error: "Failed to Download CSV File"
                                                    });
                                                    setAnchorEl(null);
                                                }}>
                                                    Download CSV
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    </Box>
                                </Box>
                                <Collapse in={openAssistantTable}>

                                                {/* Table */}
                                    <Box sx={{ width: '100%', mb: 2,pr:3,pl:3 }}>
                                        <TableContainer sx={{ maxHeight: 500, width: '100%' }}>
                                            <Table 
                                                stickyHeader 
                                                aria-label="sticky table"
                                                sx={{ width: '100%', tableLayout: 'fixed' }}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell scope="col" sx={{ width: '25%' }}>Assistant</TableCell>
                                                        <TableCell scope="col" sx={{ width: '25%' }}>Total Calls</TableCell>
                                                        <TableCell scope="col" sx={{ width: '25%' }}>Avg. Duration</TableCell>
                                                        <TableCell scope="col" sx={{ width: '25%' }}>Total Cost ($)</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody sx={{ overflow: 'hidden' }}>
                                                    {dashboardData.assistantPerformance.loading ? (
                                                        <TableRow>
                                                            <TableCell colSpan={4}>
                                                                <ShimmerLoader height="100px" />
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : dashboardData.assistantPerformance.data?.length > 0 ? (
                                                        dashboardData.assistantPerformance.data.map((performance) => (
                                                            <TableRow 
                                                                key={performance.assistantName} 
                                                                sx={{
                                                                    '&:hover': { backgroundColor: '#f9f9f9', cursor: 'pointer' },
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                <TableCell sx={{ width: '25%' }}>{performance.assistantName}</TableCell>
                                                                <TableCell sx={{ width: '25%' }}>{performance.totalCalls}</TableCell>
                                                                <TableCell sx={{ width: '25%' }}>{performance.averageDuration}</TableCell>
                                                                <TableCell sx={{ width: '25%' }}>{performance.totalCost}</TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={4} sx={{ textAlign: 'center', py: 2 }}>
                                                                Assistant performance metrics will appear here once the call is made.
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                            <Box
                                                sx={{
                                                    position: 'sticky',
                                                    bottom: 0,
                                                    backgroundColor: 'white',
                                                    borderTop: '1px solid #ccc',
                                                    zIndex: 1,
                                                    width: '100%'
                                                }}
                                                >
                                                <TablePagination
                                                    component="div"
                                                    count={totalRows}
                                                    page={page}
                                                    onPageChange={(e, newPage) => setPage(newPage)}
                                                    rowsPerPage={rowsPerPage}
                                                    onRowsPerPageChange={(e) => {
                                                        setRowsPerPage(Number.parseInt(e.target.value, 10));
                                                        setPage(0);
                                                    }}
                                                    sx={{ width: '100%' }}
                                                />
                                            </Box>
                                        </TableContainer>
                                    </Box>


                                </Collapse>
                            </Box>
                        </Grid>



                        {/* Phone Numbers Section */}

                        <Grid item xs={12} md={12} lg={6}>

                            <Box sx={{ 
                                display: "flex", 
                                flexDirection:'column',
                                alignItems: "center",  
                                bgcolor: 'white', 
                                borderWidth: 1, 
                                borderStyle: 'solid', 
                                borderColor: '#E5E7EB', 
                                borderRadius: '16px'
                            }}>

                                <Box sx={{ p: 4, width:'100%'}}>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                        <Box sx={{display:'flex', flexDirection:'row', gap:2,justifyItems:'center', alignItems:'center' }}>
                                            <AnalyticsOutlined sx={{ fontSize: "2rem" }} />
                                            <Box>
                                                <Typography variant="h6">Phone Numbers</Typography>
                                                <Typography sx={{ fontSize: ".8rem" }} color="#6A6A6A">
                                                    See the performance of your assistant and the metrics.
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <IconButton onClick={() => setPhoneNumberTable(!openPhoneNumberTable)}>
                                                {openPhoneNumberTable ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Collapse in={openPhoneNumberTable}>

                                        {/* Table */}
                                        <Box sx={{ width: '100%',mb:-1, mt: 3}}>
                                            <TableContainer sx={{ maxHeight: 500, width: '100%' }}>
                                                <Table 
                                                    stickyHeader 
                                                    aria-label="sticky table"
                                                    sx={{ width: '100%', tableLayout: 'fixed' }}
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ width: '25%' }}>Phone Number</TableCell>
                                                            <TableCell sx={{ width: '25%' }}>Total Calls</TableCell>
                                                            <TableCell sx={{ width: '25%' }}>Avg. Duration</TableCell>
                                                            <TableCell sx={{ width: '25%' }}>Total Cost</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody sx={{ overflow: 'hidden' }}>
                                                        {dashboardData.phoneNumberPerformance.loading ? (
                                                            <TableRow>
                                                                <TableCell colSpan={4}>
                                                                    <ShimmerLoader height="100px" />
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : dashboardData.phoneNumberPerformance.data?.result?.length > 0 ? 
                                                            dashboardData.phoneNumberPerformance.data.result.map((phone) => (
                                                                <TableRow 
                                                                    key={phone.PhoneNumber}
                                                                    sx={{
                                                                        '&:hover': { backgroundColor: '#f9f9f9', cursor: 'pointer' },
                                                                        width: '100%'
                                                                    }}
                                                                >
                                                                    <TableCell sx={{ width: '25%' }}>{phone.PhoneNumber}</TableCell>
                                                                    <TableCell sx={{ width: '25%' }}>{phone.totalCalls}</TableCell>
                                                                    <TableCell sx={{ width: '25%' }}>{phone.averageDuration}</TableCell>
                                                                    <TableCell sx={{ width: '25%' }}>{phone.totalCost}</TableCell>
                                                                </TableRow>
                                                            )) : (
                                                                <TableRow>
                                                                    <TableCell colSpan={4} sx={{ textAlign: 'center', py: 2 }}>
                                                                        There are no phone numbers assigned at the moment.
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                    </TableBody>
                                                </Table>
                                                <Box
                                                    sx={{
                                                        position: 'sticky',
                                                        bottom: 0,
                                                        backgroundColor: 'white',
                                                        borderTop: '1px solid #ccc',
                                                        zIndex: 1,
                                                        width: '100%'
                                                    }}
                                                >
                                                    <TablePagination
                                                        component="div"
                                                        count={totalRows}
                                                        page={page}
                                                        onPageChange={(e, newpage) => setPage(newpage)}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={(e) => { 
                                                            setRowsPerPage(Number.parseInt(e.target.value, 10));
                                                            setPage(0) 
                                                        }}
                                                        sx={{ width: '100%' }}
                                                    />
                                                </Box>
                                            </TableContainer>
                                        </Box>
                                    </Collapse>


                                </Box>
                            </Box>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={6}>
                            <ChartContainer
                                title="Call Analysis"
                                subtitle="Insights on call performance on various factors."
                                download={() => downloadCallAnalysisReport({ filter, organization: company_id })}
                                chart={
                                    dashboardData.callAnalysisMetrics.loading ? (
                                        <ShimmerLoader height="300px" />
                                    ) : Object.values(dashboardData.callAnalysisMetrics.data ?? {}).some(Boolean) ? (
                                        <CustomRadarChart data={[
                                            { name: "Call Transferred", value: dashboardData.callAnalysisMetrics.data?.transferedResult || 0 },
                                            { name: "Goal Achievement", value: dashboardData.callAnalysisMetrics.data?.goalAchievementResult || 0 },
                                            { name: "User Satisfaction", value: dashboardData.callAnalysisMetrics.data?.userSatisfactionResult || 0 },
                                            { name: "SOP Adherence (%)", value: dashboardData.callAnalysisMetrics.data?.scriptAdherenceResult || 0 },
                                            { name: "Call Abandonment", value: (dashboardData.callAnalysisMetrics.data?.callAbandonmentResult || 0).toFixed(2) },
                                        ]}
                                        />
                                    ) : (
                                        "No data found for applied filters."
                                    )
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <ChartContainer
                                title="Total Spent"
                                download={() => downloadTotalSpentCSV({ filter, assistant, organization: company_id })}
                                chart={
                                    dashboardData.totalCost.loading ? (
                                        <ShimmerLoader height="300px" />
                                    ) : dashboardData.totalCost.data?.dataPoints?.length ? (
                                        <AreaChart data={dashboardData.totalCost.data.dataPoints} >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis
                                                dataKey="date"
                                                tickFormatter={formatDate}
                                                interval="preserveStartEnd"
                                                minTickGap={50}
                                                angle={-45}
                                                textAnchor="end"
                                                height={80}
                                            />
                                            <YAxis
                                                tickFormatter={(value) => (value)}
                                            />
                                            <Tooltip
                                                labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                                formatter={(value) => [`$ ${value}`, "Total Cost"]}
                                            />
                                            <Area type="monotone" dataKey="totalCost" stroke="#02042D" fill="#A5A8E6" fillOpacity={0.3} />
                                        </AreaChart>
                                    ) : (
                                        "Your call cost metrics will appear here once the call is made."
                                    )
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <ChartContainer
                                title={"Average Handle Time by Issue Type"}
                                subtitle="Average resolution time per issue type."
                                download={() => downloadAvgDurationByIssueTypeCSV({ filter, assistant, organization: company_id })}
                                chart={
                                    dashboardData.avgCallMinutesByIssueType.loading ? (
                                        <ShimmerLoader height="300px" />
                                    ) : dashboardData.avgCallMinutesByIssueType.data?.length ? (
                                        <BarChart data={dashboardData.avgCallMinutesByIssueType.data} width={500} height={300}>
                                            <CartesianGrid stroke="#f5f5f5" />
                                            <XAxis
                                                dataKey="issueType"
                                                fontSize={10}
                                                tick={<CustomTick width={400 / dashboardData.avgCallMinutesByIssueType.data.length} />}
                                            />
                                            <YAxis
                                                fontSize={10}
                                                tick={<CustomTick width={dashboardData.avgCallMinutes / 3600} />}
                                            >
                                                <Label value="Average Handle Time" angle={-90} position="insideLeft" style={{ textAnchor: "middle" }} />
                                            </YAxis>
                                            <Tooltip
                                                formatter={(value, name) => {
                                                    const roundedValue = Math.round(value);
                                                    const hours = Math.floor(roundedValue / 3600);
                                                    const minutes = Math.floor((roundedValue % 3600) / 60);
                                                    const seconds = roundedValue % 60;

                                                    // Return formatted time as hh:mm:ss or h:mm:ss
                                                    return [`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`, "Average Duration"];

                                                }}
                                                cursor={{ fill: "transparent" }}
                                            />
                                            <Bar dataKey="avgDuration" fill="#02042D" />
                                        </BarChart>
                                    ) : (
                                        "No data found for applied filters."
                                    )
                                }
                            />

                        </Grid>
                    </Grid>
                </Box>
            </Box >

            <SetPassword open={openSetPasswordModel} onClose={() => setOpenPasswordModel(false)} />
        </>
    )
}

export default Overview;
