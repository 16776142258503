import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { AdminRoutes } from "../admin.route";
import { SuperAdminRoutes } from "../superadmin.route";
import Member from "../pages/admin/Member.admin";
import Profile from "../pages/Profile";
import APIKeyManagement from "../pages/APIKeyManagement";
import Pricing from "../pages/admin/Pricing.admin";
import ManageBilling from "../pages/ManageBilling";
import ViewAssistantTemplate from "../pages/ViewAssistantTemplate";
import CreateAssistantFromTemplate from "../pages/createAssistantFromTemplate";
import BuyCallingMinutes from "../pages/BuyCallingMinutes";
import { useGetAllPermissions } from "../pages/utils/getAllPermissions";
import Loader from "../components/Loader";
import OrganizationSettings from "../pages/OrganizationSettings";

const AllRoutes = ({ userRole }) => {
  const { permissions, permissionsLoading, getAllPermissions } = useGetAllPermissions();

  useEffect(() => {
    getAllPermissions();
  }, [getAllPermissions]);

  // Early return to show loader while permissions are permissionsLoading
  if (permissionsLoading) {
    return <Loader/>;
  }

  // Conditional Routes based on userRole and permissions
  const isAdminOrUser = userRole === "Admin" || userRole === "User";
  const isSuperAdmin = userRole === "SuperAdmin";
  const canManageBilling = permissions?.includes("manage_billing");

  return (
    <Routes>
      {/* Role-based Routes */}
      {isAdminOrUser && <Route path="/*" element={<AdminRoutes />} />}
      {isSuperAdmin && <Route path="/*" element={<SuperAdminRoutes />} />}

      {/* General Routes */}
      <Route path="/organisation-settings" element={<OrganizationSettings />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/api-key-management" element={<APIKeyManagement />} />
      <Route path="/company/:company_id/pricing" element={<Pricing />} />
      <Route path="/company/:company_id/pricing/subscription-success" element={<Pricing />} />
      <Route path="/assistant/template/:id" element={<ViewAssistantTemplate />} />
      <Route path="/assistant/create/template/:template_id" element={<CreateAssistantFromTemplate />} />
      <Route path="/company/:company_id/member" element={<Member />} />
      {/* Protected Routes based on permissions */}
      {canManageBilling && (
        <>
          <Route path="/manage-billing" element={<ManageBilling />} />
          <Route path="/buy-calling-minutes" element={<BuyCallingMinutes />} />
        </>
      )}
    </Routes>
  );
};

export default AllRoutes;