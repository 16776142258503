import { useState, useEffect } from 'react';
import api from "../api";
import { toast } from 'sonner';

const useOutboundSettings = (data, updatePhone) => {
    const [outbound, setOutbound] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (data) {
            setOutbound(data?.OutboundAssistantId || '');
        }
    }, [data]);

    const handleAssistantChange = (event) => {
        setOutbound(event.target.value);
    };

    const updateOutboundSettings = async () => {
        setLoading(true);
        setError(null);
        try {
            if (!outbound) {
                toast.error("Please select an assistant first");
                setError("No assistant is Selected");
                return;
            }
            
            const token = JSON.parse(localStorage.getItem("token"))?.token;
            if (!token) {
                setError("No token found");
                throw new Error("Token not found");
            }
            
            if (!data?._id) {
                setError("Invalid phone ID");
                toast.error("Cannot update outbound without a valid phone ID");
                return;
            }
    
            await api.put(`/phones/${data?._id}`, {
                OutboundAssistantId: outbound,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            
            toast.success("Outbound assistant updated successfully");
            
            if (typeof updatePhone === 'function') {
                updatePhone("outbound", false);
            }
    
        } catch (error) {
            const errorMessage = error.response?.status === 401 
                ? "Unauthorized access" 
                : error.response?.status === 404 
                    ? "Resource not found"
                    : error.message || "Unknown error occurred";
            setError(errorMessage);
            console.error("Error updating outbound settings:", error);
            toast.error(`Failed to update outbound settings: ${errorMessage}`);
        }
     finally {
        setLoading(false);
     }
    };

    return {
        outbound,
        handleAssistantChange,
        updateOutboundSettings,
        loading,
        error
    };
};

export default useOutboundSettings;
