import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Navbar from '../components/Navbar';
import CustomDomain from '../components/OrganisationSettings/DomainContainer';
import EnhancedLogoUpload from '../components/OrganisationSettings/ImageUpload';
import validateText from './utils/TestValidationWithNums';
import { fetchConfig } from './utils/fetchConfig';
import { toast } from 'sonner';


const boxColumn = {
  width: "100%",
  height:'auto',
  px: { xs: 2, sm: 4 },
  py: { xs: 2, sm: 4 },
  bgcolor: "#ffffff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  borderColor: "#E5E7EB",
  borderWidth: "1px",
  borderRadius: "12px",
  borderStyle: "solid",
  gap: 2,
};

const OrganizationSettings = () => {

  const [maxFileSize, setMaxFileSize] = useState(3); // Default 3MB
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState('');
  const [token, setToken] = useState("");
  

  //Fetching Logo Size
  const CONFIG = [
    'max_logo_size'
  ];

  const getDefaultConfig = async () => {
    try {
        await fetchConfig(CONFIG, ({ data }) => {
           setMaxFileSize(data?.max_logo_size ?? 3);
        });
    } catch (error) {
        console.error("Failed to load configuration. Using default values.");
    }
};

const getToken = async () => {
  const tokenData = localStorage.getItem("token");
  if (tokenData) {
      const t = JSON.parse(tokenData).token;
      setToken(t);
  } else {
      console.error("Token not found in local storage");
  }
};

useEffect(() => {
        getToken();
        getDefaultConfig();
    }, []);

  // Form states
  const [formData, setFormData] = useState({
    organizationName: '',
    customDomain: '',
    logo: null
  });

  // Domain validation states
  const [domainValidation, setDomainValidation] = useState({
    minLength: false,
    validTLD: false,
    noHyphenEnds: true,
    hasDot: false
  });

  // Fetch data from localStorage when component mounts
  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem("organizationFormData"));
    const savedLogo = localStorage.getItem("organizationLogoPreview");

    if (savedFormData) {
      setFormData(savedFormData);
    }

    if (savedLogo) {
      setPreviewUrl(savedLogo); // Set preview URL here
    }

    const savedDomainValidation = JSON.parse(localStorage.getItem("domainValidation"));
    if (savedDomainValidation) {
      setDomainValidation(savedDomainValidation);
    }
  }, []);

  // Store the form data and domain validation in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("organizationFormData", JSON.stringify(formData));
    localStorage.setItem("domainValidation", JSON.stringify(domainValidation));
    console.log("Saved to Local Storage:", formData);
  }, [formData, domainValidation]);

  
  // Handle logo change
  
  const handleLogoChange = ({ file, previewUrl }) => {
    if (file && previewUrl) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        localStorage.setItem("organizationLogo", reader.result); // Save in local storage
        localStorage.setItem("organizationLogoPreview", previewUrl); // Save preview URL
      };
      setFormData(prev => ({
        ...prev,
        logo: file
      }));
      setPreviewUrl(previewUrl);
    } else {
      setFormData(prev => ({
        ...prev,
        logo: null
      }));
      setPreviewUrl(null); // Clear the preview when there's no logo
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'customDomain') {
      validateDomain(value);
    }
     else if (name === 'organizationName') {
    const { isValid, message } = validateText(value, {
      minLength: 3,
      maxLength: 50,
      allowSpecialChars: true,
      allowSpaces: true,
    });
    setError(isValid ? '' : message);  
    setFieldErrors(prev => ({
      ...prev,
      organizationName: message || '' // If no error, set to empty
    }));
  }
};

  //Set Field Errors
  const [fieldErrors, setFieldErrors] = useState({
    organizationName: '',
    customDomain: ''
  });

  // Validate domain
  const validateDomain = (domain) => {
    const validation = {
      minLength: domain.length >= 3,
      validTLD: /\.(com|org|net)$/.test(domain),
      noHyphenEnds: !domain.startsWith('-') && !domain.endsWith('-'),
      hasDot: domain.includes('.')
    };

    setDomainValidation(validation);
    localStorage.setItem("domainValidation", JSON.stringify(validation)); // Save validation status to local storage
    let errorMessage = '';
    if (!validation.minLength) errorMessage = 'Domain must be at least 3 characters long.';
    else if (!validation.validTLD) errorMessage = 'Invalid domain TLD. Only .com, .org, or .net are allowed.';
    else if (validation.noHyphenEnds && !validation.hasDot) errorMessage = 'Domain should contain a dot (.)';
    
    setFieldErrors(prev => ({
      ...prev,
      customDomain: errorMessage || '' // If no error, set to empty
    }));
  };

  // Handle save changes
  const handleSaveChanges = () => {
    setOpenConfirmDialog(true);
  };

  // Handle confirm and save
  const handleConfirmSave = () => {
    setOpenConfirmDialog(false);
    toast.success("Changes Saved Successfully", {
        position: "top-right",
        duration: 4000, // Customize duration
    });
};

  return (
    <>
    <Navbar title=" Organization Settings" />
    {/* Main Content */}
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        p: { xs: 2, sm: 4 },
        gap: 2,
      }}
    >
      {/* Save Changes Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end',alignContent:'center', width: '100%', gap: 2 }}>
      <Button
        onClick={handleSaveChanges}
        variant="contained"
        sx={{
          backgroundColor: '#000',
          '&:hover': { backgroundColor: '#333' },
        }}
        disabled={ fieldErrors.organizationName || fieldErrors.customDomain}
      >
        Save Changes
      </Button>
      </Box>

    {/* Logo and Brand Name Section */}

    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: 2, }}>
     
     {/*  Logo Section */}
      
     <EnhancedLogoUpload
      previewUrl={previewUrl}
      onLogoChange={handleLogoChange}
      maxFileSize={maxFileSize}
      boxColumn={boxColumn} // Your existing box styling
      recommended="256x256px or larger, PNG or JPEG"
    />
    

     {/* Brand Name Section */}
      <Box
          sx={boxColumn}
      >
         {/* Title and Subtitle */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>  
        
        <Typography variant="h6">
          Brand Identity
        </Typography>

        <Typography sx={{fontSize:'1rem', color:'#7C7C83', fontWeight:'500'}}>
          Set your organization's name and identity
        </Typography>
        </Box>
          
        {/* Text Field */}
        <Box sx={{
          width:'100%',
          height:100,
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          justifyContent:'center'
        }}>
         <TextField
              fullWidth
              label="Organization Name"
              name="organizationName"
              value={formData.organizationName}
              onChange={handleInputChange}
              required
              helperText={error || "This name will be displayed across your organization's profile and communications."}
              FormHelperTextProps={{
                sx: {
                  fontSize: "0.75rem",
                  color: error ? "#FF4D4D" : "#A1A1B0", // Red for error, gray for normal text
                  fontWeight: "500",
                  margin: 0,
                  mt: '0.5',
                },
              }}
              error={!!error} // Show error styling if there is an error
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E5E7EB",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E5E7EB",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E5E7EB",
                  borderWidth: "2px",
                },
                "& .MuiInputLabel-root": {
                  color: "#7C7C83",
                  fontWeight: "500",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#02042D",
                  backgroundColor: '#FFFFFF',
                },
              }}
            />
            
          </Box>
    </Box>
    </Box>

    {/* Custom Domain Validation Form */}
    <CustomDomain 
        domainValidation={domainValidation} 
        handleInputChange={handleInputChange} 
        formData={formData} 
    />
      {/* Confirm Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
            sx: {
            padding: 1, // Add padding inside the dialog
            borderRadius: 3, // Adjust border radius
            },
        }}>
          <IconButton
            aria-label="close"
            onClick={() => setOpenConfirmDialog(false)}
            sx={{ position: 'absolute', right: 12, top: 12 }}
          >
            <CloseIcon />
          </IconButton>
        <DialogContent>

        <Typography variant="h5" sx={{mb:1}}>
                Confirm Changes
        </Typography>
        <Typography sx={{fontSize:'1rem', color:'#7C7C83', fontWeight:'500', mb:2}}>
        Please review your changes before saving:
        </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              border: '1px solid #E0E0E0',
              borderRadius: 2,
              p: 2,
              backgroundColor: '#F9FAFB',
            }}
          >
            {/* Logo */}
            {formData.logo ? (
                <Box
                  component="img"
                  src={previewUrl} // Use previewUrl here
                  alt="Organization Logo"
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: '8px',
                    backgroundColor: '#E0E0E0',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: '8px',
                    backgroundColor: '#E0E0E0',
                  }}
                />
              )}

            {/* Organization Info */}
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                {formData.organizationName || 'Organization Name'}
              </Typography>
              <Typography variant="body2" sx={{ color: '#757575' }}>
                {formData.customDomain || 'CustomDomain.com'}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ gap:2,justifyContent: 'flex-end', p: 2 }}>
          <Button
            onClick={() => setOpenConfirmDialog(false)}
            variant="outlined"
            sx={{
              color: '#757575',
              borderColor: '#E0E0E0',
              '&:hover': { borderColor: '#BDBDBD', backgroundColor: '#F5F5F5' },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmSave}
            variant="contained"
            sx={{
              backgroundColor: '#000',
              '&:hover': { backgroundColor: '#333' },
            }}
          >
            Confirm and Save
          </Button>
        </DialogActions>
    </Dialog>

    </Box>
    </>
  );
};

export default OrganizationSettings;