import React, { useState,useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  FormControl,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import { fetchConfig } from '../../pages/utils/fetchConfig';
const CONFIG = [
  'default_country_code'
];

const PhoneCall = ({ avatar, name }) => {
  const [selectedNumber, setSelectedNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [defaultCountryCode, setDefaultCountryCode] = useState('in');
  const token = JSON.parse(localStorage.getItem("token")).token;
  const getDefaultConfig = async () => {
          try {
              await fetchConfig(CONFIG, ({ data }) => {
                setDefaultCountryCode(data?.default_country_code ?? 'in');
              });
          } catch (error) {
              console.error("Failed to load configuration. Using default values.");
          }
      };

    useEffect(() => {
      getDefaultConfig();
    }, [token]);
  const handleNumberChange = (event) => {
    setSelectedNumber(event.target.value);
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setError(value ? '' : 'Please enter a valid phone number');
  };

  return (
    <Box 
      sx={{
        position: 'relative',
        p: 1,
        maxWidth: 420,
        width: '100%',
        mx: 'auto',
        height: '100%',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mt: '1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography 
          sx={{ 
            textAlign: 'left',
            fontWeight: 600,
            fontSize: '1rem',
            color: 'text.primary',
          }}
        >
          Call Details
        </Typography>
        
        <FormControl fullWidth>
          <TextField 
            select
            value={selectedNumber} 
            onChange={handleNumberChange} 
            label="Select a Number"
            variant="outlined"
          >
            {/* <MenuItem value="number1">Number 1</MenuItem>
            <MenuItem value="number2">Number 2</MenuItem>
            <MenuItem value="number3">Number 3</MenuItem> */}
          </TextField>
        </FormControl>
      </Box>
      <Box>
        <Typography 
          variant="subtitle1" 
          sx={{ 
            mb: 1,
            fontWeight: 400,
            fontSize: '0.875rem',
            color: 'text.primary',
          }}
        >
          Enter Phone Number
        </Typography>
        
        <PhoneInput
          country={defaultCountryCode}
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          inputStyle={{
            width: '100%',
            height: '2.75rem',
            fontSize: '1rem',
            paddingLeft: '3rem',
            borderRadius: '8px',
            border: '1px solid #c4c4c4'
          }}
          buttonStyle={{
            border: '1px solid #c4c4c4',
            borderRadius: '8px 0 0 8px',
          }}
          containerStyle={{
            width: '100%',
          }}
        />
        {error && (
          <Typography 
            color="error" 
            sx={{ 
              fontSize: '0.875rem',
              mt: 1
            }}
          >
            {error}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          backdropFilter: 'blur(10px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
          zIndex: 10,
        }}
      >
        <Typography
          sx={{
            fontSize: '1.75rem',
            fontWeight: 'bold',
            color: 'text.primary',
            p: 2,
          }}
        >
          🚀 Coming Soon
        </Typography>
      </Box>

    </Box>
  );
};

export default PhoneCall;