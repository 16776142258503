import { FaRupeeSign, FaDollarSign } from 'react-icons/fa';
const CURRENCIES = {
    USD: {
      code: 'USD',
      symbol: '$',
      label: 'USD ($)',
      icon: FaDollarSign,
    },
    INR: {
      code: 'INR',
      symbol: '₹',
      label: 'INR (₹)',
      icon: FaRupeeSign,
    },
  };
  
  export default CURRENCIES;
  