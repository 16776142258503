import React, { useCallback, useEffect, useState } from 'react'
import Navbar from "../../components/Navbar"
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Pagination, Select, TextField, Typography } from '@mui/material';
import CallCard from "../../components/CallCard ";
import api from "../../api";
import { useParams } from 'react-router-dom';
import NoDataFound from '../../components/NoDataFound';
import Loader from '../../components/Loader';
import { Add, Close } from '@mui/icons-material';
import { toast } from 'sonner';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString, isPossiblePhoneNumber } from "libphonenumber-js";
import { useGetAllPermissions } from '../utils/getAllPermissions';

const PhoneNumber = () => {
    const [ PhoneNumberList, setPhoneNumberList ] = useState([]);
    const token = JSON.parse(localStorage.getItem("token")).token;
    const OrganizationId = localStorage.getItem("Organization");
    const { company_id } = useParams();
    const [ loading, setLoading ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ phoneProviders, setPhoneProviders ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(0);
    const [ currentPage, setCurrentPage ] = useState(0);
    const [error, setError] = useState(""); // Error message state
    const { permissions, permissionsLoading, getAllPermissions } = useGetAllPermissions();

    
    const [ formValues, setFormValues ] = useState({
        PhoneNumber: '',
        ApiCredentials: '',
        Provider: '',
        Label: ''
    });


    const getAllPhoneNumbers = async () => {
        setLoading(true);
        try {
            const response = await api.post(`/organizations/phones-by-company`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

            setPhoneNumberList(response.data.data);
            setTotalPages(response.data.totalPages);
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const getAllPhoneProviders = useCallback(async () => {
        try {
            const response = await api.get(`/providers`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

            setPhoneProviders(response.data?.data?.providers);
        } catch (error) {
            console.log(error);
        }
    }, [ token ]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formValues.OrganizationId) {
            formValues.Organization = OrganizationId;
        }
        try {
            await api.post(`/phones/create-phone`, formValues, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            toast.success("Phone created successfully");
            setOpen(false);
            getAllPhoneNumbers();
        } catch (error) {
            toast.error('Error creating phone');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [ name ]: value });
    };

    const handlePhoneNumberInputChange = (value, country) => {
        if (!value) {
                    setError(`Please enter a phone number for ${country.name}.`);
                    return;
                }
        
                try {
                    const phoneNumber = parsePhoneNumberFromString(`+${value}`, country.countryCode.toUpperCase());
                    // Check if phone number is valid
                    if (!phoneNumber || !phoneNumber.isValid() || !isPossiblePhoneNumber(`+${value}`, country.countryCode.toUpperCase())) {
                        setError(`Invalid phone number format for ${country.name}.`);
                    } else {
                        setError(""); // Clear error if valid
                    }
                } catch (error) {
                    setError(`Invalid input. Please check the phone number for ${country.name}.`);
                }

        setFormValues({ ...formValues, PhoneNumber: value });
    };
    
    useEffect(() => {
        if (token) {
            getAllPermissions();
        }
    }, [ token ]);

    useEffect(() => {
        getAllPhoneNumbers();
        getAllPhoneProviders();
    }, [ permissions?.includes("fetch_phone") ])

    if (loading || permissionsLoading) {
        return <Loader />
    }

    return (
        <>
            < Navbar title="Phone Numbers" />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
                {
                   permissions?.includes("create_phone") &&
                    <Box sx={{ alignSelf: "flex-end" }}>
                        <Button sx={{ bgcolor: "#02042D", color: "#FFF" }} variant="contained" onClick={() => setOpen(true)}><Add /> Add Phone Number</Button>
                    </Box>
                }

                <Grid container spacing={2}>
                    {
                        permissions?.includes("fetch_phone") ? (
                            PhoneNumberList && PhoneNumberList.length > 0 ? (
                                PhoneNumberList.map((number) => (
                                    <Grid item xs={12} sm={6} md={4} lg={4} key={number.id}>
                                        <CallCard
                                            data={number}
                                            url={`/company/${company_id}/phone-number/${number._id}`}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <Box sx={{ display: 'flex', justifyContent: 'center', width: "100%", height: "90vh" }}>
                                    <NoDataFound
                                        title="Phone Numbers"
                                        subtitle="No phone numbers assigned to this company."
                                    />
                                </Box>
                            )
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', width: "100%", height: "90vh" }}>
                                <NoDataFound
                                    title="Phone Numbers"
                                    subtitle="You do not have permission to view phone numbers."
                                />
                            </Box>
                        )
                    }
                </Grid>

                {PhoneNumberList && PhoneNumberList.length > 0 && (
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(e, page) => {
                            setCurrentPage(page);
                        }}
                        color="#02042D"
                        style={{ alignSelf: "center" }}
                    />
                )}
            </Box>


            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={{ width: "100dvw", display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>

                    <Box sx={{ width: "30rem", display: "flex", flexDirection: "column", gap: "2rem", bgcolor: "#FFF", padding: "2rem", borderRadius: "8px" }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                <Typography variant="h6" color="text.secondary" sx={{ color: "#020429" }}>
                                    Add Phone Number
                                </Typography>

                            </Box>

                            <Close onClick={() => setOpen(false)} sx={{ cursor: "pointer" }} />

                        </Box>

                        <form onSubmit={handleSubmit} style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <FormControl fullWidth size="small">
                            <InputLabel>Provider</InputLabel>
                            <Select
                                label="Phone Provider"
                                name="Provider"
                                value={formValues.Provider}
                                onChange={handleInputChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 250,
                                        },
                                    },
                                }}
                                sx={{
                                    '.MuiSelect-select': {
                                        display: 'flex',
                                        alignItems: 'center',  // Ensures vertical alignment of the text
                                    },
                                    '.MuiInputBase-input': {
                                        padding: '10px', // Adjust padding as needed
                                        textAlign: 'left', // Align text to the left
                                    },
                                }}
                            >
                                {phoneProviders?.map((provider, index) => (
                                    <MenuItem key={index} value={provider._id}>
                                        {provider.ProviderName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            label="Label"
                            name="Label"
                            value={formValues.Label}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            size="small"
                            sx={{
                                '.MuiInputBase-input': {
                                    display: 'flex',
                                    alignItems: 'center',  // Ensures vertical alignment of the text
                                    padding: '10px', // Adjust padding as needed
                                    textAlign: 'left', // Align text to the left
                                },
                            }}
                        />

<Box sx={{
                                display:'flex',
                                flexDirection:'column',
                                gap:0.5,
                            }}>
                            <PhoneInput
                                country={"us"}
                                value={formValues.PhoneNumber}
                                onChange={(value, country) => handlePhoneNumberInputChange(value, country)}
                                enableSearch={true}
                                searchPlaceholder="Search countries"
                                inputStyle={{
                                    width: "100%",
                                    height: "40px",
                                    fontSize: "16px",
                                    paddingLeft: "48px",
                                }}
                                buttonStyle={{
                                    border: "1px solid #c4c4c4",
                                    borderRadius: "4px 0 0 4px",
                                }}
                                inputProps={{
                                    name: "PhoneNumber",
                                    required: true,
                                    label: "Phone Number",
                                    'aria-label': 'Phone Number',
                                    'aria-invalid': !!error,
                                    'aria-describedby': error ? 'phone-error' : undefined,
                                }}
                            />
                            {error && (
                                <p id="phone-error" role="alert" style={{ color: "red", marginTop: "0.5rem", fontSize: "14px" }}>
                                    {error}
                                </p>
                            )}
                            </Box>
                           
                            <TextField
                                label="API Credentials"
                                name="ApiCredentials"
                                value={formValues.ApiCredentials}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                size="small"
                                multiline
                                rows={5}
                                sx={{
                                    '.MuiInputBase-input': {
                                        display: 'flex',
                                        alignItems: 'center',  // Ensures vertical alignment of the text
                                        padding: '10px', // Adjust padding as needed
                                        textAlign: 'left', // Align text to the left
                                    },
                                }}
                            />

                            <Button
                            type="submit"
                            variant="contained"
                            sx={{ bgcolor: "#02042D", color: "white", marginTop: "1rem" }}
                            disabled={error || !formValues.PhoneNumber} // Disable on error or empty value
                        >
                            Submit
                        </Button>
                        </form>

                    </Box>

                </Box>
            </Modal>
        </>
    )
}

export default PhoneNumber;
