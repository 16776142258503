export const PROMPT_VALIDATION = {
    // Length constraints
    MINIMUM_INPUT_LENGTH: 10,
    MAXIMUM_INPUT_LENGTH: 500,
    
    // Patterns
    PATTERNS: {
      SPECIAL_CHARS: /[<>{}]/g,  // Disallow potentially harmful characters
      CONSECUTIVE_SPACES: /\s{3,}/g, // No more than 2 consecutive spaces
    },
    
    // Messages
    MESSAGES: {
      EMPTY: "Please provide context for your assistant",
      TOO_SHORT: (minLength) => `Your input must be at least ${minLength} characters long`,
      TOO_LONG: (maxLength) => `Your input exceeds the maximum length of ${maxLength} characters`,
      INVALID_CHARS: "Please avoid using special characters like < > { }",
      FORMATTING: "Please avoid excessive spacing",
    }
  };

  